import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { countries } from "../../Data/CountryList/Defaultdata";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ButtonLoader from '../ButtonLoader';
import { Nav, Tab } from "react-bootstrap";
import Select from 'react-select';
import { ChevronUp, ChevronDown, ChevronsUp } from "react-feather";
import { useSelector } from "react-redux";

const UserForm = ({ title, action, navigateUrl, cancel, preData, setAdd }) => {
    const [stepItem, setStepItem] = useState(1);
    const [data, setData] = useState(action === 'Edit' ? preData : '');
    const [loading, setLoading] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const { register, formState: { errors }, reset, getValues, handleSubmit, setValue } = useForm({ defaultValues: data });
    const [subscriptionList, setSubscriptionList] = useState();
    const [locationData, setLocationData] = useState([]);
    const [filteredItems, setFilteredItems] = useState();
    const [net0traceAnalyst, setNet0traceAnalyst] = useState(false);
    const [net0Approver, setNet0Approver] = useState(false);
    const [net0AnalystItems, setNet0AnalystItems] = useState([]);
    const [net0ApproverItems, setNet0ApproverItems] = useState([]);
    const [sustainabilityAdmin, setSustainabilityAdmin] = useState(false);
    const [sustainabilityAnalyst, setSustainabilityAnalyst] = useState(false);
    const [carbonAnalyst, setCarbonAnalyst] = useState(false);
    const [carbonAdmin, setCarbonAdmin] = useState(false);
    const [aqiAnalyst, setAqiAnalyst] = useState(false);
    const [aqiAdmin, setAqiAdmin] = useState(false);
    const [decarbAnalyst, setDecarbAnalyst] = useState(false);
    const [decarbAdmin, setDecarbAdmin] = useState(false);
    const [hvacAnalyst, setHvacAnalyst] = useState(false);
    const [hvacAdmin, setHvacAdmin] = useState(false);
    const [net0Admin, setNet0Admin] = useState(false);
    const [approverLocationExpand, setApproverLocationExpand] = useState(false);
    const [analystLocationExpand, setAnalystLocationExpand] = useState(false);
    const [net0ReportUser, setNet0ReportUser] = useState(false);
    const [sustainabilityReportUser, setSustainabilityReportUser] = useState(false);
    const [basicData, setBasicData] = useState();
    const userId = useParams()?.id;
    const appName = useSelector(state => state?.user?.appName);

    const getRoles = async () => {
        await fetchWrapper(`${API_URL}/subscriptions/customer/roles`)
            .then((res) => {
                console.log(res.json)
                setRoleList(res?.json)
            }, (err) => {
                console.log(err)
            })
    }

    const loadRolesLocationsData = async () => {
        await fetchWrapper(`${API_URL}/userModuleRoles/user/${userId}`)
            .then((res) => {
                console.log(res?.json)
                setCarbonAnalyst(res?.json?.some(ele => ele?.moduleId == 1 && ele?.role === 'ASO_USER'));
                setCarbonAdmin(res?.json?.some(ele => ele?.moduleId == 1 && ele?.role === 'ASO_ADMIN'));
                setNet0Admin(res?.json?.some((item) => item?.moduleId == 2 && item?.role === 'EMISSIONS_ADMIN'));
                setNet0Approver(res?.json?.some((item) => item?.moduleId == 2 && item?.role === 'EMISSIONS_APPROVER'));
                setNet0traceAnalyst(res?.json?.some((item) => item?.moduleId == 2 && item?.role === 'EMISSIONS_ANALYST'));
                setNet0ApproverItems(res?.json?.filter((item) => item?.moduleId == 2 && item?.role === 'EMISSIONS_APPROVER'));
                setNet0AnalystItems(res?.json?.filter((item) => item?.moduleId == 2 && item?.role === 'EMISSIONS_ANALYST'));
                setSustainabilityAnalyst(res?.json?.some((item) => item?.moduleId == 6 && item?.role === 'SUSTAINABILITY_ANALYST'));
                setSustainabilityAdmin(res?.json?.some((item) => item?.moduleId == 6 && item?.role === 'SUSTAINABILITY_ADMIN'));
                setDecarbAdmin(res?.json?.some(ele => ele?.moduleId == 3 && ele?.role === 'DECARB_ADMIN'));
                setDecarbAnalyst(res?.json?.some(ele => ele?.moduleId == 3 && ele?.role === 'DECARB_ANALYST'));
                setHvacAdmin(res?.json?.some(ele => ele?.moduleId == 4 && ele?.role === 'HVAC_ADMIN'));
                setHvacAnalyst(res?.json?.some(ele => ele?.moduleId == 4 && ele?.role === 'HVAC_ANALYST'));
                setAqiAdmin(res?.json?.some(ele => ele?.moduleId == 5 && ele?.role === 'AQI_ADMIN'));
                setAqiAnalyst(res?.json?.some(ele => ele?.moduleId == 5 && ele?.role === 'AQI_ANALYST'));
                setNet0ReportUser(res?.json?.some(ele => ele?.moduleId == 2 && ele?.role === 'EMISSIONS_REPORT_USER'));
                setSustainabilityReportUser(res?.json?.some(ele => ele?.moduleId == 6 && ele?.role === 'SUSTAINABILITY_REPORT_USER'));
            }, (err) => {
                console.log(err);
            })
    }

    const getLocations = async () => {
        await fetchWrapper(`${API_URL}/location`)
            .then((res) => {
                const data = res?.json?.map(ele => ({ value: ele?.locationId, label: ele?.locationName }))
                setLocationData(res?.json);
                setFilteredItems(res?.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getSubscription = async () => {
        await fetchWrapper(`${API_URL}/subscriptions/customer`)
            .then((res) => {
                setSubscriptionList(res?.json)
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getRoles()
        getSubscription()
        getLocations()
    }, [])

    const defaultUser = {
        ASO_ADMIN: 'ASO_USER',
        ASR_ADMIN: 'ASR_USER',
        PRP_ADMIN: 'PRP_USER'
    }

    const navigate = useNavigate();

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        reset(preData)
        loadRolesLocationsData()
    }, [preData])

    const handleNet0TraceAnalyst = (value, index, locationId) => {
        if (value === true) {
            let checkedLocation = locationData?.find(ele => ele?.locationId == locationId);
            let tempData = [...net0AnalystItems];
            tempData?.push(checkedLocation);
            setNet0AnalystItems(tempData);
        } else {
            setNet0AnalystItems(net0AnalystItems?.filter(item => item?.locationId != locationId))
        }
    }

    const handleNet0TraceApprover = (value, index, locationId) => {
        console.log(locationId, value)
        if (value === true) {
            let checkedLocation = locationData?.find(ele => ele?.locationId == locationId);
            let tempData = [...net0ApproverItems];
            tempData?.push(checkedLocation);
            setNet0ApproverItems(tempData);
        } else {
            setNet0ApproverItems(net0ApproverItems?.filter(item => item?.locationId != locationId))
        }
    }

    const handleFilter = (search) => {
        const filteredLocations = locationData.filter((location) => {
            const searchTerm = search?.toLowerCase();
            return (
                location.locationName?.toLowerCase().includes(searchTerm) ||
                location.addressLine1?.toLowerCase().includes(searchTerm) ||
                location.stateName?.toLowerCase().includes(searchTerm) ||
                location.stateCode?.toLowerCase().includes(searchTerm) ||
                location.countryName?.toLowerCase().includes(searchTerm) ||
                location.countryCode?.toLowerCase().includes(searchTerm) ||
                location.zipCode.includes(searchTerm) ||
                location.city?.toLowerCase().includes(searchTerm)
            );
        });

        console.log(filteredLocations);
        setFilteredItems(filteredLocations);
    }

    const getRolesandLocations = () => {
        let data = [];
        if (carbonAdmin || carbonAnalyst) {
            const carbonData = {
                moduleId: 1,
                rolesLocationsData: [
                    {
                        role: carbonAdmin ? 'ASO_ADMIN' : 'ASO_USER'
                    }
                ]
            }
            data.push(carbonData);
        }
        if (net0Admin) {
            const net0data = {
                moduleId: 2,
                rolesLocationsData: [
                    {
                        role: 'EMISSIONS_ADMIN'
                    }
                ]
            }
            data.push(net0data);
        } else if (net0Approver || net0traceAnalyst) {
            const net0data = {
                moduleId: 2,
                rolesLocationsData: [
                    ...(net0Approver === true ? [{
                        role: 'EMISSIONS_APPROVER',
                        locationsData: net0ApproverItems?.map((item) => ({ locationId: item?.locationId }))
                    }] : []),
                    ...(net0traceAnalyst === true ? [{
                        role: 'EMISSIONS_ANALYST',
                        locationsData: net0AnalystItems?.map((item) => ({ locationId: item?.locationId }))
                    }] : [])
                ]
            }
            data.push(net0data);
        } else if(net0ReportUser) {
            const net0data = {
                moduleId: 2,
                rolesLocationsData: [
                    {
                        role: 'EMISSIONS_REPORT_USER'
                    }
                ]
            }
            data.push(net0data);
        }
        if (sustainabilityAdmin || sustainabilityAnalyst || sustainabilityReportUser) {
            const sustainabilityData = {
                moduleId: 6,
                rolesLocationsData: [
                    {
                        role: sustainabilityAdmin ? 'SUSTAINABILITY_ADMIN' :  sustainabilityAnalyst ? 'SUSTAINABILITY_ANALYST' : 'SUSTAINABILITY_REPORT_USER'
                    }
                ]
            }
            data.push(sustainabilityData);
        }
        if (aqiAdmin || aqiAnalyst) {
            const aqiData = {
                moduleId: 5,
                rolesLocationsData: [
                    {
                        role: aqiAdmin ? 'AQI_ADMIN' : 'AQI_ANALYST'
                    }
                ]
            }
            data.push(aqiData)
        }
        if (decarbAdmin || decarbAnalyst) {
            const decarbData = {
                moduleId: 3,
                rolesLocationsData: [
                    {
                        role: decarbAdmin ? 'DECARB_ADMIN' : 'DECARB_ANALYST'
                    }
                ]
            }
            data.push(decarbData)
        }
        if (hvacAdmin || hvacAnalyst) {
            const decarbData = {
                moduleId: 4,
                rolesLocationsData: [
                    {
                        role: hvacAdmin ? 'HVAC_ADMIN' : 'HVAC_ANALYST'
                    }
                ]
            }
            data.push(decarbData)
        }
        console.log(data);
        return data;
    }

    const saveRolesLocation = async (userId) => {
        console.log(getRolesandLocations())
        const options = {
            method: 'POST',
            body: {
                userId: userId,
                roleModuleLocationsData: getRolesandLocations()
            }
        }
        await fetchWrapper(`${API_URL}/userModuleRoles`, options)
            .then((res) => {
                console.log(res?.json)

            }, (err) => {
                console.log(err)
                setLoading(false)
                if (err.status === 200) {
                    toast.success('User created successfully!')
                    setAdd(true)
                    navigate(navigateUrl)

                }
                if (err?.status === 500) {
                    toast?.error('User created successfully! But Roles data doesn"t saved..')
                    navigate(navigateUrl)
                }

            })
    }

    const handleBasicDetails = (data) => {
        setBasicData(data)
        setStepItem(2)
    }

    const onSubmit = async (data) => {
        setLoading(true)
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        try {
            if (action === 'Add') {
                data.persona_type = sessionStorage?.getItem('userType') === 'ASO_ADMIN' ? data.role : defaultUser[sessionStorage.getItem('userType')]
                url = `${API_URL}/users`
                message = 'User Created Successfully!'
            }
            if (action === 'Edit') {
                url = `${API_URL}/users/${basicData.id}`
                message = 'User Updated Successfully!'
            }
            const options = {
                method: method,
                body: basicData
            }

            await fetchWrapper(url, options)
                .then(async (res) => {
                    await saveRolesLocation(res?.json?.id)
                })
                .catch((err) => {
                    setLoading(false)
                    if (err.status == 409) {
                        toast.error('User Already Exist..')
                    }
                })

        } catch (error) {

        }
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col lg='12'>
                        {title && <H3 attrH3={{ className: 'mtop-2' }}>{title}</H3>}
                        <Card >
                            <CardBody>
                                <div className="d-flex">
                                    <div className="w-25per">
                                        <VerticalStepper activeStep={stepItem} />
                                    </div>
                                    <div className="vertical-line-div"></div>
                                    <div className="w-75per">
                                        {
                                            stepItem === 1 && <div>
                                                <h3>Basics</h3>
                                                <Form
                                                    onSubmit={handleSubmit(handleBasicDetails)}
                                                    className="user-form">
                                                    <Row>
                                                        <Col lg='6'>
                                                            <div style={{ marginBottom: "35px" }}>
                                                                <FormGroup style={{ marginBottom: "8px" }}>
                                                                    <Label for="exampleSelect">First Name <span className='required'>*</span></Label>
                                                                    <input
                                                                        className="form-control"
                                                                        name="first_name"
                                                                        type="text"
                                                                        defaultValue={data?.first_name || ''}
                                                                        {...register('first_name', { required: true })}
                                                                    />
                                                                    <span className="text-danger">{errors.first_name && 'First Name is Required'}</span>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div style={{ marginBottom: "35px" }}>
                                                                <FormGroup >
                                                                    <Label for="exampleEmail">  Last Name <span className='required'>*</span></Label>
                                                                    <input
                                                                        className="form-control"
                                                                        name="last_name"
                                                                        placeholder=""
                                                                        type="text"
                                                                        defaultValue={data?.last_name || ''}
                                                                        {...register('last_name', { required: true })}
                                                                    />
                                                                    <span className="text-danger">{errors.last_name && 'Last Name is Required'}</span>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg='6'>
                                                            <div style={{ marginBottom: "35px" }}>
                                                                <FormGroup style={{ marginBottom: "8px " }}>
                                                                    <Label for="exampleSelect">Email {action === 'Add' && <span className='required'>*</span>}</Label>
                                                                    <input
                                                                        className="form-control"
                                                                        name="email"
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled={action === 'Edit' ? true : false}
                                                                        defaultValue={data?.email || ''}
                                                                        {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                                    />
                                                                    <span className="text-danger">{errors.email?.type === 'required' && 'email is Required'}</span>
                                                                    <span className="text-danger">{errors.email?.type === 'pattern' && 'Invalid Email'}</span>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div style={{ marginBottom: "35px" }}>
                                                                <FormGroup >
                                                                    <Label for="exampleEmail">Phone</Label>
                                                                    <input
                                                                        className="form-control"
                                                                        name="phone"
                                                                        placeholder=""
                                                                        type="text"
                                                                        defaultValue={data?.phone || ''}
                                                                        {...register('phone', { pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/ })}
                                                                    />
                                                                    <span className="text-danger">{errors.phone?.type === 'required' && 'Phone is Required'}</span>
                                                                    <span className="text-danger">{errors.phone?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div>
                                                    <Link to={navigateUrl}>
                                                        <Button color="light"  > Cancel</Button>
                                                    </Link>
                                                    <Button color="primary" className="ml-1">Next</Button>
                                                    </div>
                                                    
                                                </Form>
                                            </div>
                                        }
                                        {
                                            stepItem === 2 && <div>
                                                <div className="d-flex justify-between">
                                                    <h3>Roles</h3>
                                                    <div className="d-block">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder="Search.."
                                                            onChange={(e) => handleFilter(e.target.value)}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="mtop-1">
                                                    {
                                                        subscriptionList?.some(ele => ele?.name === 'Carbon Credits' && ele?.issubscribed === true && ele?.personaType?.includes('ADMIN') && appName === ele?.moduleId) && <div className="mtop-1">
                                                            <div className="d-flex align-baseline">
                                                                <h5 className="f-w-600 w-12">Carbon Credits</h5>
                                                                <div className="ml-2">
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={carbonAnalyst}
                                                                            checked={carbonAdmin}
                                                                            onChange={(e) => setCarbonAdmin(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Admin
                                                                        </label>
                                                                    </div>
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={carbonAdmin}
                                                                            checked={carbonAnalyst}
                                                                            onChange={(e) => setCarbonAnalyst(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Analyst
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        subscriptionList?.some(ele => ele?.name === 'Net0Trace' && ele?.issubscribed === true && ele?.personaType?.includes('ADMIN') && appName === ele?.moduleId) && <div className="mtop-1">
                                                            <div className="d-flex align-baseline">
                                                                <h5 className="f-w-600 w-12">Net0Trace</h5>
                                                                <div className="mtop-1 ml-2">
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            checked={net0Admin}
                                                                            disabled={net0Approver || net0traceAnalyst || net0ReportUser}
                                                                            onChange={(e) => setNet0Admin(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Admin
                                                                        </label>
                                                                    </div>
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={net0Admin || net0ReportUser}
                                                                            value={net0Approver}
                                                                            checked={net0Approver}
                                                                            onChange={(e) => setNet0Approver(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Approver
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        net0Approver === true && <div className="checkbox-container ml-0">
                                                                            <div className="div-line-1px"></div>
                                                                            <div className="d-flex mtop-1 justify-between w-45r mb-1rem">
                                                                                <h5 className="f-w-600">Locations</h5>
                                                                                {
                                                                                    approverLocationExpand ? <ChevronDown onClick={() => setApproverLocationExpand(false)} /> : <ChevronUp onClick={() => setApproverLocationExpand(true)} />
                                                                                }
                                                                            </div>
                                                                            {
                                                                                approverLocationExpand == true && filteredItems?.map((item, index) => (
                                                                                    <div className={`form-check ${index === (filteredItems?.length - 1) && 'mb-1rem'}`} key={item?.locationId}>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input ml-1"
                                                                                            checked={net0ApproverItems?.some(ele => ele?.locationId == item?.locationId)}
                                                                                            value={item?.checked}
                                                                                            disabled={net0AnalystItems?.some(ele => ele?.locationId == item?.locationId)}
                                                                                            onChange={(e) => handleNet0TraceApprover(e.target.checked, index, item?.locationId)}
                                                                                            id={`childCheckbox${item?.locationId}`}
                                                                                        />
                                                                                        <label className="form-check-label ml-1" htmlFor={`childCheckbox${item?.locationId}`}>
                                                                                            {`${item?.locationCode} - ${item?.locationName}, ${item?.addressLine1}, ${item?.city}, ${item?.stateCode}, ${item?.countryCode}`}
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={net0Admin || net0ReportUser}
                                                                            checked={net0traceAnalyst}
                                                                            value={net0traceAnalyst}
                                                                            onChange={(e) => setNet0traceAnalyst(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Analyst
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        net0traceAnalyst === true && <div className="checkbox-container ml-0">
                                                                            <div className="div-line-1px"></div>
                                                                            <div className="d-flex mtop-1 justify-between w-45r">
                                                                                <h5 className="f-w-600">Locations</h5>
                                                                                {
                                                                                    analystLocationExpand ? <ChevronDown onClick={() => setAnalystLocationExpand(false)} /> : <ChevronUp onClick={() => setAnalystLocationExpand(true)} />
                                                                                }
                                                                            </div>
                                                                            {
                                                                                analystLocationExpand === true && filteredItems?.map((item, index) => (
                                                                                    <div className={`form-check`} key={index}>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input ml-1"
                                                                                            checked={net0AnalystItems?.some(ele => ele?.locationId == item?.locationId)}
                                                                                            value={item?.checked}
                                                                                            disabled={net0ApproverItems?.some(ele => ele?.locationId == item?.locationId)}
                                                                                            onChange={(e) => handleNet0TraceAnalyst(e.target.checked, index, item?.locationId)}
                                                                                            id={`childCheckbox${index}`}
                                                                                        />
                                                                                        <label className="form-check-label ml-1" htmlFor={`childCheckbox${index}`}>
                                                                                            {`${item?.locationCode} - ${item?.locationName}, ${item?.addressLine1}, ${item?.city}, ${item?.stateCode}, ${item?.countryCode}`}
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={net0Admin || net0Approver || net0traceAnalyst}
                                                                            checked={net0ReportUser}
                                                                            value={net0ReportUser}
                                                                            onChange={(e) => setNet0ReportUser(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Report User
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        subscriptionList?.some(ele => ele?.name === 'Sustainability Reporting' && ele?.issubscribed === true && ele?.personaType?.includes('ADMIN') && appName === ele?.moduleId) && <div className="mtop-1">
                                                            <div className="d-flex align-baseline">
                                                                <h5 className="f-w-600 w-12">Sustainability Reporting</h5>
                                                                <div className="mtop-1 ml-2">
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            checked={sustainabilityAdmin}
                                                                            disabled={sustainabilityAnalyst || sustainabilityReportUser}
                                                                            onChange={(e) => setSustainabilityAdmin(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Admin
                                                                        </label>
                                                                    </div>
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            checked={sustainabilityAnalyst}
                                                                            disabled={sustainabilityAdmin || sustainabilityReportUser}
                                                                            onChange={(e) => setSustainabilityAnalyst(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Analyst
                                                                        </label>
                                                                    </div>
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={sustainabilityAdmin || sustainabilityAnalyst}
                                                                            checked={sustainabilityReportUser}
                                                                            value={sustainabilityReportUser}
                                                                            onChange={(e) => setSustainabilityReportUser(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Report User
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        subscriptionList?.some(ele => ele?.name === 'Decarbonization Analytics' && ele?.issubscribed === true && ele?.personaType?.includes('ADMIN') && appName === ele?.moduleId) && <div className="mtop-1">
                                                            <div className="d-flex align-baseline">
                                                                <h5 className="f-w-600 w-12">Decarbonization Analytics</h5>
                                                                <div className="mtop-1 ml-2">
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={decarbAnalyst}
                                                                            checked={decarbAdmin}
                                                                            onChange={(e) => setDecarbAdmin(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Admin
                                                                        </label>
                                                                    </div>
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={decarbAdmin}
                                                                            checked={decarbAnalyst}
                                                                            onChange={(e) => setDecarbAnalyst(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Analyst
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }
                                                    {
                                                        subscriptionList?.some(ele => ele?.name === 'HVAC-Insights' && ele?.issubscribed === true && ele?.personaType?.includes('ADMIN') && appName === ele?.moduleId) && <div className="mtop-1">
                                                            <div className="d-flex align-baseline">
                                                                <h5 className="f-w-600 w-12">HVAC-Insights</h5>
                                                                <div className="mtop-1 ml-2">
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={hvacAnalyst}
                                                                            checked={hvacAdmin}
                                                                            onChange={(e) => setHvacAdmin(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Admin
                                                                        </label>
                                                                    </div>
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={hvacAdmin}
                                                                            checked={hvacAnalyst}
                                                                            onChange={(e) => setHvacAnalyst(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Analyst
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }
                                                    {
                                                        subscriptionList?.some(ele => ele?.name === 'AQI-Insights' && ele?.issubscribed === true && ele?.personaType?.includes('ADMIN') && appName === ele?.moduleId) && <div className="mtop-1">
                                                            <div className="d-flex align-baseline">
                                                                <h5 className="f-w-600 w-12">AQI-Insights</h5>
                                                                <div className="mtop-1 ml-2">
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={aqiAnalyst}
                                                                            checked={aqiAdmin}
                                                                            onChange={(e) => setAqiAdmin(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Admin
                                                                        </label>
                                                                    </div>
                                                                    <div className={`form-check checkbox-container`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            disabled={aqiAdmin}
                                                                            checked={aqiAnalyst}
                                                                            onChange={(e) => setAqiAnalyst(e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            Analyst
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }
                                                </div>
                                                <div className="d-flex mtop-1" >
                                                    <Link to={navigateUrl}>
                                                        <Button color="light"  > Cancel</Button>
                                                    </Link>
                                                    <Button color="secondary" className="ml-1" onClick={() => setStepItem(1)}> Back</Button>
                                                    {
                                                        loading ? <ButtonLoader color="primary" loaderText={'Submitting'} className="ml-1" /> :
                                                            <Button color="primary" onClick={onSubmit} className="ml-1">Submit</Button>
                                                    }

                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </Fragment>
    )
}

export default UserForm;

const steps = ['Basics', 'Roles'];

const VerticalStepper = ({ activeStep }) => {
    return (
        <div className="stepper-container">
            {steps.map((label, index) => (
                <div
                    key={index}
                    className={`step ${activeStep > index ? 'completed' : activeStep === index ? 'active' : 'incomplete'
                        }`}
                >
                    <div className="circle"></div>
                    <span className="step-label">{label}</span>
                </div>
            ))}
        </div>
    );
}