import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button } from "reactstrap";
import moment from "moment";
import { diffabledEmployee, diffabledWorkers, employee, female, permanentEmpData, workers } from "../../Settings/settingshelper";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";
import { Trash2 } from "react-feather";

const Employees = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [employeeData, setEmployeeData] = useState(employee);
    const [workersData, setWorkersData] = useState(workers);
    const [diffAbledEmployeeData, setDiffAbledEmployeeData] = useState(diffabledEmployee);
    const [diffAbledWorkersData, setDiffAbledWorkersData] = useState(diffabledWorkers);
    const [femaleParticipants, setFemaleParticipants] = useState(female);
    const [permanentEmp, setpermanentEmp] = useState(permanentEmpData);
    const [employeeBottomPinned, setEmployeePinned] = useState({
        particulars: 'Total employees (D + E)',
        total: employeeData?.reduce((sum, row) => sum + row?.total, 0) || 0,
        maleCount: employeeData?.reduce((sum, row) => sum + row?.maleCount, 0) || 0,
        malePercentage: employeeData?.reduce((sum, row) => sum + row?.percentageOfMales, 0) || 0,
        femaleCount: employeeData?.reduce((sum, row) => sum + row?.femaleCount, 0) || 0,
        femalePercentage: employeeData?.reduce((sum, row) => sum + row?.percentageOfFemales, 0) || 0,
        editable: true
    });
    const [workerBottomPinned, setWorkersPinned] = useState({
        particulars: 'Total workers (F + G)',
        total: workersData?.reduce((sum, row) => sum + row?.total, 0) || 0,
        maleCount: workersData?.reduce((sum, row) => sum + row?.maleCount, 0) || 0,
        malePercentage: workersData?.reduce((sum, row) => sum + row?.percentageOfMales, 0) || 0,
        femaleCount: workersData?.reduce((sum, row) => sum + row?.femaleCount, 0) || 0,
        femalePercentage: workersData?.reduce((sum, row) => sum + row?.percentageOfFemales, 0) || 0,
        editable: true
    });
    const [diffAbledEmpPinned, setDiffAbledEmpPinned] = useState({
        particulars: 'Total employees (D + E)',
        total: diffAbledEmployeeData?.reduce((sum, row) => sum + row?.total, 0) || 0,
        maleCount: diffAbledEmployeeData?.reduce((sum, row) => sum + row?.maleCount, 0) || 0,
        malePercentage: diffAbledEmployeeData?.reduce((sum, row) => sum + row?.percentageOfMales, 0) || 0,
        femaleCount: diffAbledEmployeeData?.reduce((sum, row) => sum + row?.femaleCount, 0) || 0,
        femalePercentage: diffAbledEmployeeData?.reduce((sum, row) => sum + row?.percentageOfFemales, 0) || 0,
        editable: true
    });
    const [diffAbledWorkPinned, setDiffAbledWorkPinned] = useState({
        particulars: 'Total workers (F + G)',
        total: workersData?.reduce((sum, row) => sum + row?.total, 0) || 0,
        maleCount: workersData?.reduce((sum, row) => sum + row?.maleCount, 0) || 0,
        malePercentage: workersData?.reduce((sum, row) => sum + row?.percentageOfMales, 0) || 0,
        femaleCount: workersData?.reduce((sum, row) => sum + row?.femaleCount, 0) || 0,
        femalePercentage: workersData?.reduce((sum, row) => sum + row?.percentageOfFemales, 0) || 0,
        editable: true
    });

    const getEmployeeData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeesData/${financialYear}`)
            .then((res) => {
                console.log(res)
                const employeeArray = res.json?.filter(ele => ele?.type === 'Employee');
                const tempEmp = employeeArray?.filter(ele => employee?.find(item => item?.particulars === ele?.particulars))
                setEmployeeData(tempEmp);
                setEmployeePinned({
                    particulars: 'Total employees (D + E)',
                    ...handleTotal(tempEmp),
                    editable: true
                })
                const workersArray = res.json?.filter(ele => ele?.type === 'Workers');
                const tempWorkers = workersArray?.filter(ele => workers?.find(item => item?.particulars === ele?.particulars));
                setWorkersData(tempWorkers);
                setWorkersPinned({
                    particulars: 'Total workers (F + G)',
                    ...handleTotal(tempWorkers),
                    editable: true
                })
                const diffAbledEmpArray = res.json?.filter(ele => ele?.type === 'DifferentAbledEmployee');
                const diffEmp = diffAbledEmpArray?.filter(ele => diffabledEmployee?.find(item => item?.particulars === ele?.particulars))
                setDiffAbledEmployeeData(diffEmp);
                setDiffAbledEmpPinned({
                    particulars: 'Total employees (D + E)',
                    ...handleTotal(diffEmp),
                    editable: true
                })
                const diffAbledWorkersArray = res.json?.filter(ele => ele?.type === 'DifferentAbledWorkers');
                const diffWorkers = diffAbledWorkersArray?.filter(ele => diffabledWorkers?.find(item => item?.particulars === ele?.particulars));
                setDiffAbledWorkersData(diffWorkers);
                setDiffAbledWorkPinned({
                    particulars: 'Total workers (F + G)',
                    ...handleTotal(diffWorkers),
                    editable: true
                })
            }, (err) => {
                console.log(err)
                setEmployeeData(employee)
                setWorkersData(workers)
                setDiffAbledEmployeeData(diffabledEmployee)
                setDiffAbledWorkersData(diffabledWorkers)
            })
    }

    const getWomanRepressionData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/womenRepresentationData/${financialYear}`)
            .then((res) => {
                console.log(res)
                const tempData = female?.map(ele => {
                    const temp = res?.json?.find(item => item?.representationType === ele?.representationType);
                    return temp ? temp : ele;
                })
                setFemaleParticipants(tempData)
            }, (err) => console.log(err))
    }

    const getEmployeeTurnoverData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeTurnOverRateData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const employeeTurnoverArray = res?.json?.filter(ele => permanentEmpData?.find(item => item?.particulars === ele?.particulars));
                setpermanentEmp(employeeTurnoverArray);
            }, (err) => { console.log(err) })
    }

    useEffect(() => {
        if (financialYear) {
            getEmployeeData();
            getWomanRepressionData();
            getEmployeeTurnoverData();
        }

    }, [financialYear])

    const handleClear = (params, type) => {
        let updatedRowData;
        if ((type === 1) && (params.context === 'employee')) {
            updatedRowData = employeeData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars } : { ...row }
            });
            const tempObj = handleTotal(updatedRowData);
            setEmployeePinned({
                particulars: 'Total employees (D + E)',
                ...tempObj,
                editable: true
            })
            setEmployeeData(updatedRowData)
        } else if ((type === 1) && (params.context === 'diffAbledEmployees')) {
            updatedRowData = diffAbledEmployeeData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars } : { ...row }
            });
            setDiffAbledEmployeeData(updatedRowData);
        } else if ((type === 2) && (params.context === 'workers')) {
            updatedRowData = workersData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars } : { ...row }
            });
            setWorkersData(updatedRowData);
        } else if ((type === 2) && (params.context === 'diffAbledWorkers')) {
            updatedRowData = diffAbledWorkersData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars } : { ...row }
            });
            setDiffAbledWorkersData(updatedRowData);
        } else if (type === 3) {
            updatedRowData = femaleParticipants?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.representationType } : { ...row }
            });
            setFemaleParticipants(updatedRowData)
        } else if (type === 4) {
            updatedRowData = permanentEmp?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars } : { ...row }
            });
            setpermanentEmp(updatedRowData);
        }
    }

    const employeeColumns = [
        {
            headerName: 'Particulars',
            field: 'particulars',
            editable: false,
            filter: false,
            flax: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Total (A)',
            field: 'total',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
            },
            flex: 1,
            editable: (params) => {
                return params.node.rowPinned !== 'bottom'
            },
            cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
        },
        {
            headerName: 'Male',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'No. (B)',
                    field: 'maleCount',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.total > params.data.maleCount) {
                            return params.data.maleCount;
                        } else {
                            delete params.data.maleCount;
                        }
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
                {
                    headerName: '% (B/A)',
                    field: 'malePercentage',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data?.maleCount && params.data?.total) {
                            params.data.malePercentage = (Number(params.data?.maleCount) / Number(params.data?.total)) * 100;
                            return params.data?.malePercentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.malePercentage?.toFixed(2)}</div>
                    },
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
            ]
        },
        {
            headerName: 'Female',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'No. (C)',
                    field: 'femaleCount',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data.total > params.data.femaleCount) {
                            return params.data.femaleCount;
                        } else {
                            delete params.data.femaleCount;
                        }
                    },
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
                {
                    headerName: '% (C/A)',
                    field: 'femalePercentage',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data?.femaleCount && params.data?.total) {
                            params.data.femalePercentage = (Number(params.data?.femaleCount) / Number(params.data?.total)) * 100;
                            return params.data?.femalePercentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.femalePercentage?.toFixed(2)}</div>
                    },
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
            ]
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if ((params.data.hasOwnProperty('total') ||
                    params.data.hasOwnProperty('maleCount') ||
                    params.data.hasOwnProperty('femaleCount')) &&
                    params.node.rowPinned !== 'bottom'
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 1)} />
                        </div>
                    )
            }
        }
    ]

    const workersColumns = [
        {
            headerName: 'Particulars',
            field: 'particulars',
            editable: false,
            filter: false,
            flax: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Total (A)',
            field: 'total',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
            },
            flex: 1,
            editable: (params) => {
                return params.node.rowPinned !== 'bottom'
            },
            cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
        },
        {
            headerName: 'Male',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'No. (B)',
                    field: 'maleCount',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.total > params.data.maleCount) {
                            return params.data.maleCount;
                        } else {
                            delete params.data.maleCount;
                        }
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
                {
                    headerName: '% (B/A)',
                    field: 'malePercentage',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data?.maleCount && params.data?.total) {
                            params.data.malePercentage = (Number(params.data?.maleCount) / Number(params.data?.total)) * 100;
                            return params.data?.malePercentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.malePercentage?.toFixed(2)}</div>
                    },
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
            ]
        },
        {
            headerName: 'Female',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'No. (C)',
                    field: 'femaleCount',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.total > params.data.femaleCount) {
                            return params.data.femaleCount;
                        } else {
                            delete params.data.femaleCount;
                        }
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
                {
                    headerName: '% (C/A)',
                    field: 'femalePercentage',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    valueGetter: (params) => {
                        if (params.data?.femaleCount && params.data?.total) {
                            params.data.femalePercentage = (Number(params.data?.femaleCount) / Number(params.data?.total)) * 100;
                            return params.data?.femalePercentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.femalePercentage?.toFixed(2)}</div>
                    },
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
            ]
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if ((params.data.hasOwnProperty('total') ||
                    params.data.hasOwnProperty('maleCount') ||
                    params.data.hasOwnProperty('femaleCount')) &&
                    params.node.rowPinned !== 'bottom'
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 2)} />
                        </div>
                    )
            }
        }
    ]

    const femaleColumns = [
        {
            headerName: '',
            field: 'representationType',
            editable: false,
            filter: false,
            flax: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Total (A)',
            field: 'total',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
            },
            flex: 1,
            editable: true,
        },
        {
            headerName: 'No. and percentage of Females',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'No. (B)',
                    field: 'count',
                    flex: 1,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    editable: true,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: '% (B/A)',
                    field: 'percentage',
                    flex: 1,
                    editable: false,
                    valueGetter: (params) => {
                        if (params.data?.count && params.data?.total) {
                            params.data.percentage = (Number(params.data?.count) / Number(params.data?.total)) * 100;
                            return params.data?.percentage
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.percentage?.toFixed(2)}</div>
                    },
                    cellClass: 'disable-cell',
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if ((params.data.hasOwnProperty('total') ||
                    params.data.hasOwnProperty('count')) &&
                    params.node.rowPinned !== 'bottom'
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 3)} />
                        </div>
                    )
            }
        }
    ]

    const rateOfPermanentEmpColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flax: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Male',
                    field: 'currentYearMaleRate',
                    flex: 1,
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Female',
                    field: 'currentYearFemaleRate',
                    flex: 1,
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Total',
                    field: 'currentYearTotalRate',
                    flex: 1,
                    editable: false,
                    valueGetter: (params) => {
                        if (params.data?.currentYearMaleRate || params.data?.currentYearFemaleRate) {
                            params.data.currentYearTotalRate = (Number(params.data?.currentYearMaleRate) || 0) + (Number(params.data?.currentYearFemaleRate) || 0);
                            return params.data?.currentYearTotalRate;
                        }
                    },
                    cellClass: 'disable-cell',
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Male',
                    field: 'previousYearMaleRate',
                    flex: 1,
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Female',
                    field: 'previousYearFemaleRate',
                    flex: 1,
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Total',
                    field: 'previousYearTotalRate',
                    flex: 1,
                    editable: false,
                    valueGetter: (params) => {
                        if (params.data?.previousYearMaleRate || params.data?.previousYearFemaleRate) {
                            params.data.previousYearTotalRate = (Number(params.data?.previousYearMaleRate) || 0) + (Number(params.data?.previousYearFemaleRate) || 0);
                            return params.data?.previousYearTotalRate;
                        }
                    },
                    cellClass: 'disable-cell',
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previous2Years}` : 'FY _____',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Male',
                    field: 'predecessorYearMaleRate',
                    flex: 1,
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Female',
                    field: 'predecessorYearFemaleRate',
                    flex: 1,
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Total',
                    field: 'predecessorYearTotalRate',
                    flex: 1,
                    editable: false,
                    valueGetter: (params) => {
                        if (params.data?.predecessorYearMaleRate || params.data?.predecessorYearFemaleRate) {
                            params.data.predecessorYearTotalRate = (Number(params.data?.predecessorYearMaleRate) || 0) + (Number(params.data?.predecessorYearFemaleRate) || 0);
                            return params.data?.predecessorYearTotalRate;
                        }
                    },
                    cellClass: 'disable-cell',
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if ((params.data.hasOwnProperty('currentYearMaleRate') ||
                    params.data.hasOwnProperty('currentYearFemaleRate') ||
                    params.data.hasOwnProperty('currentYearTotalRate') ||
                    params.data.hasOwnProperty('previousYearMaleRate') ||
                    params.data.hasOwnProperty('previousYearFemaleRate') ||
                    params.data.hasOwnProperty('previousYearTotalRate') ||
                    params.data.hasOwnProperty('predecessorYearMaleRate') ||
                    params.data.hasOwnProperty('predecessorYearFemaleRate') ||
                    params.data.hasOwnProperty('predecessorYearTotalRate')) &&
                    params.node.rowPinned !== 'bottom'
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 4)} />
                        </div>
                    )
            }
        }
    ];

    const handleTotal = (data) => {
        return {
            total: data?.reduce((sum, row) => {
                const value = Number(row?.total);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            maleCount: data?.reduce((sum, row) => {
                const value = Number(row?.maleCount);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            malePercentage: data?.reduce((sum, row) => {
                const value = Number(row?.malePercentage);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            femaleCount: data?.reduce((sum, row) => {
                const value = Number(row?.femaleCount);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            femalePercentage: data?.reduce((sum, row) => {
                const value = Number(row?.femalePercentage);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
        }
    }

    const onCellValueChanged = (params, type) => {
        if (type === 1) {
            const tempObj = handleTotal(employeeData);
            setEmployeePinned({
                particulars: 'Total employees (D + E)',
                ...tempObj,
                editable: true
            })
        } else if (type === 2) {
            const tempObj = handleTotal(workersData)
            setWorkersPinned({
                particulars: 'Total employees (F + G)',
                ...tempObj,
                editable: true
            })
        } else if (type === 3) {
            const tempObj = handleTotal(diffAbledEmployeeData)
            setDiffAbledEmpPinned({
                particulars: 'Total employees (D + E)',
                ...tempObj,
                editable: true
            })
        } else if (type === 4) {
            const tempObj = handleTotal(diffAbledWorkersData)
            setDiffAbledWorkPinned({
                particulars: 'Total employees (F + G)',
                ...tempObj,
                editable: true
            })
        }
    }

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    };

    const getTotal = (data) => {
        const tot = data.reduce((acc, obj) => {
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'number') acc[key] = (acc[key] || 0) + obj[key];
            });
            return acc;
        }, {});
        return tot;
    }

    const onSubmit = async () => {
        const data = {
            financialYear: financialYear,
            employeesData: [
                ...[...employeeData, { ...getTotal(employeeData), particulars: 'Total employees (D + E)' }]
                    ?.map((item, index) => ({ ...item, type: 'Employee', serialNo: index + 1 })),
                ...[...workersData, { ...getTotal(workersData), particulars: 'Total employees (F + G)' }]
                    ?.map((item, index) => ({ ...item, type: 'Workers', serialNo: 4 + index })),
                ...[...diffAbledEmployeeData, { ...getTotal(diffAbledEmployeeData), particulars: 'Total employees (D + E)' }]
                    ?.map((item, index) => ({ ...item, type: 'DifferentAbledEmployee', serialNo: index + 1 })),
                ...[...diffAbledWorkersData, { ...getTotal(diffAbledWorkersData), particulars: 'Total employees (F + G)' }]
                    ?.map((item, index) => ({ ...item, type: 'DifferentAbledWorkers', serialNo: 4 + index }))
            ],
            womenRepresentationData: [...femaleParticipants]?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            employeeTurnOverRateData: [...permanentEmp]?.map((item, index) => ({ ...item, serialNo: index + 1 }))
        }
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {

            }, (err) => {
                if (err.status === 200) {
                    setActive(1)
                } else if (err.status === 500) {
                    toast.error('Something went wrong!')
                }

            })
    }

    return (
        <Fragment>
            <h3>Employees & Workers</h3>
            <div className="mtop-1 company-profile ag-group-header">
                <h5>Employees(including differently abled) </h5>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={employeeData}
                            columnData={employeeColumns}
                            context='employee'
                            gridOptions={gridOptions}
                            pinnedBottomRowData={employeeBottomPinned}
                            onCellValueChanged={(params) => onCellValueChanged(params, 1)}
                        />
                    </div>
                </div>
            </div>

            <div className="mtop-1 company-profile ag-group-header">
                <h5>Workers (including differently abled) </h5>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={workersData}
                            columnData={workersColumns}
                            context={'workers'}
                            gridOptions={gridOptions}
                            pinnedBottomRowData={workerBottomPinned}
                            onCellValueChanged={(params) => onCellValueChanged(params, 2)}
                        />
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile ag-group-header">
                <h5>Differently abled employees </h5>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={diffAbledEmployeeData}
                            columnData={employeeColumns}
                            context={'diffAbledEmployees'}
                            gridOptions={gridOptions}
                            pinnedBottomRowData={diffAbledEmpPinned}
                            onCellValueChanged={(params) => onCellValueChanged(params, 3)}
                        />
                    </div>
                </div>
            </div>

            <div className="mtop-1 company-profile ag-group-header">
                <h5>Differently abled workers  </h5>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={diffAbledWorkersData}
                            columnData={workersColumns}
                            gridOptions={gridOptions}
                            context={'diffAbledWorkers'}
                            pinnedBottomRowData={diffAbledWorkPinned}
                            onCellValueChanged={(params) => onCellValueChanged(params, 4)}
                        />
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile ag-group-header">
                <h5>Participation/Inclusion/Representation of women:  </h5>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={femaleParticipants}
                            columnData={femaleColumns}
                            gridOptions={gridOptions}
                        />
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile ag-group-header">
                <h5>Turnover rate for permanent employees and workers (Disclose trends for the past 3 years) </h5>
                <div className="employee-turnover">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={permanentEmp}
                            columnData={rateOfPermanentEmpColumns}
                            gridOptions={gridOptions}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default Employees