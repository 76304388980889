import React, { Fragment } from "react";
import mainImg from '../../../assets/images/maintain.png';
import { H3 } from "../../../AbstractElements";
import { Col, Container, Row } from 'reactstrap';
import { Image } from '../../../AbstractElements';


const MaintenanceNotification = () => {
    return (
        <Fragment>
            <section>
                <Container className="p-0" fluid={true}>
                    <Row>
                        <Col xl='12'>
                            <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../../assets/images/logo/airoi.png')}` }} />
                        </Col>
                        <Col xl='12'>
                            <div className="maintanance">
                                <div className="w-45 dashboard-content">
                                    <img src={mainImg} width={300} height={300} />
                                    <H3 attrH3={{ className: 'credits-table text-center mtop-1' }}>Our system is undergoing upgrade to help us serve you better.
                                        Please come back after 20mins</H3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </Fragment>
    )
}

export default MaintenanceNotification;
