import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { addNewRow, CustomFooter, thousandSeparator } from "../../../api/helper";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Nav, Tab, Row, Col, Tabs, Accordion, Card, FormGroup } from "react-bootstrap";
import { CardBody, Container } from "reactstrap";
import LandingPage from "../LandingPage";
import ScopeConfiguration from "../ScopeConfiguration";
import FinancialYearSetup from "../FinancialYearSetup";
import { useSelector } from "react-redux";

const ApplicationSettings = () => {
    const [activeKey, setActiveKey] = useState(1);
    const userObject = useSelector(state => state?.user?.userRoles);
    const app = useSelector(state => state?.user?.appName);

    return (
        <Fragment>
            <Container fluid>
                <Breadcrumbs mainTitle={'General'} />

                <Card>
                    <CardBody>
                        <Tab.Container activeKey={activeKey} defaultActiveKey={'1'} onSelect={(key) => setActiveKey(key)} >
                            <Nav variant="underlines">
                                <Nav.Item className="mr-5px">
                                    <Nav.Link eventKey={'1'} className={activeKey == 1 ? 'application-active' : ''}>Landing Page</Nav.Link>
                                </Nav.Item>
                                {
                                    (userObject[6]?.length > 0 && app === 2) && <Nav.Item>
                                        <Nav.Link eventKey={'2'} className={activeKey == 2 ? 'application-active' : ''} >Financial Year</Nav.Link>
                                    </Nav.Item>
                                }
                                {
                                    (['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER']?.some(role => userObject[2]?.includes(role)) && app === 2) && <Nav.Item>
                                    <Nav.Link eventKey={'3'} className={activeKey == 3 ? 'application-active' : ''} >Scope Applicability</Nav.Link>
                                </Nav.Item>
                                }
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey={'1'}>
                                    <LandingPage />
                                </Tab.Pane>
                                <Tab.Pane eventKey={'2'}>
                                    <FinancialYearSetup />
                                </Tab.Pane>
                                <Tab.Pane eventKey={'3'}>
                                    <ScopeConfiguration />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </CardBody>

                </Card>
            </Container>


        </Fragment>
    )
}

export default ApplicationSettings;