import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button } from "reactstrap";
import moment from "moment";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";
import { CustomFooter } from "../../../api/helper";
import { Trash2 } from "react-feather";

const EnvironmentalProtection = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [patScheme, setPatScheme] = useState();
    const [isZLD, setIsZLD] = useState();
    const [zeroLiquidMachanism, setZeroLiquid] = useState();
    const [wasteManagement, setWasteManagement] = useState();
    const [ecoSensitiveData, setEcoSensitive] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));
    const [projectAssessmentData, setProjectAssessmentData] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));
    const [envComplianceData, setEnvComplianceData] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));
    const [biodiversityDetails, setBiodiversityDetails] = useState();
    const [disasterManagementPlan, setDisasterPlan] = useState();
    const [environmentImpact, setEnvironmentImpact] = useState();
    const [partnersPercentage, setPartnersPercentage] = useState()
    const [resourceInitiativeData, setInitiative] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));
    const [isRelateGHG, setIsRelateGHG] = useState();
    const [ghgDetails, setGHGDetails] = useState()

    const getAttributesKeyValuesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json?.filter(ele => ele.groupName === 'environmentalProtectionEfforts');
                console.log(data)
                setPatScheme(data?.find(ele => ele.key === 'Q38')?.value);
                setIsZLD(data?.find(ele => ele.key === 'Q39')?.value);
                setZeroLiquid(data?.find(ele => ele.key === 'Q40')?.value);
                setIsRelateGHG(data?.find(ele => ele.key === 'Q41')?.value)
                setGHGDetails(data?.find(ele => ele.key === 'Q42')?.value)
                setWasteManagement(data?.find(ele => ele.key === 'Q43')?.value);
                setBiodiversityDetails(data?.find(ele => ele.key === 'Q44')?.value);
                setDisasterPlan(data?.find(ele => ele.key === 'Q45')?.value);
                setEnvironmentImpact(data?.find(ele => ele.key === 'Q46')?.value)
                setPartnersPercentage(data?.find(ele => ele.key === 'Q47')?.value)
            }, (err) => console.log(err))
    }

    const getEcoSensitiveOperations = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/ecoSensitiveOperationsData/${financialYear}`)
            .then((res) => {
                setEcoSensitive(res.json)
            }, (err) => {
                setEcoSensitive([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
            })
    }

    const getProjectAssessmentsData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/projectAssessmentsData/${financialYear}`)
            .then((res) => {
                setProjectAssessmentData(res.json)
            }, (err) => {
                setProjectAssessmentData([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
            })
    }
    const getEnvComplianceData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/envComplianceData/${financialYear}`)
            .then((res) => {
                setEnvComplianceData(res.json)
            }, (err) => {
                setEnvComplianceData([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
            })
    }

    const getResourceInitiativesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/resourceInitiativesData/${financialYear}`)
            .then((res) => {
                setInitiative(res.json)
            }, (err) => {
                setInitiative([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
            })
    }

    useEffect(() => {
        if (financialYear) {
            getAttributesKeyValuesData()
            getEcoSensitiveOperations()
            getProjectAssessmentsData()
            getEnvComplianceData()
            getResourceInitiativesData()
        }
    }, [financialYear])

    const handleClear = (params, type) => {
        let updatedRowData;
        if (type === 1) {
            updatedRowData = ecoSensitiveData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { serialNo: index + 1 } : { ...row }
            })
            setEcoSensitive(updatedRowData)
        } else if (type === 2) {
            updatedRowData = projectAssessmentData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { serialNo: index + 1 } : { ...row }
            })
            setProjectAssessmentData(updatedRowData)
        } else if (type === 3) {
            updatedRowData = envComplianceData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { serialNo: index + 1 } : { ...row }
            })
            setEnvComplianceData(updatedRowData)
        } else if (type === 4) {
            updatedRowData = resourceInitiativeData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { serialNo: index + 1 } : { ...row }
            })
            setInitiative(updatedRowData)
        }
    }

    const ecoSensitiveColumn = [
        {
            headerName: 'Location of Operations',
            field: 'locationOfOperations',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Type of Operations',
            field: 'typeOfOperations',
            editable: true,
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Environmental Compliance Approval',
            field: 'environmentalApprovalCompliance',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: true,
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Reasons & Corrective Action',
            field: 'reasonsAndCorrectiveAction',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if (params.data.hasOwnProperty('locationOfOperations') ||
                    params.data.hasOwnProperty('typeOfOperations') ||
                    params.data.hasOwnProperty('environmentalApprovalCompliance') ||
                    params.data.hasOwnProperty('reasonsAndCorrectiveAction')
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 1)} />
                        </div>
                    )
            }
        }
    ]

    const projectAssessmentColumns = [
        {
            headerName: 'Project Name',
            field: 'projectName',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Project Details',
            field: 'briefDetails',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'EIA Notification no',
            field: 'eiaNotificationNo',
            editable: true,
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 1.25,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Date',
            field: 'assessmentDate',
            flex: 1,
            editable: true,
            cellEditor: 'agDateCellEditor',
            valueGetter: (params) => {
                if (params.data.assessmentDate) {
                    return moment(params.data?.assessmentDate).format('DD MMM, YYYY');
                }
            },

        },
        {
            headerName: 'Independent External Agency(Y/N)',
            field: 'externalAgency',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: true,
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Publicly disclosed(Y/N)',
            field: 'publicDomain',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: true,
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Weblink',
            field: 'webLink',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            cellRenderer: (params) => {
                if (params.data.webLink) {
                    return (
                        <a href={params.data.webLink} target="_blank">{params.data.webLink}</a>
                    )
                }

            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if (params.data.hasOwnProperty('projectName') ||
                    params.data.hasOwnProperty('briefDetails') ||
                    params.data.hasOwnProperty('eiaNotificationNo') ||
                    params.data.hasOwnProperty('assessmentDate') ||
                    params.data.hasOwnProperty('externalAgency') ||
                    params.data.hasOwnProperty('publicDomain') ||
                    params.data.hasOwnProperty('webLink')
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 2)} />
                        </div>
                    )
            }
        }
    ]

    const envComplianceColumn = [
        {
            headerName: 'Specify the law / regulation / guidelines',
            field: 'regulation',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Non-compliance details',
            field: 'nonComplianceDetails',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Any fines / penalties / action taken by regulatory agencies',
            field: 'actionsTaken',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Corrective taken, if any action',
            field: 'correctiveActionsTaken',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if (params.data.hasOwnProperty('regulation') ||
                    params.data.hasOwnProperty('nonComplianceDetails') ||
                    params.data.hasOwnProperty('actionsTaken')
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 3)} />
                        </div>
                    )
            }
        }
    ]

    const resourceInitiativesColumn = [
        {
            headerName: 'Initiative undertaken',
            field: 'initiativeTaken',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Details of the initiative (Web-link, if any, may be provided along-with summary)',
            field: 'initiativeDetails',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Outcome of the initiative',
            field: 'initiativeOutcome',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if (params.data.hasOwnProperty('initiativeTaken') ||
                    params.data.hasOwnProperty('initiativeDetails') ||
                    params.data.hasOwnProperty('initiativeOutcome')
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 4)} />
                        </div>
                    )
            }
        }
    ]


    const onSubmit = async () => {
        const bodyData = {
            financialYear: financialYear,
            ecoSensitiveOperationsData: ecoSensitiveData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            projectAssessmentsData: projectAssessmentData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            envComplianceData: envComplianceData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            resourceInitiativesData: resourceInitiativeData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            attributesKeyValueData: [
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q38',
                    value: patScheme
                },
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q39',
                    value: isZLD
                },
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q40',
                    value: zeroLiquidMachanism
                },
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q41',
                    value: isRelateGHG
                },
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q42',
                    value: ghgDetails
                },
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q43',
                    value: wasteManagement
                },
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q44',
                    value: biodiversityDetails
                },
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q45',
                    value: disasterManagementPlan
                },
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q46',
                    value: environmentImpact
                },
                {
                    serialNo: 6,
                    groupName: 'environmentalProtectionEfforts',
                    key: 'Q47',
                    value: partnersPercentage
                }
            ]
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(3)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <h3>Environmental Protection Efforts</h3>
            <h4 className="mtop-1">Essential Indicators</h4>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    1. Does the entity have any sites / facilities identified as designated
                    consumers (DCs) under the Performance, Achieve and Trade (PAT) Scheme of
                    the Government of India? (Y/N) If yes, have the targets been met?
                    If not, what remedial actions have been taken?
                </h5>
                <textarea className="form-control ml-1" value={patScheme} rows={3}
                    onChange={(e) => setPatScheme(e.target.value)} />
            </div>
            <div className="mtop-1">
                <h5>
                    2. Has the entity implemented a mechanism for Zero Liquid Discharge?
                    If yes, provide details of its coverage and implementation
                </h5>
                <div className="d-flex mtop-1">
                    <div className="col-6">
                        <select className="form-select" value={isZLD}
                            onChange={(e) => setIsZLD(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            isZLD === 'Yes' && <input type="text" className="form-control ml-1"
                                value={zeroLiquidMachanism} onChange={(e) => setZeroLiquid(e.target.value)} />
                        }
                    </div>
                </div>
            </div>
            <div className="mtop-1">
                <h5>
                    3. Does the entity have any project related to reducing Green House Gas emission?
                    If yes, then provide details
                </h5>
                <div className="d-flex mtop-1">
                    <div className="col-6">
                        <select className="form-select" value={isRelateGHG}
                            onChange={(e) => setIsRelateGHG(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            isRelateGHG === 'Yes' && <input type="text" className="form-control ml-1"
                                value={ghgDetails} onChange={(e) => setGHGDetails(e.target.value)} />
                        }
                    </div>
                </div>
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    4. Briefly describe the waste management practices at your establishments and the
                    strategy to reduce hazardous chemical usage in products and processes. How do you manage such wastes?
                </h5>
                <textarea className="form-control ml-1" value={wasteManagement} rows={3}
                    onChange={(e) => setWasteManagement(e.target.value)} />
            </div>
            <div className="mtop-1 company-profile">
                <h5>5. If the entity has operations/offices in/around ecologically sensitive areas (such as national parks,
                    wildlife sanctuaries, biosphere reserves, wetlands, biodiversity hotspots, forests, coastal regulation zones etc.)
                    where environmental approvals / clearances are required, please specify details in the following format:</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={ecoSensitiveData}
                        columnData={ecoSensitiveColumn}
                    />
                    <CustomFooter handleAdd={() => setEcoSensitive([...ecoSensitiveData, { serialNo: ecoSensitiveData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>6. Details of environmental impact assessments of projects undertaken by the entity based on applicable
                    laws, in the current financial year</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={projectAssessmentData}
                        columnData={projectAssessmentColumns}
                    />
                    <CustomFooter handleAdd={() => setProjectAssessmentData([...projectAssessmentData, { serialNo: projectAssessmentData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>7. Environmental Compliance Status</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={envComplianceData}
                        columnData={envComplianceColumn}
                    />
                    <CustomFooter handleAdd={() => setEnvComplianceData([...envComplianceData, { serialNo: envComplianceData?.length + 1 }])} />
                </div>
            </div>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    1. With respect to the ecologically sensitive areas reported at Question 5 of Essential Indicators above,
                    provide details of significant direct & indirect impact of the entity on biodiversity in such areas along-with
                    prevention and remediation activities
                </h5>
                <textarea className="form-control ml-1" value={biodiversityDetails} rows={3}
                    onChange={(e) => setBiodiversityDetails(e.target.value)} />
            </div>
            <div className="mtop-1 company-profile">
                <h5>2. Resource Efficiency Initiatives and Outcomes</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={resourceInitiativeData}
                        columnData={resourceInitiativesColumn}
                    />
                    <CustomFooter handleAdd={() => setInitiative([...resourceInitiativeData, { serialNo: resourceInitiativeData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    3. Does the entity have a business continuity and disaster management plan? Give details in 100 words/ web link
                </h5>
                <textarea className="form-control ml-1" value={disasterManagementPlan} rows={3}
                    onChange={(e) => setDisasterPlan(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    4. Disclose any significant adverse impact to the environment arising from the value chain of the entity.
                    What mitigation or adaptation measures have been taken by the entity in this regard
                </h5>
                <textarea className="form-control ml-1" value={environmentImpact} rows={3}
                    onChange={(e) => setEnvironmentImpact(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    5. Percentage of value chain partners (by value of business done with such partners) that were assessed for environmental impacts
                </h5>
                <input className="form-control ml-1" value={partnersPercentage} type="number"
                    onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setPartnersPercentage(0) : setPartnersPercentage(e.target.value)}
                    onChange={(e) => setPartnersPercentage(e.target.value)} />
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(1)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default EnvironmentalProtection;