import React, { Fragment, useEffect, useState, useRef } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { defaultUserType, thousandSeparator } from '../../api/helper';
import { Plus, Trash2, XCircle } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import { uuidGenerator } from "./ghgHelper";
import { useSelector } from "react-redux";
import moment from "moment";
import { alertFunction, notifyFunction, storeData, deleteCacheData, getCacheData } from "./ghgHelper";


let error = false;
const Steam = ({ setSteps, preData, setSteam, steam, timeStamp, requestId, setRequestId }) => {
    const [data, setData] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })));
    const [noOfRow, setNoOfRow] = useState(1);
    const [loading, setLoading] = useState(false);
    const [rowIndex, setRowIndex] = useState([]);
    const steamRef = useRef();
    const [fuelData, setFuelData] = useState([]);
    const [combustedData, setCombustedData] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState()
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }


    const getFuelData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/StationaryCombustion/${preData?.countryCode}`)
            .then((res) => {
                const data = res?.json?.map(ele => ele.sourceDescription)
                setFuelData(data);
                setCombustedData(res?.json)
            }, (err) => console.log(err))
    }

    const populateTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setData(tempData)
    }

    const loadData = (savedData, cTimestamp) => {
        if (steam?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateTable(steam)
        } else if (steam?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if(steam?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, steam, populateTable)
            } else { //saved is latest
                notifyFunction(steam, populateTable)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        loadData(cacheData?.steamEnergyData, cacheData?.modifiedTs)
    }

    useEffect(() => {
        getFuelData()
        getCache()
    }, [])

    const handleClear = async(params) => {
        const updatedRowData = data.map((row, index) => {
            return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                : ((row.id === params.data.id) && row.isSubmit === false) ?
                    { id: index + 1, fuelState: undefined, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                    : row
        });
        const tempData = {
            ...cacheData,
            steamEnergyData: updatedRowData?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await deleteCacheData(keyObject, tempData)
        setData(updatedRowData);
    };

    const handleUpload = async (e, params, nodeIndex) => {
        console.log(e.target.files[0])
        let dataId;
        if (typeof params?.id === 'string') {
            dataId = params.id;
        } else {
            let id = uuidGenerator();
            dataId = id;
            data[nodeIndex].id = id;
        }
        let tempData = new FormData();
        tempData.append('files', e.target.files[0]);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            },
            body: tempData
        }
        await fetchWrapper(`${API_URL}/document/uploadEvidences?category=Steam&uploadPeriod=${preData?.uploadPeriod}
            &categoryDetailId=${dataId}&year=${preData?.year}&month=${preData?.month}&quarter=${preData?.quarter}
            &requestId=${requestId ? requestId : ''}&locationId=${preData?.locationId}`, options)
            .then((res) => {
                setLoading(false)
                let tempData = [...data];
                tempData[nodeIndex].documentId = res?.json?.documentId;
                tempData[nodeIndex].fileName = res?.json?.uploadFileName;
                setRequestId(res?.json?.requestId)
                tempData[nodeIndex].isInsert = true;
                tempData[nodeIndex].isUpdate = false;
                setData(tempData)
                createCache(data?.filter(ele => ele?.sourceId))
            })
            .catch((err) => {
                console.log(err)
                toast.error('Evidence upload failed!')
                setLoading(false)
            })
    }

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            suppressNavigable: true,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription')
                    || params.data.hasOwnProperty('sourceArea')
                    || params.data.hasOwnProperty('fuelType')
                    || params.data.hasOwnProperty('boilerEfficiency')
                    || params.data.hasOwnProperty('steamPurchased')
                    || params.data.hasOwnProperty('units')
                    || params.data.hasOwnProperty('locationCO2Factor')
                    || params.data.hasOwnProperty('locationCH4Factor')
                    || params.data.hasOwnProperty('locationN2OFactor')
                    || params.data.hasOwnProperty('marketCO2Factor')
                    || params.data.hasOwnProperty('marketCH4Factor')
                    || params.data.hasOwnProperty('marketN2OFactor')
                ) {
                    params.data.sourceId = `ST-${params.node.rowIndex + 1}`
                    return params.data.sourceId;
                }

            },
            filter: false,
            width: 100,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: true,
            filter: false,
            suppressNavigable: true,
            width: 200
        },
        {
            headerName: 'Source Area (Sq Ft)',
            field: 'sourceArea',
            cellEditor: 'agNumberCellEditor',
            width: 150,
            cellEditorParams: {
                min: 0,
                precision: 4,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.sourceArea
                    ? thousandSeparator(params?.data?.sourceArea?.toFixed(4)) : ''}</div>;
            },
            filter: false,
            editable: true,
            sortable: false,
            suppressNavigable: true,
        },
        {
            headerName: 'Fuel Type',
            field: 'fuelType',
            cellEditor: 'agSelectCellEditor',
            width: 250,
            cellEditorParams: {
                values: fuelData,
            },
            valueGetter: (params) => {
                if(params.data.fuelType) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelType);
                    params.data.resourceType = getCombusted?.resourceType;
                    return params.data.fuelType;
                }
            },
            editable: true,
            sortable: false,
            suppressNavigable: true,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Fuel State',
            field: 'fuelState',
            filter: false,
            editable: false,
            suppressNavigable: true,
            valueGetter: (params) => {
                if (params.data.fuelType) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelType);
                    params.data.fuelState = getCombusted?.itemType;
                    return params.data?.fuelState;
                }
            },
            width: 100,
            sortable: false
        },
        {
            headerName: 'Boiler Electricity (%)',
            field: 'boilerEfficiency',
            filter: false,
            width: 150,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.boilerEfficiency
                    && thousandSeparator(params?.data?.boilerEfficiency?.toFixed(4))}</div>;
            },
            editable: true,
            suppressNavigable: true,
        },
        {
            headerName: 'Steam Purchased',
            field: 'steamPurchased',
            cellEditor: 'agNumberCellEditor',
            width: 150,
            cellEditorParams: {
                min: 0,
                precision: 4,
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.steamPurchased
                    ? thousandSeparator(params?.data?.steamPurchased?.toFixed(4)) : ''}</div>;
            },
            filter: false,
            suppressNavigable: true,
            editable: true,
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            suppressNavigable: true,
            editable: (params) => {
                if (params.data?.fuelType) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelType);
                    let unitData = getCombusted?.uiUnit?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.fuelType) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelType);
                    let unitData = getCombusted?.uiUnit?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.fuelType) {
                    let getCombusted = combustedData?.find((item) => item?.sourceDescription === params.data?.fuelType);
                    let unitData = getCombusted?.uiUnit?.split(',');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.units) {
                    return params.data?.units
                }
            },
            width: 150,
            sortable: false
        },
        {
            headerName: 'Location Based',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'CO\u2082 Factor (kg/unit)',
                    field: 'locationCO2Factor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.locationCO2Factor
                            ? thousandSeparator(params?.data?.locationCO2Factor?.toFixed(4)) : ''}</div>;
                    },
                    editable: true,
                    headerClass: 'group-header-class',
                    suppressNavigable: true,

                },
                {
                    headerName: 'CH\u2084 Factor (g/unit)',
                    field: 'locationCH4Factor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.locationCH4Factor
                            ? thousandSeparator(params?.data?.locationCH4Factor?.toFixed(4)) : ''}</div>;
                    },
                    editable: true,
                    suppressNavigable: true,
                    headerClass: 'group-header-class',
                },
                {
                    headerName: 'N\u2082O Factor (g/unit)',
                    field: 'locationN2OFactor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.locationN2OFactor
                            ? thousandSeparator(params?.data?.locationN2OFactor?.toFixed(4)) : ''}</div>;
                    },
                    editable: true,
                    suppressNavigable: true,
                    headerClass: 'group-header-class',
                },
            ]
        },
        {
            headerName: 'Market Based',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'CO\u2082 Factor (kg/unit)',
                    field: 'marketCO2Factor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4,
                    },
                    suppressNavigable: true,
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.marketCO2Factor
                            ? thousandSeparator(params?.data?.marketCO2Factor?.toFixed(4)) : ''}</div>;
                    },
                    editable: true,
                    headerClass: 'group-header-class'
                },
                {
                    headerName: 'CH\u2084 Factor (g/unit)',
                    field: 'marketCH4Factor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.marketCH4Factor
                            ? thousandSeparator(params?.data?.marketCH4Factor?.toFixed(4)) : ''}</div>;
                    },
                    editable: true,
                    suppressNavigable: true,
                    headerClass: 'group-header-class',
                },
                {
                    headerName: 'N\u2082O Factor (g/unit)',
                    field: 'marketN2OFactor',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.marketN2OFactor
                            ? thousandSeparator(params?.data?.marketN2OFactor?.toFixed(4)) : ''}</div>;
                    },
                    editable: true,
                    suppressNavigable: true,
                    headerClass: 'group-header-class',
                },
            ]
        },
        {
            headerName: 'Evidence',
            autoHeight: true,
            wrapText: true,
            cellRenderer: (params) => {
                if(!params.data.fileName) {
                    return (
                        <div>
                            <label className="upload-btn">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex)}
                                />
                                Upload
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <h5 className="mb-0">{params.data.fileName}</h5>
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    params.data.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        }
    ]

    const addingRows = (val) => {
        setNoOfRow(val)
    }

    const addRows = () => {
        const rowData = [];
        for (let i = 1; i <= noOfRow; i++) {
            rowData.push({
                id: data?.length + i, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false
            })
        }
        setData([...data, ...rowData]);
    }

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    };

    const getGridData = () => {
        if (steamRef.current) {
            const tableState = data
                .filter((rowNode) => rowNode.sourceId)
                .map((rowNode, index) => {
                    if (rowNode.sourceArea && rowNode.fuelType && rowNode.sourceDescription && rowNode.fuelState
                        && (rowNode.boilerEfficiency > -1) && rowNode.steamPurchased && rowNode.resourceType) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            sourceArea: Number(rowNode.sourceArea),
                            fuelType: rowNode.fuelType,
                            fuelState: rowNode.fuelState,
                            resourceType: rowNode.resourceType,
                            units: rowNode.units,
                            boilerEfficiency: parseFloat(rowNode.boilerEfficiency),
                            steamPurchased: parseFloat(rowNode.steamPurchased),
                            locationCO2Factor: rowNode.locationCO2Factor ? parseFloat(rowNode.locationCO2Factor) : null,
                            locationCH4Factor: rowNode.locationCH4Factor ? parseFloat(rowNode.locationCH4Factor) : null,
                            locationN2OFactor: rowNode.locationN2OFactor ? parseFloat(rowNode.locationN2OFactor) : null,
                            marketCO2Factor: rowNode.marketCO2Factor ? parseFloat(rowNode.marketCO2Factor) : null,
                            marketCH4Factor: rowNode.marketCH4Factor ? parseFloat(rowNode.marketCH4Factor) : null,
                            marketN2OFactor: rowNode.marketN2OFactor ? parseFloat(rowNode.marketN2OFactor) : null,
                            documentId: rowNode.documentId ? rowNode.documentId : null,
                            fileName: rowNode.fileName ? rowNode.fileName : null,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(rowNode?.sourceId.split('-')[1]);
                        return {}
                    }
                });

            if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                error = true;
            } else {
                error = false
            }
            return tableState.filter(ele => Object.keys(ele)?.length > 0);
        }
        return [];
    }

    const onSubmit = async () => {
        const gridData = getGridData();
        if (error === true) {
            toast.error(`Please fill missing values for row ${[...new Set(rowIndex)].join(',')}`)
            setRowIndex([])
            error = false;
            return
        } else {
            setSteam(gridData)
            setSteps(0)
        }
    }

    const createCache = async (data) => {
        const tempData = {
            ...cacheData,
            steamEnergyData: data?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = (event) => {
        console.log(event.data)
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === false || event.data.isUpdate === true || event.data.isInsert === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false && typeof event.data.id !== 'string') {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        console.log(event.data)
        createCache(data)
    }

    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}>Fuel, Boiler, Steam and Emission Factor Data for Steam Purchased</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            {deleteLoading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div className="mtop-1 ag-group-header" style={{ opacity: deleteLoading ? '0.2' : '1' }}>
                <div className="steam-table">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={data?.filter(ele => ele?.isDelete === false)}
                            columnData={columnData}
                            gridOptions={gridOptions}
                            agRef={steamRef}
                            onCellValueChanged={onCellValueChanged}
                        />
                        <div className="ag-custom-footer">
                            <div className="d-flex ">
                                <button className="add-row-button" onClick={addRows}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                <input
                                    type="number"
                                    className="ml-1 input-number"
                                    value={noOfRow}
                                    onChange={(e) => addingRows(e.target.value)}
                                />
                                <h5 className="ml-1">rows</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default Steam;