// dashbaord
import UserDashboard from '../User/Dashboard/UserDashboard';
import PowerBiReport from '../User/Dashboard/PowerBiReport';

//Green-Carbon Pages
import Projects from '../User/ProjectHistory';
import CustomersList from '../Admin/Customer';
import EditProfile from '../Common/Component/Profile/EditProfile';
import UserManagement from '../Admin/UserManagement/UserManagement';
import EditUser from '../Admin/UserManagement/EditUser';
import ProponentProjects from '../Proponent/AsmUserProjectHistory';
import PaymentMethod from '../User/PaymentMethod';
import Subscription from '../User/Subscription';
import ViewAsmUserProject from '../Proponent/AsmUserProjectHistory/ViewProject';
import AdminProjects from '../Admin/AdminProjects';
import EditCustomer from '../Admin/Customer/Edit Customer';
import AssessorProjects from '../Assessor/AssessorProjects';
import UserProjectInit from '../User/UserProjectInit';
import AsmUserProjectInit from '../Proponent/AsmUserProjectInitiation';
import ViewAssessorProject from '../Assessor/AssessorProjects/ViewAssessorProject';
import ViewUserProject from '../User/ProjectHistory/ViewProject';
import ViewAdminProject from '../Admin/AdminProjects/AdminViewProject';
import AsoAdminProjectInit from '../ASOAdmin/AsoAdminProjectInit';
import AsoAdminProjectList from '../ASOAdmin/AsoAdminProjectList';
import ViewAsoAdminProject from '../ASOAdmin/AsoAdminProjectList/AsoAdminViewProject';
import AsmAdminProjectInit from '../ASMAdmin/AsmAdminProjectInit';
import AsmAdminProjectList from '../ASMAdmin/AsmAdminProjectList';
import ViewAsmAdminProject from '../ASMAdmin/AsmAdminProjectList/AsmAdminViewProject';
import AsrAdminProjectList from '../ASRAdmin/AsrAdminProjectList';
import ViewAsrAdminProject from '../ASRAdmin/AsrAdminProjectList/ViewAsrAdminProject';
import AsoUserlist from '../ASOAdmin/AsoUserOnboard/AsoUserlist';
import EditAsoUser from '../ASOAdmin/AsoUserOnboard/EditAsoUser';
import AsrUserlist from '../ASRAdmin/AssessorOnBoarding/AsrUserlist';
import EditAsrUser from '../ASRAdmin/AssessorOnBoarding/EditAsrUser';
import AsmUserlist from '../ASMAdmin/AsmUserOnboard/AsmUserlist';
import EditAsmUser from '../ASMAdmin/AsmUserOnboard/EditAsmUser';
import EditUserProject from '../User/ProjectHistory/EditProject';
import AsoAdminEditProject from '../ASOAdmin/AsoAdminProjectList/AsoAdminEditProject';
import ASMMapping from '../Common/Component/ASMMapping';
import AdminEditProject from '../Admin/AdminProjects/AdminEditProject';
import IssuedCredits from '../Common/Component/IssuedCredits';
import CertifyCredits from '../Common/Component/CertifyCredits';
import WalletSummary from '../Common/Component/Wallet/Summary';
import WalletDetailsPage from '../Common/Component/Wallet/Details';
import EditAssessorProject from '../Assessor/AssessorProjects/EditAssessorProject';
import EditAsrAdminProject from '../ASRAdmin/AsrAdminProjectList/EditAsrAdminProject';
import OrderDetails from '../Common/Component/CarbonTrading/Orders/OrderDetails';
import ViewCustomer from '../Admin/Customer/ViewCustomer';
import AdminOrders from '../Admin/AdminOrders';
import BOUserProjects from '../BackOffice/Project';
import ViewBOUserProject from '../BackOffice/Project/BOUserViewProject';
import BOUserEditProject from '../BackOffice/Project/BOUserEditProject';
import BOCustomersList from '../BackOffice/Customer';
import BOEditCustomer from '../BackOffice/Customer/BOEdit Customer';
import BOViewCustomer from '../BackOffice/Customer/BOViewCustomer';
import ModuleSubscribe from '../Common/Component/ModuleSubscription/moduleSubscribe';
import BOOrders from '../BackOffice/BOOrders';
import EditGroup from '../Common/Component/Groups/EditGroup';
import EditLocation from '../Common/Component/Location/EditLocation';
import ViewLocation from '../Common/Component/Location/ViewLocation';
import EmissionList from '../Common/Component/CarbonEmissionData/EmissionList';
import OrgSummary from '../Common/Component/OrgSummary';
import BuyOffsetsList from '../Common/Component/BuyOffsets/BuyOffsetsList';
import CategoryList from '../Common/Component/CreditRates/CategoryList';
import EditCategory from '../Common/Component/CreditRates/EditCategory';
import ExchangeRateList from '../Common/Component/ExchangeRate/ExchangeRateList';
import EditExchangeRate from '../Common/Component/ExchangeRate/EditExchangeRate';
import AsmAdminEditProject from '../ASMAdmin/AsmAdminProjectList/AsmAdminEditProject';
import CarbonTrading from '../Common/Component/CarbonTrading';
import CommonDashboard from '../Common/Component/Dashboard/CommonDashboard';
import ViewOrders from '../Common/Component/CarbonTrading/Orders/ViewOrders';
import GroupList from '../Common/Component/Groups/GroupList';
import LocationList from '../Common/Component/Location/LocationList';
import EvStationList from '../Common/Component/EvStations/EvStationList';
import ViewEvStation from '../Common/Component/EvStations/ViewEvStation';
import EditEvStation from '../Common/Component/EvStations/EditEvStation';
import EvChargerConsumptionList from '../Common/Component/EvChargerConsumption/EvChargerConsumptionList';
import EvChargerList from '../Common/Component/EvChargers/EvChargerList';
import EditEvCharger from '../Common/Component/EvChargers/EditEvCharger';
import EvStationConsumptionList from '../Common/Component/EvStationConsumption/EvStationConsumption';
import ConsumptionHistory from '../Common/Component/EvChargerConsumption/ConsumptionHistory';
import SubOrganizationList from '../Common/Component/SubOrganization/SubOrganizationList';
import EditSubOrganization from '../Common/Component/SubOrganization/EditSuborganization';
import ViewSubOrganization from '../Common/Component/SubOrganization/ViewSubOrganization';
import OwnershipHistory from '../Common/Component/OwnershipHistory';
import Monitoring from '../Common/Component/Monitoring';
import MonitoringHistory from '../Common/Component/MonitoringHistory/MonitoringHistory';
import MonitoringDocUpload from '../Common/Component/MonitoringDocUpload/MonitoringDocUpload';
import Offset from '../Common/Component/Offset';
import OffsetProjectList from '../Common/Component/BuyOffsets/OffsetProjectList';
import OffsetProjectDetails from '../Common/Component/BuyOffsets/OffsetProjectDetails';
import OrderReview from '../Common/Component/BuyOffsets/OrderReview';
import ProjectInitiation from '../Common/Component/ProjectInitiation';
import LandingPage from '../Common/Component/Settings/LandingPage';
import TargetSettingView from '../Common/Component/TargetSetting/TargetSettingView';
import ReportDownload from '../User/Dashboard/ReportDownload';
import ActionPlan from '../Common/Component/ActionPlan';
import EvidenceList from '../Common/Component/GhgDataCapture/EvidenceList';
import GhgDataCapture from '../Common/Component/GhgDataCapture';
import ScopeConfiguration from '../Common/Component/Settings/ScopeConfiguration';
import FaqHelp from '../Common/Layout/FaqPopup/FaqHelp';
import EmissionUnits from '../Common/Component/EmissionUnits/EmissionUnits';
import ApplicationSettings from '../Common/Component/Settings/Application';
import BusinessInfo from '../Common/Component/Settings/CompanyProfile/BusinessInfo';
import Environmental from '../Common/Component/SustainabilityReporting/Environmental/Environmental';
import Social from '../Common/Component/SustainabilityReporting/Social/Social';
import Governance from '../Common/Component/SustainabilityReporting/Governance/Governance';
import Reporting from '../Common/Component/SustainabilityReporting/Reporting';
import ApprovalQueue from '../Common/Component/GhgDataCapture/ApprovalQueue';
import AddAsoUser from '../ASOAdmin/AsoUserOnboard/AddAsoUser';
import ProgressSheet from '../Common/Component/GhgDataCapture/ProgressSheet';
import CustomerManagement from '../Common/Component/AdminForm/CustomerManagement';

const ghg_emission_request = {
        "moduleId": "GHGEmissions",
        "reportId": "GHGEmissionDashboard"
};

const gri_request = {
        "moduleId": "GHGEmissions",
        "reportId": "GRIDashboard"
};

const lighting_request = {
        "moduleId": "LIGHTING",
        "reportId": "LIGHTINGDashboard"
};

const hvac_request = {
        "moduleId": "HVAC",
        "reportId": "HVACDashboard"
};

const aqi_request = {
        "moduleId": "AQI",
        "reportId": "AQIDashboard"
};

const dimming_request = {
        "moduleId": "SmartEnergy-Lighting",
        "reportId": "Dimming"
};

const lifespan_request = {
        "moduleId": "SmartEnergy-Lighting",
        "reportId": "Lifespan"
};

const dimming_weather_request = {
        "moduleId": "SmartEnergy-Lighting",
        "reportId": "Dimming_Weather"
};

const energy_cost_request = {
        "moduleId": "SmartEnergy-Lighting",
        "reportId": "Energy_Cost"
};

const target_net_zero_request = {
        "moduleId": "GHGEmissions",
        "reportId": "Target-Net-Zero-Emission"
};

const emission_insights_request = {
        "moduleId": "GHGEmissions",
        "reportId": "EmissionsInsight"
};

const validation_report = {
        "moduleId": "GHGEmissions",
        "reportId": "ValidationReport"
};

const esg_scope1 = {
        "moduleId": "GHGEmissions",
        "reportId": "Scope1"
};

const esg_scope2 = {
        "moduleId": "GHGEmissions",
        "reportId": "Scope2"
};

const esg_scope3 = {
        "moduleId": "GHGEmissions",
        "reportId": "Scope3"
}

const customer_dashboard = {
        "moduleId": "Customer",
        "reportId": "CustomerProjectList"
}

const energy_dashboard = {
        "moduleId": "SustainabilityReporting",
        "reportId": "Energy"
}

const water_dashboard = {
        "moduleId": "SustainabilityReporting",
        "reportId": "Water"
}

const gri_302_dashboard = {
        "moduleId": "SustainabilityReporting",
        "reportId": "GRI302"
}

const emission_dashboard = {
        "moduleId": "SustainabilityReporting",
        "reportId": "GHGEmissions"
}

const waste_dashboard = {
        "moduleId": "SustainabilityReporting",
        "reportId": "DirectWaste"
}

export const routes = [

        { path: `${process.env.PUBLIC_URL}/edit-profile`, Component: <EditProfile /> },
        //FAQ and help
        { path: `${process.env.PUBLIC_URL}/faq-and-help`, Component: <FaqHelp /> },
        
        //Carbon Credits
        { path: `${process.env.PUBLIC_URL}/project-initiation/:origin`, Component: <AsoAdminProjectInit />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/projects`, Component: <AsoAdminProjectList />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/view-project/:id`, Component: <ViewAsoAdminProject />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/edit-project/:id`, Component: <AsoAdminEditProject />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/wallet-summary`, Component: <WalletSummary />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/carbon-trading/trading`, Component: <CarbonTrading />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/carbon-trading/orders`, Component: <ViewOrders />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/carbon-trading/orders-details/:id`, Component: <OrderDetails />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/ev-station-lists/:id/:name`, Component: <EvStationList />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/view-ev-station/:id`, Component: <ViewEvStation />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/edit-ev-station/:id`, Component: <EditEvStation />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/ev-charging-consumption/:id/:name`, Component: <EvChargerConsumptionList />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/monitoring/:id/:name/:type?/:frequency`, Component: <Monitoring />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/ev-charger-lists/:id/:name`, Component: <EvChargerList />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/edit-ev-charger/:id`, Component: <EditEvCharger />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/ev-station-consumption/:id/:name`, Component: <EvStationConsumptionList />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/ev-consumption-history/:id/:name`, Component: <ConsumptionHistory />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/monitoring-history/:id/:name/:type`, Component: <MonitoringHistory />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },
        { path: `${process.env.PUBLIC_URL}/monitoring-document-upload/:id/:name`, Component: <MonitoringDocUpload />, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], moduleId: 1 },

        //GHG
        { path: `${process.env.PUBLIC_URL}/group-list`, Component: <GroupList />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/groups/:id`, Component: <EditGroup />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/location-list`, Component: <LocationList />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/edit-location/:id`, Component: <EditLocation />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/view-location/:id`, Component: <ViewLocation />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/organization-summary`, Component: <OrgSummary />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/ghg-emission/:type/evidenceList/:id`, Component: <EvidenceList />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/target-settings`, Component: <TargetSettingView />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/ghg-action-plan`, Component: <ActionPlan />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/data-capture/:reqId?/:status?`, Component: <GhgDataCapture />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/data-capture/queue/:type`, Component: <ApprovalQueue />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/data-capture/progress`, Component: <ProgressSheet />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], moduleId: 2 },

        //Sustainability Reporting
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/environmental`, Component: <Environmental/>, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST'], moduleId: 6 },
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/business-info`, Component: <BusinessInfo />, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST'], moduleId: 6 },
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/social`, Component: <Social/>, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST'], moduleId: 6 },
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/governance`, Component: <Governance/>, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST'], moduleId: 6 },
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/reporting`, Component: <Reporting/>, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST'], moduleId: 6 },

        //Settings
        { path: `${process.env.PUBLIC_URL}/settings/application-settings`, Component: <ApplicationSettings />, allowedRoles: ['ASO_ADMIN', 'ASO_USER', 'EMISSIONS_ADMIN', 'EMISSIONS_ANALYST', 'EMISSIONS_APPROVER', 
                'DECARB_ADMIN', 'DECARB_ANALYST', 'AQI_ADMIN', 'AQI_ANALYST','HVAC_ADMIN', 'HVAC_ANALYST', 'SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'EMISSIONS_REPORT_USER', 'SUSTAINABILITY_REPORT_USER'], moduleId: 7 },
        { path: `${process.env.PUBLIC_URL}/downloads`, Component: <ReportDownload />, allowedRoles: ['ASO_ADMIN', 'ASO_USER', 'EMISSIONS_ADMIN', 'EMISSIONS_ANALYST', 'EMISSIONS_APPROVER', 
                'DECARB_ADMIN', 'DECARB_ANALYST', 'AQI_ADMIN', 'AQI_ANALYST','HVAC_ADMIN', 'HVAC_ANALYST', 'SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'EMISSIONS_REPORT_USER', 'SUSTAINABILITY_REPORT_USER'], moduleId: 7 },
        //User Onboarding
        { path: `${process.env.PUBLIC_URL}/user-list`, Component: <AsoUserlist />, allowedRoles: ['ASO_ADMIN', 'EMISSIONS_ADMIN', 'DECARB_ADMIN', 'AQI_ADMIN', 'HVAC_ADMIN', 'SUSTAINABILITY_ADMIN'], moduleId: 7 },
        { path: `${process.env.PUBLIC_URL}/add-user`, Component: <AddAsoUser/>, allowedRoles: ['ASO_ADMIN', 'EMISSIONS_ADMIN', 'DECARB_ADMIN', 'AQI_ADMIN', 'HVAC_ADMIN', 'SUSTAINABILITY_ADMIN'], moduleId: 7 },
        { path: `${process.env.PUBLIC_URL}/edit-user/:id`, Component: <EditAsoUser />, allowedRoles: ['ASO_ADMIN', 'EMISSIONS_ADMIN', 'DECARB_ADMIN', 'AQI_ADMIN', 'HVAC_ADMIN', 'SUSTAINABILITY_ADMIN'], moduleId: 7},
        { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <CommonDashboard /> },

        //Sub Org
        { path: `${process.env.PUBLIC_URL}/sub-organization-list`, Component: <SubOrganizationList />,allowedRoles: ['ASO_ADMIN', 'EMISSIONS_ADMIN', 'DECARB_ADMIN', 'AQI_ADMIN', 'HVAC_ADMIN', 'SUSTAINABILITY_ADMIN'], moduleId: 7 },
        { path: `${process.env.PUBLIC_URL}/edit-sub-organization/:id`, Component: <EditSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/view-sub-organization/:id`, Component: <ViewSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/module-origanization`, Component: <ModuleSubscribe /> },
        { path: `${process.env.PUBLIC_URL}/ownership-history/:id/:name`, Component: <OwnershipHistory />, allowedRoles: ['ASO_ADMIN', 'EMISSIONS_ADMIN', 'DECARB_ADMIN', 'AQI_ADMIN', 'HVAC_ADMIN', 'SUSTAINABILITY_ADMIN'], moduleId: 7 },
        
        //PowerBi dashboard
        { path: `${process.env.PUBLIC_URL}/ghg-accounting`, Component: <PowerBiReport request_param={ghg_emission_request} />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/target-net-zero-emission`, Component: <PowerBiReport request_param={target_net_zero_request} />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/emission-insights`, Component: <PowerBiReport request_param={emission_insights_request} />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], moduleId: 2 },
        { path: `${process.env.PUBLIC_URL}/validation-report`, Component: <PowerBiReport request_param={validation_report} />, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], moduleId: 2 },


        //sustainability reporting dahsboards
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/energy`, Component: <PowerBiReport request_param={energy_dashboard} />, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER'], moduleId: 6 },
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/water`, Component: <PowerBiReport request_param={water_dashboard} />, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER'], moduleId: 6 },
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/emission`, Component: <PowerBiReport request_param={emission_dashboard} />, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER'], moduleId: 6 },
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/waste`, Component: <PowerBiReport request_param={waste_dashboard} />, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER'], moduleId: 6 },
        { path: `${process.env.PUBLIC_URL}/sustainability-reporting/gri-302`, Component: <PowerBiReport request_param={gri_302_dashboard} />, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER'], moduleId: 6 },

        
        //decar
        { path: `${process.env.PUBLIC_URL}/forecasted-dimming-dashboard`, Component: <PowerBiReport request_param={dimming_request} />, allowedRoles: ['DECARB_ADMIN', 'DECARB_ANALYST'], moduleId: 3 },
        { path: `${process.env.PUBLIC_URL}/saved-energy-dashboard`, Component: <PowerBiReport request_param={energy_cost_request} />, allowedRoles: ['DECARB_ADMIN', 'DECARB_ANALYST'], moduleId: 3 },
        { path: `${process.env.PUBLIC_URL}/dimming-manager-dashboard`, Component: <PowerBiReport request_param={dimming_weather_request} />, allowedRoles: ['DECARB_ADMIN', 'DECARB_ANALYST'], moduleId: 3 },
        { path: `${process.env.PUBLIC_URL}/forecasted-lifespan-dashboard`, Component: <PowerBiReport request_param={lifespan_request} />, allowedRoles: ['DECARB_ADMIN', 'DECARB_ANALYST'], moduleId: 3 },
        //HVAC and AQI
        { path: `${process.env.PUBLIC_URL}/hvac-dashboard`, Component: <PowerBiReport request_param={hvac_request} />, allowedRoles: ['HVAC_ADMIN', 'HVAC_ANALYST'], moduleId: 4 },
        { path: `${process.env.PUBLIC_URL}/aqi-dashboard`, Component: <PowerBiReport request_param={aqi_request} />, allowedRoles: ['AQI_ADMIN', 'AQI_ANALYST'], moduleId: 5 },
        
        //Aso Admin
        //Carbon Credits
        { path: `${process.env.PUBLIC_URL}/aso-admin/project-initiation/:origin`, Component: <AsoAdminProjectInit /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/projects`, Component: <AsoAdminProjectList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/view-project/:id`, Component: <ViewAsoAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-project/:id`, Component: <AsoAdminEditProject /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/wallet-summary`, Component: <WalletSummary /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/trading`, Component: <CarbonTrading /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/orders`, Component: <ViewOrders /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/orders-details/:id`, Component: <OrderDetails /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-station-lists/:id/:name`, Component: <EvStationList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/view-ev-station/:id`, Component: <ViewEvStation /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-ev-station/:id`, Component: <EditEvStation /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-charging-consumption/:id/:name`, Component: <EvChargerConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/monitoring/:id/:name/:type?/:frequency`, Component: <Monitoring /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-charger-lists/:id/:name`, Component: <EvChargerList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-ev-charger/:id`, Component: <EditEvCharger /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-station-consumption/:id/:name`, Component: <EvStationConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-consumption-history/:id/:name`, Component: <ConsumptionHistory /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/monitoring-history/:id/:name/:type`, Component: <MonitoringHistory /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/monitoring-document-upload/:id/:name`, Component: <MonitoringDocUpload /> },

        //GHG
        { path: `${process.env.PUBLIC_URL}/aso-admin/group-list`, Component: <GroupList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/groups/:id`, Component: <EditGroup /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/location-list`, Component: <LocationList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-location/:id`, Component: <EditLocation /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/view-location/:id`, Component: <ViewLocation /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-emission-list`, Component: <EmissionList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/organization-summary`, Component: <OrgSummary /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-emission/:type/evidenceList/:id`, Component: <EvidenceList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/buy-offsets`, Component: <OffsetProjectList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/target-settings`, Component: <TargetSettingView /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/offsets`, Component: <Offset /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-action-plan`, Component: <ActionPlan /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/data-capture/:reqId?/:status?`, Component: <GhgDataCapture /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/data-capture/approval-queue`, Component: <ApprovalQueue /> },

        //Sustainability Reporting
        { path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/environmental`, Component: <Environmental/> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/business-info`, Component: <BusinessInfo /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/social`, Component: <Social/> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/governance`, Component: <Governance/> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/reporting`, Component: <Reporting/> },

        //User Onboarding
        { path: `${process.env.PUBLIC_URL}/aso-admin/user-list`, Component: <AsoUserlist /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/add-user`, Component: <AddAsoUser/> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-aso-user/:id`, Component: <EditAsoUser /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/dashboard`, Component: <CommonDashboard /> },
        
        //Settings
        { path: `${process.env.PUBLIC_URL}/aso-admin/settings/landing-page`, Component: <LandingPage /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/settings/scope-applicability`, Component: <ScopeConfiguration /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/settings/application-settings`, Component: <ApplicationSettings /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/downloads`, Component: <ReportDownload /> },

        //Sub Org
        { path: `${process.env.PUBLIC_URL}/aso-admin/sub-organization-list`, Component: <SubOrganizationList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-sub-organization/:id`, Component: <EditSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/view-sub-organization/:id`, Component: <ViewSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/module-origanization`, Component: <ModuleSubscribe /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ownership-history/:id/:name`, Component: <OwnershipHistory /> },
        
        //PowerBi dashboard
        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-accounting`, Component: <PowerBiReport request_param={ghg_emission_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/lighting-dashboard`, Component: <PowerBiReport request_param={lighting_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/hvac-dashboard`, Component: <PowerBiReport request_param={hvac_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/aqi-dashboard`, Component: <PowerBiReport request_param={aqi_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/gri-dashboard`, Component: <PowerBiReport request_param={gri_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/target-net-zero-emission`, Component: <PowerBiReport request_param={target_net_zero_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/emission-insights`, Component: <PowerBiReport request_param={emission_insights_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-emission-dashboard-scope1`, Component: <PowerBiReport request_param={esg_scope1} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-emission-dashboard-scope2`, Component: <PowerBiReport request_param={esg_scope2} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-emission-dashboard-scope3`, Component: <PowerBiReport request_param={esg_scope3} /> },
        //sustainability reporting dahsboards
        { path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/energy`, Component: <PowerBiReport request_param={energy_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/water`, Component: <PowerBiReport request_param={water_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/emission`, Component: <PowerBiReport request_param={emission_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/waste`, Component: <PowerBiReport request_param={waste_dashboard} /> },
        
        
        { path: `${process.env.PUBLIC_URL}/aso-admin/forecasted-dimming-dashboard`, Component: <PowerBiReport request_param={dimming_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/saved-energy-dashboard`, Component: <PowerBiReport request_param={energy_cost_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/dimming-manager-dashboard`, Component: <PowerBiReport request_param={dimming_weather_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/forecasted-lifespan-dashboard`, Component: <PowerBiReport request_param={lifespan_request} /> },

        //ASO User
        { path: `${process.env.PUBLIC_URL}/aso-user/dashboard`, Component: <CommonDashboard /> },

        //Carbon Credits
        { path: `${process.env.PUBLIC_URL}/aso-user/project-initiation/:origin`, Component: <UserProjectInit /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/projects`, Component: <Projects /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/payment`, Component: <PaymentMethod /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/subscription`, Component: <Subscription /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/view-project/:id`, Component: <ViewUserProject /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/edit-project/:id`, Component: <EditUserProject /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/wallet-summary`, Component: <WalletSummary /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/wallet-details`, Component: <WalletDetailsPage /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/carbon-trading/trading`, Component: <CarbonTrading /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/carbon-trading/orders`, Component: <ViewOrders /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/carbon-trading/orders-details/:id`, Component: <OrderDetails /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/ev-station-lists/:id/:name`, Component: <EvStationList /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/view-ev-station/:id`, Component: <ViewEvStation /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/edit-ev-station/:id`, Component: <EditEvStation /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/ev-charging-consumption/:id/:name`, Component: <EvChargerConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/monitoring/:id/:name/:type(/:frequency)`, Component: <Monitoring /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/ev-charger-lists/:id/:name`, Component: <EvChargerList /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/edit-ev-charger/:id`, Component: <EditEvCharger /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/ev-station-consumption/:id/:name`, Component: <EvStationConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/ev-consumption-history/:id/:name`, Component: <ConsumptionHistory /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/monitoring-document-upload/:id/:name`, Component: <MonitoringDocUpload /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/monitoring-history/:id/:name/:type`, Component: <MonitoringHistory /> },

        //GHG
        { path: `${process.env.PUBLIC_URL}/aso-user/ghg-action-plan`, Component: <ActionPlan /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/group-list`, Component: <GroupList /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/groups/:id`, Component: <EditGroup /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/location-list`, Component: <LocationList /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/edit-location/:id`, Component: <EditLocation /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/view-location/:id`, Component: <ViewLocation /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/carbon-emission-list`, Component: <EmissionList /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/organization-summary`, Component: <OrgSummary /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/target-settings`, Component: <TargetSettingView /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/data-capture`, Component: <GhgDataCapture /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/ghg-emission/:type/evidenceList/:id`, Component: <EvidenceList /> },
        
        //Sustainability Reporting
        { path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/environmental`, Component: <Environmental/> },
        { path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/business-info`, Component: <BusinessInfo /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/social`, Component: <Social/> },
        { path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/governance`, Component: <Governance/> },
        { path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/reporting`, Component: <Reporting/> },


        //Settings
        { path: `${process.env.PUBLIC_URL}/aso-admin/settings/application-settings`, Component: <ApplicationSettings /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/downloads`, Component: <ReportDownload /> },

        //power BI
        { path: `${process.env.PUBLIC_URL}/aso-user/lighting-dashboard`, Component: <PowerBiReport request_param={lighting_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/hvac-dashboard`, Component: <PowerBiReport request_param={hvac_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/aqi-dashboard`, Component: <PowerBiReport request_param={aqi_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/gri-dashboard`, Component: <PowerBiReport request_param={gri_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/target-net-zero-emission`, Component: <PowerBiReport request_param={target_net_zero_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/ghg-accounting`, Component: <PowerBiReport request_param={ghg_emission_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/forecasted-dimming-dashboard`, Component: <PowerBiReport request_param={dimming_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/saved-energy-dashboard`, Component: <PowerBiReport request_param={energy_cost_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/dimming-manager-dashboard`, Component: <PowerBiReport request_param={dimming_weather_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/forecasted-lifespan-dashboard`, Component: <PowerBiReport request_param={lifespan_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/emission-insights`, Component: <PowerBiReport request_param={emission_insights_request} /> },
        //sustainability reporting dahsboards
        { path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/energy`, Component: <PowerBiReport request_param={energy_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/water`, Component: <PowerBiReport request_param={water_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/emission`, Component: <PowerBiReport request_param={emission_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/waste`, Component: <PowerBiReport request_param={waste_dashboard} /> },
        
        //Analyst
        { path: `${process.env.PUBLIC_URL}/analyst/group-list`, Component: <GroupList /> },
        { path: `${process.env.PUBLIC_URL}/analyst/groups/:id`, Component: <EditGroup /> },
        { path: `${process.env.PUBLIC_URL}/analyst/location-list`, Component: <LocationList /> },
        { path: `${process.env.PUBLIC_URL}/analyst/edit-location/:id`, Component: <EditLocation /> },
        { path: `${process.env.PUBLIC_URL}/analyst/view-location/:id`, Component: <ViewLocation /> },
        { path: `${process.env.PUBLIC_URL}/analyst/carbon-emission-list`, Component: <EmissionList /> },
        { path: `${process.env.PUBLIC_URL}/analyst/organization-summary`, Component: <OrgSummary /> },
        { path: `${process.env.PUBLIC_URL}/analyst/ghg-emission/:type/evidenceList/:id`, Component: <EvidenceList /> },
        { path: `${process.env.PUBLIC_URL}/analyst/buy-offsets`, Component: <OffsetProjectList /> },
        { path: `${process.env.PUBLIC_URL}/analyst/target-settings`, Component: <TargetSettingView /> },
        { path: `${process.env.PUBLIC_URL}/analyst/offsets`, Component: <Offset /> },
        { path: `${process.env.PUBLIC_URL}/analyst/ghg-action-plan`, Component: <ActionPlan /> },
        { path: `${process.env.PUBLIC_URL}/analyst/data-capture/:reqId?/:status?`, Component: <GhgDataCapture /> },
        { path: `${process.env.PUBLIC_URL}/analyst/data-capture/approval-queue`, Component: <ApprovalQueue /> },

        //Sustainability Reporting
        { path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/environmental`, Component: <Environmental/> },
        { path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/business-info`, Component: <BusinessInfo /> },
        { path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/social`, Component: <Social/> },
        { path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/governance`, Component: <Governance/> },
        { path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/reporting`, Component: <Reporting/> },

        //settings
        { path: `${process.env.PUBLIC_URL}/analyst/settings/application-settings`, Component: <ApplicationSettings /> },
        { path: `${process.env.PUBLIC_URL}/analyst/downloads`, Component: <ReportDownload /> },

        //powerbi
        //ghg
        { path: `${process.env.PUBLIC_URL}/analyst/target-net-zero-emission`, Component: <PowerBiReport request_param={target_net_zero_request} /> },
        { path: `${process.env.PUBLIC_URL}/analyst/ghg-accounting`, Component: <PowerBiReport request_param={ghg_emission_request} /> },
        { path: `${process.env.PUBLIC_URL}/analyst/emission-insights`, Component: <PowerBiReport request_param={emission_insights_request} /> },
        
        //sustainability reporting dahsboards
        { path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/energy`, Component: <PowerBiReport request_param={energy_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/water`, Component: <PowerBiReport request_param={water_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/emission`, Component: <PowerBiReport request_param={emission_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/waste`, Component: <PowerBiReport request_param={waste_dashboard} /> },
        

        //Approver
        { path: `${process.env.PUBLIC_URL}/approver/group-list`, Component: <GroupList /> },
        { path: `${process.env.PUBLIC_URL}/approver/groups/:id`, Component: <EditGroup /> },
        { path: `${process.env.PUBLIC_URL}/approver/location-list`, Component: <LocationList /> },
        { path: `${process.env.PUBLIC_URL}/approver/edit-location/:id`, Component: <EditLocation /> },
        { path: `${process.env.PUBLIC_URL}/approver/view-location/:id`, Component: <ViewLocation /> },
        { path: `${process.env.PUBLIC_URL}/approver/carbon-emission-list`, Component: <EmissionList /> },
        { path: `${process.env.PUBLIC_URL}/approver/organization-summary`, Component: <OrgSummary /> },
        { path: `${process.env.PUBLIC_URL}/approver/ghg-emission/:type/evidenceList/:id`, Component: <EvidenceList /> },
        { path: `${process.env.PUBLIC_URL}/approver/buy-offsets`, Component: <OffsetProjectList /> },
        { path: `${process.env.PUBLIC_URL}/approver/target-settings`, Component: <TargetSettingView /> },
        { path: `${process.env.PUBLIC_URL}/approver/offsets`, Component: <Offset /> },
        { path: `${process.env.PUBLIC_URL}/approver/ghg-action-plan`, Component: <ActionPlan /> },
        { path: `${process.env.PUBLIC_URL}/approver/data-capture/:reqId?/:status?`, Component: <GhgDataCapture /> },
        { path: `${process.env.PUBLIC_URL}/approver/data-capture/approval-queue`, Component: <ApprovalQueue /> },

        //Sustainability Reporting
        { path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/environmental`, Component: <Environmental/> },
        { path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/business-info`, Component: <BusinessInfo /> },
        { path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/social`, Component: <Social/> },
        { path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/governance`, Component: <Governance/> },
        { path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/reporting`, Component: <Reporting/> },

        //settings
        { path: `${process.env.PUBLIC_URL}/approver/settings/application-settings`, Component: <ApplicationSettings /> },
        { path: `${process.env.PUBLIC_URL}/approver/downloads`, Component: <ReportDownload /> },
        
        //powerbi
        //ghg
        { path: `${process.env.PUBLIC_URL}/approver/target-net-zero-emission`, Component: <PowerBiReport request_param={target_net_zero_request} /> },
        { path: `${process.env.PUBLIC_URL}/approver/ghg-accounting`, Component: <PowerBiReport request_param={ghg_emission_request} /> },
        { path: `${process.env.PUBLIC_URL}/approver/emission-insights`, Component: <PowerBiReport request_param={emission_insights_request} /> },
        
        //sustainability reporting dahsboards
        { path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/energy`, Component: <PowerBiReport request_param={energy_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/water`, Component: <PowerBiReport request_param={water_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/emission`, Component: <PowerBiReport request_param={emission_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/waste`, Component: <PowerBiReport request_param={waste_dashboard} /> },
        

        //Backoffice pages
        { path: `${process.env.PUBLIC_URL}/back-office/dashboard`, Component: <UserDashboard /> },
        { path: `${process.env.PUBLIC_URL}/back-office/view-edit/:action/:id`, Component: <ViewBOUserProject /> },
        { path: `${process.env.PUBLIC_URL}/back-office/edit-project/:id`, Component: <BOUserEditProject /> },
        { path: `${process.env.PUBLIC_URL}/back-office/projects`, Component: <BOUserProjects /> },
        { path: `${process.env.PUBLIC_URL}/back-office/customer-list`, Component: <BOCustomersList /> },
        { path: `${process.env.PUBLIC_URL}/back-office/edit-customer/:id`, Component: <BOEditCustomer /> },
        { path: `${process.env.PUBLIC_URL}/back-office/view-customer/:id`, Component: <BOViewCustomer /> },
        { path: `${process.env.PUBLIC_URL}/back-office/asm-management/:id`, Component: <ASMMapping /> },
        { path: `${process.env.PUBLIC_URL}/back-office/project/:id/credits-issued`, Component: <IssuedCredits /> },
        { path: `${process.env.PUBLIC_URL}/back-office/orders`, Component: <BOOrders /> },
        { path: `${process.env.PUBLIC_URL}/back-office/module-origanization`, Component: <ModuleSubscribe /> },

        //Admin pages
        { path: `${process.env.PUBLIC_URL}/admin/customer-list`, Component: <CustomersList /> },
        { path: `${process.env.PUBLIC_URL}/admin/user-management`, Component: <UserManagement /> },
        { path: `${process.env.PUBLIC_URL}/admin/edit-user`, Component: <EditUser /> },
        { path: `${process.env.PUBLIC_URL}/admin/projects`, Component: <AdminProjects /> },
        { path: `${process.env.PUBLIC_URL}/admin/view-project/:id`, Component: <ViewAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/admin/edit-project/:id`, Component: <AdminEditProject /> },
        { path: `${process.env.PUBLIC_URL}/admin/edit-customer/:id`, Component: <EditCustomer /> },
        { path: `${process.env.PUBLIC_URL}/admin/asm-management/:id`, Component: <ASMMapping /> },
        { path: `${process.env.PUBLIC_URL}/admin/project/:id/credits-issued`, Component: <IssuedCredits /> },
        { path: `${process.env.PUBLIC_URL}/admin/view-customer/:id`, Component: <ViewCustomer /> },
        { path: `${process.env.PUBLIC_URL}/admin/dashboard`, Component: <PowerBiReport request_param={customer_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/admin/orders`, Component: <AdminOrders /> },
        { path: `${process.env.PUBLIC_URL}/admin/module-origanization`, Component: <ModuleSubscribe /> },
        { path: `${process.env.PUBLIC_URL}/admin/project/:id/certify-credits`, Component: <CertifyCredits /> },
        { path: `${process.env.PUBLIC_URL}/admin/credit-rates`, Component: <CategoryList /> },
        { path: `${process.env.PUBLIC_URL}/admin/credit-rates/edit/:id`, Component: <EditCategory /> },
        { path: `${process.env.PUBLIC_URL}/admin/exchange-rate-list`, Component: <ExchangeRateList /> },
        { path: `${process.env.PUBLIC_URL}/admin/exchange-rates/edit/:id`, Component: <EditExchangeRate /> },
        { path: `${process.env.PUBLIC_URL}/admin/sub-organization-list`, Component: <SubOrganizationList /> },
        { path: `${process.env.PUBLIC_URL}/admin/edit-sub-organization`, Component: <EditSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/admin/view-sub-organization/:id`, Component: <ViewSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/admin/project-initiation/:origin`, Component: <ProjectInitiation /> },
        { path: `${process.env.PUBLIC_URL}/admin/emission-factor-list`, Component: <EmissionUnits /> },
        { path: `${process.env.PUBLIC_URL}/admin/customer-management`, Component: <CustomerManagement /> },

        //Asset Manager Pages
        { path: `${process.env.PUBLIC_URL}/asm-user/projects`, Component: <ProponentProjects /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/project-initiation`, Component: <AsmUserProjectInit /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/view-project`, Component: <ViewAsmUserProject /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/ev-station-lists/:id/:name`, Component: <EvStationList /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/view-ev-station/:id`, Component: <ViewEvStation /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/edit-ev-station/:id`, Component: <EditEvStation /> },
        //Assessor pages
        { path: `${process.env.PUBLIC_URL}/assessor/dashboard`, Component: <UserDashboard /> },
        { path: `${process.env.PUBLIC_URL}/assessor/projects`, Component: <AssessorProjects /> },
        { path: `${process.env.PUBLIC_URL}/assessor/view-edit/:action/:id`, Component: <ViewAssessorProject /> },
        { path: `${process.env.PUBLIC_URL}/assessor/project/:id/credits-issued`, Component: <IssuedCredits /> },
        { path: `${process.env.PUBLIC_URL}/assessor/project/:id/certify-credits`, Component: <CertifyCredits /> },
        { path: `${process.env.PUBLIC_URL}/assessor/edit-project/:id`, Component: <EditAssessorProject /> },

        
        
        //Asset Manager Admin
        { path: `${process.env.PUBLIC_URL}/asm-admin/project-initiation/:origin`, Component: <AsmAdminProjectInit /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/projects`, Component: <AsmAdminProjectList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/view-project/:id`, Component: <ViewAsmAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-project/:id`, Component: <AsmAdminEditProject /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/dashboard`, Component: <CommonDashboard /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/wallet-summary`, Component: <WalletSummary /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/wallet-details`, Component: <WalletDetailsPage /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/trading`, Component: <CarbonTrading /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/orders`, Component: <ViewOrders /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/orders-details/:id/:orgId`, Component: <OrderDetails /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/group-list`, Component: <GroupList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/groups/:id`, Component: <EditGroup /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/location-list`, Component: <LocationList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-location/:id`, Component: <EditLocation /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/view-location/:id`, Component: <ViewLocation /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-emission-list`, Component: <EmissionList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/organization-summary`, Component: <OrgSummary /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ghg-emission-dashboard`, Component: <PowerBiReport request_param={ghg_emission_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/lighting-dashboard`, Component: <PowerBiReport request_param={lighting_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/hvac-dashboard`, Component: <PowerBiReport request_param={hvac_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/aqi-dashboard`, Component: <PowerBiReport request_param={aqi_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/gri-dashboard`, Component: <PowerBiReport request_param={gri_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-dimming-dashboard`, Component: <PowerBiReport request_param={dimming_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/saved-energy-dashboard`, Component: <PowerBiReport request_param={energy_cost_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/dimming-manager-dashboard`, Component: <PowerBiReport request_param={dimming_weather_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-lifespan-dashboard`, Component: <PowerBiReport request_param={lifespan_request} /> },

        { path: `${process.env.PUBLIC_URL}/aso-admin/buy-offsets`, Component: <OffsetProjectList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/offset-project-details/:id`, Component: <OffsetProjectDetails /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/user-list`, Component: <AsmUserlist /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-asm-user/:id`, Component: <EditAsmUser /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-station-lists/:id/:name`, Component: <EvStationList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/view-ev-station/:id`, Component: <ViewEvStation /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-ev-station/:id`, Component: <EditEvStation /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-charging-consumption/:id/:name`, Component: <EvChargerConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/monitoring/:id/:name/:type?/:frequency`, Component: <Monitoring /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-charger-lists/:id/:name`, Component: <EvChargerList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-ev-charger/:id`, Component: <EditEvCharger /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-station-consumption/:id/:name`, Component: <EvStationConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-consumption-history/:id/:name`, Component: <ConsumptionHistory /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/monitoring-document-upload/:id/:name`, Component: <MonitoringDocUpload /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/monitoring-history/:id/:name/:type`, Component: <MonitoringHistory /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/monitoring/:id/:name/:type?/:frequency`, Component: <Monitoring /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ownership-history/:id/:name`, Component: <OwnershipHistory /> },

        //Assessor Admin
        { path: `${process.env.PUBLIC_URL}/asr-admin/projects`, Component: <AsrAdminProjectList /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/view-project/:id`, Component: <ViewAsrAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/edit-project/:id`, Component: <EditAsrAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/user-list`, Component: <AsrUserlist /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/edit-asr-user/:id`, Component: <EditAsrUser /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/project/:id/credits-issued`, Component: <IssuedCredits /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/project/:id/certify-credits`, Component: <CertifyCredits /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/edit-project/:id`, Component: <EditAsrAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/dashboard`, Component: <UserDashboard /> }
];


