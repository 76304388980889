import React, { Fragment, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H6, Image } from '../../../AbstractElements';
import { API_URL } from '../../Config/Config';
import { EmailAddress } from '../../Constant';
import fetchWrapper from '../../api/fetchWrapper';
import { useForm } from 'react-hook-form';
import { Eye, EyeOff } from 'react-feather';
import { useNavigate } from 'react-router';
import ButtonLoader from '../ButtonLoader';

const ForgotPwd = () => {
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false)
    const { register, formState: { errors }, getValues, trigger, handleSubmit } = useForm()
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    const handleSent = async () => {
        setLoading(true)
        if (email != '') {
            const requestOptions = {
                method: 'POST',
            };
            await fetch(`${API_URL}/forgotPassword?email=${email}`, requestOptions)
                .then((res) => {
                    res.json()
                        .then((json) => {
                            if (res.status === 200) {
                                setSent(true);
                                setLoading(false)
                                toast.success(json.message)
                            } else if (res.status === 500) {
                                toast.error(json.message)
                                setLoading(false)
                                setSent(false)
                            }
                        })
                }, (error) => {
                    console.log(error);
                    setLoading(false)
                    if (error.status === 500) {
                        toast.error("Something went wrong")
                        setSent(false);
                    }

                });
        } else {
            setLoading(false)
            toast.error('Please Enter Email')
        }
    }

    const onSubmit = async (data) => {
        setLoading(true)
        const bodyData = {
            confirmationCode: data.otp,
            userName: email,
            newPassword: data.confirmPassword
        }
        const options = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bodyData),
        }
        await fetch(`${API_URL}/confirmForgotPassword`, options)
            .then((res) => {
                res.json()
                    .then((json) => {
                        if (res.status === 200) {
                            toast.success(json.message)
                            setLoading(false)
                            setTimeout(() => {
                                navigate(`${process.env.PUBLIC_URL}/login`)
                            }, 2000)
                        } else if (res.status === 500) {
                            toast.success(json.message)
                            setSent(false)
                            setLoading(false)
                        }
                    })
            }, (error) => {
                console.log(error);
                if (error.status === 500) {
                    toast.error("Something went wrong")
                    setSent(false);
                    setLoading(false)
                }
            });
    }

    return (
        <Fragment>
            <ToastContainer />
            <section>
                <Container className="p-0" fluid={true}>
                    <Row>
                        <Col xl='12'>
                            {
                                sent === false &&
                                <div className="login-card">
                                    <div className='login-main'>
                                        <div className='logo'>
                                            <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../../assets/images/logo/airoi.png')}` }} />
                                            <H6>Enter your email address and we'll send you a link to reset your password</H6>
                                        </div>
                                        <Form className="theme-form">
                                            <FormGroup className='form-group'>
                                                <Label className="col-form-label">{EmailAddress}<span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    required={true}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup className="form-group mb-0">
                                                <div className='text-end mt-3'>
                                                    {
                                                        loading ?
                                                            <ButtonLoader color='primary w-100' className={'btn-block'} loaderText={'Submitting'} /> :
                                                            <Btn attrBtn={{ color: 'primary w-100', className: 'btn-block', onClick: () => handleSent() }}>{'Submit'}</Btn>
                                                    }
                                                </div>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </div>
                            }
                            {
                                sent == true && <div className="login-card">
                                    <div className='login-main'>
                                        <div className='logo'>
                                            <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../../assets/images/logo/airoi.png')}` }} />
                                            <H6>Enter your email address and we'll send you a link to reset your password</H6>
                                        </div>
                                        <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                            <FormGroup className='form-group'>
                                                <Label className="col-form-label">Verification Code<span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    type="otp"
                                                    required={true}
                                                    {...register('otp', { required: true })}
                                                    onChange={e => setOtp(e.target.value)}
                                                />
                                                <span className='text-danger'>{errors.otp && errors.otp.type === 'required' && 'Please Enter Verification Code'}</span>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>New Password <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control mb-1"
                                                    name="newPassword"
                                                    onCopy={(e) => e.preventDefault()}
                                                    onPaste={(e) => e.preventDefault()}
                                                    type={toggleNewPassword ? 'text' : 'password'}
                                                    {...register('newPassword', { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g })}
                                                />
                                                <div className="show-hide reset-password" onClick={() => setToggleNewPassword(!toggleNewPassword)}>
                                                    <span>{toggleNewPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                                </div>
                                                <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'required' && 'Enter New Password'}</span>
                                                <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'pattern' && 'Your password contains 8 characters, One Upper case, One Special characters, One Numeric Characters'}</span>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Confirm New Password <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control mb-1"
                                                    name="confirmPassword"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    onCopy={(e) => e.preventDefault()}
                                                    onPaste={(e) => e.preventDefault()}
                                                    type={toggleConfirmPassword ? 'text' : 'password'}
                                                    {...register('confirmPassword', { required: true, validate: (value) => getValues('newPassword') === value })}
                                                />
                                                <div className="show-hide reset-password" onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
                                                    <span>{toggleConfirmPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                                </div>
                                                <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'required' && 'Enter Confirm Password'}</span>
                                                <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'validate' && 'New password and Confirm password must be same'}</span>
                                            </FormGroup>
                                            <FormGroup className="form-group mb-0">
                                                <div className='text-end mt-3'>
                                                    {
                                                        loading ?
                                                            <ButtonLoader color='primary w-100' className={'btn-block'} loaderText={'Reseting...'} /> :
                                                            <Btn attrBtn={{ color: 'primary w-100', className: 'btn-block', type: 'submit' }}>{'Reset Password'}</Btn>
                                                    }
                                                </div>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default ForgotPwd;