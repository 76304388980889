import React, { Fragment, useMemo } from "react";
import { AgGridReact } from 'ag-grid-react';

const AgGridDatasheet = ({ columnData, tableData, agRef, onGridReady, pinnedBottomRowData,
    onCellValueChanged, gridOptions, alwaysShowHorizontalScroll, rowValueChanged, context }) => {

    const defaultColDef = useMemo(() => {
        return {
          filter: false,
        };
      }, []);
    

    return (
        <Fragment>
            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                <AgGridReact
                    gridOptions={gridOptions}
                    columnDefs={columnData}
                    rowData={tableData}
                    domLayout='autoHeight'
                    context={context}
                    animateRows={true}
                    rowModelType='clientSide'
                    defaultColDef={defaultColDef}
                    ref={agRef}
                    enableBrowserTooltips={true}
                    alwaysShowHorizontalScroll={alwaysShowHorizontalScroll}
                    overlayLoadingTemplate={
                        `<div class="custom-loading-overlay">
                        <i class="fa fa-spinner fa-spin"></i> <span class='f-15'>Please wait while your data is loading...</span>
                      </div>`
                    }
                    overlayNoRowsTemplate={
                        '<span style="padding: 10px; border: 2px solid #444; border-radius: 5px; background: #0038680f">No Data Found</span>'
                    }
                    onGridReady={onGridReady}
                    pagination={false}
                    enterNavigatesVerticallyAfterEdit={true}
                    stopEditingWhenCellsLoseFocus={true}
                    singleClickEdit={true}
                    rowHeight={35}
                    onCellValueChanged={onCellValueChanged}
                    suppressMovableColumns={true}
                    scrollbarWidth={10}
                    rowValueChanged={rowValueChanged}
                    pinnedBottomRowData={pinnedBottomRowData && [pinnedBottomRowData]}
                    suppressRowTransform={true}
                />
            </div>
        </Fragment>
    )
}

export default AgGridDatasheet;