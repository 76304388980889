import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { LI, UL } from '../../../AbstractElements';
// import { MenuItem } from './Menu';
import { MenuItem } from './MenuModules';
import SidebarMenulist from './SidebarMenulist';
import { useStore, useSelector, useDispatch } from 'react-redux';
import { API_URL } from '../../../Common/Config/Config';
import fetchWrapper from '../../../Common/api/fetchWrapper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutHelper } from '../../api/logout_helper';
import SimpleBar from 'simplebar-react';
import { deepCopy } from '../../api/helper';

const SidebarMenuItems = ({ wrapper, margin, isToggle }) => {
  let userType = sessionStorage.getItem('userType');
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const [menuList, setMenuList] = useState();
  let tempMenu = deepCopy(MenuItem);
  const [moduleDetailsData, setModuleDetailsData] = useState();
  let suborgsOption = useStore().getState().user?.userDetails?.subOrgsEnabled;
  suborgsOption = suborgsOption ? suborgsOption : sessionStorage.getItem('suborgsEnabled');
  userType = userType ? userType : sessionStorage.getItem('userType');
  
  const dispatch = useDispatch();
  const appName = useSelector(state => state?.user?.appName);

  const addIsActiveToSubMenus = (menuData) => {

    const data = menuData?.map(item => {
      if (item.type === "sub") {
        item.isActive = isToggle ? true : false;
      }

      // Loop through the child elements if present
      if (item?.children) {
        item.children = item?.children?.map(child => {
          if (child.type === "sub") {
            child.isActive = isToggle ? true : false;
          }

          // Loop through the grandchild elements if present
          if (child?.children) {
            child.children = child?.children?.map(grandchild => {
              if (grandchild.type === "sub") {
                grandchild.isActive = isToggle ? true : false;
              }
              return grandchild;
            });
          }
          return child;
        });
      }
      return item;
    });
    return data;
  };

  useEffect(() => {
    if (!['SYS_USER', 'SYS_ADMIN', 'ASM_USER', 'ASR_ADMIN', 'ASR_USER', 'ASM_ADMIN']?.includes(userType)) {
      getSubscribeMenu()
    } else {
      setMenuList(MenuItem[userType])
    }

  }, [isToggle, appName])

  const getSubscribeMenu = async () => {
    await fetchWrapper(`${API_URL}/subscriptions/customer`)
      .then((res) => {
        console.log(res?.json)
        const data = res?.json;
        const defaultServiceMenu = {
          1: [MenuItem?.CarbonCredits],
          2: res?.json?.some(ele => ele?.moduleId == 6 && ele?.issubscribed == true) ? [MenuItem?.Net0TraceMenu, MenuItem?.SustainabilityReportingMenu] : [MenuItem?.Net0TraceMenu],
          3: [MenuItem?.DecarbonizationMenu],
          4: [MenuItem?.HVACInsight],
          5: [MenuItem?.AQIInsights],
        }
        if (data.length > 0) {
          let clientServiceMenu = {
            menutitle: '',
            menucontent: 'Dashboards,Widgets',
            Items: []
          }
          let detailObj = {};
          clientServiceMenu?.Items?.push(...defaultServiceMenu[appName])
          const result = data.reduce((acc, { moduleId, personaType }) => {
            if (!acc[moduleId]) {
              acc[moduleId] = [];
            }
            if (!acc[moduleId].includes(personaType)) {
              acc[moduleId].push(personaType);
            }
            return acc;
          }, {});
          detailObj = {
            ...result,
            7: [...new Set(Object?.values(result)?.flat())]
          }
          setModuleDetailsData(detailObj);
          let defaultItems = {...MenuItem?.defaultMenu};
          defaultItems.children = MenuItem?.defaultMenu?.children?.filter(ele => ele?.moduleId?.includes(appName))
          console.log(defaultItems)
          clientServiceMenu?.Items?.push(defaultItems);
          let tempMenu = { ...clientServiceMenu }
          tempMenu.Items = addIsActiveToSubMenus(tempMenu?.Items);
          setMenuList([tempMenu])
        } else {
          unSubscribeUser()
        }
      }).catch((err) => {
        console.log(err)
      });
  }



  const unSubscribeUser = () => {
    toast.error('Not subscribe any module for this organization. Please contact to green carbon admin.');
    logoutHelper()
    Navigate(`${process.env.PUBLIC_URL}/login`);
  }

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className = 'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
      if (item?.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header ';
        document.querySelector('.sidebar-main').className = 'sidebar-main ';
      }
    }
  };

  return (
    <Fragment>
      <UL attrUL={{ className: 'sidebar-links simple-list', id: 'simple-bar' }}>
        <SimpleBar style={{ height: '350px' }}>
          {/* <LI attrLI={{ className: 'back-btn' }}>
            <div className="mobile-back text-end"><span>{Back}</span><i class="fa-solid fa-angle-right ps-2"></i></div>
          </LI> */}
          {menuList && <SidebarMenulist MENUITEMS={menuList} t={t} toggletNavActive={toggletNavActive} isToggle={isToggle} moduleDetailsData={moduleDetailsData} />}
        </SimpleBar>
        <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
          <div className="simplebar-scrollbar" style={{ width: 0, display: 'none' }}></div>
        </div>
        <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
          <div className="simplebar-scrollbar" style={{ height: 77, transform: 'translate3d(0, 304, 0)', display: 'block' }}></div>
        </div>
      </UL >
    </Fragment >
  );
};
export default SidebarMenuItems;



// if(suborgsOption === true &&  !MenuItem[userType][0].defaultMenu[0]?.children?.some(ele => ele?.title === 'Sub Organizations')) {
//   MenuItem[userType][0].defaultMenu[0]?.children.push(...MenuItem[userType][0].subOrgMenu)
// }