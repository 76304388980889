import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { X } from "react-feather";
import UserForm from "../../Common/Component/UserForm";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";

const AddAsoUser = ({value, setOpenModal, orgData, setAdd}) => {
    const [open, setOpen] = useState(value);
        
    const onClose = () => {
        setOpen(false);
        setOpenModal(false)
    }

    
    return (
        <Fragment>
            <UserForm action={'Add'} title={'Add User'} navigateUrl={`${process.env.PUBLIC_URL}/aso-admin/user-list`} setAdd={setAdd}/>
            {/* <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{display: 'flex'}}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>New Member Onboarding <X className="x-button" onClick={onClose}/></H3>
                </ModalHeader>
                <ModalBody>
                    
                </ModalBody>
            </Modal> */}
        </Fragment>
    )
}

export default AddAsoUser;