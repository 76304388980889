import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, FormGroup, Label, Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";
import ButtonLoader from '../../ButtonLoader';
import LayoutLoader from '../../LayoutLoader/LayoutLoader';

const LandingPage = () => {
    const [modules, setModules] = useState([]);
    const [preferences, setPrefrences] = useState([]);
    const [screenId, setScreenId] = useState();
    const [moduleId, setModuleId] = useState();
    const [screenName, setScreenName] = useState();
    const [loading, setLoading] = useState(false);
    const [savedPref, setSavedPref] = useState({});

    const getSavedPreference = async () => {
        await fetchWrapper(`${API_URL}/preferences`)
            .then(async (res) => {
                console.log(res.json);
                setSavedPref(res.json[0])
                setModuleId(savedPref?.moduleId);
                await getPrefrences(savedPref?.moduleId);
                await setScreenId(savedPref?.screenId);
            }, (err) => console.log(err))
    }

    const getModules = async () => {
        await fetchWrapper(`${API_URL}/preferences/modules`)
            .then((res) => {
                setModules(res.json);
            }, (err) => console.log(err))
    }

    const getPrefrences = async (val) => {
        setModuleId(val);
        await fetchWrapper(`${API_URL}/preferences/module/${val}/list`)
            .then((res) => {
                setPrefrences(res.json); 
            }, (err) => console.log(err))
    }

    const handlePreferences = (val) => {
        setScreenId(val)
        const selectedItem = preferences?.find(ele => ele?.menuItemId == val);
        setScreenName(selectedItem?.menuItemName)
    }

    useEffect(() => {
        getModules()
        getSavedPreference()
    }, [])



    useEffect(() => {
        if (savedPref?.moduleId) {
            getPrefrences(savedPref?.moduleId);
        }
        console.log(savedPref)
    }, [savedPref])
    
    useEffect(()=>{
        if(savedPref && preferences?.length > 0) {
            setScreenId(savedPref?.screenId);
        }
    }, [preferences])
    
    const onSubmit = async () => {
        setLoading(true)
        const bodyData = [
            {
                moduleId,
                screenId,
                screenName: screenName
            }
        ]
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/preferences`, options)
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Landing Page saved successfully!')
                }
            }, (err) => {
                console.log(err)
                if (err?.status === 200) {
                    setLoading(false)
                    toast.success('Landing Page saved successfully!')
                }
            })
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col lg='10'>
                        <div className="d-flex mtop-1">
                            <Breadcrumbs mainTitle="Landing Page" />
                        </div>
                        <Card>
                            <CardBody className='data-tables responsive-view'>
                                <Row>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup>
                                                <Label for="exampleEmail">Select Module <span className='required'>*</span> </Label>
                                                <select name="moduleId" className="form-select"
                                                    value={moduleId}
                                                    onChange={(e) => getPrefrences(e.target.value)}>
                                                    <option value=''>-Select Module-</option>
                                                    {
                                                        modules?.map((item) => <option value={item?.moduleId}>{item?.moduleName}</option>)
                                                    }
                                                </select>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup>
                                                <Label for="exampleEmail">Select Landing Page <span className='required'>*</span> </Label>
                                                <select name="screenId" className="form-select"
                                                    value={screenId}
                                                    onChange={(e) => handlePreferences(e.target.value)}>
                                                    <option value=''>-Select Landing Page-</option>
                                                    {
                                                        preferences?.map((item) => <option value={item?.menuItemId}>{item?.menuItemName}</option>)
                                                    }
                                                </select>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mtop-1">
                                    {
                                        loading ? <ButtonLoader color={"primary"} loaderText={'Submitting'} /> :
                                            <Button color="primary" onClick={onSubmit}>Submit</Button>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default LandingPage;