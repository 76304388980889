import React, { Fragment, useEffect, useState, useRef, useCallback } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { defaultUserType, thousandSeparator } from '../../api/helper';
import { Plus, Trash2, XCircle } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import { uuidGenerator } from "./ghgHelper";
import { useSelector } from "react-redux";
import moment from "moment";
import { alertFunction, notifyFunction, storeData, deleteCacheData, getCacheData } from "./ghgHelper";


let error = false, table2Error = false, table3Error = false;
const FireSuppression = ({ setSteps, preData, status, setFsMaterial, setFsSimplified,
    setFsScreening, fsMaterial, fsSimplified, fsScreening, timeStamp, requestId, setRequestId }) => {
    const [data, setData] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })));
    const [table2Data, setTable2Data] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })))
    const [table3Data, setTable3Data] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })))
    const [noOfRow, setNoOfRow] = useState(1);
    const [noOfRowTable2, setNoOfRowTable2] = useState(1);
    const [noOfRowTable3, setNoOfRowTable3] = useState(1);
    const [loading, setLoading] = useState(false);
    const [gasData, setGasData] = useState([]);
    const [gasesArray, setGasesArray] = useState([]);
    const materialRef = useRef();
    const simplifiedRef = useRef();
    const screeningRef = useRef();
    const [equipment, setEquipment] = useState([]);
    const [rowIndex, setRowIndex] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [type, setType] = useState(1)
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState()
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }

    const getGasData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/FSScreeningMethod/${preData?.countryCode}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json;
                setGasData(data);
                const gases = data?.filter(ele => (ele?.availableFireSuppression === true)).map((item) => item.gas);
                setGasesArray(gases);
            }, (err) => console.log(err))
    }

    const getEquipmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/FSLeakRate/${preData?.countryCode}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json?.map((item) => item.typeOfEquipment);
                setEquipment(data);
            }, (err) => console.log(err))
    }
    const populateMaterial = (currentData) => {
        let count = currentData.length < 10 ? (10 - currentData.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setData(tempData)
        currentData?.length > 0 && setType(1)
    }

    const populateSimplifiedMaterial = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        const data = currentData?.map(({ gas, ...rest }) => ({ gasSimplified: gas, ...rest }))
        let tempData = [...data, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setTable2Data(tempData)
        currentData?.length > 0 && setType(2)
    }

    const populatedScreening = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
            let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
                id: index + 1, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false
            }))];
            setTable3Data(tempData)
            currentData?.length > 0 && setType(3)
    }

    const loadMaterialData = (savedData, cTimestamp) => {
        if (fsMaterial?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateMaterial(fsMaterial)
        } else if (fsMaterial?.length === 0 && savedData?.length > 0) {
            populateMaterial(savedData)
        } else if(fsMaterial?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, fsMaterial, populateMaterial)
            } else { //saved is latest
                notifyFunction(fsMaterial, populateMaterial)
            }
        }
    }

    const loadSimplifiedData = (savedData, cTimestamp) => {
        if (fsSimplified?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateSimplifiedMaterial(fsSimplified)
        } else if (fsSimplified?.length === 0 && savedData?.length > 0) {
            populateSimplifiedMaterial(savedData)
        } else if(fsSimplified?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, fsSimplified, populateSimplifiedMaterial)
            } else { //saved is latest
                notifyFunction(fsSimplified, populateSimplifiedMaterial)
            }
        }
    }

    const loadScreeningData = (savedData, cTimestamp) => {
        if (fsScreening?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populatedScreening(fsScreening)
        } else if (fsScreening?.length === 0 && savedData?.length > 0) {
            populatedScreening(savedData)
        } else if(fsScreening?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, fsScreening, populatedScreening)
            } else { //saved is latest
                notifyFunction(fsScreening, populatedScreening)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        if(cacheData?.fsMaterialData?.length > 0 || cacheData?.fsSimplifiedMaterialData?.length > 0 || cacheData?.fsScreeningData?.length > 0) {
            if(cacheData?.fsMaterialData?.length > 0) {
                loadMaterialData(cacheData?.fsMaterialData, cacheData?.modifiedTs)
            } else if(cacheData?.fsSimplifiedMaterialData?.length > 0) {
                loadSimplifiedData(cacheData?.fsSimplifiedMaterialData, cacheData?.modifiedTs)
            } else if(cacheData?.fsScreeningData?.length > 0) {
                loadScreeningData(cacheData?.fsScreeningData, cacheData?.modifiedTs)
            }
        } else {
            loadMaterialData()
            loadSimplifiedData()
            loadScreeningData() 
        }
    }

    const getGrids = async () => {
        fsMaterial?.length > 0 ? setType(1) : fsSimplified?.length > 0 ? setType(2) : fsScreening?.length > 0 ? setType(3) : setType(1);
        if (fsMaterial?.length > 0) {
            loadMaterialData()
        }
        if (fsSimplified?.length > 0) {
            loadSimplifiedData()
        }
        if (fsScreening?.length > 0) {
            loadScreeningData()
        }
    }

    useEffect(() => {
        if (status?.length > 0) {
            setType(status?.some((ele) => ele?.category === 'FSMaterialBalance') ? 1 :
                status?.some((ele) => ele?.category === 'FSSimplifiedMaterialBalance') ? 2 :
                    status?.some((ele) => ele?.category === 'FSScreeningMethod') ? 3 : 1)
        } else (
            setType(1)
        )
        getGasData()
        getEquipmentData()
        getCache()
    }, [])

    const handleClear = async(params, type) => {
        let tempData = type === 1 ? [...data] : type === 2 ? [...table2Data] : [...table3Data];
        const updatedRowData = tempData.map((row, index) => {
            return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                : ((row.id === params.data.id) && row.isSubmit === false) ?
                    { id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                    : row
        });
        let tempObj;
        if(type === 1) {
            tempObj = {
                ...cacheData,
                fsMaterialData: updatedRowData?.filter(ele => typeof ele?.id === 'string'),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if(type === 2) {
            tempObj = {
                ...cacheData,
                fsSimplifiedMaterialData: updatedRowData?.filter(ele => typeof ele?.id === 'string'),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if(type === 3) {
            tempObj = {
                ...cacheData,
                fsScreeningData: updatedRowData?.filter(ele => ele?.sourceId),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        }
        await deleteCacheData(keyObject, tempObj)
        type === 1 ? setData(updatedRowData) : type === 2 ? setTable2Data(updatedRowData) : setTable3Data(updatedRowData);
    };

    const handleUpload = async(e, params, nodeIndex, type) => {
        console.log(e.target.files[0])
        let dataId;
        if (typeof params?.id === 'string') {
            dataId = params.id;
        } else {
            let id = uuidGenerator();
            dataId = id;
            data[nodeIndex].id = id;
        }
        let tempData = new FormData();
        tempData.append('files', e.target.files[0]);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            },
            body: tempData
        }
        await fetchWrapper(`${API_URL}/document/uploadEvidences?category=FireSuppression&uploadPeriod=${preData?.uploadPeriod}
            &categoryDetailId=${dataId}&year=${preData?.year}&month=${preData?.month}&quarter=${preData?.quarter}
            &requestId=${requestId ? requestId : ''}&locationId=${preData?.locationId}`, options)
            .then((res) => {
                setLoading(false)
                let tempData = type === 1 ? [...data] : type === 2 ? [...table2Data] : [...table3Data];
                tempData[nodeIndex].documentId = res?.json?.documentId;
                tempData[nodeIndex].fileName = res?.json?.uploadFileName;
                setRequestId(res?.json?.requestId)
                tempData[nodeIndex].isInsert = true;
                tempData[nodeIndex].isUpdate = false;
                type === 1 ? setData(tempData) : type === 2 ? setTable2Data(tempData) : setTable3Data(tempData);
                type === 1 ? createCache(data?.filter(ele => ele?.sourceId)) : type === 2 ? createCache(table2Data?.filter(ele => ele?.sourceId)) : createCache(table3Data?.filter(ele => ele?.sourceId))
            })
            .catch((err) => {
                console.log(err)
                toast.error('Evidence upload failed!')
                setLoading(false)
            })
    }

    const table1ColumnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('gas') || params.data.hasOwnProperty('inventoryChange')
                    || params.data.hasOwnProperty('transferredAmount') || params.data.hasOwnProperty('capacityChange')) {
                    params.data.sourceId = `FS-${params.node.rowIndex + 1}`;
                    return params.data.sourceId;
                }
            },
            filter: false,
            width: 100,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Gas',
            field: 'gas',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: gasesArray,
            },
            valueGetter: (params) => {
                if(params.data.gas) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gas);
                    params.data.resourceType = getGasData?.resourceType
                    return params.data.gas;
                }
            },
            editable: true,
            filter: false,
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'Inventory Change',
            field: 'inventoryChange',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.inventoryChange ?
                    thousandSeparator(params?.data?.inventoryChange?.toFixed(4)) : ''}</div>;
            },
            flex: 2,
            editable: true,
        },
        {
            headerName: 'Transferred Amount',
            field: 'transferredAmount',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.transferredAmount ?
                    thousandSeparator(params?.data?.transferredAmount?.toFixed(4)) : ''}</div>;
            },
            flex: 2,
            editable: true,
        },
        {
            headerName: 'Capacity Change',
            field: 'capacityChange',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.capacityChange ?
                    thousandSeparator(params?.data?.capacityChange?.toFixed(4)) : ''}</div>;
            },
            flex: 2,
            editable: true,
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.gas) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gas);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.gas) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gas);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.gas) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gas);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.units) {
                    return params.data?.units
                }
            },
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            cellRenderer: (params) => {
                if(!params.data.fileName) {
                    return (
                        <div>
                            <label className="upload-btn">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex, type)}
                                />
                                Upload
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <h5 className="mb-0">{params.data.fileName}</h5>
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    (params.data.hasOwnProperty('gas') || params.data.hasOwnProperty('inventoryChange')
                        || params.data.hasOwnProperty('transferredAmount') || params.data.hasOwnProperty('capacityChange')) ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 1)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const table2Columns = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('gasSimplified')
                    || params.data.hasOwnProperty('unitsCharge')
                    || params.data.hasOwnProperty('unitsCapacity')
                    || params.data.hasOwnProperty('unitsRecharge')
                    || params.data.hasOwnProperty('disposedCapacity')
                    || params.data.hasOwnProperty('disposedRecovered')) {
                    params.data.sourceId = `FS-${params.node.rowIndex + 1}`;
                    return params.data.sourceId;
                }
            },
            filter: false,
            width: 100,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Gas',
            field: 'gasSimplified',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: gasesArray,
            },
            valueGetter: (params) => {
                if(params.data.gasSimplified) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gas);
                    params.data.resourceType = getGasData?.resourceType
                    return params.data.gasSimplified;
                }
            },
            editable: true,
            filter: false,
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'New Units',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Charge',
                    field: 'unitsCharge',
                    flex: 1,
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.unitsCharge ?
                            thousandSeparator(params?.data?.unitsCharge?.toFixed(4)) : ''}</div>;
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Capacity',
                    field: 'unitsCapacity',
                    cellEditor: 'agNumberCellEditor',
                    editable: true,
                    cellEditorParams: {
                        min: 0,
                        precision: 4
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.unitsCapacity ?
                            thousandSeparator(params?.data?.unitsCapacity?.toFixed(4)) : ''}</div>;
                    },
                    flex: 1,
                    headerClass: 'border-top-group-header-cell'
                },
            ],
        },
        {
            headerName: 'Existing Units',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Recharge',
                    flex: 1,
                    field: 'unitsRecharge',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        precision: 4
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.unitsRecharge ?
                            thousandSeparator(params?.data?.unitsRecharge?.toFixed(4)) : ''}</div>;
                    },
                    headerClass: 'border-top-group-header-cell'
                },
            ],
        },
        {
            headerName: 'Disposed Units',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Capacity',
                    flex: 1,
                    field: 'disposedCapacity',
                    cellEditor: 'agNumberCellEditor',
                    editable: true,
                    cellEditorParams: {
                        min: 0,
                        precision: 4
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.disposedCapacity ?
                            thousandSeparator(params?.data?.disposedCapacity?.toFixed(4)) : ''}</div>;
                    },
                    headerClass: 'border-top-group-header-cell'
                },
                {
                    headerName: 'Recovered',
                    flex: 1,
                    field: 'disposedRecovered',
                    cellEditor: 'agNumberCellEditor',
                    editable: true,
                    cellEditorParams: {
                        min: 0,
                        precision: 4
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.disposedRecovered ?
                            thousandSeparator(params?.data?.disposedRecovered?.toFixed(4)) : ''}</div>;
                    },
                    headerClass: 'border-top-group-header-cell'
                },
            ],
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.gasSimplified) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasSimplified);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.gasSimplified) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasSimplified);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.gasSimplified) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasSimplified);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.units) {
                    return params.data?.units
                }
            },
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            cellRenderer: (params) => {
                if(!params.data.fileName) {
                    return (
                        <div>
                            <label className="upload-btn">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex, type)}
                                />
                                Upload
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <h5 className="mb-0">{params.data.fileName}</h5>
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    (params.data.hasOwnProperty('gasSimplified')
                        || params.data.hasOwnProperty('unitsCharge')
                        || params.data.hasOwnProperty('unitsCapacity')
                        || params.data.hasOwnProperty('unitsRecharge')
                        || params.data.hasOwnProperty('disposedCapacity')
                        || params.data.hasOwnProperty('disposedRecovered')) ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 2)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const table3Columns = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data?.typeOfEquipment) {
                    params.data.sourceId = `FS-${params.node.rowIndex + 1}`
                }
                return params.data?.typeOfEquipment ? `FS-${params.node.rowIndex + 1}` : ''
            },
            filter: false,
            flex: 0.5,
        },
        {
            headerName: 'Type of Equipment',
            field: 'typeOfEquipment',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: equipment,
            },
            editable: true,
            filter: false,
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'Gas',
            field: 'gasType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: gasesArray,
            },
            valueGetter: (params) => {
                if(params.data.gasType) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasType);
                    params.data.resourceType = getGasData?.resourceType
                    return params.data.gasType;
                }
            },
            editable: true,
            filter: false,
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'Unit Capacity',
            field: 'unitCapacity',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.unitCapacity ?
                    thousandSeparator(params?.data?.unitCapacity?.toFixed(4)) : ''}</div>;
            },
            flex: 1,
            editable: true,
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.gasType) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasType);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return true
                    }
                } return false;
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.gasType) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasType);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length > 1) {
                        return {
                            values: unitData, // your dropdown options
                        };
                    }
                }
            },
            valueGetter: (params) => {
                if (params.data?.gasType) {
                    let getGasData = gasData?.find((item) => item?.gas === params.data?.gasType);
                    let unitData = getGasData?.units?.split(',');
                    if (unitData?.length === 1) {
                        params.data.units = unitData[0];
                        return unitData[0];
                    }
                }
                if (params.data?.units) {
                    return params.data?.units
                }
            },
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            cellRenderer: (params) => {
                if(!params.data.fileName) {
                    return (
                        <div>
                            <label className="upload-btn">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex, type)}
                                />
                                Upload
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <h5 className="mb-0">{params.data.fileName}</h5>
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    (params.data.hasOwnProperty('typeOfEquipment')
                        || params.data.hasOwnProperty('gasType')
                        || params.data.hasOwnProperty('unitCapacity')
                    ) ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 3)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    };

    const addingRows = (val, type) => {
        type === 1 ? setNoOfRow(val) : type === 2 ? setNoOfRowTable2(val) : setNoOfRowTable3(val);
    }

    const addRows = (type) => {
        const typeOfRow = type === 1 ? noOfRow : type === 2 ? noOfRowTable2 : noOfRowTable3;
        const rowData = [];
        for (let i = 1; i <= typeOfRow; i++) {
            const length = type === 1 ? data?.length : type === 2 ? table2Data?.length : table3Data?.length;
            rowData.push({
                id: length + i, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false
            })
        }
        type === 1 ? setData([...data, ...rowData]) : type === 2 ? setTable2Data([...table2Data, ...rowData]) : setTable3Data([...table3Data, ...rowData]);
    }

    const createCache = async (data, type) => {
        let tempData;
        if(type === 1) {
            tempData = {
                ...cacheData,
                fsSimplifiedMaterialData:[],
                fsScreeningData: [],
                fsMaterialData: data?.filter(ele => typeof ele?.id === 'string'),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if(type === 2) {
            tempData = {
                ...cacheData,
                fsScreeningData: [],
                fsMaterialData: [],
                fsSimplifiedMaterialData: data?.filter(ele => typeof ele?.id === 'string'),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if(type === 3) {
            tempData = {
                ...cacheData,
                fsSimplifiedMaterialData:[],
                fsMaterialData: [],
                fsScreeningData: data?.filter(ele => ele?.sourceId),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = (event, type) => {
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === false || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false && typeof event.data.id !== 'string') {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        type === 1 ? createCache(data, 1) : type === 2 ? createCache(table2Data, 2) : createCache(table3Data, 3)
    }

    const getGridData = (type) => {
        if (type === 1) {
            if (materialRef.current) {
                const tableState = data
                    .filter(rowNode => (rowNode.sourceId))
                    .map((rowNode, index) => {
                        if (rowNode.gas && rowNode.inventoryChange && rowNode.resourceType
                            && rowNode.transferredAmount && rowNode.units
                            && rowNode.capacityChange) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                gas: rowNode.gas,
                                resourceType: rowNode.resourceType,
                                inventoryChange: parseFloat(rowNode.inventoryChange),
                                transferredAmount: parseFloat(rowNode.transferredAmount),
                                capacityChange: parseFloat(rowNode.capacityChange),
                                units: rowNode.units,
                                documentId: rowNode.documentId ? rowNode.documentId : null,
                                fileName: rowNode.fileName ? rowNode.fileName : null,
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndex.push(rowNode?.sourceId.split('-')[1])
                            return {}
                        }
                    });
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    error = true;
                } else {
                    error = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        } else if (type === 2) {
            if (simplifiedRef.current) {
                const tableState = table2Data
                    .filter(rowNode => rowNode.sourceId)
                    .map((rowNode, index) => {
                        if (rowNode.gasSimplified && rowNode.unitsCharge && rowNode.resourceType && 
                            rowNode.unitsCapacity && rowNode.unitsRecharge && rowNode.units
                            && rowNode.disposedCapacity && rowNode.disposedRecovered) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                gas: rowNode.gasSimplified,
                                unitsCharge: parseFloat(rowNode.unitsCharge),
                                unitsCapacity: parseFloat(rowNode.unitsCapacity),
                                unitsRecharge: parseFloat(rowNode.unitsRecharge),
                                disposedCapacity: parseFloat(rowNode.disposedCapacity),
                                disposedRecovered: parseFloat(rowNode.disposedRecovered),
                                resourceType: rowNode.resourceType,
                                units: rowNode.units,
                                documentId: rowNode.documentId ? rowNode.documentId : null,
                                fileName: rowNode.fileName ? rowNode.fileName : null,
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndex.push(rowNode?.sourceId.split('-')[1])
                            return {}
                        }
                    });
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    table2Error = true;
                } else {
                    table2Error = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        } else {
            if (screeningRef.current) {
                const tableState = table3Data
                    .filter(rowNode => rowNode.sourceId)
                    .map((rowNode, index) => {
                        if (rowNode.typeOfEquipment && rowNode.gasType && rowNode.units
                            && rowNode.unitCapacity && rowNode.resourceType) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                typeOfEquipment: rowNode.typeOfEquipment,
                                resourceType: rowNode.resourceType,
                                gasType: rowNode.gasType,
                                unitCapacity: parseFloat(rowNode.unitCapacity),
                                units: rowNode.units,
                                documentId: rowNode.documentId ? rowNode.documentId : null,
                                fileName: rowNode.fileName ? rowNode.fileName : null,
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndex.push(rowNode?.sourceId.split('-')[1])
                            return {};
                        }
                    });
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    table3Error = true;
                } else {
                    table3Error = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        }
    }

    const onSubmit = async () => {
        const material = type === 1 ? getGridData(1) : [];
        const simplifiedMaterial = type === 2 ? getGridData(2) : [];
        const screenData = type === 3 ? getGridData(3) : [];
        if (error === true || table2Error === true || table3Error === true) {
            toast.error(`Please fill missing values in row ${[...new Set(rowIndex)].join(',')}`)
            setRowIndex([])
            error = false;
            return
        } else {
            setFsMaterial(material);
            setFsSimplified(simplifiedMaterial);
            setFsScreening(screenData);
            setSteps(0);
        }
    }

    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}> Organization-Wide Fire Suppression Gas CO<sub>2</sub> Equivalent Emissions</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            <div className='d-flex mtop-1'>
                <h5 className="mb-0 f-15 f-w-600">Fire Suppression Type:</h5>
                <input className="form-check-input radio_animated ml-1" type='radio' value={1}
                    checked={type === 1 ? true : false}
                    onChange={(e) => setType(1)}
                    disabled={(table2Data?.some(ele => ele?.createdBy) || table3Data?.some(ele => ele?.createdBy)) ? true : false}
                /> <lable>Material Balance</lable>
                <input className="form-check-input radio_animated ml-1" type='radio' value={2}
                    checked={type === 2 ? true : false}
                    onChange={(e) => setType(2)}
                    disabled={(data?.some(ele => ele?.createdBy) || table3Data?.some(ele => ele?.createdBy)) ? true : false}
                /> <lable>Simplified Material Balance</lable>
                <input className="form-check-input radio_animated ml-1" type='radio' value={3}
                    checked={type === 3 ? true : false}
                    onChange={(e) => setType(3)}
                    disabled={(table2Data?.some(ele => ele?.createdBy) || data?.some(ele => ele?.createdBy)) ? true : false}
                /> <lable>Screening Method</lable>
            </div>
            {deleteLoading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div style={{ opacity: deleteLoading ? '0.2' : '1' }}>
                {
                    type === 1 && <div className="mtop-1">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={data?.filter(ele => ele?.isDelete === false)}
                                columnData={table1ColumnData}
                                agRef={materialRef}
                                onCellValueChanged={(e) => onCellValueChanged(e, 1)}
                            />
                            <div className="ag-custom-footer">
                                <div className="d-flex ">
                                    <button className="add-row-button" onClick={() => addRows(1)}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                    <input
                                        type="number"
                                        className="ml-1 input-number"
                                        value={noOfRow}
                                        onChange={(e) => addingRows(e.target.value, 1)}
                                    />
                                    <h5 className="ml-1">rows</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    type === 2 && <div className="mtop-1  ag-group-header">
                        <div className="fs-simply-table">
                            <div style={{ width: '100%' }} className="ag-datasheet">
                                <AgGridDatasheet
                                    tableData={table2Data?.filter(ele => ele?.isDelete === false)}
                                    columnData={table2Columns}
                                    gridOptions={gridOptions}
                                    agRef={simplifiedRef}
                                    onCellValueChanged={(e) => onCellValueChanged(e, 2)}
                                />
                                <div className="ag-custom-footer">
                                    <div className="d-flex ">
                                        <button className="add-row-button" onClick={() => addRows(2)}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                        <input
                                            type="number"
                                            className="ml-1 input-number"
                                            value={noOfRowTable2}
                                            onChange={(e) => addingRows(e.target.value, 2)}
                                        />
                                        <h5 className="ml-1">rows</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {
                    type === 3 && <div className="mtop-1">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={table3Data?.filter(ele => ele?.isDelete === false)}
                                columnData={table3Columns}
                                gridOptions={gridOptions}
                                agRef={screeningRef}
                                onCellValueChanged={(e) => onCellValueChanged(e, 3)}
                            />
                            <div className="ag-custom-footer">
                                <div className="d-flex ">
                                    <button className="add-row-button" onClick={() => addRows(3)}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                    <input
                                        type="number"
                                        className="ml-1 input-number"
                                        value={noOfRowTable3}
                                        onChange={(e) => addingRows(e.target.value, 3)}
                                    />
                                    <h5 className="ml-1">rows</h5>
                                </div>
                            </div>

                        </div>

                    </div>
                }

            </div>


        </Fragment>
    )
}

export default FireSuppression;