import { Routes, Route, BrowserRouter as Router, useLocation, Navigate } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { defaultUserType } from '../Common/api/helper';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import Swal from 'sweetalert2';
import { logoutHelper } from '../Common/api/logout_helper';

const ValidateRouter = ({ children }) => {
    const location = useLocation();
    const pathUser = location.pathname?.split('/')[1];
    const userType = useSelector(state => state?.user?.userType);
    const authUrl = ['login', 'reset-password', 'forgot-password', '404-error', 'edit-profile',
        'session-expired', 'faq-and-help', 'system-maintenance', 'apps'];
    const expiringTime = sessionStorage.getItem('expiresIn');
    const currentTime = moment().unix();
    const accessToken = sessionStorage.getItem('accessToken');


    if (['SYS_USER', 'SYS_ADMIN', 'ASM_USER', 'ASR_ADMIN', 'ASR_USER', 'ASM_ADMIN'].includes(userType)) {
        if (defaultUserType[userType] === pathUser) {
            // return children
            if (Number(expiringTime) > currentTime) {
                return children
            } else {
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#006666',
                    title: 'Your Session has been Timeout. Please Login again',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: 'Logout',
                    allowOutsideClick: false
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        logoutHelper();
                    }
                })
            }
        } else if (authUrl?.includes(pathUser))
            return children
        else if (!pathUser)
            return (
                <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />
            )
        else {
            if (accessToken) {
                return (
                    <Navigate exact to={`${process.env.PUBLIC_URL}/404-error`} />
                )
            } else {
                return (
                    <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />
                )
            }
        }

    } else {
        return children;
    }
}


export default ValidateRouter;


