import React, { Fragment, useState, useEffect } from "react";
import LocationForm from "../Forms/LocationForm";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { Breadcrumbs } from "../../../AbstractElements";
import { defaultUserType } from "../../api/helper";
import { useParams } from "react-router";

const EditLocation = () => {
    const [ data, setData ] = useState();
    const locationId = useParams()?.id;

    useEffect(() => {
        loadData(locationId)
    }, []);

    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/location/${id}`, options)
        .then((res)=> {
            console.log(res)
            setData(res.json)
        })
        .catch((error) => {
            console.debug(error)
        })
    }

    const userType = sessionStorage.getItem('userType')

    return (
        <Fragment>
            <Breadcrumbs parent="Location" title="Edit" mainTitle='Edit Location' />
            <LocationForm action={'Edit'} navigateUrl={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/location-list`} preData={data} />
        </Fragment>
    )
}

export default EditLocation;