import React, { Fragment, useState, useEffect } from "react";
import { H3 } from "../../../AbstractElements";
import { Card, CardBody, Row, Col, FormGroup, Label, Badge, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import DatePicker from 'react-datepicker';
import {
    addIsSubmit, categoriesType, convertMonthToDate, convertQuarterToDate,
    removeIsSubmit, scope1Categories, scope2Categories, scope3Categories, uuidGenerator, deleteCache,
    getCacheData,
    addIsSubmitFalse,
    storeData
} from "./ghgHelper";
import AgGridDatasheet from "./AgGridDatasheet";
import { useNavigate, useParams } from "react-router";
import { convertTimeformat, defaultUserType } from "../../api/helper";
import Accordion from 'react-bootstrap/Accordion';
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import check from '../../../assets/images/tick.png';
import pending from '../../../assets/images/pending-icon.svg';
import { Paperclip, X } from 'react-feather';
import { useSelector } from "react-redux";
import ButtonLoader from '../ButtonLoader'
import TablePreview from "./GhgTablePreview";
import { CircularProgressbar } from "react-circular-progressbar";
import { Tooltip } from "react-tooltip";

const Summary = ({
    setSteps,
    setPreData,
    preData,
    setEmissionId,
    setType,
    setStatus,
    stationaryCombustionData, setStationaryCombustion,
    mobilesourcesData, setMobileSourcesData,
    rfgMaterialData, setRfgMaterial,
    rfgSimplifiedMaterialData, setRfgSimplified,
    rfgScreeningData, setRfgScreening,
    fsMaterialData, setFsMaterial,
    fsSimplifiedMaterialData, setFsSimplified,
    fsScreeningData, setFsScreening,
    purchasedGasesData, setPurchasedGases,
    energyData, setElectricity,
    steamEnergyData, setSteam,
    businessTravelPersonalData, setTravelPersonalData,
    businessTravelPublicData, setTravelPublicData,
    businessTravelAirwaysData, setTravelAirwaysData,
    travelCommutePersonalData, setTravelCommutePersonalData,
    travelCommutePublicData, setTravelCommutePublicData,
    upstreamVehicleMilesData, setTransportData,
    upstreamVehicleTonsData, setDistributeData,
    indirectWasteData, setIndirectWaste,
    waterConsumptionData, setWaterConsumptionData,
    waterDischargeData, setWaterDischargeData,
    directWasteData, setDirectWasteData,
    timeStamp, setTimestamp,
    requestId, setRequestId,
    reqId, reviewStatus,
    setLocationDetails
}) => {

    const [locationData, setLocationData] = useState([]);
    const [locationId, setLocationId] = useState(Object.keys(preData)?.length > 0 ? preData?.locationId : '');
    const [uploadPeriod, setUploadPeriod] = useState('YEAR');
    const [year, setYearValue] = useState(Object.keys(preData)?.length > 0 ? new Date(convertTimeformat(preData?.year)) : new Date(moment(new Date()).format('YYYY')));
    const [monthValue, setMonthValue] = useState(Object.keys(preData)?.length > 0 ? new Date(convertMonthToDate(preData?.month, preData?.year)) : new Date(convertMonthToDate(preData?.month, moment(year).format('YYYY'))));
    const [quarter, setQuarterValue] = useState('');
    const { register, formState: { errors }, trigger } = useForm();
    const [scope1, setScope1Categories] = useState([])
    const [scope2, setScope2Categories] = useState([])
    const [scope3, setScope3Categories] = useState([])
    const [emissionSetId, setEmissionSetId] = useState([]);
    const [countryCode, setCountryCode] = useState();
    const [employeeCount, setEmployeeCount] = useState();
    const ghgReportingFrequency = useSelector(state => state?.user?.userDetails?.ghgReportingFrequency);
    const [draftLoading, setDraftLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [tablesObject, setObject] = useState({});
    const [ghgStatus, setGhgStatus] = useState('NO_DATA');
    const [dataObject, setDataObject] = useState({});
    const [scopeCategoryList, setScopeCategoryList] = useState([]);
    const [scopeList, setScopeList] = useState([]);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState();
    const [rejectModalOpen, setRejectionOpen] = useState(false);
    const [rejectionNotes, setRejectionNotes] = useState();
    const [stateObject, setStateObject] = useState({
        stationaryCombustionData: stationaryCombustionData,
        mobilesourcesData: mobilesourcesData,
        rfgMaterialData: rfgMaterialData,
        rfgSimplifiedMaterialData: rfgSimplifiedMaterialData,
        rfgScreeningData: rfgScreeningData,
        fsMaterialData: fsMaterialData,
        fsSimplifiedMaterialData: fsSimplifiedMaterialData,
        fsScreeningData: fsScreeningData,
        purchasedGasesData: purchasedGasesData,
        energyData: energyData,
        steamEnergyData: steamEnergyData,
        businessTravelPersonalData: businessTravelPersonalData,
        businessTravelPublicData: businessTravelPublicData,
        businessTravelAirwaysData: businessTravelAirwaysData,
        travelCommutePersonalData: travelCommutePersonalData,
        travelCommutePublicData: travelCommutePublicData,
        upstreamVehicleMilesData: upstreamVehicleMilesData,
        upstreamVehicleTonsData: upstreamVehicleTonsData,
        indirectWasteData: indirectWasteData,
        directWasteData, setDirectWasteData,
        waterConsumptionData: waterConsumptionData,
        waterDischargeData, setWaterDischargeData,
    })

    const userObject = useSelector(state => state?.user?.userRoles);
    const navigate = useNavigate();

    const unfreezeStatus = {
        'EMISSIONS_ADMIN': ['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT', 'DATA_SUBMITTED', 'REJECTED'],
        'EMISSIONS_APPROVER': ['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT', 'DATA_SUBMITTED', 'REJECTED'],
        'EMISSIONS_ANALYST': ['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT', 'REJECTED']
    }

    const scope1Mapping = {
        stationaryCombustionData: 0,
        mobilesourcesData: 1,
        rfgMaterialData: 2,
        rfgSimplifiedMaterialData: 2,
        rfgScreeningData: 2,
        fsMaterialData: 3,
        fsSimplifiedMaterialData: 3,
        fsScreeningData: 3,
        purchasedGasesData: 4,
    };

    const scope2Mapping = {
        energyData: 0,
        steamEnergyData: 1,
    };

    const scope3Mapping = {
        businessTravelPersonalData: 0,
        businessTravelPublicData: 0,
        businessTravelAirwaysData: 0,
        travelCommutePersonalData: 1,
        travelCommutePublicData: 1,
        upstreamVehicleMilesData: 2,
        upstreamVehicleTonsData: 2,
        indirectWasteData: 3,
        directWasteData: 3,
        waterConsumptionData: 4,
        waterDischargeData: 4
    };

    const getLocations = async () => {
        let url = userObject[2]?.includes('EMISSIONS_ADMIN') ? 'location' : 'location/user/module';
        await fetchWrapper(`${API_URL}/${url}`)
            .then((res) => {
                if (userObject[2]?.includes('EMISSIONS_ANALYST') && reviewStatus !== 'review') {
                    setLocationData(res.json?.filter((res) => res?.role === 'EMISSIONS_ANALYST'))
                } else if (userObject[2]?.includes('EMISSIONS_APPROVER') && reviewStatus === 'review') {
                    setLocationData(res.json?.filter((res) => res?.role === 'EMISSIONS_APPROVER'))
                } else if (userObject[2]?.includes('EMISSIONS_ADMIN')) {
                    setLocationData(res.json)
                }

            }, (err) => console.log(err))
    }

    const getCache = async (dataObject) => {
        const keyObject = {
            customerId: userProfile?.org,
            userId: userProfile?.id,
            ...dataObject
        }
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData);
        const scope1Updated = [...scope1Categories];
        const scope2Updated = [...scope2Categories];
        const scope3Updated = [...scope3Categories];
        cacheData?.employeeCountData?.count ? setEmployeeCount(cacheData?.employeeCountData?.count) : setEmployeeCount(0)
        Object.keys(scope1Mapping).forEach(key => {
            if (scope1Mapping[key] === 2) {
                if (cacheData?.rfgMaterialData?.length > 0
                    || cacheData?.rfgSimplifiedMaterialData?.length > 0
                    || cacheData?.rfgScreeningData?.length > 0) {
                    scope1Updated[2].value = 1;
                } else {
                    scope1Updated[2].value = 0;
                }
            } else if (scope1Mapping[key] === 3) {
                if (cacheData?.fsMaterialData?.length > 0
                    || cacheData?.fsSimplifiedMaterialData?.length > 0
                    || cacheData?.fsScreeningData?.length > 0) {
                    scope1Updated[3].value = 1;
                } else {
                    scope1Updated[3].value = 0;
                }
            } else {
                if (cacheData[key]?.length > 0) {
                    scope1Updated[scope1Mapping[key]].value = 1;
                } else {
                    scope1Updated[scope1Mapping[key]].value = 0;
                }
            }

        });
        Object.keys(scope2Mapping).forEach(key => {
            if (cacheData[key]?.length > 0) {
                scope2Updated[scope2Mapping[key]].value = 1;
            } else {
                scope2Updated[scope2Mapping[key]].value = 0;
            }
        });
        Object.keys(scope3Mapping).forEach(key => {
            if (scope3Mapping[key] === 0) {
                if (cacheData?.businessTravelPersonalData?.length > 0
                    || cacheData?.businessTravelPublicData?.length > 0
                    || cacheData?.businessTravelAirwaysData?.length > 0) {
                    scope3Updated[0].value = 1
                } else {
                    scope3Updated[0].value = 0
                }
            } else if (scope3Mapping[key] === 1) {
                if (cacheData?.travelCommutePersonalData?.length > 0
                    || cacheData?.travelCommutePublicData?.length > 0) {
                    scope3Updated[1].value = 1
                } else {
                    scope3Updated[1].value = 0
                }
            } else if (scope3Mapping[key] === 2) {
                if (cacheData?.upstreamVehicleMilesData?.length > 0
                    || cacheData?.upstreamVehicleTonsData?.length > 0) {
                    scope3Updated[2].value = 1
                } else {
                    scope3Updated[2].value = 0
                }
            } else if (scope3Mapping[key] === 3) {
                if ((cacheData?.indirectWasteData?.length > 0
                    || cacheData?.directWasteData?.length > 0)) {
                    scope3Updated[3].value = 1
                } else {
                    scope3Updated[3].value = 0
                }
            } else if (scope3Mapping[key] === 4) {
                if ((cacheData?.waterConsumptionData?.length > 0
                    || cacheData?.waterDischargeData?.length > 0)) {
                    scope3Updated[4].value = 1
                } else {
                    scope3Updated[4].value = 0
                }
            }
        });
    }

    const getScopeCategories = async () => {
        await fetchWrapper(`${API_URL}/scopecategories/organization`)
            .then((res) => {
                let list = res?.json?.map(ele => ele?.categoryName);
                let scopeList = res?.json?.map(ele => ele?.scope);
                setScopeList([...new Set(scopeList)])
                setScopeCategoryList([...list]);
                setScope1Categories(scope1Categories?.filter(item => list?.includes(item?.category)))
                setScope2Categories(scope2Categories?.filter(item => list?.includes(item?.category)))
                setScope3Categories(scope3Categories?.filter(item => list?.includes(item?.category)))
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getScopeCategories()
    }, [])

    useEffect(() => {

    }, [scopeCategoryList])

    const deepMerge = (target, source) => {
        for (let key in source) {
            if (source[key] instanceof Object && key in target) {
                Object.assign(source[key], deepMerge(target[key], source[key]));
            }
        }
        Object.assign(target || {}, source);
        return target;
    }

    useEffect(() => {
        if (reviewStatus === 'review' && reqId) {
            getEmissionSetId()
        }
    }, [reviewStatus, reqId])

    const getEmissionSetId = async (data) => {
        let url, options;
        if (reviewStatus === 'review' && reqId) {
            url = `ghgemissionsdata/reqId/${reqId}`
            options = {
                method: 'GET'
            }
        } else {
            url = 'ghgemissionsdata';
            options = {
                method: 'POST',
                body: data
            }
        }
        await fetchWrapper(`${API_URL}/${url}`, options)
            .then(async (res) => {
                if (res.status === 200) {
                    console.log(res.json);
                    const keyObject = {
                        customerId: userProfile?.org,
                        userId: userProfile?.id,
                        ...data
                    }
                    let cacheData = await getCacheData(keyObject);
                    const tempData = res.json;
                    setGhgStatus(res.json?.status)
                    setDataObject(tempData);
                    setTimestamp(tempData?.lastModifiedTS);
                    setRequestId(data?.requestId);
                    let targetData;
                    targetData = deepMerge(tempData, cacheData);
                    // if(moment(cacheData?.modifiedTs).isAfter(moment(tempData?.lastModifiedTS))) {
                    //     targetData = deepMerge(cacheData, tempData)
                    // } else {
                    //     targetData = deepMerge(tempData, cacheData);
                    // }
                    setLocationId(res.json?.locationId)
                    setYearValue(new Date(convertTimeformat(res.json?.year)))
                    setMonthValue(new Date(convertMonthToDate(res.json?.month, res.json?.year)))
                    setQuarterValue(convertQuarterToDate(res.json?.quarter))
                    if (res.json?.status === 'REJECTED') {
                        setRejectionNotes(res.json?.approvalDetails[0]?.notes)
                    } else {
                        setRejectionNotes()
                    }
                    targetData?.employeeCountData?.count ? setEmployeeCount(targetData?.employeeCountData?.count) : setEmployeeCount(0);
                    if (res?.json?.version >= 1) {
                        console.log(addIsSubmit(targetData?.stationaryCombustionData, res.json?.status))
                        setStationaryCombustion(addIsSubmit(targetData?.stationaryCombustionData, res.json?.status));
                        setMobileSourcesData(addIsSubmit(targetData?.mobilesourcesData, res.json?.status));
                        setRfgMaterial(addIsSubmit(targetData?.rfgMaterialData, res.json?.status));
                        setRfgSimplified(addIsSubmit(targetData?.rfgSimplifiedMaterialData, res.json?.status));
                        setRfgScreening(addIsSubmit(targetData?.rfgScreeningData, res.json?.status));
                        setFsMaterial(addIsSubmit(targetData?.fsMaterialData, res.json?.status));
                        setFsSimplified(addIsSubmit(targetData?.fsSimplifiedMaterialData, res.json?.status));
                        setFsScreening(addIsSubmit(targetData?.fsScreeningData, res.json?.status));
                        setPurchasedGases(addIsSubmit(targetData?.purchasedGasesData, res.json?.status));
                        setElectricity(addIsSubmit(targetData?.energyData, res.json?.status));
                        setSteam(addIsSubmit(targetData?.steamEnergyData, res.json?.status));
                        setTravelPersonalData(addIsSubmit(targetData?.businessTravelPersonalData, res.json?.status));
                        setTravelPublicData(addIsSubmit(targetData?.businessTravelPublicData, res.json?.status));
                        setTravelAirwaysData(addIsSubmit(targetData?.businessTravelAirwaysData, res.json?.status));
                        setTravelCommutePersonalData(addIsSubmit(targetData?.travelCommutePersonalData, res.json?.status));
                        setTravelCommutePublicData(addIsSubmit(targetData?.travelCommutePublicData, res.json?.status));
                        setTransportData(addIsSubmit(targetData?.upstreamVehicleMilesData, res.json?.status));
                        setDistributeData(addIsSubmit(targetData?.upstreamVehicleTonsData, res.json?.status));
                        setIndirectWaste(addIsSubmit(targetData?.indirectWasteData, res.json?.status));
                        setDirectWasteData(addIsSubmit(targetData?.directWasteData, res.json?.status));
                        setWaterConsumptionData(addIsSubmit(targetData?.waterConsumptionData, res.json?.status));
                        setWaterDischargeData(addIsSubmit(targetData?.waterDischargeData, res.json?.status));
                    } else if ((res.json?.status === 'SAVE_AS_DRAFT' && res.json?.version < 2) || (res.json?.status === 'DATA_SUBMITTED' && res.json?.version == null)) {
                        console.log(addIsSubmitFalse(targetData?.stationaryCombustionData), 'get')
                        setStationaryCombustion(addIsSubmitFalse(targetData?.stationaryCombustionData));
                        setMobileSourcesData(addIsSubmitFalse(targetData?.mobilesourcesData));
                        setRfgMaterial(addIsSubmitFalse(targetData?.rfgMaterialData));
                        setRfgSimplified(addIsSubmitFalse(targetData?.rfgSimplifiedMaterialData));
                        setRfgScreening(addIsSubmitFalse(targetData?.rfgScreeningData));
                        setFsMaterial(addIsSubmitFalse(targetData?.fsMaterialData));
                        setFsSimplified(addIsSubmitFalse(targetData?.fsSimplifiedMaterialData));
                        setFsScreening(addIsSubmitFalse(targetData?.fsScreeningData));
                        setPurchasedGases(addIsSubmitFalse(targetData?.purchasedGasesData));
                        setElectricity(addIsSubmitFalse(targetData?.energyData));
                        setSteam(addIsSubmitFalse(targetData?.steamEnergyData));
                        setTravelPersonalData(addIsSubmitFalse(targetData?.businessTravelPersonalData));
                        setTravelPublicData(addIsSubmitFalse(targetData?.businessTravelPublicData));
                        setTravelAirwaysData(addIsSubmitFalse(targetData?.businessTravelAirwaysData));
                        setTravelCommutePersonalData(addIsSubmitFalse(targetData?.travelCommutePersonalData));
                        setTravelCommutePublicData(addIsSubmitFalse(targetData?.travelCommutePublicData));
                        setTransportData(addIsSubmitFalse(targetData?.upstreamVehicleMilesData));
                        setDistributeData(addIsSubmitFalse(targetData?.upstreamVehicleTonsData));
                        setIndirectWaste(addIsSubmitFalse(targetData?.indirectWasteData));
                        setDirectWasteData(addIsSubmitFalse(targetData?.directWasteData));
                        setWaterConsumptionData(addIsSubmitFalse(targetData?.waterConsumptionData));
                        setWaterDischargeData(addIsSubmitFalse(targetData?.waterDischargeData));
                    }
                    await updateSummaryStatus(targetData)
                }
            }, async (err) => {
                console.log(err)
                if (err.status === 404) {
                    setGhgStatus('NO_DATA')
                    setEmissionId()
                    setEmployeeCount(0)
                    setRequestId()
                    await setStationaryCombustion([]);
                    await setMobileSourcesData([]);
                    await setRfgMaterial([]);
                    await setRfgSimplified([]);
                    await setRfgScreening([]);
                    await setFsMaterial([]);
                    await setFsSimplified([]);
                    await setFsScreening([]);
                    await setPurchasedGases([]);
                    await setElectricity([]);
                    await setSteam([]);
                    await setTravelPersonalData([]);
                    await setTravelPublicData([]);
                    await setTravelAirwaysData([]);
                    await setTravelCommutePersonalData([]);
                    await setTravelCommutePublicData([]);
                    await setTransportData([]);
                    await setDistributeData([]);
                    await setIndirectWaste([]);
                    await setDirectWasteData([]);
                    await setWaterConsumptionData([]);
                    await setWaterDischargeData([]);
                    await getCache(data)
                }
            })
    }

    const handleSheets = async (type, hashValue) => {
        if (locationId) {
            let data = locationData.find(ele => ele.locationId == locationId);
            const objectData = {
                locationId: Number(locationId),
                countryCode: data?.countryCode === 'UAE' ? 'AE' : data?.countryCode,
                uploadPeriod: ghgReportingFrequency ? ghgReportingFrequency : 'YEAR',
                year: year ? parseInt(moment(year).format('YYYY')) : '',
                month: ghgReportingFrequency === 'MONTH' ? moment(monthValue).format('MMMM') : null,
                quarter: ghgReportingFrequency === 'QUARTER' ? parseInt(moment(quarter).format('Q')) : 0
            }
            setPreData({ ...objectData })
            setSteps(categoriesType[type])
        } else {
            toast.error('Please select location and period')
        }
    }

    const handleEvidence = (id, type) => {
        if (id && locationId) {
            setType(type)
            console.log(preData);
            setPreData(preData)
            setSteps(-1)
        } else if (!locationId) {
            toast.error('Please select location and period')
        } else if (!id) {
            toast.error('Please update GHG data')
        }

    }

    const columnData = [
        {
            headerName: 'Category',
            field: 'category',
            filter: false,
            flex: 3
        },
        {
            headerName: 'Status',
            filter: false,
            flex: 1,
            cellRenderer: (params) => {
                return <div>
                    {
                        params.data.value === 1 ? <img src={check} height={30} width={30} /> : ''
                    }
                </div>
            },
            cellClass: 'tick-center'
        },
        {

            width: 100,
            cellRenderer: (params) => {
                return (
                    <div>
                        <button
                            className={['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT', 'DATA_SUBMITTED', 'REJECTED'].includes(ghgStatus) ? "border-1px-solid" : 'disabled-button-border'}
                            disabled={!['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT', 'DATA_SUBMITTED', 'REJECTED'].includes(ghgStatus)}
                            onClick={() => handleSheets(params.data.category, params.data.hashValue)}>Click here</button>
                    </div>
                )
            }
        },
    ]

    const updateSummaryStatus = async (data) => {
        const scope1Updated = [...scope1Categories];
        const scope2Updated = [...scope2Categories];
        const scope3Updated = [...scope3Categories];

        if (Object?.keys(data)?.length > 0) {
            Object.keys(scope1Mapping).forEach(key => {
                if (scope1Mapping[key] === 2) {
                    if (data?.rfgMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.rfgSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.rfgScreeningData?.filter(ele => ele?.isDelete === false)?.length > 0) {
                        scope1Updated[2].value = 1;
                    } else {
                        scope1Updated[2].value = 0;
                    }
                } else if (scope1Mapping[key] === 3) {
                    if (data?.fsMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.fsSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.fsScreeningData?.filter(ele => ele?.isDelete === false)?.length > 0) {
                        scope1Updated[3].value = 1;
                    } else {
                        scope1Updated[3].value = 0;
                    }
                } else {
                    if (data[key]?.filter(ele => ele?.isDelete === false)?.length > 0) {
                        scope1Updated[scope1Mapping[key]].value = 1;
                    } else {
                        scope1Updated[scope1Mapping[key]].value = 0;
                    }
                }

            });
            Object.keys(scope2Mapping).forEach(key => {
                if (data[key]?.filter(ele => ele?.isDelete === false)?.length > 0) {
                    scope2Updated[scope2Mapping[key]].value = 1;
                } else {
                    scope2Updated[scope2Mapping[key]].value = 0;
                }
            });
            Object.keys(scope3Mapping).forEach(key => {
                if (scope3Mapping[key] === 0) {
                    if (data?.businessTravelPersonalData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.businessTravelPublicData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.businessTravelAirwaysData?.filter(ele => ele?.isDelete === false)?.length > 0) {
                        scope3Updated[0].value = 1
                    } else {
                        scope3Updated[0].value = 0
                    }
                } else if (scope3Mapping[key] === 1) {
                    if (data?.travelCommutePersonalData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.travelCommutePublicData?.filter(ele => ele?.isDelete === false)?.length > 0) {
                        scope3Updated[1].value = 1
                    } else {
                        scope3Updated[1].value = 0
                    }
                } else if (scope3Mapping[key] === 2) {
                    if (data?.upstreamVehicleMilesData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.upstreamVehicleTonsData?.filter(ele => ele?.isDelete === false)?.length > 0) {
                        scope3Updated[2].value = 1
                    } else {
                        scope3Updated[2].value = 0
                    }
                } else if (scope3Mapping[key] === 3) {
                    if ((data?.indirectWasteData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.directWasteData?.filter(ele => ele?.isDelete === false)?.length > 0)
                    ) {
                        scope3Updated[3].value = 1
                    } else {
                        scope3Updated[3].value = 0
                    }
                } else if (scope3Mapping[key] === 4) {
                    if ((data?.waterConsumptionData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || data?.waterDischargeData?.filter(ele => ele?.isDelete === false)?.length > 0)) {
                        scope3Updated[4].value = 1
                    } else {
                        scope3Updated[4].value = 0
                    }
                }
            });
        } else if (Object?.keys(data)?.length === 0) {
            Object.keys(scope1Mapping).forEach(key => {
                if (scope1Mapping[key] === 2) {
                    if ((stateObject?.rfgMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || stateObject?.rfgSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || stateObject?.rfgScreeningData?.filter(ele => ele?.isDelete === false)?.length > 0) 
                        || (cacheData?.rfgMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || cacheData?.rfgSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || cacheData?.rfgScreeningData?.filter(ele => ele?.isDelete === false)?.length > 0)) {
                        scope1Updated[2].value = 1;
                    } else {
                        scope1Updated[2].value = 0;
                    }
                } else if (scope1Mapping[key] === 3) {
                    if ((stateObject?.fsMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || stateObject?.fsSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || stateObject?.fsScreeningData?.filter(ele => ele?.isDelete === false)?.length > 0) 
                        || (cacheData?.fsMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || cacheData?.fsSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || cacheData?.fsScreeningData?.filter(ele => ele?.isDelete === false)?.length > 0)) {
                        scope1Updated[3].value = 1;
                    } else {
                        scope1Updated[3].value = 0;
                    }
                } else {
                    if (((stateObject?.[key] || [])?.filter(ele => ele?.isDelete === false)?.length > 0) 
                        || ((cacheData?.[key] || [])?.filter(ele => ele?.isDelete === false)?.length > 0)) {
                        scope1Updated[scope1Mapping[key]].value = 1;
                    } else {
                        scope1Updated[scope1Mapping[key]].value = 0;
                    }
                }

            });
            Object.keys(scope2Mapping).forEach(key => {
                if (((stateObject?.[key]?.filter(ele => ele?.isDelete === false) || [])?.length > 0) 
                    || ((cacheData?.[key]?.filter(ele => ele?.isDelete === false) || [])?.length > 0)) {
                    scope2Updated[scope2Mapping[key]].value = 1;
                } else {
                    scope2Updated[scope2Mapping[key]].value = 0;
                }
            });

            // Update scope3Categories
            Object.keys(scope3Mapping).forEach(key => {
                if (scope3Mapping[key] === 0) {
                    if ((cacheData?.businessTravelPersonalData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || cacheData?.businessTravelPublicData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || cacheData?.businessTravelAirwaysData?.filter(ele => ele?.isDelete === false)?.length > 0)
                        || (stateObject?.businessTravelPersonalData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || stateObject?.businessTravelPublicData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || stateObject?.businessTravelAirwaysData?.filter(ele => ele?.isDelete === false)?.length > 0)) {
                        scope3Updated[0].value = 1
                    } else {
                        scope3Updated[0].value = 0
                    }
                } else if (scope3Mapping[key] === 1) {
                    if ((cacheData?.travelCommutePersonalData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || cacheData?.travelCommutePublicData?.filter(ele => ele?.isDelete === false)?.length > 0)
                        || (stateObject?.travelCommutePersonalData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || stateObject?.travelCommutePublicData?.filter(ele => ele?.isDelete === false)?.length > 0)) {
                        scope3Updated[1].value = 1
                    } else {
                        scope3Updated[1].value = 0
                    }
                } else if (scope3Mapping[key] === 2) {
                    if ((cacheData?.upstreamVehicleMilesData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || cacheData?.upstreamVehicleTonsData?.filter(ele => ele?.isDelete === false)?.length > 0)
                        || (stateObject?.upstreamVehicleMilesData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || stateObject?.upstreamVehicleTonsData?.filter(ele => ele?.isDelete === false)?.length > 0)) {
                        scope3Updated[2].value = 1
                    } else {
                        scope3Updated[2].value = 0
                    }
                } else if (scope3Mapping[key] === 3) {
                    if ((cacheData?.indirectWasteData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || cacheData?.directWasteData?.filter(ele => ele?.isDelete === false)?.length > 0)
                        || (stateObject?.indirectWasteData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || stateObject?.directWasteData?.filter(ele => ele?.isDelete === false)?.length > 0)) {
                        scope3Updated[3].value = 1
                    } else {
                        scope3Updated[3].value = 0
                    }
                } else if (scope3Mapping[key] === 4) {
                    if ((cacheData?.waterConsumptionData?.filter(ele => ele?.isDelete === false)?.length > 0
                        || cacheData?.waterDischargeData?.filter(ele => ele?.isDelete === false)?.length > 0)
                        || (stateObject?.waterConsumptionData?.filter(ele => ele?.isDelete === false)?.length > 0
                            || stateObject?.waterDischargeData?.filter(ele => ele?.isDelete === false)?.length > 0)) {
                        scope3Updated[4].value = 1
                    } else {
                        scope3Updated[4].value = 0
                    }
                }
            });
        }
    }

    const getSummaryStatus = async (locationId, uploadPeriod, year, month, quarter) => {
        let data = locationData.find(ele => ele.locationId == locationId);
        const dataObject = {
            locationId: Number(locationId),
            countryCode: data?.countryCode,
            uploadPeriod: ghgReportingFrequency ? ghgReportingFrequency : 'YEAR',
            year: year ? parseInt(moment(year).format('YYYY')) : parseInt(moment(new Date()).format('YYYY')),
            month: (ghgReportingFrequency === 'MONTH' && month) ? moment(month).format('MMMM') :
                (ghgReportingFrequency === 'MONTH' && monthValue) ? moment(monthValue).format('MMMM') : null,
            quarter: ghgReportingFrequency === 'QUARTER' ? parseInt(moment(quarter).format('Q')) : 0
        }
        console.log(dataObject)
        await getEmissionSetId(dataObject);
        setPreData(dataObject)
        // await updateSummaryStatus()
    }

    const handleLocation = async (value) => {
        setLocationId(value)
        let data = locationData.find(ele => ele.locationId === value);
        setLocationDetails(data);
        setCountryCode(data?.countryCode);
        await getSummaryStatus(value, uploadPeriod, year, monthValue, quarter)
    }

    const handleYear = async (e) => {
        setYearValue(e)
        await getSummaryStatus(locationId, uploadPeriod, e, monthValue, quarter)
    }

    const handleMonth = async (e) => {
        setMonthValue(e)
        await getSummaryStatus(locationId, uploadPeriod, year, e, quarter)
    }

    const handleQuarter = async (e) => {
        setQuarterValue(e)
        await getSummaryStatus(locationId, uploadPeriod, year, monthValue, e)
    }

    const handlePeriod = async (value) => {
        setUploadPeriod(value);
        await getSummaryStatus(locationId, value, year, monthValue, quarter)
    }

    useEffect(() => {
        getLocations()
        console.log(monthValue, year)
        if (preData) {
            setYearValue(new Date(convertTimeformat(preData?.year)))
            setMonthValue(new Date(convertMonthToDate(preData?.month)))
            setQuarterValue(convertQuarterToDate(preData?.quarter))
            getCache(preData)
        }
    }, [])

    useEffect(() => {
        updateSummaryStatus(dataObject);
    }, [scope1, scope2, scope3]);


    const handleSaveDraft = async () => {
        const tableObject = {
            ...preData,
            status: 'SAVE_AS_DRAFT',
            stationaryCombustionData: stationaryCombustionData?.length > 0 ? removeIsSubmit(stationaryCombustionData) : cacheData?.stationaryCombustionData ? removeIsSubmit(cacheData?.stationaryCombustionData) : [],
            mobilesourcesData: mobilesourcesData?.length > 0 ? removeIsSubmit(mobilesourcesData) : cacheData?.mobilesourcesData ? removeIsSubmit(cacheData?.mobilesourcesData) : [],
            rfgMaterialData: rfgMaterialData?.length > 0 ? removeIsSubmit(rfgMaterialData) : cacheData?.rfgMaterialData?.length > 0 ? removeIsSubmit(cacheData?.rfgMaterialData) : [],
            rfgSimplifiedMaterialData: rfgSimplifiedMaterialData?.length > 0 ? removeIsSubmit(rfgSimplifiedMaterialData) : cacheData?.rfgSimplifiedMaterialData?.length > 0 ? removeIsSubmit(cacheData?.rfgSimplifiedMaterialData) : [],
            rfgScreeningData: rfgScreeningData?.length > 0 ? removeIsSubmit(rfgScreeningData) : cacheData?.rfgScreeningData?.length > 0 ? removeIsSubmit(cacheData?.rfgScreeningData) : [],
            fsMaterialData: fsMaterialData?.length > 0 ? removeIsSubmit(fsMaterialData) : cacheData?.fsMaterialData?.length ? removeIsSubmit(cacheData?.fsMaterialData) : [],
            fsSimplifiedMaterialData: fsSimplifiedMaterialData?.length > 0 ? removeIsSubmit(fsSimplifiedMaterialData) : cacheData?.fsSimplifiedMaterialData?.length > 0 ? removeIsSubmit(cacheData?.fsSimplifiedMaterialData) : [],
            fsScreeningData: fsScreeningData?.length > 0 ? removeIsSubmit(fsScreeningData) : cacheData?.fsScreeningData?.length > 0 ? removeIsSubmit(cacheData?.fsScreeningData) : [],
            purchasedGasesData: purchasedGasesData?.length > 0 ? removeIsSubmit(purchasedGasesData) : cacheData?.purchasedGasesData ? removeIsSubmit(cacheData?.purchasedGasesData) : [],
            energyData: energyData?.length > 0 ? removeIsSubmit(energyData) : cacheData?.energyData ? removeIsSubmit(cacheData?.energyData) : [],
            steamEnergyData: steamEnergyData?.length > 0 ? removeIsSubmit(steamEnergyData) : cacheData?.steamEnergyData ? removeIsSubmit(cacheData?.steamEnergyData) : [],
            businessTravelPersonalData: businessTravelPersonalData?.length > 0 ? removeIsSubmit(businessTravelPersonalData) : cacheData?.businessTravelPersonalData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelPersonalData) : [],
            businessTravelPublicData: businessTravelPublicData?.length > 0 ? removeIsSubmit(businessTravelPublicData) : cacheData?.businessTravelPublicData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelPublicData) : [],
            businessTravelAirwaysData: businessTravelAirwaysData?.length > 0 ? removeIsSubmit(businessTravelAirwaysData) : cacheData?.businessTravelAirwaysData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelAirwaysData) : [],
            travelCommutePersonalData: travelCommutePersonalData?.length > 0 ? removeIsSubmit(travelCommutePersonalData) : cacheData?.travelCommutePersonalData?.length > 0 ? removeIsSubmit(cacheData?.travelCommutePersonalData) : [],
            travelCommutePublicData: travelCommutePublicData?.length > 0 ? removeIsSubmit(travelCommutePublicData) : cacheData?.travelCommutePublicData?.length > 0 ? removeIsSubmit(cacheData?.travelCommutePublicData) : [],
            upstreamVehicleMilesData: upstreamVehicleMilesData?.length > 0 ? removeIsSubmit(upstreamVehicleMilesData) : cacheData?.upstreamVehicleMilesData?.length > 0 ? removeIsSubmit(cacheData?.upstreamVehicleMilesData) : [],
            upstreamVehicleTonsData: upstreamVehicleTonsData?.length > 0 ? removeIsSubmit(upstreamVehicleTonsData) : cacheData?.upstreamVehicleTonsData?.length > 0 ? removeIsSubmit(cacheData?.upstreamVehicleTonsData) : [],
            indirectWasteData: indirectWasteData?.length > 0 ? removeIsSubmit(indirectWasteData) : cacheData?.indirectWasteData ? removeIsSubmit(cacheData?.indirectWasteData) : [],
            directWasteData: directWasteData?.length > 0 ? removeIsSubmit(directWasteData) : cacheData?.directWasteData ? removeIsSubmit(cacheData?.directWasteData) : [],
            waterConsumptionData: waterConsumptionData?.length > 0 ? removeIsSubmit(waterConsumptionData) : cacheData?.waterConsumptionData ? removeIsSubmit(cacheData?.waterConsumptionData) : [],
            waterDischargeData: waterDischargeData?.length > 0 ? removeIsSubmit(waterDischargeData) : cacheData?.waterDischargeData ? removeIsSubmit(cacheData?.waterDischargeData) : [],
            employeeCountData: {
                id: uuidGenerator(),
                count: employeeCount,
                isInsert: true,
                isUpdate: false,
                isDelete: false
            }
        };
        const options = {
            method: 'POST',
            body: tableObject
        }
        await fetchWrapper(`${API_URL}/ghgemissionsdata/saveasdraft`, options)
            .then((res) => {
                console.log(res.json)
                toast.success('Data saved as a draft successfully!')
            }, async (err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Data saved as a draft successfully!')
                    await deleteCache({
                        customerId: userProfile?.org,
                        userId: userProfile?.id,
                        ...preData,
                    })
                    getEmissionSetId(preData)
                }
            })
    }

    const hanldeSubmit = async () => {
        const tableObject = {
            ...preData,
            status: 'DATA_SUBMITTED',
            stationaryCombustionData: stationaryCombustionData?.length > 0 ? removeIsSubmit(stationaryCombustionData) : cacheData?.stationaryCombustionData ? removeIsSubmit(cacheData?.stationaryCombustionData) : [],
            mobilesourcesData: mobilesourcesData?.length > 0 ? removeIsSubmit(mobilesourcesData) : cacheData?.mobilesourcesData ? removeIsSubmit(cacheData?.mobilesourcesData) : [],
            rfgMaterialData: rfgMaterialData?.length > 0 ? removeIsSubmit(rfgMaterialData) : cacheData?.rfgMaterialData?.length > 0 ? removeIsSubmit(cacheData?.rfgMaterialData) : [],
            rfgSimplifiedMaterialData: rfgSimplifiedMaterialData?.length > 0 ? removeIsSubmit(rfgSimplifiedMaterialData) : cacheData?.rfgSimplifiedMaterialData?.length > 0 ? removeIsSubmit(cacheData?.rfgSimplifiedMaterialData) : [],
            rfgScreeningData: rfgScreeningData?.length > 0 ? removeIsSubmit(rfgScreeningData) : cacheData?.rfgScreeningData?.length > 0 ? removeIsSubmit(cacheData?.rfgScreeningData) : [],
            fsMaterialData: fsMaterialData?.length > 0 ? removeIsSubmit(fsMaterialData) : cacheData?.fsMaterialData?.length ? removeIsSubmit(cacheData?.fsMaterialData) : [],
            fsSimplifiedMaterialData: fsSimplifiedMaterialData?.length > 0 ? removeIsSubmit(fsSimplifiedMaterialData) : cacheData?.fsSimplifiedMaterialData?.length > 0 ? removeIsSubmit(cacheData?.fsSimplifiedMaterialData) : [],
            fsScreeningData: fsScreeningData?.length > 0 ? removeIsSubmit(fsScreeningData) : cacheData?.fsScreeningData?.length > 0 ? removeIsSubmit(cacheData?.fsScreeningData) : [],
            purchasedGasesData: purchasedGasesData?.length > 0 ? removeIsSubmit(purchasedGasesData) : cacheData?.purchasedGasesData ? removeIsSubmit(cacheData?.purchasedGasesData) : [],
            energyData: energyData?.length > 0 ? removeIsSubmit(energyData) : cacheData?.energyData ? removeIsSubmit(cacheData?.energyData) : [],
            steamEnergyData: steamEnergyData?.length > 0 ? removeIsSubmit(steamEnergyData) : cacheData?.steamEnergyData ? removeIsSubmit(cacheData?.steamEnergyData) : [],
            businessTravelPersonalData: businessTravelPersonalData?.length > 0 ? removeIsSubmit(businessTravelPersonalData) : cacheData?.businessTravelPersonalData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelPersonalData) : [],
            businessTravelPublicData: businessTravelPublicData?.length > 0 ? removeIsSubmit(businessTravelPublicData) : cacheData?.businessTravelPublicData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelPublicData) : [],
            businessTravelAirwaysData: businessTravelAirwaysData?.length > 0 ? removeIsSubmit(businessTravelAirwaysData) : cacheData?.businessTravelAirwaysData?.length > 0 ? removeIsSubmit(cacheData?.businessTravelAirwaysData) : [],
            travelCommutePersonalData: travelCommutePersonalData?.length > 0 ? removeIsSubmit(travelCommutePersonalData) : cacheData?.travelCommutePersonalData?.length > 0 ? removeIsSubmit(cacheData?.travelCommutePersonalData) : [],
            travelCommutePublicData: travelCommutePublicData?.length > 0 ? removeIsSubmit(travelCommutePublicData) : cacheData?.travelCommutePublicData?.length > 0 ? removeIsSubmit(cacheData?.travelCommutePublicData) : [],
            upstreamVehicleMilesData: upstreamVehicleMilesData?.length > 0 ? removeIsSubmit(upstreamVehicleMilesData) : cacheData?.upstreamVehicleMilesData?.length > 0 ? removeIsSubmit(cacheData?.upstreamVehicleMilesData) : [],
            upstreamVehicleTonsData: upstreamVehicleTonsData?.length > 0 ? removeIsSubmit(upstreamVehicleTonsData) : cacheData?.upstreamVehicleTonsData?.length > 0 ? removeIsSubmit(cacheData?.upstreamVehicleTonsData) : [],
            indirectWasteData: indirectWasteData?.length > 0 ? removeIsSubmit(indirectWasteData) : cacheData?.indirectWasteData ? removeIsSubmit(cacheData?.indirectWasteData) : [],
            directWasteData: directWasteData?.length > 0 ? removeIsSubmit(directWasteData) : cacheData?.directWasteData ? removeIsSubmit(cacheData?.directWasteData) : [],
            waterConsumptionData: waterConsumptionData?.length > 0 ? removeIsSubmit(waterConsumptionData) : cacheData?.waterConsumptionData ? removeIsSubmit(cacheData?.waterConsumptionData) : [],
            waterDischargeData: waterDischargeData?.length > 0 ? removeIsSubmit(waterDischargeData) : cacheData?.waterDischargeData ? removeIsSubmit(cacheData?.waterDischargeData) : [],
            employeeCountData: {
                id: dataObject?.employeeCountData?.id,
                count: employeeCount,
                isInsert: ghgStatus === 'SAVE_AS_DRAFT' ? true : false,
                isUpdate: (ghgStatus === 'PROCESSING_COMPLETE') ? true : false,
                isDelete: false
            }
        };
        setObject(tableObject);
        setPreviewOpen(true);
    }

    const handleEmployeeCount = (value) => {
        if (locationId) {
            setEmployeeCount(value)
        } else {
            setEmployeeCount()
        }
    }



    const saveEmployeeCount = async (value) => {
        if (!locationId) {
            setEmployeeCount()
            toast.error('Please select Location and Period')
        } else {
            setEmployeeCount(value)
            let data = locationData.find(ele => ele.locationId == locationId);
            const dataObject = {
                customerId: userProfile?.org,
                userId: userProfile?.id,
                locationId: Number(locationId),
                countryCode: data.countryCode,
                uploadPeriod: ghgReportingFrequency,
                year: year ? parseInt(moment(year).format('YYYY')) : parseInt(moment(new Date()).format('YYYY')),
                month: ghgReportingFrequency === 'MONTH' ? moment(monthValue).format('MMMM') : new Date(moment(new Date()).subtract(1, 'month').format('MMM')),
                quarter: ghgReportingFrequency === 'QUARTER' ? parseInt(moment(quarter).format('Q')) : 0,
            }
            let getCache = await getCacheData(dataObject);

            const tempData = {
                ...getCache,
                employeeCountData: {
                    id: uuidGenerator(),
                    count: value,
                    isInsert: true,
                    isUpdate: false,
                    isDelete: false
                }
            }
            console.log(tempData)
            await storeData(dataObject, tempData)

        }
    }

    const handleApprove = async () => {
        const options = {
            method: 'POST',
            body: {
                requestId: reqId,
                status: 'APPROVED'
            }
        }
        await fetchWrapper(`${API_URL}/ghgemissionsdata/approved`, options)
            .then((res) => {
                console.log(res.json)
                if (res.status === 200) {
                    toast.success('Data Approved Successfully!')
                    setTimeout(() => {
                        navigate(`${process.env.PUBLIC_URL}/data-capture/queue/approval`)
                    }, 500)
                }
            }, (err) => {
                console.log(err)
                if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
                if (err.status === 200) {
                    toast.success('Data Approved Successfully!')
                    setTimeout(() => {
                        navigate(`${process.env.PUBLIC_URL}/data-capture/queue/approval`)
                    }, 500)
                }
            })
    }

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className='mtop-1'>
                        <Row>
                            <Col lg='3'>
                                <FormGroup>
                                    <Label className='d-block'>Location</Label>
                                    <select className='form-select ' value={locationId} onChange={(e) => handleLocation(e.target.value)}>
                                        <option value="">--Select Location--</option>
                                        {
                                            locationData?.map((item) => (<option value={item?.locationId}>{item?.locationName}</option>))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col lg='2'>
                                <FormGroup>
                                    <label className='d-block'>Period</label>
                                    <select className='form-select' value={ghgReportingFrequency}
                                        {...register('upload_period', { required: true })}
                                        onChange={(e) => handlePeriod(e.target.value)} disabled
                                    >
                                        <option value=''>Select Period</option>
                                        <option value="MONTH">MONTH</option>
                                        <option value="QUARTER">QUARTER</option>
                                        <option value="YEAR">YEAR</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col lg='2'>
                                <FormGroup>
                                    <label className='d-block mr-1'>Select Year</label>
                                    {console.log(year, '************')}
                                    <DatePicker
                                        className="datepicker-here form-control"
                                        selected={year}
                                        onChange={(e) => handleYear(e)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        maxDate={new Date()}
                                    />
                                </FormGroup>
                            </Col>
                            {
                                ghgReportingFrequency === 'MONTH' && <Col lg='2'>
                                    <FormGroup className="month-picker">
                                        <label className='d-block'>Select Month</label>
                                        {console.log(monthValue, '#############')}
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={reviewStatus === 'review' ? monthValue : new Date(convertMonthToDate(preData?.month, moment(year).format('YYYY')))}
                                            onChange={(e) => handleMonth(e)}
                                            showMonthYearPicker
                                            dateFormat="MMMM"
                                            showTwoColumnMonthYearPicker
                                            placeholderText="-Month-"
                                            maxDate={new Date(moment(new Date()).subtract(1, 'month'))}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            {
                                ghgReportingFrequency === 'QUARTER' && <Col lg='2'>
                                    <FormGroup>
                                        <label className='d-block'>Select Quarter</label>
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={quarter}
                                            onChange={(e) => handleQuarter(e)}
                                            showQuarterYearPicker
                                            dateFormat="QQQ"
                                            placeholderText="-Quarter-"
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            <Col lg='3'>
                                <FormGroup>
                                    <label className='d-block'>Employee Count</label>
                                    <input
                                        className='form-control'
                                        type='number'
                                        min={0}
                                        placeholder="0"
                                        value={employeeCount}
                                        disabled={!locationId}
                                        onChange={(e) => handleEmployeeCount(e.target.value)}
                                        onBlur={(e) => saveEmployeeCount(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    {
                        ['APPROVED', 'REJECTED'].includes(ghgStatus) && <div className="mtop-1 d-flex">
                            <h5 className="mb-0">Status: </h5> <a data-tooltip-id="status" data-tooltip-content={rejectionNotes} data-tooltip-place="top"
                            ><div className={`ml-1 ${ghgStatus === 'REJECTED' && 'rejected-status'} ${ghgStatus === 'APPROVED' && 'approval-status'}`}> {ghgStatus.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</div>
                            </a>
                            <Tooltip id="status" classNameArrow="d-none" className="pad-10" />
                        </div>
                    }

                    <div className="mtop-1">
                        <H3 attrH3={{ className: 'f-s-16' }}>Summary of Organization's Emissions</H3>
                    </div>
                    <div className="accord-container">
                        <Row>
                            <Col lg='12'
                                style={{ opacity: !['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT', 'DATA_SUBMITTED', 'REJECTED'].includes(ghgStatus) ? 0.1 : 1 }}
                            >
                                <Accordion>
                                    {
                                        scopeList?.includes(1) && <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <div className="d-flex accordian-summary-button">
                                                    <div>Scope 1 Emissions</div>
                                                    <CirclePrograssBar scope={scope1} list={scopeCategoryList} />
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body >
                                                <div style={{ width: '100%' }} className="ag-datasheet">
                                                    <AgGridDatasheet
                                                        tableData={scope1?.filter(ele => scopeCategoryList?.includes(ele?.category))}
                                                        columnData={columnData}
                                                    />
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }
                                    {
                                        scopeList?.includes(2) && <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                <div className="d-flex accordian-summary-button">
                                                    <div>Scope 2 Emissions - Location and Market based</div>
                                                    <CirclePrograssBar scope={scope2} list={scopeCategoryList} />
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ width: '100%' }} className="ag-datasheet">
                                                    <AgGridDatasheet
                                                        tableData={scope2?.filter(ele => scopeCategoryList?.includes(ele?.category))}
                                                        columnData={columnData}
                                                    />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }
                                    {
                                        scopeList?.includes(3) && <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                <div className="d-flex accordian-summary-button">
                                                    <div>Scope 3 Emissions</div>
                                                    <CirclePrograssBar scope={scope3} list={scopeCategoryList} />
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{ width: '100%' }} className="ag-datasheet">
                                                    <AgGridDatasheet
                                                        tableData={scope3?.filter(ele => scopeCategoryList?.includes(ele?.category))}
                                                        columnData={columnData}
                                                    />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    }

                                </Accordion>
                            </Col>
                        </Row>
                        {
                            !['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT', 'DATA_SUBMITTED', 'REJECTED'].includes(ghgStatus) && <div className="accord-overlay">
                                <div className="accord-overlay-p w-55">
                                    <p className="text-center">Data processing is currently in progress, so editing is temporarily unavailable. Thank you for your patience.</p>
                                </div>

                            </div>
                        }

                    </div>
                    <div className="d-flex mtop-1">
                        {
                            reviewStatus !== 'review' && (draftLoading ? <ButtonLoader color={"light"} loaderText={'Saving'} />
                                : <Button color="light"
                                    disabled={!['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(ghgStatus)}
                                    onClick={handleSaveDraft}
                                >Save as Draft</Button>)
                        }
                        {
                            reviewStatus !== 'review' && (submitLoading ? <ButtonLoader color={"primary"} loaderText={'Submitting'} />
                                : <Button color="primary"
                                    disabled={!['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(ghgStatus)}
                                    className={'ml-1'}
                                    onClick={hanldeSubmit}
                                >Submit for Approval</Button>)
                        }
                        {
                            ((reviewStatus === 'review') && (['EMISSIONS_APPROVER', 'EMISSIONS_ADMIN']?.some(ele => userObject[2]?.includes(ele)))) && ghgStatus === 'DATA_SUBMITTED' && <div>
                                <Button color="primary" onClick={handleApprove}>Approve</Button>
                                <Button color="secondary" className="ml-1" onClick={() => setRejectionOpen(true)}>Reject</Button>
                            </div>
                        }
                        {
                            ((reviewStatus === 'review')) &&
                            ['APPROVED', 'REJECTED'].includes(ghgStatus) && <div>
                                <Button color="primary" onClick={() => navigate(`${process.env.PUBLIC_URL}/data-capture/queue/approve`)}>Back</Button>
                            </div>
                        }
                    </div>

                </CardBody>
            </Card>
            {
                previewOpen && <TablePreview
                    value={previewOpen}
                    setOpenModal={setPreviewOpen}
                    tableData={tablesObject}
                    status={ghgStatus}
                    keyObject={{
                        customerId: userProfile?.org,
                        userId: userProfile?.id,
                        ...preData,
                    }}
                    setSubmitStatus={(e) => e === true && getEmissionSetId(preData)}
                />
            }
            {
                rejectModalOpen && <RejectionModal value={rejectModalOpen} setOpenModal={setRejectionOpen} requestId={reqId} />
            }
        </Fragment>
    )
}

export default Summary;


const CirclePrograssBar = ({ scope, list }) => {
    console.log(scope, list);
    let total = 0, entryValue = 0, percentage = 0;
    total = scope?.filter(ele => list?.includes(ele?.category))?.length;
    entryValue = scope?.filter(ele => (ele?.value == 1 && list?.includes(ele?.category)))?.length;
    percentage = parseInt((entryValue / total) * 100);
    console.log(total, entryValue);
    
    return (
        <Fragment>
            <CircularProgressbar value={percentage} maxValue={100} text={`${percentage}%`} />
        </Fragment>
    )
}


const RejectionModal = ({ value, setOpenModal, requestId }) => {
    const [open, setOpen] = useState(value);
    const [reason, setReason] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onClose = async () => {
        try {
            setLoading(true)
            const options = {
                method: 'POST',
                body: {
                    requestId: requestId,
                    status: 'REJECTED',
                    notes: reason
                }
            }
            await fetchWrapper(`${API_URL}/ghgemissionsdata/rejected`, options)
                .then((res) => {
                    console.log(res)
                    if (res.status == 200) {
                        toast.error('Data Rejected!')
                        setTimeout(() => {
                            navigate(`${process.env.PUBLIC_URL}/aso-admin/data-capture/approval-queue`)
                        }, 500)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    if (err.status == 200) {
                        toast.error('Data Rejected!')
                        setTimeout(() => {
                            navigate(`${process.env.PUBLIC_URL}/aso-admin/data-capture/approval-queue`)
                        }, 500)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }


    const handleClose = () => {
        setOpen(false);
        setOpenModal(false);
    }


    return (
        <Fragment>
            <Modal centered={true} isOpen={open}>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Reason for Rejection</H3>
                    <X className="x-button" onClick={handleClose} />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md='12'>
                            <textarea className="form-control" rows="5" cols="5"
                                placeholder="Enter your Reason.."
                                onChange={(e) => setReason(e.target.value)}
                            ></textarea>
                            <div className="d-flex mtop-2">
                                {
                                    loading ? <ButtonLoader loaderText={'Submitting'} color="primary" /> : <Button color="primary" onClick={onClose}>Submit</Button>
                                }
                                <Button color="secondary" className="ml-1" onClick={handleClose}>Cancel</Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

