import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button, FormGroup, Input, Row, Col } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";

const Operations = ({ financialYear }) => {
    const [exportContribution, setExportContribution] = useState();
    const [typeOfCustomer, setTypeOfCustomer] = useState();
    const [stateLocationCount, setStateLocationCount] = useState();
    const [countryLocationCount, setCountryLocationCount] = useState();
    const [locationData, setLocations] = useState()

    const getMarketLocationData = async() => {
        await fetchWrapper(`${API_URL}/emissionsReport/marketLocationsData/${financialYear}`)
        .then((res) => {
            console.log(res.json)
            setLocations(res?.json)
            setStateLocationCount(res.json?.find((ele) => ele?.name === 'National (No. of States)')?.count)
            setCountryLocationCount(res.json?.find((ele) => ele?.name === 'International (No. of Countries)')?.count)
        }, (err) => {
            console.log(err)
        })
    }

    const getOperationData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json?.filter((item) => item?.groupName === 'Operations')
                setExportContribution(data?.find(ele => ele?.key === 'Q1')?.value)
                setTypeOfCustomer(data?.find(ele => ele?.key === 'Q2')?.value)
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (financialYear) {
            getOperationData();
            getMarketLocationData();
        }
    }, [financialYear])

    const onSubmit = async () => {
        const data = [
            {
                serialNo: 1,
                groupName: 'Operations',
                key: 'Q1',
                value: exportContribution
            },
            {
                serialNo: 1,
                groupName: 'Operations',
                key: 'Q2',
                value: typeOfCustomer
            }
        ];
        let marketsData;
        if(locationData) {
            marketsData = [...locationData];
            marketsData[0].count = stateLocationCount
            marketsData[1].count = countryLocationCount
        } else {
            marketsData = [
                {
                    serialNo: 1,
                    name: 'National (No. of States)',
                    count: stateLocationCount
                },
                {
                    serialNo: 2,
                    name: 'International (No. of Countries)',
                    count: countryLocationCount
                },
            ]
        }
        const options = {
            method: 'POST',
            body: {
                financialYear: financialYear,
                attributesKeyValueData: data,
                marketLocationsData: marketsData
            }
        }
        
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Data saved successfully!')
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <h3>Operations</h3>
            <h5>a. Number of locations</h5>
            <Col className="mtop-1 d-flex" lg='12'>
                <label className="col-6">National (No. of States)</label>
                <input type="number" className="form-control" value={stateLocationCount} placeholder="0"
                    onChange={(e) => setStateLocationCount(e.target.value)} />
            </Col>
            <Col className="mtop-1 d-flex" lg='12'>
                <label className="col-6">International (No. of Countries)</label>
                <input type="number" className="form-control" value={countryLocationCount} placeholder="0"
                    onChange={(e) => setCountryLocationCount(e.target.value)} />
            </Col>
            <Col className="mtop-1 d-flex" lg='12'>
                <label className="col-6">b. What is the contribution of exports as a percentage of the total turnover of the entity?</label>
                <input type="number" className="form-control" value={exportContribution} placeholder="0" 
                onBlur={(e) => (e.target.value < 0 || e.target.value > 100) ? setExportContribution(0) : setExportContribution(e.target.value)}
                    onChange={(e) => setExportContribution(e.target.value)} />
            </Col>
            <Col className="mtop-1 d-flex" lg='12'>
                <label className="col-6">c. A brief on type of customers</label>
                <textarea className="form-control" rows={3} value={typeOfCustomer}
                    onChange={(e) => setTypeOfCustomer(e.target.value)} />
            </Col>
            <div className="d-flex mtop-1" >
                <Button color="primary" disabled={!financialYear ? true : false} onClick={onSubmit}>Submit</Button>
            </div>
        </Fragment>
    )
}

export default Operations;