import React, { Fragment, useEffect, useRef, useState } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { thousandSeparator } from '../../api/helper';
import { Plus } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import { Trash2, XCircle } from "react-feather";
import { alertFunction, deleteCacheData, getCacheData, notifyFunction, storeData, uuidGenerator } from './ghgHelper';
import { useSelector } from "react-redux";
import moment from "moment";
import { Nav, Tab } from "react-bootstrap";


let error = false, errorTable2 = false;
const WaterConsumption = ({ setSteps, preData, setWaterDischargeData, waterDischargeData,
    setWaterConsumptionData, waterConsumptionData, timeStamp, requestId, setRequestId }) => {
    const [intensityData, setIntensityData] = useState([...Array(10)].map((_, index) => ({ id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false })));
    const [dischargeData, setDischargeData] = useState([...Array(10)].map((_, index) => ({ id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false })));
    const [recycleSourceData, setRecycleSourceData] = useState([]);
    const [nonRecycleSourceData, setNonRecycleSourceData] = useState([]);
    const [waterSourceData, setWaterSourceData] = useState([]);
    const [wasteDestination, setDestination] = useState();
    const [loading, setLoading] = useState();
    const [treatmentLevel, setTreatmentLevel] = useState();
    const [noOfRow, setNoOfRow] = useState(1);
    const [noOfRowTable2, setNoOfRowTable2] = useState(1);
    const [rowIndex, setRowIndex] = useState([]);
    const [rowIndexofDischarge, setRowIndexOfDischarge] = useState([]);
    const [wasteDischargeType, setWasteDischargeType] = useState([]);
    const [wasteWaterTypes, setWasteWaterTypes] = useState([])
    const waterRef = useRef();
    const dischargeRef = useRef();
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState();
    const [activeKey, setActiveKey] = useState('1');
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    };
    const defaultTabRef = useRef(null);

    const getWaterDestination = async (type) => {
        setLoading(true)
        let data;
        await fetchWrapper(`${API_URL}/emissionunits/dischargeDestination/${type}`)
            .then((res) => {
                console.log(res.json)
                data = res.json;
            }, (err) => {
                console.log(err)
                data = [];
            })
        return data;
    }

    const getDischargableType = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/WaterDischarge/${preData?.countryCode}`)
            .then((res) => {
                console.log(res.json)
                setWasteDischargeType(res.json)
            }, (err) => console.log(err))
    }

    const getDestinationData = async (data) => {
        let obj = {};
        let tempData = [...data];
        data?.filter(item => (item.wasteWaterType))?.map(async (ele, index) => {
            await getWaterDestination(ele?.wasteWaterType)
                .then((res) => {
                    obj.destinationTypes = [...res];
                    tempData[index].destinationTypes = [...res];
                    if (!res) {
                        tempData[index].destinationTypes = []
                    }
                })
        })
        setLoading(false)
        return tempData;
    }

    const populateTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setIntensityData(tempData)
    }

    const loadData = (savedData, cTimestamp) => {
        if (waterConsumptionData?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateTable(waterConsumptionData)
        } else if (waterConsumptionData?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if (waterConsumptionData?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, waterConsumptionData, populateTable)
            } else { //saved is latest
                notifyFunction(waterConsumptionData, populateTable)
            }
        }
    }

    const populateDischargeTable = async (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        const getData = await getDestinationData(tempData)
        setDischargeData(getData)
    }

    const loadDischargeData = (savedData, cTimestamp) => {
        if (waterDischargeData?.length > 0 && (savedData?.length === 0 || !savedData)) {
            populateDischargeTable(waterDischargeData)
        } else if (waterDischargeData?.length === 0 && savedData?.length > 0) {
            populateDischargeTable(savedData)
        } else if (waterDischargeData?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, waterDischargeData, populateDischargeTable)
            } else { //saved is latest
                notifyFunction(waterDischargeData, populateDischargeTable)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        loadData(cacheData?.waterConsumptionData, cacheData?.modifiedTs)
        loadDischargeData(cacheData?.waterDischargeData, cacheData?.modifiedTs)
    }

    const getWaterSource = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/WaterConsumption/${preData?.countryCode}`)
            .then((res) => {
                console.log(res.json)
                setRecycleSourceData(res.json?.filter(ele => ele?.categoryType === 'Recycled')?.map((item) => item?.source));
                setNonRecycleSourceData(res.json?.filter(ele => ele?.categoryType === 'Non-Recycled')?.map((item) => item?.source));
                setWaterSourceData(res.json)
            }, (err) => console.log(err))
    }

    const getWasteWaterTypes = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/wasteWaterTypes`)
            .then((res) => {
                console.log(res.json)
                setWasteWaterTypes(res.json);
            }, (err) => {
                console.log(err)
            })
    }

    const getTreatmentLevel = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/levelOfTreatment`)
            .then((res) => {
                console.log(res.json)
                setTreatmentLevel(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getDischargableType()
        getWasteWaterTypes()
        getTreatmentLevel()
        getCache()
        getWaterSource()
        if (defaultTabRef.current) {
            defaultTabRef.current.focus();
        }
    }, [])

    const handleClear = async (params, type) => {
        let tempData, updatedRowData;
        if (type === 1) {
            updatedRowData = intensityData.map((row, index) => {
                return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                    : ((row.id === params.data.id) && row.isSubmit === false) ?
                        { id: index + 1, fuelState: undefined, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                        : row
            });
            tempData = {
                ...cacheData,
                waterConsumptionData: updatedRowData?.filter(ele => ele?.sourceId),
                waterDischargeData: dischargeData?.filter(ele => ele?.sourceId),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        } else if (type === 2) {
            updatedRowData = dischargeData.map((row, index) => {
                return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                    : ((row.id === params.data.id) && row.isSubmit === false) ?
                        { id: index + 1, fuelState: undefined, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                        : row
            });
            tempData = {
                ...cacheData,
                waterConsumptionData: intensityData?.filter(ele => ele?.sourceId),
                waterDischargeData: updatedRowData?.filter(ele => ele?.sourceId),
                modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
            }
        }
        await deleteCacheData(keyObject, tempData)
        type === 1 ? setIntensityData(updatedRowData) : setDischargeData(updatedRowData);
    };

    const handleUpload = async (e, params, nodeIndex, type) => {
        console.log(e.target.files[0])
        let dataId;
        if (typeof params?.id === 'string') {
            dataId = params.id;
        } else {
            let id = uuidGenerator();
            dataId = id;
            if (type === 1)
                intensityData[nodeIndex].id = id;
            else
                dischargeData[nodeIndex].id = id;
        }
        let tempData = new FormData();
        tempData.append('files', e.target.files[0]);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            },
            body: tempData
        }
        await fetchWrapper(`${API_URL}/document/uploadEvidences?category=WaterConsumption&uploadPeriod=${preData?.uploadPeriod}
            &categoryDetailId=${dataId}&year=${preData?.year}&month=${preData?.month}&quarter=${preData?.quarter}
            &requestId=${requestId ? requestId : ''}&locationId=${preData?.locationId}`, options)
            .then((res) => {
                setLoading(false)
                let tempData = type === 1 ? [...intensityData] : [...dischargeData];
                tempData[nodeIndex].documentId = res?.json?.documentId;
                tempData[nodeIndex].fileName = res?.json?.uploadFileName;
                setRequestId(res?.json?.requestId)
                tempData[nodeIndex].isInsert = true;
                tempData[nodeIndex].isUpdate = false;
                type === 1 ? setIntensityData(tempData) : setDischargeData(tempData);
                type === 1 ? createCache(intensityData?.filter(ele => ele?.sourceId)) : createCache(dischargeData?.filter(ele => ele?.sourceId))
            })
            .catch((err) => {
                console.log(err)
                toast.error('Evidence upload failed!')
                setLoading(false)
            })
    }

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('waterSource')
                    || params.data.hasOwnProperty('categoryType')
                    || params.data.hasOwnProperty('waterConsumption')
                    || params.data.hasOwnProperty('energyUsed')
                    || params.data.hasOwnProperty('treatmentType')
                    || params.data.hasOwnProperty('recycledWaterUsed')
                ) {
                    params.data.sourceId = `WC-${params.node.rowIndex + 1}`
                    return params.data.sourceId;
                }
            },
            filter: false,
            flex: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Category',
            field: 'categoryType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Recycled', 'Non-Recycled']
            },
            filter: false,
            editable: true,
            flex: 2.5,
            sortable: false
        },
        {
            headerName: 'Water Source',
            field: 'waterSource',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data.categoryType === 'Recycled') {
                    return { values: recycleSourceData }
                } else {
                    return { values: nonRecycleSourceData }
                }
            },
            valueGetter: (params) => {
                if (params.data.waterSource) {
                    const tempData = waterSourceData?.find(ele => (ele?.categoryType === params.data.categoryType
                        && ele?.source === params.data.waterSource));
                    params.data.units = tempData?.units;
                    params.data.energyUnits = tempData?.energyUnits;
                    params.data.resourceType = tempData?.resourceType;
                    return params.data.waterSource;
                }
            },
            filter: false,
            editable: true,
            flex: 2.5,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Water Consumption',
            field: 'waterConsumption',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.waterConsumption ?
                    thousandSeparator(params?.data?.waterConsumption.toFixed(4)) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Energy Used(kWh)',
            field: 'energyUsed',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.energyUsed ?
                    thousandSeparator(params?.data?.energyUsed.toFixed(4)) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Treatment Type',
            field: 'treatmentType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.waterSource) {
                    const treatmentData = waterSourceData?.find(ele => (ele?.categoryType === params.data.categoryType
                        && ele?.source === params.data.waterSource))
                    return {
                        values: treatmentData?.treatmentType?.split(':')
                    }
                }
            },
            filter: false,
            editable: (params) => {
                return params.data.categoryType === 'Non-Recycled' ? false : true
            },
            cellClassRules: {
                'disable-cell': params => params.data.categoryType === 'Non-Recycled'
            },
            flex: 2.5,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Recycle Water Used',
            field: 'recycledWaterUsed',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.recycledWaterUsed ?
                    thousandSeparator(params?.data?.recycledWaterUsed.toFixed(4)) : ''}</div>;
            },
            cellClassRules: {
                'disable-cell': params => params.data.categoryType === 'Non-Recycled'
            },
            flex: 2,
            editable: (params) => {
                return params.data.categoryType === 'Recycled' ? true : false;
            },
            sortable: true
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: false,
            flex: 2,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            cellRenderer: (params) => {
                if (!params.data.fileName) {
                    return (
                        <div>
                            <label className="upload-btn">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex, 1)}
                                />
                                Upload
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <h5 className="mb-0">{params.data.fileName}</h5>
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    params.data.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 1)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ];

    const dischargeColumns = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('wasteWaterType')
                    || params.data.hasOwnProperty('waterVolume')
                ) {
                    params.data.sourceId = `WD-${params.node.rowIndex + 1}`
                    return params.data.sourceId;
                }
            },
            filter: false,
            flex: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Waste Water Type',
            field: 'wasteWaterType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                return {
                    values: wasteWaterTypes?.map((ele) => ele?.wasteWaterType)
                }
            },
            valueGetter: (params) => {
                if (params.data.wasteWaterType) {
                    const tempData = wasteDischargeType?.find(ele => (ele?.wasteWaterType === params.data.wasteWaterType))
                    params.data.units = tempData?.units;
                    params.data.resourceType = tempData?.resourceType;
                    return params.data.wasteWaterType;
                }
            },
            filter: false,
            editable: true,
            flex: 2.5,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Waste Discharge Destination',
            field: 'dischargeDestination',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                return {
                    values: params.data.destinationTypes
                }
            },
            filter: false,
            editable: true,
            flex: 2.5,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Level of Treatment',
            field: 'levelOfTreatment',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                return {
                    values: treatmentLevel
                }
            },
            filter: false,
            editable: true,
            flex: 2.5,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Water Volume',
            field: 'waterVolume',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.waterVolume ?
                    thousandSeparator(params?.data?.waterVolume.toFixed(4)) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: false,
            flex: 2,
            sortable: false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            cellRenderer: (params) => {
                if (!params.data.fileName) {
                    return (
                        <div>
                            <label className="upload-btn">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex, 2)}
                                />
                                Upload
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <h5 className="mb-0">{params.data.fileName}</h5>
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    params.data.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 2)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ];

    const addingRows = (val, type) => {
        type === 1 ? setNoOfRow(val) : setNoOfRowTable2(val)
    }

    const addRows = (type) => {
        const typeOfRow = type === 1 ? noOfRow : noOfRowTable2;
        const rowData = [];
        for (let i = 1; i <= typeOfRow; i++) {
            const length = type === 1 ? intensityData?.length : dischargeData?.length;
            rowData.push({
                id: length + i, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false
            })
        }
        type === 1 ? setIntensityData([...intensityData, ...rowData]) : setDischargeData([...dischargeData, ...rowData]);
    }

    const getGridData = (type) => {
        if (type === 1) {
            if (waterRef.current) {
                const tableState = intensityData
                    .filter((rowNode) => rowNode.sourceId)
                    .map((rowNode, index) => {
                        console.log(rowNode.categoryType === 'Non-Recycled' && !rowNode.recycledWaterUsed, index)
                        if (rowNode.waterSource && rowNode.waterConsumption && rowNode.categoryType && rowNode.resourceType
                            && rowNode.energyUsed && rowNode.units && ((rowNode.categoryType === 'Non-Recycled' && !rowNode.recycledWaterUsed) || (rowNode.categoryType === 'Recycled' && rowNode.recycledWaterUsed))) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                categoryType: rowNode.categoryType,
                                waterSource: rowNode.waterSource,
                                energyUnits: rowNode.energyUnits,
                                waterConsumption: parseFloat(rowNode.waterConsumption),
                                energyUsed: parseFloat(rowNode.energyUsed),
                                recycledWaterUsed: parseFloat(rowNode.recycledWaterUsed),
                                treatmentType: rowNode.treatmentType,
                                resourceType: rowNode.resourceType,
                                documentId: rowNode.documentId ? rowNode.documentId : null,
                                fileName: rowNode.fileName ? rowNode.fileName : null,
                                units: rowNode.units,
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndex.push(rowNode?.sourceId.split('-')[1]);
                            return {}
                        }
                    });
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    error = true;
                } else {
                    error = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        } else if (type === 2) {
            if (dischargeRef.current) {
                const tableState = dischargeData
                    .filter((rowNode) => rowNode.sourceId)
                    .map((rowNode, index) => {
                        if (rowNode.wasteWaterType && rowNode.waterVolume && rowNode.resourceType && rowNode.dischargeDestination
                            && rowNode.units) {
                            let data = {
                                id: rowNode.id,
                                sourceId: rowNode.sourceId,
                                wasteWaterType: rowNode.wasteWaterType,
                                dischargeDestination: rowNode.dischargeDestination,
                                levelOfTreatment: rowNode.levelOfTreatment,
                                waterVolume: parseFloat(rowNode.waterVolume),
                                resourceType: rowNode.resourceType,
                                units: rowNode.units,
                                documentId: rowNode.documentId ? rowNode.documentId : null,
                                fileName: rowNode.fileName ? rowNode.fileName : null,
                                isInsert: rowNode.isInsert,
                                isUpdate: rowNode.isUpdate,
                                isDelete: rowNode.isDelete,
                                isSubmit: rowNode.isSubmit
                            }
                            return data;
                        } else {
                            rowIndexofDischarge.push(rowNode?.sourceId.split('-')[1]);
                            return {}
                        }
                    });
                if (tableState?.some(ele => Object.keys(ele)?.length === 0)) {
                    errorTable2 = true;
                } else {
                    errorTable2 = false
                }
                return tableState.filter(ele => Object.keys(ele)?.length > 0);
            }
            return [];
        }
    }

    const onCellValueChanged = async (event) => {
        if (event.colDef.field === 'wasteWaterType') {
            await getWaterDestination(event.data.wasteWaterType)
                .then((res) => {
                    event.data.destinationTypes = res
                    setLoading(false)
                })
        }

        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === false || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if (event.data.isSubmit === false && event.data.isInsert === false && typeof event.data.id !== 'string') {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if (event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        createCache()
    }

    const createCache = async () => {
        const tempData = {
            ...cacheData,
            waterConsumptionData: intensityData?.filter(ele => ele?.sourceId),
            waterDischargeData: dischargeData?.filter(ele => ele?.sourceId),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onSubmit = () => {
        const gridData = getGridData(1);
        const gridDischargeData = getGridData(2);
        if (error === true || errorTable2 === true) {
            if (error === true) {
                toast.error(`Please fill missing values for row ${[...new Set(rowIndex)].join(',')} in Water consumption Table`);
                setRowIndex([])
                error = false;
            }
            if (errorTable2 === true) {
                toast.error(`Please fill missing values for row ${[...new Set(rowIndexofDischarge)].join(',')} in Water Discharge Table`)
                setRowIndexOfDischarge([])
                errorTable2 = false;
            }
            return
        } else {
            setWaterConsumptionData(gridData)
            setWaterDischargeData(gridDischargeData)
            setSteps(0)
        }

    }

    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}>Water</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            <div className="data-capture">
                <Tab.Container activeKey={activeKey} defaultActiveKey={'1'} onSelect={(key) => setActiveKey(key)} >
                    <Nav variant="underlines">
                        <Nav.Item className="mr-5px">
                            <Nav.Link eventKey={'1'} className={activeKey == 1 ? 'active' : ''} ref={defaultTabRef}>Water Consumption</Nav.Link>
                        </Nav.Item>
                        <Nav.Item >
                            <Nav.Link eventKey={'2'} className={activeKey == 2 ? 'active' : ''} >Water Discharge</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="mtop-1">
                        <Tab.Pane eventKey={'1'}>
                            <div className="mtop-1" >
                                <div style={{ width: '100%' }} className="ag-datasheet">
                                    <AgGridDatasheet
                                        agRef={waterRef}
                                        tableData={intensityData?.filter(ele => ele?.isDelete === false)}
                                        columnData={columnData}
                                        onCellValueChanged={onCellValueChanged}
                                    />
                                    <div className="ag-custom-footer">
                                        <div className="d-flex ">
                                            <button className="add-row-button" onClick={() => addRows(1)}><Plus className="mr-10"
                                                color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                            <input
                                                type="number"
                                                className="ml-1 input-number"
                                                value={noOfRow}
                                                onChange={(e) => addingRows(e.target.value, 1)}
                                            />
                                            <h5 className="ml-1">rows</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'2'}>
                            {loading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
                            <div className="mtop-1" style={{ opacity: loading ? '0.2' : '1' }}>
                                <div style={{ width: '100%' }} className="ag-datasheet">
                                    <AgGridDatasheet
                                        agRef={dischargeRef}
                                        tableData={dischargeData?.filter(ele => ele?.isDelete === false)}
                                        columnData={dischargeColumns}
                                        onCellValueChanged={onCellValueChanged}
                                    />
                                    <div className="ag-custom-footer">
                                        <div className="d-flex ">
                                            <button className="add-row-button" onClick={() => addRows(2)}><Plus className="mr-10"
                                                color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                            <input
                                                type="number"
                                                className="ml-1 input-number"
                                                value={noOfRowTable2}
                                                onChange={(e) => addingRows(e.target.value, 2)}
                                            />
                                            <h5 className="ml-1">rows</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>

        </Fragment>
    )
}

export default WaterConsumption;
