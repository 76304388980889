import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button } from "reactstrap";
import { externalParticulars, policyQuestions, principleList, reviewParticulars } from "../../Settings/settingshelper";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";


const PolicyCoverage = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [policyCoverageData, setPolicyCoverage] = useState(policyQuestions);

    const getPolicyCoverageData = async() => {
        await fetchWrapper(`${API_URL}/emissionsReport/disclosureQuestionsData/${financialYear}`)
        .then((res) => {
            const tempArray = policyQuestions?.map(ele => {
                const temp = res?.json?.find(item => item?.questionText === ele?.questionText);
                return temp ? temp : ele;
            })
            tempArray?.length > 0 ? setPolicyCoverage(tempArray) : setPolicyCoverage(policyQuestions);
           
        }, (err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(financialYear) {
            getPolicyCoverageData()
        }
    }, [financialYear])

    const policyCoverageColumns = [
        {
            headerName: 'Questions',
            field: 'questionText',
            editable: false,
            filter: false,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P1',
            field: 'p1Set1',
            editable: true,
            filter: false,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P2',
            field: 'p2Set1',
            editable: true,
            filter: false,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P3',
            field: 'p3Set1',
            editable: true,
            filter: false,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P4',
            field: 'p4Set1',
            editable: true,
            filter: false,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P5',
            field: 'p5Set1',
            editable: true,
            filter: false,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P6',
            field: 'p6Set1',
            editable: true,
            filter: false,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P7',
            field: 'p7Set1',
            editable: true,
            filter: false,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P8',
            field: 'p8Set1',
            editable: true,
            filter: false,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
        {
            headerName: 'P9',
            field: 'p9Set1',
            editable: true,
            filter: false,
            flex: 1,
            cellEditorSelector: (params) => {
                if (params.data.type === 'text') {
                    return {
                        component: 'agLargeTextCellEditor',
                        params: {
                            maxLength: 250,
                            popupHeight: 300
                        }
                    }
                }
                if (params.data.type === 'dropdown') {
                    return {
                        component: 'agSelectCellEditor',
                        params: {
                            values: ['Yes', 'No'],
                            maxLength: 100,
                        }
                    }
                }
            },
            cellEditorPopup: (params) => params.data.type === 'text' ? true : false,
            autoHeight: true,
            wrapText: true,
        },
    ]

    const onSubmit = async() => {
        const data = {
            financialYear: financialYear,
            disclosureQuestionsData: policyCoverageData?.map((item, index) => ({ ...item, serialNo: 15 + index}))
        }
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
        .then((res) => {

        }, (err) => {
            console.log(err)
            if(err.status === 200) {
                setActive(7)
            } else if(err.status === 500) {
                toast.error('Something went wrong!')
            }
        })
    }
    
    return (
        <Fragment>
            <h4>Policy Coverage</h4>
            <div className="mtop-1 company-profile">
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={policyCoverageData}
                        columnData={policyCoverageColumns}
                    />
                </div>
            </div>
            <div className="mtop-1">
                <div className="">
                    <h4>Note:</h4>
                    {
                        principleList?.map((item, index) => (
                            <p className="mb-0 ml-1">P{index + 1} - <span className="ml-1">{item}</span></p>
                        ))
                    }
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(5)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default PolicyCoverage;