import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button, Col, FormGroup, Row } from "reactstrap";
import {
    annualAssessmentParticulars, complaintsData, employeeComplaintsCategory, employeeHarassmentParticulars,
    employeeRemunerationCategory, employeeWagesCategory, humanrightsCategory
} from "../../Settings/settingshelper";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { currencySymbol } from "../../../api/currencyData";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import moment from "moment";

const gridOptions = {
    groupHeaderHeight: 35, // Set the height of the group header row
    headerHeight: 70,
}

const HumanRightsTrainingComponent = ({ rowData, columnData, context }) => {
    const [tableRowPinned, setRowPinned] = useState({
        category: 'Total Employees',
        totalCurrentFY: rowData?.reduce((sum, row) => sum + row?.totalCurrentFY, 0) || 0,
        coveredCurrentFY: rowData?.reduce((sum, row) => sum + row?.coveredCurrentFY, 0) || 0,
        percentageCoveredCurrentFY: rowData?.reduce((sum, row) => sum + row?.percentageCoveredCurrentFY, 0) || 0,
        totalPreviousFY: rowData?.reduce((sum, row) => sum + row?.totalPreviousFY, 0) || 0,
        coveredPreviousFY: rowData?.reduce((sum, row) => sum + row?.coveredPreviousFY, 0) || 0,
        percentageCoveredPreviousFY: rowData?.reduce((sum, row) => sum + row?.percentageCoveredPreviousFY, 0) || 0,
        editable: false
    })

    const handleTableTotal = (data) => {
        return {
            totalCurrentFY: data?.reduce((sum, row) => {
                const value = Number(row?.totalCurrentFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            coveredCurrentFY: data?.reduce((sum, row) => {
                const value = Number(row?.coveredCurrentFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            percentageCoveredCurrentFY: data?.reduce((sum, row) => {
                const value = Number(row?.percentageCoveredCurrentFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            totalPreviousFY: data?.reduce((sum, row) => {
                const value = Number(row?.totalPreviousFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            coveredPreviousFY: data?.reduce((sum, row) => {
                const value = Number(row?.coveredPreviousFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
            percentageCoveredPreviousFY: data?.reduce((sum, row) => {
                const value = Number(row?.percentageCoveredPreviousFY);
                return sum + (isNaN(value) ? 0 : value);
            }, 0) || 0,
        }
    }

    useEffect(() => {
        setRowPinned({
            category: 'Total Employees',
            ...handleTableTotal(rowData),
            editable: false
        })

    }, [rowData])

    const onCellValueChanged = () => {
        const tempObj = handleTableTotal(rowData);
        console.log(tempObj, 'asdasd')
        setRowPinned({
            category: 'Total Employees',
            ...tempObj,
            editable: false
        })
    }

    return (
        <Fragment>
            <div className="ngrbc-review">
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        context={context}
                        tableData={rowData}
                        columnData={columnData}
                        gridOptions={gridOptions}
                        pinnedBottomRowData={tableRowPinned}
                        onCellValueChanged={() => onCellValueChanged()}
                    />
                </div>
            </div>
        </Fragment>
    )
}

const EmployeeWagesComponent = ({ rowData, columnData, context }) => {


    return (
        <Fragment>
            <div className="ag-3layer-header">
                <div className="ag-group-header">
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                context={context}
                                tableData={rowData}
                                columnData={columnData}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const HumanRightsAdvocacy = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [humanRightsEmployeesData, setHumanRightsEmployeesData] = useState(humanrightsCategory);
    const [humanRightsWorkersData, setHumanRightsWorkersData] = useState(humanrightsCategory);
    const [employeeWagesData, setEmployeeWagesData] = useState(employeeWagesCategory);
    const [workersWagesData, setWorkersWagesData] = useState(employeeWagesCategory);
    const [employeeRemunerationData, setEmployeeRemuneration] = useState(employeeRemunerationCategory);
    const [currentFyGrossWage, setCurrentGrossWage] = useState();
    const [previousFyGrossWage, setPreviousGrossWage] = useState();
    const [businessContribution, setBusinessContribution] = useState();
    const [internalMechanism, setInternalMechanism] = useState();
    const [employeeComplaintsData, setEmployeeComplaintsData] = useState(employeeComplaintsCategory);
    const [employeeHarassmentData, setEmployeeHarassmentData] = useState(employeeHarassmentParticulars);
    const [adverseMechanism, setAdverseMechanism] = useState()
    const [businessAgreements, setBusinessAgreements] = useState();
    const [annualAssessmentData, setAnnualAssessmentData] = useState(annualAssessmentParticulars)
    const [riskDetails, setRiskDetails] = useState()
    const [businessProcessDetails, setBusinessProccessDetails] = useState();
    const [humanRightsScope, setHumanRightsScope] = useState()
    const [officeAccessibility, setOfficeAccessibility] = useState();
    const [valueChainPartnersAssessmentData, setValueChainPartners] = useState(annualAssessmentParticulars)
    const [correctiveActionDetails, setCorrectiveActionDetails] = useState();
    const [femaleWagesData, setFemaleWagesData] = useState()

    const getFemaleWagesShareData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/femaleWagesShareData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setFemaleWagesData(res.json)
                setCurrentGrossWage(res.json[0]?.femaleWagePercentageCurrentYear);
                setPreviousGrossWage(res.json[0]?.femaleWagePercentagePreviousYear);
            }, (err) => console.log(err))
    }

    const getHumanRightsTrainingData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/humanRightsTrainingData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempEmployeeArray = humanrightsCategory?.map(ele => {
                    const temp = res?.json?.filter(ele => ele?.type === 'Employees')?.find(item => item?.category === ele?.category);
                    return temp ? temp : ele;
                })
                setHumanRightsEmployeesData(tempEmployeeArray);
                const tempWorkersArray = humanrightsCategory?.map(ele => {
                    const temp = res?.json?.filter(ele => ele?.type === 'Workers')?.find(item => item?.category === ele?.category);
                    return temp ? temp : ele;
                })
                setHumanRightsWorkersData(tempWorkersArray)
            }, (err) => console.log(err))
    }

    const getEmployeeWagesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeWagesData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempEmployeeArray = employeeWagesCategory?.map(ele => {
                    const temp = res?.json?.filter(ele => ele?.type === 'Employees')?.find(item => item?.category === ele?.category);
                    return temp ? temp : ele;
                })
                setEmployeeWagesData(tempEmployeeArray);
                const tempWorkersArray = employeeWagesCategory?.map(ele => {
                    const temp = res?.json?.filter(ele => ele?.type === 'Workers')?.find(item => item?.category === ele?.category);
                    return temp ? temp : ele;
                })
                setWorkersWagesData(tempWorkersArray)
            }, (err) => console.log(err))
    }

    const getEmployeeRemunerationData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeRemunerationData/${financialYear}`)
            .then((res) => {
                const tempArray = employeeRemunerationCategory?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setEmployeeRemuneration(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getEmployeeComplaintsData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeComplaintsData/${financialYear}`)
            .then((res) => {
                const tempArray = employeeComplaintsCategory?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setEmployeeComplaintsData(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getEmployeeHarassmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/employeeHarassmentData/${financialYear}`)
            .then((res) => {
                const tempArray = employeeHarassmentParticulars?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setEmployeeHarassmentData(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getAnnualAssessmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/annualAssessmentData/${financialYear}`)
            .then((res) => {
                const tempArray = annualAssessmentParticulars?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setAnnualAssessmentData(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getValueChainPartnersAssessmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/valueChainPartnersAssessmentData/${financialYear}`)
            .then((res) => {
                const tempArray = annualAssessmentParticulars?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setValueChainPartners(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getAttributesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json?.filter(ele => ele.groupName === 'humanRightsAdvocacy');
                setBusinessContribution(data?.find(ele => ele.key === 'Q29')?.value);
                setInternalMechanism(data?.find(ele => ele.key === 'Q30')?.value);
                setAdverseMechanism(data?.find(ele => ele.key === 'Q31')?.value);
                setBusinessAgreements(data?.find(ele => ele.key === 'Q32')?.value);
                setRiskDetails(data?.find(ele => ele.key === 'Q33')?.value)
                setBusinessProccessDetails(data?.find(ele => ele.key === 'Q34')?.value)
                setHumanRightsScope(data?.find(ele => ele.key === 'Q35')?.value)
                setOfficeAccessibility(data?.find(ele => ele.key === 'Q36')?.value)
                setCorrectiveActionDetails(data?.find(ele => ele.key === 'Q37')?.value)
            }, (err) => console.log(err))
    }

    useEffect(() => {
        if (financialYear) {
            getHumanRightsTrainingData()
            getEmployeeWagesData()
            getEmployeeRemunerationData()
            getEmployeeComplaintsData()
            getEmployeeHarassmentData()
            getAnnualAssessmentData()
            getValueChainPartnersAssessmentData()
            getAttributesData()
            getFemaleWagesShareData()
        }
    }, [financialYear])

    const handleClear = (params, type) => {
        let updatedRowData;
    }

    const humanRightsTrainingColumns = [
        {
            headerName: '',
            field: 'category',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Total(A)',
                    field: 'totalCurrentFY',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'No. of Emp Covered(B)',
                    field: 'coveredCurrentFY',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.totalCurrentFY > params.data.coveredCurrentFY) {
                            return params.data.coveredCurrentFY;
                        } else {
                            delete params.data.coveredCurrentFY;
                        }
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell',
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                },
                {
                    headerName: '%(B/A)',
                    field: 'percentageCoveredCurrentFY',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data?.coveredCurrentFY && params.data?.totalCurrentFY) {
                            params.data.percentageCoveredCurrentFY = (Number(params.data?.coveredCurrentFY) / Number(params.data?.totalCurrentFY)) * 100;
                            return params.data?.percentageCoveredCurrentFY
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.percentageCoveredCurrentFY?.toFixed(2)}</div>
                    },
                    editable: false,
                    cellClass: 'disable-cell',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Total(C)',
                    field: 'totalPreviousFY',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'No. of Emp Covered(D)',
                    field: 'coveredPreviousFY',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data.totalPreviousFY > params.data.coveredPreviousFY) {
                            return params.data.coveredPreviousFY;
                        } else {
                            delete params.data.coveredPreviousFY;
                        }
                    },
                    flex: 1,
                    editable: (params) => {
                        return params.node.rowPinned !== 'bottom'
                    },
                    cellClass: (params) => params.node.rowPinned === 'bottom' ? 'disable-cell' : '',
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: '%(D/A)',
                    field: 'percentageCoveredPreviousFY',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    valueGetter: (params) => {
                        if (params.data?.coveredPreviousFY && params.data?.totalPreviousFY) {
                            params.data.percentageCoveredPreviousFY = (Number(params.data?.coveredPreviousFY) / Number(params.data?.totalPreviousFY)) * 100;
                            return params.data?.percentageCoveredPreviousFY
                        }
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data?.percentageCoveredPreviousFY?.toFixed(2)}</div>
                    },
                    editable: false,
                    cellClass: 'disable-cell',
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                }
            ]
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if ((params.data.hasOwnProperty('total') ||
                    params.data.hasOwnProperty('maleCount') ||
                    params.data.hasOwnProperty('femaleCount')) &&
                    params.node.rowPinned !== 'bottom'
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 1)} />
                        </div>
                    )
            }
        }
    ]

    const employeeWagesColumn = [
        {
            headerName: 'Category',
            field: 'category',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            editable: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Total(A)',
                    field: 'totalCurrentFY',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    editable: true,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Equal to Min wage',
                    marryChildren: true,
                    headerGroupComponent: 'spannedHeaderComponent',
                    headerClass: 'group-header-align',
                    children: [
                        {
                            headerName: 'No.(B)',
                            field: 'equalToMinWageCurrentFY',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            editable: true,
                            valueGetter: (params) => {
                                if (params.data.totalCurrentFY > params.data.equalToMinWageCurrentFY) {
                                    return params.data.equalToMinWageCurrentFY;
                                } else {
                                    delete params.data.equalToMinWageCurrentFY;
                                }
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                        {
                            headerName: '%(B/A)',
                            field: 'percentageEqualMinWageCurrentFY',
                            cellEditor: 'agNumberCellEditor',
                            editable: false,
                            cellEditorParams: {
                                min: 0,
                            },
                            valueGetter: (params) => {
                                if (params.data?.equalToMinWageCurrentFY && params.data?.totalCurrentFY) {
                                    params.data.percentageEqualMinWageCurrentFY = (Number(params.data?.equalToMinWageCurrentFY) / Number(params.data?.totalCurrentFY)) * 100;
                                    return params.data?.percentageEqualMinWageCurrentFY
                                }
                            },
                            cellRenderer: (params) => {
                                return <div>{params.data?.percentageEqualMinWageCurrentFY?.toFixed(2)}</div>
                            },
                            flex: 1,
                            cellClass: 'disable-cell',
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                    ]
                },
                {
                    headerName: 'More than Min wage',
                    marryChildren: true,
                    headerGroupComponent: 'spannedHeaderComponent',
                    headerClass: 'group-header-align',
                    children: [
                        {
                            headerName: 'No.(C)',
                            field: 'moreThanMinWageCurrentFY',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            editable: true,
                            valueGetter: (params) => {
                                if (params.data.totalCurrentFY > params.data.moreThanMinWageCurrentFY) {
                                    return params.data.moreThanMinWageCurrentFY;
                                } else {
                                    delete params.data.moreThanMinWageCurrentFY;
                                }
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                        {
                            headerName: '%(C/A)',
                            field: 'percentageMoreMinWageCurrentFY',
                            cellEditor: 'agNumberCellEditor',
                            editable: false,
                            cellEditorParams: {
                                min: 0,
                            },
                            valueGetter: (params) => {
                                if (params.data?.moreThanMinWageCurrentFY && params.data?.totalCurrentFY) {
                                    params.data.percentageMoreMinWageCurrentFY = (Number(params.data?.moreThanMinWageCurrentFY) / Number(params.data?.totalCurrentFY)) * 100;
                                    return params.data?.percentageMoreMinWageCurrentFY
                                }
                            },
                            cellRenderer: (params) => {
                                return <div>{params.data?.percentageMoreMinWageCurrentFY?.toFixed(2)}</div>
                            },
                            cellClass: 'disable-cell',
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                    ]
                }
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Total(D)',
                    field: 'totalPreviousFY',
                    cellEditor: 'agNumberCellEditor',
                    editable: true,
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Equal to Min wage',
                    marryChildren: true,
                    headerGroupComponent: 'spannedHeaderComponent',
                    headerClass: 'group-header-align',
                    children: [
                        {
                            headerName: 'No.(E)',
                            field: 'equalToMinWagePreviousFY',
                            cellEditor: 'agNumberCellEditor',
                            valueGetter: (params) => {
                                if (params.data.totalPreviousFY > params.data.equalToMinWagePreviousFY) {
                                    return params.data.equalToMinWagePreviousFY;
                                } else {
                                    delete params.data.equalToMinWagePreviousFY;
                                }
                            },
                            editable: true,
                            cellEditorParams: {
                                min: 0,
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                        {
                            headerName: '%(E/D)',
                            field: 'percentageEqualMinWagePreviousFY',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            editable: false,
                            valueGetter: (params) => {
                                if (params.data?.equalToMinWagePreviousFY && params.data?.totalPreviousFY) {
                                    params.data.percentageEqualMinWagePreviousFY = (Number(params.data?.equalToMinWagePreviousFY) / Number(params.data?.totalPreviousFY)) * 100;
                                    return params.data?.percentageEqualMinWagePreviousFY
                                }
                            },
                            cellRenderer: (params) => {
                                return <div>{params.data?.percentageEqualMinWagePreviousFY?.toFixed(2)}</div>
                            },
                            cellClass: 'disable-cell',
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                    ]
                },
                {
                    headerName: 'More than Min wage',
                    marryChildren: true,
                    headerGroupComponent: 'spannedHeaderComponent',
                    headerClass: 'group-header-align',
                    children: [
                        {
                            headerName: 'No.(F)',
                            field: 'moreThanMinWagePreviousFY',
                            valueGetter: (params) => {
                                if (params.data.totalPreviousFY > params.data.moreThanMinWagePreviousFY) {
                                    return params.data.moreThanMinWagePreviousFY;
                                } else {
                                    delete params.data.moreThanMinWagePreviousFY;
                                }
                            },
                            cellEditor: 'agNumberCellEditor',
                            editable: true,
                            cellEditorParams: {
                                min: 0,
                            },
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                        {
                            headerName: '%(F/D)',
                            field: 'percentageMoreMinWagePreviousFY',
                            cellEditor: 'agNumberCellEditor',
                            cellEditorParams: {
                                min: 0,
                            },
                            editable: false,
                            valueGetter: (params) => {
                                if (params.data?.moreThanMinWagePreviousFY && params.data?.totalPreviousFY) {
                                    params.data.percentageMoreMinWagePreviousFY = (Number(params.data?.moreThanMinWagePreviousFY) / Number(params.data?.totalPreviousFY)) * 100;
                                    return params.data?.percentageMoreMinWagePreviousFY
                                }
                            },
                            cellRenderer: (params) => {
                                return <div>{params.data?.percentageMoreMinWagePreviousFY?.toFixed(2)}</div>
                            },
                            cellClass: 'disable-cell',
                            flex: 1,
                            headerClass: 'group-header-class, border-top-group-header-cell'
                        },
                    ]
                }
            ]
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if ((params.data.hasOwnProperty('totalCurrentFY') ||
                    params.data.hasOwnProperty('equalToMinWageCurrentFY') ||
                    params.data.hasOwnProperty('moreThanMinWageCurrentFY') ||
                    params.data.hasOwnProperty('totalPreviousFY') ||
                    params.data.hasOwnProperty('equalToMinWagePreviousFY') ||
                    params.data.hasOwnProperty('moreThanMinWagePreviousFY')) &&
                    params.node.rowPinned !== 'bottom'
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 2)} />
                        </div>
                    )
            }
        }
    ];

    const employeeRemunerationColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `Male`,
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Number',
                    field: 'maleNumber',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Median remuneration/ Salary/ wages of respective category',
                    field: 'maleMedianRemuneration',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 2,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: `Female`,
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Number',
                    field: 'femaleNumber',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Median remuneration/ Salary/ wages of respective category',
                    field: 'femaleMedianRemuneration',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 2,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        }
    ]

    const employeeComplaintsColumn = [
        {
            headerName: 'Benefits',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'No of Filed',
                    field: 'filledCurrentFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.filledCurrentFY ?
                            thousandSeparator(params?.data?.filledCurrentFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Pending',
                    field: 'pendingResolutionCurrentFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.pendingResolutionCurrentFY ?
                            thousandSeparator(params?.data?.pendingResolutionCurrentFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Remarks',
                    field: 'remarksCurrentFY',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    filter: false,
                    flex: 2,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'No of Filed',
                    field: 'filledPreviousFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.filledPreviousFY ?
                            thousandSeparator(params?.data?.filledPreviousFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Pending',
                    field: 'pendingResolutionPreviousFY',
                    filter: false,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                        max: 100,
                        precision: 2
                    },
                    cellRenderer: (params) => {
                        return <div className="text-right w-100">{params?.data?.pendingResolutionPreviousFY ?
                            thousandSeparator(params?.data?.pendingResolutionPreviousFY.toFixed(2)) : ''}</div>;
                    },
                    flex: 1.5,
                    editable: true,
                    sortable: true
                },
                {
                    headerName: 'Remarks',
                    field: 'remarksPreviousFY',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    filter: false,
                    flex: 2,
                    autoHeight: true,
                    wrapText: true,
                    sortable: false,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        }
    ];

    const employeeHarassmentColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            field: 'currentFinancialYear',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.currentFinancialYear ?
                    thousandSeparator(params?.data?.currentFinancialYear) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            field: 'previousFinancialYear',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.previousFinancialYear ?
                    thousandSeparator(params?.data?.previousFinancialYear) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        }
    ];

    const annualAssessmentColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `% of your plants and offices that were assessed (by entity or statutory authorities or third parties)`,
            field: 'assessmentPercentage',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.assessmentPercentage ?
                    thousandSeparator(params?.data?.assessmentPercentage) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
    ]

    const valueChainPartnersAssessmentColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `% of value chain partners (by value of business done with such partners) that were assessed`,
            field: 'assesmentPercentageByBusinessValue',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.assesmentPercentageByBusinessValue ?
                    thousandSeparator(params?.data?.assesmentPercentageByBusinessValue) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
    ]

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    }

    const onSubmit = async () => {
        let femaleWagesShares = [];
        if (femaleWagesData) {
            femaleWagesShares = [...femaleWagesData];
            femaleWagesShares[0].femaleWagePercentageCurrentYear = currentFyGrossWage;
            femaleWagesShares[0].femaleWagePercentagePreviousYear = previousFyGrossWage;
        } else {
            femaleWagesShares = [{
                serialNo: 1,
                particulars: "Gross wages paid to female’s as % of total wages",
                femaleWagePercentageCurrentYear: currentFyGrossWage,
                femaleWagePercentagePreviousYear: previousFyGrossWage
            }]
        }
        const bodyData = {
            financialYear: financialYear,
            humanRightsTrainingData: [
                ...humanRightsEmployeesData?.map((item, index) => ({ ...item, type: 'Employees', serialNo: index + 1 })),
                ...humanRightsWorkersData?.map((item, index) => ({ ...item, type: 'Workers', serialNo: index + 1 }))
            ],
            employeeWagesData: [
                ...employeeWagesData?.map((item, index) => ({ ...item, type: 'Employees', serialNo: index + 1 })),
                ...workersWagesData?.map((item, index) => ({ ...item, type: 'Workers', serialNo: index + 1 }))
            ],
            employeeRemunerationData: employeeRemunerationData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            employeeComplaintsData: employeeComplaintsData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            employeeHarassmentData: employeeHarassmentData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            annualAssessmentData: annualAssessmentData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            valueChainPartnersAssessmentData: valueChainPartnersAssessmentData?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            femaleWagesShareData: femaleWagesShares,
            attributesKeyValueData: [
                {
                    serialNo: 5,
                    groupName: 'humanRightsAdvocacy',
                    key: 'Q29',
                    value: businessContribution
                },
                {
                    serialNo: 5,
                    groupName: 'humanRightsAdvocacy',
                    key: 'Q30',
                    value: internalMechanism
                },
                {
                    serialNo: 5,
                    groupName: 'humanRightsAdvocacy',
                    key: 'Q31',
                    value: adverseMechanism
                },
                {
                    serialNo: 5,
                    groupName: 'humanRightsAdvocacy',
                    key: 'Q32',
                    value: businessAgreements
                },
                {
                    serialNo: 5,
                    groupName: 'humanRightsAdvocacy',
                    key: 'Q33',
                    value: riskDetails
                },
                {
                    serialNo: 5,
                    groupName: 'humanRightsAdvocacy',
                    key: 'Q34',
                    value: businessProcessDetails
                },
                {
                    serialNo: 5,
                    groupName: 'humanRightsAdvocacy',
                    key: 'Q35',
                    value: humanRightsScope
                },
                {
                    serialNo: 5,
                    groupName: 'humanRightsAdvocacy',
                    key: 'Q36',
                    value: officeAccessibility
                },
                {
                    serialNo: 5,
                    groupName: 'humanRightsAdvocacy',
                    key: 'Q37',
                    value: correctiveActionDetails
                },
            ]
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res.json)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(6)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }


    return (
        <Fragment>
            <h3>Human Rights Advocacy</h3>
            <h4 className="mtop-1">Essential Indicators</h4>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>1. Employees and workers who have been provided training on human rights issues and policy(ies) of the entity, in the following format</h5>
                    <h5>Employees</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <HumanRightsTrainingComponent
                                context={'employees'}
                                rowData={humanRightsEmployeesData}
                                columnData={humanRightsTrainingColumns}
                            />
                        </div>
                    </div>
                    <h5 className="mtop-1">Workers</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <HumanRightsTrainingComponent
                                context={'workers'}
                                rowData={humanRightsWorkersData}
                                columnData={humanRightsTrainingColumns}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>2. Details of minimum wages paid to employees and workers, in the following format</h5>
                <h5>Employees</h5>
                <div className="ag-3layer-header">
                    <div className="ag-group-header">
                        <div className="ngrbc-review">
                            <div style={{ width: '100%' }} className="ag-datasheet">
                                <EmployeeWagesComponent
                                    context={'employees'}
                                    rowData={employeeWagesData}
                                    columnData={employeeWagesColumn}
                                    gridOptions={gridOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className="mtop-1">Workers</h5>
                <div className="ag-3layer-header">
                    <div className="ag-group-header">
                        <div className="ngrbc-review">
                            <div style={{ width: '100%' }} className="ag-datasheet">
                                <EmployeeWagesComponent
                                    context={'workers'}
                                    rowData={workersWagesData}
                                    columnData={employeeWagesColumn}
                                    gridOptions={gridOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>3. Details of remuneration/Salary/Wages</h5>
                    <h5 className="mtop-1">a. Median remuneration/wages</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={employeeRemunerationData}
                                columnData={employeeRemunerationColumns}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <h5 className="mtop-1">b. Gross Wages paid to females as % of total wages paid by the entity, in the following format</h5>
            <Row >
                <Col lg='6'>
                    <FormGroup>
                        <label>In current year({financialYear})</label>
                        <input type="number" className="form-control"
                            value={currentFyGrossWage} onChange={(e) => setCurrentGrossWage(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col lg='6'>
                    <FormGroup>
                        <label>In previous year({previousYear})</label>
                        <input type="number" className="form-control" placeholder=""
                            value={previousFyGrossWage} onChange={(e) => setPreviousGrossWage(e.target.value)} />
                    </FormGroup>
                </Col>
            </Row>
            <div className="d-flex mtop-1">
                <h5 className="col-6">4. Do you have a focal point (Individual/Committee) responsible for
                    addressing human rights impact or issues caused or contributed by the business? (Yes/No) </h5>
                <div className="col-6">
                    <select className="form-select ml-1" value={businessContribution}
                        onChange={(e) => setBusinessContribution(e.target.value)}>
                        <option value={''}>-Please select-</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    5. Describe the internal mechanisms in place to redress grievances related to human rights issues
                </h5>
                <textarea className="form-control ml-1" value={internalMechanism} rows={3}
                    onChange={(e) => setInternalMechanism(e.target.value)} />
            </div>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>6. Number of complaints on the following made by employees and workers</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={employeeComplaintsData}
                                columnData={employeeComplaintsColumn}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>7. Complaints filed under the Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013, in the following format</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={employeeHarassmentData}
                        columnData={employeeHarassmentColumns}
                    />
                </div>
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    8. Mechanisms to prevent adverse consequences to the complainant in discrimination and harassment cases
                </h5>
                <textarea className="form-control ml-1" value={adverseMechanism} rows={3}
                    onChange={(e) => setAdverseMechanism(e.target.value)} />
            </div>
            <div className="d-flex mtop-1">
                <h5 className="col-6">9. Do human rights requirements form part of your business agreements and contracts? (Yes/No)</h5>
                <div className="col-6">
                    <select className="form-select ml-1" value={businessAgreements}
                        onChange={(e) => setBusinessAgreements(e.target.value)}>
                        <option value={''}>-Please select-</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>10. Assessments for the year</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={annualAssessmentData}
                        columnData={annualAssessmentColumns}
                    />
                </div>
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    11. Provide details of any corrective actions taken or underway to address significant risks / concerns arising from the assessments at Question 10 above
                </h5>
                <textarea className="form-control ml-1" value={riskDetails} rows={3}
                    onChange={(e) => setRiskDetails(e.target.value)} />
            </div>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    1. Details of a business process being modified / introduced as a result of addressing human rights grievances/complaints
                </h5>
                <textarea className="form-control ml-1" value={businessProcessDetails} rows={3}
                    onChange={(e) => setBusinessProccessDetails(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    2. Details of the scope and coverage of any Human rights due diligence conducted
                </h5>
                <textarea className="form-control ml-1" value={humanRightsScope} rows={3}
                    onChange={(e) => setHumanRightsScope(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    3. Is the premise/office of the entity accessible to differently abled visitors, as per the
                    requirements of the Rights of Persons with Disabilities Act, 2016?
                </h5>
                <textarea className="form-control ml-1" value={officeAccessibility} rows={3}
                    onChange={(e) => setOfficeAccessibility(e.target.value)} />
            </div>
            <div className="mtop-1 company-profile">
                <h5>4. Details on assessment of value chain partners</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={valueChainPartnersAssessmentData}
                        columnData={valueChainPartnersAssessmentColumns}
                    />
                </div>
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    5. Provide details of any corrective actions taken or underway to address
                    significant risks / concerns arising from the assessments at Question 4 above
                </h5>
                <textarea className="form-control ml-1" value={correctiveActionDetails} rows={3}
                    onChange={(e) => setCorrectiveActionDetails(e.target.value)} />
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(4)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default HumanRightsAdvocacy;