import React, { Fragment, useEffect, useState, useRef } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { Button } from "reactstrap";
import { Plus, Trash2, XCircle } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import { thousandSeparator } from "../../api/helper";
import { uuidGenerator } from "./ghgHelper";
import { useSelector } from "react-redux";
import moment from "moment";
import { alertFunction, notifyFunction, storeData, deleteCacheData, getCacheData } from "./ghgHelper";

let error = false;
const BusinessTravel = ({ setSteps, preData, setTravelPersonalData, timeStamp,
    setTravelPublicData, setTravelAirwaysData, personalTravel, publicTravel, airwaysTravel, requestId, setRequestId }) => {
    const [data, setData] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })));
    const [noOfRow, setNoOfRow] = useState(1);
    const [vehicleTypeData, setVehicleTypeData] = useState([]);
    const [vehicleData, setVehicleData] = useState([]);
    const [publicVehicleType, setPublicVehicleType] = useState([]);
    const [publicVehicleData, setPublicVehicleData] = useState([]);
    const [airwaysData, setAirwaysData] = useState([]);
    const [airwaysTypeData, setAirwaysTypeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const carRef = useRef();
    const [rowIndex, setRowIndex] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState()
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }

    const getVehicleTypeData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/BusinessTravelPersonal/${preData?.countryCode}`)
            .then((res) => {
                const data = res.json;
                setVehicleData(data);
                setVehicleTypeData(res.json?.map(item => item?.vehicleType))
            }, (err) => console.log(err))
    }

    const getPublicVehicleTypeData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/BusinessTravelPublic/${preData?.countryCode}`)
            .then((res) => {
                const data = res.json;
                setPublicVehicleData(data);
                setPublicVehicleType(res.json?.filter(ele => ele?.units).map(item => item?.vehicleType))
            }, (err) => console.log(err))
    }

    const getAirWaysData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/BusinessTravelAirways/${preData?.countryCode}`)
            .then((res) => {
                const data = res.json;
                setAirwaysTypeData(data);
                setAirwaysData(res.json?.map(item => item?.vehicleType))
            }, (err) => console.log(err))
    }

    const populateTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setData(tempData)
    }

    const loadData = (savedData, cTimestamp) => {
        let personalData, publicData, airwaysData;
        personalData = personalTravel?.map(ele => ({ ...ele, type: 'Personal' })) || []
        publicData = publicTravel?.map(({ passengerMiles, ...rest }) => ({
            vehicleMiles: passengerMiles,
            type: 'Public', ...rest
        })) || [];
        airwaysData = airwaysTravel?.map(({ passengerMiles, ...rest }) =>
            ({ vehicleMiles: passengerMiles, ...rest, type: 'Airways' })) || [];
        const mergeData = [...personalData, ...publicData, ...airwaysData];
        if (mergeData?.length > 0 && savedData?.length === 0) {
            populateTable(mergeData)
        } else if (mergeData?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if (mergeData?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, mergeData, populateTable)
            } else { //saved is latest
                notifyFunction(mergeData, populateTable)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        
        let personalData = [], publicData = [], airwaysData = [];
        personalData = cacheData?.businessTravelPersonalData?.map(ele => ({ ...ele, type: 'Personal' })) || []
        publicData = cacheData?.businessTravelPublicData?.map(({ passengerMiles, ...rest }) => ({
            vehicleMiles: passengerMiles,
            type: 'Public', ...rest
        })) || [];
        airwaysData = cacheData?.businessTravelAirwaysData?.map(({ passengerMiles, ...rest }) =>
            ({ vehicleMiles: passengerMiles, ...rest, type: 'Airways' })) || [];
        const mergeData = [...personalData, ...publicData, ...airwaysData]
        loadData(mergeData, cacheData?.modifiedTs)
    }


    const getGrids = async () => {
        if (personalTravel?.length > 0 || publicTravel?.length > 0 || airwaysTravel?.length > 0) {
            loadData();
        }
    }

    useEffect(() => {
        getVehicleTypeData()
        getPublicVehicleTypeData()
        getAirWaysData()
        getCache()
        // getGrids()
    }, [])

    const handleClear = async(params, type) => {
        const updatedRowData = data.map((row, index) => {
            return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                : ((row.id === params.data.id) && row.isSubmit === false) ?
                    { id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                    : row
        });
        let tempData = {
            ...cacheData,
            businessTravelPersonalData: updatedRowData?.filter(ele => ele?.type === 'Personal'),
            businessTravelPublicData: updatedRowData?.filter(ele => ele?.type === 'Public'),
            businessTravelAirwaysData: updatedRowData?.filter(ele => ele?.type === 'Airways'),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await deleteCacheData(keyObject, tempData)
        setData(updatedRowData);

    };

    const handleUpload = async(e, params, nodeIndex) => {
        console.log(e.target.files[0])
        let dataId;
        if (typeof params?.id === 'string') {
            dataId = params.id;
        } else {
            let id = uuidGenerator();
            dataId = id;
            data[nodeIndex].id = id;
        }
        let tempData = new FormData();
        tempData.append('files', e.target.files[0]);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            },
            body: tempData
        }
        await fetchWrapper(`${API_URL}/document/uploadEvidences?category=BusinessTravel&uploadPeriod=${preData?.uploadPeriod}
            &categoryDetailId=${dataId}&year=${preData?.year}&month=${preData?.month}&quarter=${preData?.quarter}
            &requestId=${requestId ? requestId : ''}&locationId=${preData?.locationId}`, options)
            .then((res) => {
                setLoading(false)
                let tempData = [...data];
                tempData[nodeIndex].documentId = res?.json?.documentId;
                tempData[nodeIndex].fileName = res?.json?.uploadFileName;
                setRequestId(res?.json?.requestId)
                tempData[nodeIndex].isInsert = true;
                tempData[nodeIndex].isUpdate = false;
                setData(tempData)
                createCache(data?.filter(ele => ele?.sourceId))
            })
            .catch((err) => {
                console.log(err)
                toast.error('Evidence upload failed!')
                setLoading(false)
            })
    }

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription')
                    || params.data.hasOwnProperty('vehicleType')
                    || params.data.hasOwnProperty('vehicleMiles')
                    || params.data.hasOwnProperty('flightLength')
                    || params.data.hasOwnProperty('type')) {
                    params.data.sourceId = `BT-${params.node.rowIndex + 1}`
                    return params.data.sourceId;
                }
            },
            filter: false,
            flex: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: true,
            filter: false,
            flex: 2,
        },
        {
            headerName: 'Type',
            field: 'type',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Personal', 'Public', 'Airways'],
            },
            filter: false,
            editable: true,
            flex: 1,
        },
        {
            headerName: 'Vehicle Type',
            field: 'vehicleType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.type === 'Personal') {
                    return { values: vehicleTypeData }
                } else if (params.data?.type === 'Public') {
                    return { values: publicVehicleType }
                } else return { values: [] }
            },
            valueGetter: (params) => {
                if (params.data.type === 'Airways') {
                    return 'N/A'
                } else {
                    if (params.data?.type === 'Personal') {
                        if (params.data?.vehicleType) {
                            const unitsData = vehicleData?.find(ele => ele?.vehicleType === params.data.vehicleType)
                            console.log(unitsData)
                            params.data.resourceType = unitsData?.resourceType
                        }
                    }
                    if (params.data.type === 'Public') {
                        if (params.data?.vehicleType) {
                            const unitsData = publicVehicleData?.find(ele => ele?.vehicleType === params.data.vehicleType)
                            params.data.resourceType = unitsData?.resourceType
                        }
                    }
                    return params.data.vehicleType
                };
            },
            filter: false,
            editable: (params) => params.data.type !== 'Airways' ? true : false,
            flex: 2,
            cellClassRules: {
                'disable-cell': params => params.data.type === 'Airways'
            }
        },
        {
            headerName: 'Flight Length',
            field: 'flightLength',
            editable: (params) => params.data.type === 'Airways' ? true : false,
            filter: false,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: airwaysData,
            },
            valueGetter: (params) => {
                if (['Personal', 'Public'].includes(params.data.type)) {
                    params.data.flightLength = 'N/A'
                    return 'N/A'
                } else { 
                    if (params.data.flightLength) {
                        if (params.data?.flightLength) {
                            const unitsData = airwaysTypeData?.find(ele => ele?.vehicleType === params.data.flightLength)
                            params.data.resourceType = unitsData?.resourceType
                        }
                    }
                    return params.data.flightLength;
                }
            },
            flex: 2,
            cellClassRules: {
                'disable-cell': params => params.data.type !== 'Airways'
            },
            autoHeight: true,
            wrapText: true,
            sortable: false,
        },
        {
            headerName: 'Distance Travelled',
            field: 'vehicleMiles',
            editable: true,
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.vehicleMiles ?
                    thousandSeparator(params?.data?.vehicleMiles?.toFixed(4)) : ''}</div>;
            },
            flex: 1,
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: (params) => {
                if (params.data?.type === 'Personal') {
                    if (params.data?.vehicleType) {
                        const unitsData = vehicleData?.find(ele => ele?.vehicleType === params.data.vehicleType)
                        return unitsData?.units ? true : false
                    }
                }
                if (params.data.type === 'Public') {
                    if (params.data?.vehicleType) {
                        const unitsData = publicVehicleData?.find(ele => ele?.vehicleType === params.data.vehicleType)
                        return unitsData?.units ? true : false
                    }
                }
                if (params.data.type === 'Airways') {
                    if (params.data?.flightLength) {
                        const unitsData = airwaysTypeData?.find(ele => ele?.vehicleType === params.data.flightLength)
                        return unitsData?.units ? true : false
                    }
                }
            },
            cellEditor: 'agSelectCellEditor',
            autoHeight: true,
            wrapText: true,
            valueGetter: (params) => {
                if (params.data?.type === 'Personal') {
                    if (params.data?.vehicleType) {
                        const unitsData = vehicleData?.find(ele => ele?.vehicleType === params.data.vehicleType)
                        params.data.units = unitsData?.units
                        return params.data.units;
                    }
                }
                if (params.data.type === 'Public') {
                    if (params.data?.vehicleType) {
                        const unitsData = publicVehicleData?.find(ele => ele?.vehicleType === params.data.vehicleType)
                        params.data.units = unitsData?.units
                        return params.data.units;
                    }
                }
                if (params.data.type === 'Airways') {
                    if (params.data?.flightLength) {
                        const unitsData = airwaysTypeData?.find(ele => ele?.vehicleType === params.data.flightLength)
                        params.data.units = unitsData?.units
                        return params.data.units;
                    }
                }
            },
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            cellRenderer: (params) => {
                if(!params.data.fileName) {
                    return (
                        <div>
                            <label className="upload-btn">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex)}
                                />
                                Upload
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <h5 className="mb-0">{params.data.fileName}</h5>
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    params.data?.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]

    const addingRows = (val, type) => {
        type === 1 ? setNoOfRow(val) : type === 2 ? setNoOfRowTable2(val) : setNoOfRowTable3(val);
    }

    const addRows = () => {
        const rowData = [];
        for (let i = 1; i <= noOfRow; i++) {
            rowData.push({
                id: data?.length + i, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false
            })
        }
        setData([...data, ...rowData])
    }

    const createCache = async (data, type) => {
        let tempData = {
            ...cacheData,
            businessTravelPersonalData: data?.filter(ele => ele?.type === 'Personal'),
            businessTravelPublicData: data?.filter(ele => ele?.type === 'Public'),
            businessTravelAirwaysData: data?.filter(ele => ele?.type === 'Airways'),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = (event) => {
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === false || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false && typeof event.data.id !== 'string') {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        createCache(data)
    }

    const getGridData = () => {
        if (carRef.current) {
            const personalData = data
                .filter(rowNode => rowNode.sourceId && (rowNode.type === 'Personal'))
                .map((rowNode, index) => {
                    console.log(rowNode)
                    if (rowNode.vehicleType && rowNode.units 
                        && rowNode.vehicleMiles && rowNode.sourceDescription) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            resourceType: rowNode.resourceType,
                            vehicleType: rowNode.vehicleType,
                            vehicleMiles: parseFloat(rowNode.vehicleMiles),
                            units: rowNode.units,
                            documentId: rowNode.documentId ? rowNode.documentId : null,
                            fileName: rowNode.fileName ? rowNode.fileName : null,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(rowNode?.sourceId.split('-')[1]);
                        return {}
                    }
                });
            const publicData = data
                .filter(rowNode => rowNode.sourceId && (rowNode.type === 'Public'))
                .map((rowNode, index) => {
                    if (rowNode.vehicleType && rowNode.sourceDescription && rowNode.units 
                        && rowNode.vehicleMiles) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            resourceType: rowNode.resourceType,
                            vehicleType: rowNode.vehicleType,
                            passengerMiles: parseFloat(rowNode.vehicleMiles),
                            units: rowNode.units,
                            documentId: rowNode.documentId ? rowNode.documentId : null,
                            fileName: rowNode.fileName ? rowNode.fileName : null,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(rowNode?.sourceId.split('-')[1]);
                        return {}
                    }
                });
            const airwaysData = data
                .filter(rowNode => rowNode.sourceId && (rowNode.type === 'Airways'))
                .map((rowNode, index) => {
                    if (rowNode.flightLength && rowNode.sourceDescription && rowNode.units 
                        && rowNode.vehicleMiles) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            resourceType: rowNode.resourceType,
                            flightLength: rowNode.flightLength,
                            passengerMiles: parseFloat(rowNode.vehicleMiles),
                            units: rowNode.units,
                            documentId: rowNode.documentId ? rowNode.documentId : null,
                            fileName: rowNode.fileName ? rowNode.fileName : null,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(rowNode?.sourceId.split('-')[1]);
                        return {}
                    }
                });
            if (personalData?.some(ele => Object.keys(ele)?.length === 0) || publicData?.some(ele => Object.keys(ele)?.length === 0)
                || airwaysData?.some(ele => Object.keys(ele)?.length === 0)) {
                error = true;
            } else {
                error = false
            }
            return {
                businessTravelPersonalData: personalData.filter(ele => Object.keys(ele)?.length > 0),
                businessTravelPublicData: publicData.filter(ele => Object.keys(ele)?.length > 0),
                businessTravelAirwaysData: airwaysData.filter(ele => Object.keys(ele)?.length > 0)
            }
        }
        return [];

    }

    const onSubmit = async () => {
        const gridObject = getGridData();
        if (error === true) {
            toast.error(`Please fill missing values in row ${[...new Set(rowIndex)].join(',')}`)
            setRowIndex([])
            error = false;
            return
        } else {
            setTravelPersonalData(gridObject?.businessTravelPersonalData);
            setTravelPublicData(gridObject?.businessTravelPublicData);
            setTravelAirwaysData(gridObject?.businessTravelAirwaysData);
            setSteps(0)
        }
    }

    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}> Business Travel by Vehicle (CO<sub>2</sub>, CH<sub>4</sub> and N<sub>2</sub>O)</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            {deleteLoading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div style={{ opacity: deleteLoading ? '0.2' : '1' }}>
                <div className="mtop-1">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={data?.filter(ele => ele?.isDelete === false)}
                            columnData={columnData}
                            agRef={carRef}
                            onCellValueChanged={(e) => onCellValueChanged(e)}
                        />
                        <div className="ag-custom-footer">
                            <div className="d-flex ">
                                <button className="add-row-button" onClick={() => addRows(1)}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                <input
                                    type="number"
                                    className="ml-1 input-number"
                                    value={noOfRow}
                                    onChange={(e) => addingRows(e.target.value)}
                                />
                                <h5 className="ml-1">rows</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </Fragment>
    )
}

export default BusinessTravel;