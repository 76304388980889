import { Grid, File, UserPlus, BarChart2 } from 'react-feather';
import { HiOutlineShoppingCart, HiUsers, HiOutlineLocationMarker } from 'react-icons/hi'
import { IoWalletOutline } from 'react-icons/io5'
import { HiOutlineOfficeBuilding, HiOutlineCurrencyDollar } from 'react-icons/hi';
import { FiGrid, FiBox, FiBriefcase, FiSlack, FiUserPlus, FiWind, FiCloudLightning, FiMapPin, FiSettings, FaChartLine  } from 'react-icons/fi';
import { GrCloudUpload, GrLineChart, GrPlan, GrUserSettings   } from 'react-icons/gr';
import { SlEnergy, SlBriefcase  } from 'react-icons/sl';
import { AiOutlineLineChart, AiOutlineFileProtect, AiOutlineThunderbolt  } from 'react-icons/ai'
import { IoBarChartOutline, IoLayersOutline, IoLeafOutline,
    IoBagHandleOutline, IoCloudUploadOutline, IoSettingsOutline, IoCloudDownloadOutline, IoCartOutline    } from 'react-icons/io5';
import { GiGreenhouse, GiChart  } from "react-icons/gi";
import { HiOutlineBolt } from "react-icons/hi2";
import { FaRegChartBar, FaClipboardList, FaRegHandshake  } from "react-icons/fa";
import { TfiStatsUp } from "react-icons/tfi";
import { IoListOutline, IoWaterOutline  } from "react-icons/io5";
import { FiList, FiFilePlus, FiFileText  } from "react-icons/fi";
import { FaUncharted, FaRegFileImage, FaRecycle  } from "react-icons/fa";
import { TbFileStar } from "react-icons/tb";
import { TbFileReport } from "react-icons/tb";
import { HiOutlineBuildingLibrary, HiOutlineClipboardDocumentList, HiOutlineDocumentChartBar  } from "react-icons/hi2"
import { GrMap } from "react-icons/gr";
import { LuFileBarChart2 } from "react-icons/lu";
import { TbBuildingFactory } from "react-icons/tb";
import { PiListChecksLight } from "react-icons/pi";

const ASO_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/aso-admin/projects`, icon: HiOutlineClipboardDocumentList, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-admin/project-initiation/Internal`, icon: FiFilePlus, title: 'New Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-admin/project-initiation/External`, icon: FiFileText, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet', icon: IoWalletOutline, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/wallet-summary`
                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar , badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/trading`, icon: GiChart, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/orders`, icon: IoCartOutline, title: 'Orders', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Net0Trace', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/group-list`
                    },
                    {
                        title: 'Locations', icon: HiOutlineLocationMarker, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/location-list`
                    },
                    {
                        title: 'Data Capture', icon: IoCloudUploadOutline, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/data-capture`
                    },
                    {
                        title: 'Approval Queue', icon: PiListChecksLight, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/data-capture/approval-queue`
                    },
                    {
                        title: 'Net Zero Planner', icon: TbFileStar, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/target-settings`
                    },
                    {
                        title: 'Action Plan', icon: GrPlan, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/ghg-action-plan`
                    },
                    {
                        title: 'Analytics', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'Executive Dashboard', icon: FaRegFileImage, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-admin/ghg-accounting`
                            },
                            { path: `${process.env.PUBLIC_URL}/aso-admin/target-net-zero-emission`, icon: FaUncharted, title: 'Target vs Actual', type: 'link' },
                            {   
                                title: 'Emission Insights', icon: TbFileReport, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-admin/emission-insights`
                            },
                        ]
                    },
                ]
            },
            {
                title: 'Sustainability Reporting', id: 2, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Business Info', icon: SlBriefcase,  path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/business-info`, type: 'link'
                    },
                    {
                        title: 'Environmental', icon: IoLeafOutline,  path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/environmental`, type: 'link'
                    },
                    {
                        title: 'Social', icon: FaRegHandshake,  path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/social`, type: 'link'
                    },
                    {
                        title: 'Governance', icon: HiOutlineBuildingLibrary,  path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/governance`, type: 'link'
                    },
                    {
                        title: 'Dashboards', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'Energy', icon: AiOutlineThunderbolt, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/energy`
                            },
                            {   
                                title: 'Water', icon: IoWaterOutline, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/water`
                            },
                            {   
                                title: 'Emission', icon: TbBuildingFactory, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/emission`
                            },
                            {   
                                title: 'Effluents & Waste', icon: FaRecycle, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/waste`
                            },
                        ]
                    },
                    {
                        title: 'Reporting', icon: HiOutlineDocumentChartBar, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/sustainability-reporting/reporting`
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: BarChart2 , badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: TfiStatsUp, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/aqi-dashboard`
                    },
                ]
            },
        ],
        defaultMenu: [
            {
                title: 'Settings', icon: IoSettingsOutline , badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Holding Companies', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/sub-organization-list`
                    },
                    {
                        title: 'Application', icon: FiSettings,  path: `${process.env.PUBLIC_URL}/aso-admin/settings/application-settings`, type: 'link'
                    },
                    {
                        title: 'User-Location Map', icon: GrMap,  path: `${process.env.PUBLIC_URL}/aso-admin/settings/user-location-mapping`, type: 'link'
                    },
                    {
                        title: 'Downloads', icon: IoCloudDownloadOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/downloads`
                    },
                    {
                        title: 'Users', icon: GrUserSettings, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/user-list`
                    },
                    
                ]
            }
        ]
    }
]

const ASO_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/aso-user/projects`, icon: HiOutlineClipboardDocumentList, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-user/project-initiation/Internal`, icon: FiFilePlus, title: 'New Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-user/project-initiation/External`, icon: FiFileText, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet', icon: IoWalletOutline, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-user/wallet-summary`
                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar , badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/aso-user/carbon-trading/trading`, icon: GiChart, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-user/carbon-trading/orders`, icon: IoCartOutline, title: 'Orders', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Net0Trace', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Data Capture', icon: IoCloudUploadOutline, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-user/data-capture`
                    },
                    {
                        title: 'Net Zero Planner', icon: TbFileStar, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-user/target-settings`
                    },
                    {
                        title: 'Action Plan', icon: GrPlan, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-user/ghg-action-plan`
                    },
                    {
                        title: 'Analytics', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'Executive Dashboard', icon: FaRegFileImage, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-user/ghg-accounting`
                            },
                            { path: `${process.env.PUBLIC_URL}/aso-user/target-net-zero-emission`, icon: FaUncharted, title: 'Target vs Actual', type: 'link' },
                            {   
                                title: 'Emission Insights', icon: TbFileReport, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-user/emission-insights`
                            },
                        ]
                    },
                ]
            },
            {
                title: 'Sustainability Reporting', id: 2, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Business Info', icon: SlBriefcase,  path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/business-info`, type: 'link'
                    },
                    {
                        title: 'Environmental', icon: IoLeafOutline,  path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/environmental`, type: 'link'
                    },
                    {
                        title: 'Social', icon: FaRegHandshake,  path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/social`, type: 'link'
                    },
                    {
                        title: 'Governance', icon: HiOutlineBuildingLibrary,  path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/governance`, type: 'link'
                    },
                    {
                        title: 'Dashboards', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'Energy', icon: AiOutlineThunderbolt, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/energy`
                            },
                            {   
                                title: 'Water', icon: IoWaterOutline, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/water`
                            },
                            {   
                                title: 'Emission', icon: TbBuildingFactory, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/emission`
                            },
                            {   
                                title: 'Effluents & Waste', icon: FaRecycle, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/waste`
                            },
                        ]
                    },
                    {
                        title: 'Reporting', icon: HiOutlineDocumentChartBar, type: 'link', path: `${process.env.PUBLIC_URL}/aso-user/sustainability-reporting/reporting`
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-user/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-user/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-user/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-user/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: BarChart2 , badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/aso-user/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: TfiStatsUp, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/aso-user/aqi-dashboard`
                    },
                ]
            },
        ],
        defaultMenu: [
            {
                title: 'Settings', icon: IoSettingsOutline , badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Application', icon: FiSettings,  path: `${process.env.PUBLIC_URL}/aso-user/settings/application-settings`, type: 'link'
                    },
                    {
                        title: 'Downloads', icon: IoCloudDownloadOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-user/downloads`
                    },
                ]
            }
        ]
    },
];

const ANALYST = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items:[
            {
                title: 'Net0Trace', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    // {
                    //     title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/analyst/group-list`
                    // },
                    // {
                    //     title: 'Locations', icon: HiOutlineLocationMarker, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/analyst/location-list`
                    // },
                    {
                        title: 'Data Capture', icon: IoCloudUploadOutline, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/analyst/data-capture`
                    },
                    // {
                    //     title: 'Approval Queue', icon: PiListChecksLight, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/analyst/data-capture/approval-queue`
                    // },
                    {
                        title: 'Net Zero Planner', icon: TbFileStar, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/analyst/target-settings`
                    },
                    {
                        title: 'Action Plan', icon: GrPlan, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/analyst/ghg-action-plan`
                    },
                    {
                        title: 'Analytics', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'Executive Dashboard', icon: FaRegFileImage, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/analyst/ghg-accounting`
                            },
                            { path: `${process.env.PUBLIC_URL}/analyst/target-net-zero-emission`, icon: FaUncharted, title: 'Target vs Actual', type: 'link' },
                            {   
                                title: 'Emission Insights', icon: TbFileReport, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/analyst/emission-insights`
                            },
                        ]
                    },
                ]
            },
            {
                title: 'Sustainability Reporting', id: 2, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Business Info', icon: SlBriefcase,  path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/business-info`, type: 'link'
                    },
                    {
                        title: 'Environmental', icon: IoLeafOutline,  path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/environmental`, type: 'link'
                    },
                    {
                        title: 'Social', icon: FaRegHandshake,  path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/social`, type: 'link'
                    },
                    {
                        title: 'Governance', icon: HiOutlineBuildingLibrary,  path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/governance`, type: 'link'
                    },
                    {
                        title: 'Dashboards', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'Energy', icon: AiOutlineThunderbolt, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/energy`
                            },
                            {   
                                title: 'Water', icon: IoWaterOutline, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/water`
                            },
                            {   
                                title: 'Emission', icon: TbBuildingFactory, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/emission`
                            },
                            {   
                                title: 'Effluents & Waste', icon: FaRecycle, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/waste`
                            },
                        ]
                    },
                    {
                        title: 'Reporting', icon: HiOutlineDocumentChartBar, type: 'link', path: `${process.env.PUBLIC_URL}/analyst/sustainability-reporting/reporting`
                    },
                ]
            },
        ],
        defaultMenu: [
            {
                title: 'Settings', icon: IoSettingsOutline , badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Application', icon: FiSettings,  path: `${process.env.PUBLIC_URL}/analyst/settings/application-settings`, type: 'link'
                    },
                    {
                        title: 'Downloads', icon: IoCloudDownloadOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/analyst/downloads`
                    },
                ]
            }
        ]
    }
]

const APPROVER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items:[
            {
                title: 'Net0Trace', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/approver/group-list`
                    },
                    {
                        title: 'Locations', icon: HiOutlineLocationMarker, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/approver/location-list`
                    },
                    {
                        title: 'Data Capture', icon: IoCloudUploadOutline, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/approver/data-capture`
                    },
                    {
                        title: 'Approval Queue', icon: PiListChecksLight, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/approver/data-capture/approval-queue`
                    },
                    {
                        title: 'Net Zero Planner', icon: TbFileStar, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/approver/target-settings`
                    },
                    {
                        title: 'Action Plan', icon: GrPlan, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/approver/ghg-action-plan`
                    },
                    {
                        title: 'Analytics', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'Executive Dashboard', icon: FaRegFileImage, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/approver/ghg-accounting`
                            },
                            { path: `${process.env.PUBLIC_URL}/approver/target-net-zero-emission`, icon: FaUncharted, title: 'Target vs Actual', type: 'link' },
                            {   
                                title: 'Emission Insights', icon: TbFileReport, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/approver/emission-insights`
                            },
                        ]
                    },
                ]
            },
            {
                title: 'Sustainability Reporting', id: 2, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Business Info', icon: SlBriefcase,  path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/business-info`, type: 'link'
                    },
                    {
                        title: 'Environmental', icon: IoLeafOutline,  path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/environmental`, type: 'link'
                    },
                    {
                        title: 'Social', icon: FaRegHandshake,  path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/social`, type: 'link'
                    },
                    {
                        title: 'Governance', icon: HiOutlineBuildingLibrary,  path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/governance`, type: 'link'
                    },
                    {
                        title: 'Dashboards', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'Energy', icon: AiOutlineThunderbolt, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/energy`
                            },
                            {   
                                title: 'Water', icon: IoWaterOutline, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/water`
                            },
                            {   
                                title: 'Emission', icon: TbBuildingFactory, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/emission`
                            },
                            {   
                                title: 'Effluents & Waste', icon: FaRecycle, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/waste`
                            },
                        ]
                    },
                    {
                        title: 'Reporting', icon: HiOutlineDocumentChartBar, type: 'link', path: `${process.env.PUBLIC_URL}/approver/sustainability-reporting/reporting`
                    },
                ]
            },
        ],
        defaultMenu: [
            {
                title: 'Settings', icon: IoSettingsOutline , badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Application', icon: FiSettings,  path: `${process.env.PUBLIC_URL}/approver/settings/application-settings`, type: 'link'
                    },
                    {
                        title: 'User-Location Map', icon: GrMap,  path: `${process.env.PUBLIC_URL}/approver/settings/user-location-mapping`, type: 'link'
                    },
                    {
                        title: 'Downloads', icon: IoCloudDownloadOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/approver/downloads`
                    },
                ]
            }
        ]
    }
]

const SYS_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/dashboard`
            },
            {
                title: 'Projects', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/projects`
            },
            {
                title: 'Organizations', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/customer-list`
            },
            {
                title: 'Orders', icon: HiOutlineShoppingCart, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/orders`
            },
            {
                title: 'Subscriptions', icon: FiBriefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/module-origanization`
            },
        ]
    },
];

const SYS_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/dashboard`
            },
            {
                title: 'Projects', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/projects`
            },
            {
                title: 'Organizations', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/customer-list`
            },
            {
                title: 'Carbon Trades', icon: HiOutlineShoppingCart, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/orders`
            },
            {
                title: 'Subscriptions', icon: FiBriefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/module-origanization`
            },
            {
                title: 'Credit Rates', icon: FiSlack, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/credit-rates`
            },
            {
                title: 'Exchange Rates', icon: HiOutlineCurrencyDollar, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/exchange-rate-list`
            },
            {
                title: 'Emission Factors', icon: IoListOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/emission-factor-list`
            },
        ]
    }
]

const ASM_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-admin/projects`, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/project-initiation/Internal`, title: 'New Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/project-initiation/External`, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet Details', icon: IoWalletOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/wallet-details`

                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar , badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/trading`, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/orders`, title: 'Orders', type: 'link' },
                        ]
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-admin/baseline-calculation`, title: 'Baseline Calculation', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/monthly-reports`, title: 'Monthly Reports', type: 'link' },
                        ]
                    }
                ]
            },
            {
                title: 'GHG Emission', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/group-list`
                    },
                    {
                        title: 'Location', icon: FiMapPin, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/location-list`
                    },
                    {
                        title: 'Data Upload', icon: GrCloudUpload, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/carbon-emission-list`
                    },
                    {
                        title: 'Target settings', icon: FiSettings, type: 'link', active: false
                    },
                    {
                        title: 'Buy Offsets', icon: IoBagHandleOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/buy-offsets`
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/asm-admin/dashboard`, title: 'ESG', type: 'link' },
                            {   
                                title: 'GHG Accounting', icon: GiGreenhouse, type: 'link', badge: 'true', active: false, path: `${process.env.PUBLIC_URL}/user/ghg-accounting`
                            },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/gri-dashboard`, title: 'GRI Reports', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: GrLineChart, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: FaRegChartBar, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/aqi-dashboard`
                    },
                ]
            },
            {
                title: 'Users', icon: UserPlus, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/user-list`
            }
        ]
    }
]


const ASM_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-user/projects`, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/project-initiation/Internal`, title: 'New Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/project-initiation/External`, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet', icon: IoWalletOutline, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-user/wallet-summary`, title: 'Summary', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/wallet-details`, title: 'Detail View', type: 'link' },
                        ]
                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar , badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-user/carbon-trading/trading`, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/carbon-trading/orders`, title: 'Orders', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'GHG Emission', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/group-list`
                    },
                    {
                        title: 'Location', icon: HiOutlineLocationMarker, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/location-list`
                    },
                    {
                        title: 'Data Upload', icon: GrCloudUpload, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/carbon-emission-list`
                    },
                    {
                        title: 'Target settings', icon: FiSettings, type: 'link', active: false
                    },
                    {
                        title: 'Buy Offsets', icon: IoBagHandleOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/buy-offsets`
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/asm-user/dashboard`, title: 'ESG', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/ghg-emission-dashboard`, title: 'ESG', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/gri-dashboard`, title: 'GRI Reports', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: GrLineChart, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: FaRegChartBar, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/aqi-dashboard`
                    },
                ]
            },
        ]
    }
]

const ASR_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            // {
            //     title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard`
            // },
            {
                title: 'Project List', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/assessor/projects`
            }
        ]
    }
]




const ASR_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            // {
            //     title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asr-admin/dashboard`
            // },
            {
                title: 'Project List', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asr-admin/projects`
            },
            {
                title: 'Users', icon: UserPlus, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asr-admin/user-list`
            }
        ]
    }
]

export const MenuItem = {
    ASO_USER, ASO_ADMIN, SYS_USER, SYS_ADMIN, ASM_USER, ASR_ADMIN, ASR_USER, ASM_ADMIN, ANALYST, APPROVER
}