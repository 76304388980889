import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import AddLocationModal from './AddLocation';
import DeleteLocation from './DeleteLocation';
import OrgSummary from '../OrgSummary';
import { toast } from 'react-toastify';
import { defaultUserType } from "../../api/helper";
import AgGridTable from '../AgGridTable';
import ReactCountryFlag from "react-country-flag";
import { Tooltip } from 'react-tooltip';
import Tab from 'react-bootstrap/Tab';
import Nav from "react-bootstrap/Nav";
import MapLocationModal from './MapLocationModal';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component-with-filter';
import { Table } from 'react-bootstrap';


const LocationList = () => {
    const [tableData, setTableData] = useState([]);
    const [showFilter, setShowFilter] = useState(false)
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dataId, setDataId] = useState();
    const [treeView, setTreeView] = useState(false);
    const [addStatus, setAddStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const locationRef = useRef(null);
    const [activeKey, setActiveKey] = useState('1');
    const [mapOpen, setMapOpen] = useState(false);
    const [mapData, setMapData] = useState();
    const [locationData, setLocationData] = useState();

    const defaultUserType = {
        EMISSIONS_ANALYST: 'Analyst',
        EMISSIONS_APPROVER: 'Approver'
    }

    const userType = sessionStorage.getItem('userType');
    const userObject = useSelector(state => state?.user?.userRoles);

    const loadData = async () => {
        console.log(userObject[2][0])
        let url = userObject[2][0] === 'EMISSIONS_ADMIN' ? 'location/responsibilityMatrix' : 'location/user/module';
        await fetchWrapper(`${API_URL}/${url}`)
            .then((res) => {
                console.log(res.json)
                if (res.status === 200) {
                    if (userObject[2]?.includes('EMISSIONS_APPROVER')) {
                        setTableData(res.json?.filter(role => role?.role === 'EMISSIONS_APPROVER'))
                    } else {
                        setTableData(res.json)
                    }
                }
            }, (error) => {
                console.log(error)
            })
    }

    const handleDelete = (id) => {
        setDataId(id)
        setDeleteOpen(true)
    }

    const handleMap = (data) => {
        let geoCoordinates = data?.geoCoordinates?.split(',')
        const geoData = { lat: Number(geoCoordinates[0]), lng: Number(geoCoordinates[1]) }
        setMapData(geoData);
        setLocationData(data);
        setMapOpen(true)
    }

    const handleStatus = async (id, status) => {
        let updateStatus = status === true ? false : true;
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/location/status/${id}/${updateStatus}`, options)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.json?.isactive === true)
                        toast.success('Location Status enabled Successfully!')
                    else
                        toast.error('Location Status disabled Successfully!')
                    loadData()
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    // const columnData = [
    //     {
    //         headerName: 'Group Name',
    //         field: 'groupName',
    //         filter: true,
    //         flex: 1.5,
    //         sortable: true,
    //         suppressMovable: true,
    //         filterParams: {
    //             filterOptions: ['contains'],
    //             maxNumConditions: 0,
    //             buttons: ['reset']
    //         }
    //     },
    //     {
    //         headerName: 'Location',
    //         filter: true,
    //         flex: 3,
    //         sortable: true,
    //         cellRenderer: (params) => {
    //             const length = params.data?.locationName + params.data?.addressLine1
    //             return <div className='d-flex'>
    //                 <a className="location-link" onClick={() => handleMap(params.data)} ><i class="fa-solid fa-location-dot map-pin"></i></a>
    //                 <div className={`ml-1 ${length > 75 && 'cell-wrap'}`} >{`${params.data?.locationName}`}</div>
    //             </div>
    //         },
    //         suppressMovable: true,
    //         filterParams: {
    //             filterOptions: ['contains'],
    //             maxNumConditions: 0,
    //             buttons: ['reset']
    //         },
    //         // cellClass: 'cell-wrap'
    //     },
    //     {
    //         headerName: 'City',
    //         filter: true,
    //         flex: 1.5,
    //         sortable: true,
    //         valueGetter: (params) => {
    //             return `${params.data.city}`
    //         },
    //         suppressMovable: true,
    //         filterParams: {
    //             filterOptions: ['contains'],
    //             maxNumConditions: 0,
    //             buttons: ['reset']
    //         }
    //     },
    //     {
    //         headerName: 'State',
    //         field: 'stateName',
    //         filter: true,
    //         flex: 1,
    //         sortable: true,
    //         suppressMovable: true,
    //         filterParams: {
    //             filterOptions: ['contains'],
    //             maxNumConditions: 0,
    //             buttons: ['reset']
    //         },
    //         cellClass: 'cell-wrap'
    //     },
    //     {
    //         headerName: 'Country',
    //         field: 'country',
    //         tooltipValueGetter: (params) => params.data.countryName,
    //         cellRenderer: (params) => {
    //             return (
    //                 <div className='ml-1'>
    //                     <ReactCountryFlag countryCode={params.data.countryCode === 'UAE' ? 'UA' : params.data.countryCode} style={{
    //                         width: '2em',
    //                         height: '2em',
    //                     }} svg />

    //                 </div>
    //             )
    //         },
    //         width: 80,
    //         suppressMovable: true,
    //     },
    //     ...(userObject[2][0] === 'EMISSIONS_ADMIN' ? [{
    //         headerName: 'Status',
    //         filter: false,
    //         width: 70,
    //         sortable: true,
    //         suppressMovable: true,
    //         cellRenderer: (params) => {
    //             return (
    //                 <div className=''>
    //                     <label className="switch">
    //                         <input type="checkbox" id="togBtn"
    //                             checked={params.data.active}
    //                             onChange={() => handleStatus(params.data.locationId, params.data.active)}
    //                         />
    //                         <div className="slider round">
    //                             <span className="on"></span>
    //                             <span className="off"></span>
    //                         </div>
    //                     </label>
    //                 </div>
    //             )
    //         }
    //     }] : []),
    //     {
    //         headerName: 'View',
    //         width: 60,
    //         cellRenderer: params => {
    //             return (
    //                 <Link to={`${process.env.PUBLIC_URL}/view-location/${params.data.locationId}`} >
    //                     <Eye className={`action-icon`} />
    //                 </Link>
    //             )
    //         },
    //         suppressMovable: true,
    //     },
    //     ...(userObject[2][0] === 'EMISSIONS_ADMIN' ? [{
    //         headerName: 'Edit',
    //         width: 50,
    //         cellRenderer: params => {
    //             return (
    //                 <Link to={`${process.env.PUBLIC_URL}/edit-location/${params.data.locationId}`} >
    //                     <Edit className={`edit-icon `} />
    //                 </Link>
    //             )
    //         },
    //         suppressMovable: true,
    //     }] : []),
    //     ...(userObject[2][0] === 'EMISSIONS_ADMIN' ? [{
    //         headerName: 'Delete',
    //         width: 70,
    //         cellRenderer: params => {
    //             return (
    //                 <div onClick={() => handleDelete(params.data.locationId)} >
    //                     <Trash2 className={'trash-icon'} />
    //                 </div>
    //             )
    //         },
    //         suppressMovable: true,
    //     }] : [])
    // ]

    const ExpandedComponent = ({ data }) => (
        <div style={{ width: '50%', padding: '5px 25px' }}>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.usersList?.map((item, index) => (
                            <tr>
                                <td style={{ width: '50%' }}>{item?.userName}</td>
                                <td>{defaultUserType[item?.role]}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

        </div>
    );

    const columns = [
        {
            name: 'Group Name',
            selector: row => row.groupName,
            sortable: true,
            allowOverflow: true,
            grow: 1.5,
            wrap: true,
        },
        {
            name: 'Location',
            cell: row => (
                <div className='d-flex'>
                    <a className="location-link" onClick={() => handleMap(row)}>
                        <i className="fa-solid fa-location-dot map-pin"></i>
                    </a>
                    <div className={`ml-1 ${row.locationName?.length + row.addressLine1?.length > 75 ? 'cell-wrap' : ''}`}>
                        {row.locationName}
                    </div>
                </div>
            ),
            sortable: true,
            allowOverflow: true,
            grow: 3,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
            grow: 1.5,
        },
        {
            name: 'State',
            selector: row => row.stateName,
            sortable: true,
            grow: 1,
            wrap: true,
        },
        {
            name: 'Country',
            cell: row => (
                <ReactCountryFlag
                    countryCode={row.countryCode === 'UAE' ? 'UA' : row.countryCode}
                    svg
                    style={{
                        width: '2em',
                        height: '2em',
                    }}
                    title={row.countryName}
                />
            ),
            width: '80px',
            sortable: false,
        },
        ...(userObject[2][0] === 'EMISSIONS_ADMIN' ? [{
            name: 'Status',
            cell: row => (
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={row.active}
                        onChange={() => handleStatus(row.locationId, row.active)}
                    />
                    <div className="slider round">
                        <span className="on"></span>
                        <span className="off"></span>
                    </div>
                </label>
            ),
            width: '70px',
            sortable: true,
        }] : []),
        {
            name: 'View',
            cell: row => (
                <Link to={`${process.env.PUBLIC_URL}/view-location/${row.locationId}`}>
                    <Eye className="action-icon" />
                </Link>
            ),
            width: '60px',
            sortable: false,
        },
        ...(userObject[2][0] === 'EMISSIONS_ADMIN' ? [{
            name: 'Edit',
            cell: row => (
                <Link to={`${process.env.PUBLIC_URL}/edit-location/${row.locationId}`}>
                    <Edit className="edit-icon" />
                </Link>
            ),
            width: '50px',
            sortable: false,
        }] : []),
        ...(userObject[2][0] === 'EMISSIONS_ADMIN' ? [{
            name: 'Delete',
            cell: row => (
                <div onClick={() => handleDelete(row.locationId)}>
                    <Trash2 className="trash-icon" />
                </div>
            ),
            width: '70px',
            sortable: false,
        }] : [])
    ];

    const iconComponent = <div className='d-flex'>
        <div>
            <i style={{ marginRight: '1rem' }} onClick={() => setTreeView(false)} className="icon-style icofont  icofont-list" ></i>
            <h5 className='text-color mtop-hf'>List View</h5>
        </div>
        <div className='ml-1'>
            <i className="icon-style icofont  icofont-sub-listing" onClick={() => setTreeView(true)}></i>
            <h5 className='text-color mtop-hf'>Tree View</h5>
        </div>
    </div>



    const handleClick = () => {
        setOpen(true)
    }

    useEffect(() => {
        if ((addStatus === true) || (deleteStatus === true)) {
            loadData();
        }
    }, [addStatus, deleteStatus])

    useEffect(() => {
        loadData()
    }, [])
    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontSize: '13px',
                height: '35px',
                fontWeight: '600',
                minHeight: '35px',
                width: '30%',
                minWidth: '50px !important'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
                width: '30%',
                minWidth: '50px !important'
            },
        },
    }


    return (
        <Fragment>
            {deleteOpen && <DeleteLocation value={deleteOpen} setOpenModal={setDeleteOpen} dataId={dataId} setDelete={setDeleteStatus} />}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex mtop-1">
                            <Breadcrumbs parent="Locations" title="List" mainTitle="Locations" />
                            {
                                userObject[2][0] === 'EMISSIONS_ADMIN' && <div className="text-right  action_button mr-13">
                                    <div className="link action_button" onClick={handleClick}>
                                        <i className="icon-style icofont  icofont-plus-circle" ></i>
                                        &nbsp;&nbsp;{"Add Location"}
                                    </div>
                                </div>
                            }

                        </div>
                        <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <div>
                                <Nav variant="pills" defaultActiveKey="1">
                                    <Nav.Item>
                                        <Nav.Link eventKey="1">List View</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="2">Tree View</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>

                            <Tab.Content className="mtop-1">
                                <Tab.Pane eventKey={'1'}>
                                    {/* <div className="location-table">
                                        <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                            <AgGridTable
                                                columnData={columnData}
                                                tableData={tableData}
                                                agRef={locationRef}
                                                onGridReady={onGridReady}
                                            />
                                        </div>
                                    </div> */}
                                    <DataTable
                                        columns={columns}
                                        data={tableData}
                                        customStyles={customStyles}
                                        pagination
                                        responsive
                                        expandableRows={tableData?.some(ele => ele?.usersList)}
                                        expandableRowsComponent={ExpandedComponent}
                                        expandableRowDisabled={row => !row.usersList?.length === 0} // Optional: Disable expansion if no details
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey={'2'}>
                                    <Card className=" location-list newtable">
                                        <CardBody className='data-tables'>
                                            <OrgSummary setTree={setTreeView} />
                                        </CardBody>
                                    </Card>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
                {
                    open && <AddLocationModal value={open} setOpenModal={setOpen} setAdd={setAddStatus} />
                }
                {
                    mapOpen && <MapLocationModal value={mapOpen} setOpenModal={setMapOpen} data={mapData} locationData={locationData} />
                }
            </Container>
        </Fragment>
    )
}

export default LocationList;