import React, { Fragment, useState, useEffect } from "react";
import { dummytabledata } from "./AsoUserlist";
import UserForm from "../../Common/Component/UserForm";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { useParams } from "react-router";

const EditAsoUser = () => {
    const [ data, setData ] = useState();
    const userId = useParams()?.id;

    useEffect(() => {
        loadData(userId)
    }, []);

    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/users/${id}`, options)
        .then((res)=> {
            console.log(res)
            setData(res.json)
        })
        .catch((error) => {
            console.debug(error)
        })
    }


    return (
        <Fragment>
            <UserForm action={'Edit'} title={'Edit User'} navigateUrl={`${process.env.PUBLIC_URL}/user-list`} preData={data} />
        </Fragment>
    )
}

export default EditAsoUser;