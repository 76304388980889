import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button, FormGroup, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { currencySymbol } from "../../../api/currencyData";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";

const SocialResponsibility = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const currency = useSelector(state => state?.user?.userDetails?.currency);
    const [csrApplicable, setCsrApplicable] = useState();
    const [turnOver, setTurnover] = useState();
    const [networth, setNetworth] = useState();
    const [orgDetails, setOrg] = useState();

    const getCSRData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/csrData/${financialYear}`)
            .then((res) => {
                console.log(res.json, 'CSR')
                setCsrApplicable(res.json?.find(ele => ele?.details === 'Is CSR applicable')?.companyParticulars === 'true' ? true : false);
                setTurnover(res.json?.find(ele => ele?.details === 'turnover')?.companyParticulars);
                setNetworth(res.json?.find(ele => ele?.details === 'networth')?.companyParticulars)
            }, (err) => console.log(err))
    }

    const getOrgDetails = async () => {
        await fetchWrapper(`${API_URL}/users/orgDetails`)
            .then(res => {
                setOrg(res.json[0])
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (financialYear) {
            getCSRData()
            getOrgDetails()
        }
    }, [financialYear])

    const userDetails = useSelector(state => state?.user?.userDetails)


    const onSubmit = async () => {
        const data = [
            {
                serialNp: 1,
                details: "Is CSR applicable",
                companyParticulars: csrApplicable,
            },
            {
                serialNp: 2,
                details: "Turnover",
                companyParticulars: turnOver,
            },
            {
                serialNp: 3,
                details: "Networth",
                companyParticulars: networth,
            }
        ];
        const options = {
            method: 'POST',
            body: {
                financialYear: financialYear,
                csrData: data
            }
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            }, (err) => {
                if (err.status === 200) {
                    setActive(2)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }
    return (
        <Fragment>
            <h3>Corporate Social Responsibility</h3>
            <div className="mtop-1 company-profile">
                <div className="d-flex">
                    <h5 className="col-6">Networth (In {currencySymbol[currency]})</h5>
                    <input type="number" className="form-control ml-1" value={networth} onChange={(e) => setNetworth(e.target.value)} />
                </div>
                <div className="d-flex mtop-1">
                    <h5 className="col-6">Turnover (In {currencySymbol[currency]})</h5>
                    <input type="number" className="form-control ml-1" placeholder="" value={turnOver} onChange={(e) => setTurnover(e.target.value)} />
                </div>
                {
                    <div className="d-flex mtop-1">
                        <h5 className="col-6">Is CSR applicable as per section 135 of Companies Act, 2013</h5>
                        <div className='d-flex ml-1'>
                            <input className="form-check-input radio_animated" type='radio' value={true}
                                checked={csrApplicable === true ? true : false}
                                onChange={(e) => setCsrApplicable(true)}
                            /> <lable>Yes</lable>
                            <input className="form-check-input radio_animated ml-1" type='radio' value={false}
                                checked={csrApplicable === false ? true : false}
                                onChange={(e) => setCsrApplicable(false)}
                            /> <lable>No</lable>
                        </div>
                    </div>
                }
            </div >
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(1)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment >
    )
}

export default SocialResponsibility;