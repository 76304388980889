import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { complaintsData, misleadingData, nonmonetaryPenaltyData, numberOfConsumerComplaints, penaltyData, policyTemplateData, productRecalls, trainingData } from "../../Settings/settingshelper";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Trash2 } from "react-feather";

const ResponsibleConsumerEngagement = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [consumerComplaints, setConsumerComplaints] = useState()
    const [environmentalPercentage, setEnvironmentalPercentage] = useState()
    const [responsiblePercentage, setResponsiblePercentage] = useState()
    const [disposalPercentage, setDisposalPercentage] = useState();
    const [consumerComplaintsData, setConsumerComplaintsData] = useState(numberOfConsumerComplaints)
    const [productRecallData, setProductRecallData] = useState(productRecalls)
    const [isDataPrivacyRisk, setDataPrivacyRisk] = useState()
    const [weblinkPolicy, setWeblinkPolicy] = useState()
    const [productIssueDetails, setProductIssueDetails] = useState()
    const [numberOfDataBreaches, setDataBreaches] = useState()
    const [percentageOfDataBreaches, setDataBreachesPercentage] = useState()
    const [dataBreachesImpact, setDataBreachesImpact] = useState()
    const [productInformation, setProductInformation] = useState();
    const [informationSteps, setInformationSteps] = useState();
    const [riskMechanism, setRiskMechanism] = useState();
    const [doesDisplayInformation, setDoesDisplay] = useState();
    const [informationDetails, setInformationDetails] = useState();
    const [consumerSatisfaction, setConsumerSatisfaction] = useState()
    const [revenueShareData, setRevenueShareData] = useState();

    const getRevenueShareData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/revenueShareData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                setRevenueShareData(res.json)
                setEnvironmentalPercentage(res.json?.find(ele => ele?.particulars === "Environmental and Social parameters relevant to the product")?.turnOverRatio);
                setResponsiblePercentage(res.json?.find(ele => ele?.particulars === "Safe and responsible usage")?.turnOverRatio);
                setDisposalPercentage(res.json?.find(ele => ele?.particulars === "Recycling and/or safe disposal")?.turnOverRatio);
            }, (err) => console.log(err))
    }

    const getConsumerComplaintsData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/consumerComplaintsData/${financialYear}`)
            .then((res) => {
                const tempData = numberOfConsumerComplaints?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setConsumerComplaintsData(tempData);
            }, (err) => {
                console.log(err)
            })
    }

    const getProductsRecallData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/productsRecallData/${financialYear}`)
            .then((res) => {
                const tempData = productRecalls?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setProductRecallData(tempData);
            }, (err) => {
                console.log(err)
            })
    }

    const getAttributesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json?.filter(ele => ele.groupName === 'responsibleConsumerEngagement');
                setConsumerComplaints(data?.find(ele => ele.key === 'Q53')?.value);
                setDataPrivacyRisk(data?.find(ele => ele.key === 'Q54')?.value);
                setWeblinkPolicy(data?.find(ele => ele.key === 'Q55')?.value);
                setProductIssueDetails(data?.find(ele => ele.key === 'Q56')?.value);
                setDataBreaches(data?.find(ele => ele.key === 'Q57')?.value);
                setDataBreachesPercentage(data?.find(ele => ele.key === 'Q58')?.value);
                setDataBreachesImpact(data?.find(ele => ele.key === 'Q59')?.value);
                setProductInformation(data?.find(ele => ele.key === 'Q60')?.value);
                setInformationSteps(data?.find(ele => ele.key === 'Q61')?.value);
                setRiskMechanism(data?.find(ele => ele.key === 'Q62')?.value);
                setDoesDisplay(data?.find(ele => ele.key === 'Q63')?.value);
                setInformationDetails(data?.find(ele => ele.key === 'Q64')?.value);
                setConsumerSatisfaction(data?.find(ele => ele.key === 'Q65')?.value);

            }, (err) => console.log(err))
    }

    useEffect(() => {
        if (financialYear) {
            getConsumerComplaintsData()
            getProductsRecallData()
            getRevenueShareData()
            getAttributesData()
        }
    }, [financialYear])

    const handleClear = (params, type) => {
        let updatedRowData;
        if (type === 1) {
            updatedRowData = consumerComplaintsData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars } : { ...row }
            })
            setConsumerComplaintsData(updatedRowData)
        } else if (type === 2) {
            updatedRowData = productRecallData?.map((row, index) => {
                return (index == params.node.rowIndex) ? { particulars: row?.particulars } : { ...row }
            })
            setProductRecallData(updatedRowData)
        }
    }

    const consumerComplaintsColumn = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Received',
                    field: 'curentYearComplaints',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Pending resolution',
                    field: 'currentYearPendingResolution',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Remarks',
                    field: 'currentYearRemarks',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Received',
                    field: 'previousYearComplaints',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Pending resolution',
                    field: 'previousYearPendingResolution',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Remarks',
                    field: 'previousYearRemarks',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if (params.data.hasOwnProperty('curentYearComplaints') ||
                    params.data.hasOwnProperty('currentYearPendingResolution') ||
                    params.data.hasOwnProperty('currentYearRemarks') ||
                    params.data.hasOwnProperty('previousYearComplaints') ||
                    params.data.hasOwnProperty('previousYearPendingResolution') ||
                    params.data.hasOwnProperty('previousYearRemarks')
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 1)} />
                        </div>
                    )
            }
        }
    ];

    const productRecallColumn = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `Number`,
            field: 'number',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.number ?
                    thousandSeparator(params?.data?.number) : ''}</div>;
            },
            flex: 2,
            editable: true,
            sortable: true
        },
        {
            headerName: 'Reasons for recall',
            field: 'recallReason',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: (params) => {
                if (params.data.hasOwnProperty('number') ||
                    params.data.hasOwnProperty('recallReason')
                )
                    return (
                        <div>
                            <Trash2 className="trash-icon mtop-0" onClick={() => handleClear(params, 2)} />
                        </div>
                    )
            }
        }
    ]

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    }

    const onSubmit = async () => {
        let revenueShare = [];
        if (revenueShareData) {
            revenueShare = [...revenueShareData];
            revenueShare[0].turnOverRatio = environmentalPercentage;
            revenueShare[1].turnOverRatio = responsiblePercentage;
            revenueShare[2].turnOverRatio = disposalPercentage;
        } else {
            revenueShare = [
                {
                    serialNo: 1,
                    particulars: "Environmental and Social parameters relevant to the product",
                    turnOverRatio: environmentalPercentage,
                    financialYear: financialYear
                },
                {
                    serialNo: 2,
                    particulars: "Safe and responsible usage",
                    turnOverRatio: responsiblePercentage,
                    financialYear: financialYear
                },
                {
                    serialNo: 3,
                    particulars: "Recycling and/or safe disposal",
                    turnOverRatio: disposalPercentage,
                    financialYear: financialYear
                }
            ]
        }
        const bodyData = {
            financialYear: financialYear,
            consumerComplaintsData: consumerComplaintsData,
            productsRecallData: productRecallData,
            revenueShareData: revenueShare,
            attributesKeyValueData: [
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q53',
                    value: consumerComplaints
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q54',
                    value: isDataPrivacyRisk
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q55',
                    value: weblinkPolicy
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q56',
                    value: productIssueDetails
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q57',
                    value: numberOfDataBreaches
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q58',
                    value: percentageOfDataBreaches
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q59',
                    value: dataBreachesImpact
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q60',
                    value: productInformation
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q61',
                    value: informationSteps
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q62',
                    value: riskMechanism
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q63',
                    value: doesDisplayInformation
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q64',
                    value: informationDetails
                },
                {
                    serialNo: 9,
                    groupName: 'responsibleConsumerEngagement',
                    key: 'Q65',
                    value: consumerSatisfaction
                },

            ]
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Environmental data saved successfully!')
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <h3>Responsible Consumer Engagement</h3>
            <h4 className="mtop-1">Essential Indicators</h4>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    1. Describe the mechanism in place to receive and respond to consumer complaints and feedback
                </h5>
                <textarea className="form-control ml-1" value={consumerComplaints} rows={3}
                    onChange={(e) => setConsumerComplaints(e.target.value)} />
            </div>
            <div className="mtop-1">
                <h5>2. Turnover of products and/services as a percentage of turnover from all products/services that carry information about</h5>
                <div className="d-flex mtop-1">
                    <h5 className="col-6">(a). Environmental and Social parameters relevant to the product percentage</h5>
                    <div className="col-6">
                        <input type="number" className="form-control ml-1" value={environmentalPercentage}
                            onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setEnvironmentalPercentage(0) : setEnvironmentalPercentage(e.target.value)}
                            onChange={(e) => setEnvironmentalPercentage(e.target.value)} />
                    </div>
                </div>
                <div className="d-flex mtop-1">
                    <h5 className="col-6">(b). Safe and responsible usage percentage</h5>
                    <div className="col-6">
                        <input type="number" className="form-control ml-1" value={responsiblePercentage}
                            onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setResponsiblePercentage(0) : setResponsiblePercentage(e.target.value)}
                            onChange={(e) => setResponsiblePercentage(e.target.value)} />
                    </div>
                </div>
                <div className="d-flex mtop-1">
                    <h5 className="col-6">(c). Recycling and/or safe disposal percentage</h5>
                    <div className="col-6">
                        <input type="number" className="form-control ml-1" value={disposalPercentage}
                            onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setDisposalPercentage(0) : setDisposalPercentage(e.target.value)}
                            onChange={(e) => setDisposalPercentage(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>3. Number of consumer complaints in respect of the following</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={consumerComplaintsData}
                                columnData={consumerComplaintsColumn}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>4. Details of instances of product recalls on account of safety issues</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={productRecallData}
                        columnData={productRecallColumn}
                    />
                </div>
            </div>
            <div className="mtop-1">
                <h5 className="col-12">
                    5.Does the entity have a framework/ Policy on cyber security and risks
                    related to data privacy? (Yes/No) If available, provide a web-link of the policy.
                </h5>
                <div className="d-flex">
                    <div className="col-6">
                        <select className="form-select" value={isDataPrivacyRisk} onChange={(e) => setDataPrivacyRisk(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            isDataPrivacyRisk === 'Yes' && <textarea className="form-control ml-1 mtop-1"
                                value={weblinkPolicy} rows={3}
                                onChange={(e) => setWeblinkPolicy(e.target.value)} />
                        }
                    </div>
                </div>

            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    6. Provide details of any corrective action taken or underway on issues relating to advertising,
                    and delivery of essential services; cyber security and data privacy of customers; re-occurrence of
                    instances of product recalls; penalty/action taken by regulatory authorities on safety of products/services.
                </h5>
                <textarea className="form-control ml-1" value={productIssueDetails} rows={3}
                    onChange={(e) => setProductIssueDetails(e.target.value)} />
            </div>
            <div className="mtop-1">
                <h5>7. Provide the following information relating to data breaches</h5>
                <div className="d-flex mtop-1">
                    <h5 className="col-6">(a). Number of instances of data breaches</h5>
                    <div className="col-6">
                        <input type="number" className="form-control ml-1" value={numberOfDataBreaches}
                            onChange={(e) => setDataBreaches(e.target.value)} />
                    </div>
                </div>
                <div className="d-flex mtop-1">
                    <h5 className="col-6">(b). Percentage of data breaches involving personally identifiable information of customers:</h5>
                    <div className="col-6">
                        <input type="number" className="form-control ml-1" value={percentageOfDataBreaches}
                            onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setDataBreachesPercentage(0) : setDataBreachesPercentage(e.target.value)}
                            onChange={(e) => setDataBreachesPercentage(e.target.value)} />
                    </div>
                </div>
                <div className="d-flex mtop-1">
                    <h5 className="col-6">(b). Impact, if any, of the data breaches</h5>
                    <div className="col-6">
                        <textarea className="form-control ml-1" value={dataBreachesImpact} rows={3}
                            onChange={(e) => setDataBreachesImpact(e.target.value)} />
                    </div>
                </div>
            </div>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    1. Channels / platforms where information on products and services of the entity can be accessed (provide web link, if available)
                </h5>
                <textarea className="form-control ml-1" value={productInformation} rows={3}
                    onChange={(e) => setProductInformation(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    2. Steps taken to inform and educate consumers about safe and responsible usage of products and/or services
                </h5>
                <textarea className="form-control ml-1" value={informationSteps} rows={3}
                    onChange={(e) => setInformationSteps(e.target.value)} />
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    3. Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential services
                </h5>
                <textarea className="form-control ml-1" value={riskMechanism} rows={3}
                    onChange={(e) => setRiskMechanism(e.target.value)} />
            </div>
            <div className="mtop-1">
                <h5 className="col-12">
                    4. Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable)
                    If yes, provide details in brief.
                </h5>
                <div className="d-flex">
                    <div className="col-6">
                        <select className="form-select" value={doesDisplayInformation} onChange={(e) => setDoesDisplay(e.target.value)}>
                            <option value={''}>-Please select-</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Not Applicable">Not Applicable</option>
                        </select>
                    </div>
                    <div className="col-6">
                        {
                            doesDisplayInformation === 'Yes' && <textarea className="form-control ml-1 mtop-1"
                                value={informationDetails} rows={3}
                                onChange={(e) => setInformationDetails(e.target.value)} />
                        }
                    </div>
                </div>

            </div>
            <div className="d-flex mtop-1">
                <h5 className="col-6">
                    Did your entity carry out any survey with regard to consumer satisfaction
                    relating to the major products / services of the entity, significant locations of operation of the
                    entity or the entity as a whole? (Yes/No)
                </h5>
                <div className="col-6">
                    <select className="form-select ml-1" value={consumerSatisfaction}
                        onChange={(e) => setConsumerSatisfaction(e.target.value)}>
                        <option value={''}>-Please select-</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(10)}>Back</Button>
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Submit</Button>
            </div>
        </Fragment>
    )
}

export default ResponsibleConsumerEngagement;