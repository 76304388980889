import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, CardBody, Badge, Label, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AgGridTable from '../AgGridTable';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { defaultUserType, thousandSeparator } from '../../api/helper';
import { Edit, Trash2 } from 'react-feather';
import UpdateOwnershipData from './UpdateOwnershipData';
import { toast } from 'react-toastify';
import { Plus } from "react-feather";
import ButtonLoader from '../ButtonLoader';

const OwnershipHistory = () => {
    const orgId = useParams().id;
    const orgName = useParams()?.name;
    const [data, setData] = useState([...Array(1)].map((_, index) => ({ index: index + 1 })));
    const historyRef = useRef(null);
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState(1);
    const [updateModalOpen, setModalOpen] = useState(false);
    const [updatableData, setUpdatableData] = useState();
    const [rowIndex, setRowIndex] = useState([]);
    const [loading, setLoading] = useState(false);

    const userType = useSelector(state => state.user.userType);

    const handleUpdateData = (params) => {
        setModalOpen(true);
        setUpdatableData(params)
    }

    const handleDelete = async (id, dataIndex) => {

        if (id) {
            historyRef.current.api.showLoadingOverlay();
            const options = {
                method: 'DELETE',
            }
            await fetchWrapper(`${API_URL}/ownership/${id}`, options)
                .then((res) => {
                    console.log(res.json)
                }, (err) => {
                    console.log(err)
                    if (err.status === 204) {
                        toast.success('Record deleted successfully!')
                        historyRef.current.api.hideOverlay();
                        onGridReady()
                    }
                })
        } else {
            const updatedRow = data?.map((item, index) => {
                return (((item?.index) === dataIndex) ? { index: index + 1 } : item)
            })
            setData(updatedRow)
        }
    }

    const handleOverlap = (date, type, index) => {
        let result = data?.filter((ele) => ele?.index !== index)?.some((item) => {
            const effectiveDate = new Date(item?.effectiveDate);
            const endDate = new Date(item?.endDate);
            if ((date >= effectiveDate && date <= endDate) && (type === item?.controlType)) {
                return true;
            } else {
                return false;
            }
        })
        return result;
    }

    const columnData = [
        {
            headerName: 'Control Type',
            field: 'controlType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Operational', 'Equity', 'Financial']
            },
            filter: false,
            valueGetter: (params) => {
                let endData = data?.filter((ele) => !ele?.endDate);
                if (endData?.length > 0) {
                    const foundData = endData?.find(ele => ((ele?.controlType === params.data?.controlType) && (ele?.id !== params.data?.id))) || {};
                    if (Object.keys(foundData)?.length > 0) {
                        delete params.data?.controlType;
                    } else {
                        return params.data?.controlType
                    }
                }
            },
            editable: true,
            flex: 1,
            sortable: false,
            suppressMovable: true,
            singleClickEdit: true,
            cellEditorPopup: false,
        },
        {
            headerName: 'Control Percentage',
            field: 'controlTypePercentage',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100,
                precision: 2
            },
            cellRenderer: (params) => {
                return <div className="text-right w-150px">{params?.data?.controlTypePercentage ?
                    thousandSeparator(params?.data?.controlTypePercentage.toFixed(2)) : ''}</div>;
            },
            flex: 1,
            editable: true,
            sortable: true,
            suppressMovable: true,
            singleClickEdit: true,
            cellEditorPopup: false,
        },
        {
            headerName: 'Effective Date',
            field: 'effectiveDate',
            flex: 1,
            editable: true,
            cellEditor: 'agDateCellEditor',
            cellEditorParams: {
                min: moment(new Date()).format('YYYY-MM-DD')
            },
            valueGetter: (params) => {
                if (params.data.effectiveDate) {
                    if (!handleOverlap(params.data.effectiveDate, params.data.controlType, params.data.index)) {
                        return moment(params.data?.effectiveDate).format('DD MMM, YYYY');
                    } else {
                        delete params.data.effectiveDate;
                        return
                    }

                }
            },

        },
        {
            headerName: 'End Date',
            field: 'endDate',
            flex: 1,
            cellEditor: 'agDateCellEditor',
            editable: true,
            cellEditorParams: (params) => {
                return {
                    min: params.data.effectiveDate ? moment(params.data.effectiveDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
                }
            },
            valueGetter: (params) => {
                if (params.data.endData && params.data.effectiveDate) {
                    return params.data.endDate;
                }
            },
            cellRenderer: (params) => {
                return (
                    (params.data.endDate && params.data.effectiveDate) ? <div>{moment(params.data?.endDate).format('DD MMM, YYYY')}</div> : (params.data.id && !params.data.endDate) ? <div className='active-bg d-flex'>Active</div> : ''
                )
            }
        },
        {
            headerName: 'Updated On',
            field: 'createdTs',
            filter: true,
            flex: 1,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            valueGetter: (params) => `${params.data.createdTs ? moment(params.data.createdTs).format('DD MMM, YYYY') : '-'}`,
            suppressMovable: true
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleDelete(params.data.id, params.data.index)} >
                        <Trash2 className={`trash-icon`} />
                    </div>
                )
            },
            suppressMovable: true,
        }
    ]

    const onGridReady = useCallback(async (params) => {
        historyRef.current.api.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/ownership/${orgId}`)
            .then((res) => {
                if (res?.json?.length > 0) {
                    setData(res.json?.map((item, index) => ({ ...item, index: index + 1 })))
                } else {
                    setData([...Array(1)].map((_, index) => ({ index: index + 1 })))
                }

                historyRef.current.api.hideOverlay();
            }, (err) => {
                console.log(err)
            })
    })

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/sub-organization-list`)
    }

    const getGridData = () => {
        console.log(data)
        const tempData = data?.filter(ele => Object.keys(ele)?.length > 1)?.map((item, index) => {
            if (item.controlType && item?.controlTypePercentage && item.effectiveDate) {
                let data = {
                    controlType: item?.controlType,
                    controlTypePercentage: item?.controlTypePercentage,
                    effectiveDate: moment(item.effectiveDate).format('YYYY-MM-DD'),
                }
                if (item?.endDate) {
                    data.endDate = moment(item.endDate).format('YYYY-MM-DD')
                }
                if (item?.id) {
                    data.id = item?.id
                }
                return data;
            } else {
                rowIndex.push((index + 1));
            }
        })
        return tempData
    }

    const onSubmit = async () => {
        const bodyData = getGridData()
        if (bodyData?.length > 1) {
            setLoading(true)
            if (rowIndex?.length > 0) {
                toast.error('Please fill control type, control percentage and effective date in each row')
                setRowIndex([])
                setLoading(false)
                return;
            } else {
                const options = {
                    method: 'POST',
                    body: {
                        childOrgId: orgId,
                        ownershipList: bodyData
                    }
                }
                await fetchWrapper(`${API_URL}/ownership`, options)
                    .then((res) => {
                        console.log(res.json)
                        if (res?.status === 200) {
                            toast.success('Ownership data saved successfully!')
                        }
                    }, (err) => {
                        setLoading(false)
                        if (err?.status === 200) {
                            toast.success('Ownership data saved successfully!')
                        }
                        if (err.status === 500) {
                            toast.error('Something went wrong')
                        }
                    })
            }
        }
    }

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs mainTitle={`${orgName} - Ownership Control`} />
            <Row>
                <Col lg={12}>
                    <Card className=''>
                        <CardBody className='ownershipHistory'>

                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                <AgGridTable
                                    columnData={columnData}
                                    tableData={data}
                                    agRef={historyRef}
                                    onGridReady={onGridReady}
                                    pagination={false}
                                />
                                <div className='custom-footer-area'>
                                    <button className="mtop-1 add-row-button" onClick={() => setData([...data, { index: data?.length + 1 }])}>
                                        <Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />
                                        Add</button>
                                </div>

                            </div>
                            <div className='mtop-1'>
                                <Button color='secondary' onClick={handleBack}>Back</Button>
                                {
                                    loading ? <ButtonLoader className={'ml-1'} loaderText={'Submitting'} color='primary' /> : <Button color='primary' className='ml-1' onClick={onSubmit}>Submit</Button>
                                }

                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {updateModalOpen && <UpdateOwnershipData value={updateModalOpen} setOpenModal={setModalOpen} data={updatableData} />}
        </Fragment >
    )
}

export default OwnershipHistory;
