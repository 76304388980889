import React, { Fragment, useEffect, useState, useRef } from "react";
import AgGridDatasheet from "./AgGridDatasheet";
import { H3 } from "../../../AbstractElements";
import { Plus, Trash2, XCircle } from "react-feather";
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from "react-toastify";
import { thousandSeparator } from "../../api/helper";
import { uuidGenerator } from "./ghgHelper";
import { useSelector } from "react-redux";
import moment from "moment";
import { alertFunction, notifyFunction, storeData, deleteCacheData, getCacheData } from "./ghgHelper";


let error = false, table2Error = false;
const UpstreamTransport = ({ setSteps, preData, setTransportData, setDistributeData,
    transportationData, distributionData, timeStamp, requestId, setRequestId }) => {
    const [data, setData] = useState([...Array(10)].map((_, index) => ({
        id: index + 1, isInsert: false,
        isUpdate: false, isDelete: false, isSubmit: false
    })));
    const [noOfRow, setNoOfRow] = useState(1);
    const [vehicleType, setVehicleType] = useState([]);
    const [transportType, setTransportType] = useState([]);
    const [vehicleData, setVehicleData] = useState([]);
    const [transportData, setTransportTypeData] = useState([]);
    const vehicleRef = useRef(null);
    const [rowIndex, setRowIndex] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const userProfile = useSelector(state => state?.user?.userDetails);
    const [cacheData, setCacheData] = useState();
    const [loading, setLoading] = useState(false)
    const keyObject = {
        customerId: userProfile?.org,
        userId: userProfile?.id,
        ...preData
    }

    const getVehicleTypeData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/UpstreamTransportInMiles/${preData?.countryCode}`)
            .then((res) => {
                const data = res.json;
                setVehicleData(data);
                setVehicleType(res.json?.map(item => item?.vehicleType))
            }, (err) => console.log(err))
    }

    const getTransportData = async () => {
        await fetchWrapper(`${API_URL}/emissionunits/UpstreamTransportInTons/${preData?.countryCode}`)
            .then((res) => {
                const data = res.json;
                setTransportTypeData(data);
                setTransportType(res.json?.map(item => item?.vehicleType))
            }, (err) => console.log(err))
    }

    const populateTable = (currentData) => {
        let count = currentData?.length < 10 ? (10 - currentData?.length) : 0;
        let tempData = [...currentData, ...[...Array(count)].map((_, index) => ({
            id: index + 1, isInsert: false,
            isUpdate: false, isDelete: false, isSubmit: false
        }))];
        setData(tempData)
    }

    const loadData = (savedData, cTimestamp) => {
        let transportData, distributeData;
        transportData = transportationData?.map(ele => ({ ...ele, type: 'Transportation' })) || []
        distributeData = distributionData?.map(({ shortTonMiles, ...rest }) =>
            ({ vehicleMiles: shortTonMiles, ...rest, type: 'Distribution' })) || [];
        const mergeData = [...transportData, ...distributeData];
        if (mergeData?.length > 0 && savedData?.length === 0) {
            populateTable(mergeData)
        } else if (mergeData?.length === 0 && savedData?.length > 0) {
            populateTable(savedData)
        } else if (mergeData?.length > 0 && savedData?.length > 0) {
            if (moment(cTimestamp).isAfter(moment(timeStamp))) { //cache is latest
                alertFunction(savedData, mergeData, populateTable)
            } else { //saved is latest
                notifyFunction(mergeData, populateTable)
            }
        }
    }

    const getCache = async () => {
        let cacheData = await getCacheData(keyObject);
        setCacheData(cacheData)
        let transportData, distributeData;
        transportData = cacheData?.upstreamVehicleMilesData?.map(ele => ({ ...ele, type: 'Transportation' })) || []
        distributeData = cacheData?.upstreamVehicleTonsData?.map(({ shortTonMiles, ...rest }) =>
            ({ vehicleMiles: shortTonMiles, ...rest, type: 'Distribution' })) || [];
        const mergeData = [...transportData, ...distributeData];
        loadData(mergeData, cacheData?.modifiedTs)
    }

    const getGrids = () => {
        if (transportationData?.length > 0 && distributionData?.length > 0) {
            loadData()
        }
    }

    useEffect(() => {
        getVehicleTypeData();
        getTransportData();
        getCache()
    }, [])

    const handleClear = async(params) => {
        const updatedRowData = data.map((row, index) => {
            return ((row.id === params.data.id) && row.isSubmit === true) ? { ...row, isDelete: true, isInsert: false, isUpdate: false }
                : ((row.id === params.data.id) && row.isSubmit === false) ?
                    { id: index + 1, isInsert: false, isUpdate: false, isDelete: false, isSubmit: false }
                    : row
        });
        let tempData = {
            ...cacheData,
            upstreamVehicleMilesData: updatedRowData?.filter(ele => ele?.type === 'Transportation'),
            upstreamVehicleTonsData: updatedRowData?.filter(ele => ele?.type === 'Distribution'),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await deleteCacheData(keyObject, tempData)
        setData(updatedRowData);

    };

    const handleUpload = async (e, params, nodeIndex) => {
        console.log(e.target.files[0])
        let dataId;
        if (typeof params?.id === 'string') {
            dataId = params.id;
        } else {
            let id = uuidGenerator();
            dataId = id;
            data[nodeIndex].id = id;
        }
        let tempData = new FormData();
        tempData.append('files', e.target.files[0]);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            },
            body: tempData
        }
        await fetchWrapper(`${API_URL}/document/uploadEvidences?category=UpstreamTransport&uploadPeriod=${preData?.uploadPeriod}
            &categoryDetailId=${dataId}&year=${preData?.year}&month=${preData?.month}&quarter=${preData?.quarter}
            &requestId=${requestId ? requestId : ''}&locationId=${preData?.locationId}`, options)
            .then((res) => {
                setLoading(false)
                let tempData = [...data];
                tempData[nodeIndex].documentId = res?.json?.documentId;
                tempData[nodeIndex].fileName = res?.json?.uploadFileName;
                setRequestId(res?.json?.requestId)
                tempData[nodeIndex].isInsert = true;
                tempData[nodeIndex].isUpdate = false;
                setData(tempData)
                createCache(data?.filter(ele => ele?.sourceId))
            })
            .catch((err) => {
                console.log(err)
                toast.error('Evidence upload failed!')
                setLoading(false)
            })
    }

    const columnData = [
        {
            headerName: 'Source ID',
            field: 'sourceId',
            editable: false,
            valueGetter: (params) => {
                if (params.data.hasOwnProperty('sourceDescription')
                    || params.data.hasOwnProperty('vehicleType')
                    || params.data.hasOwnProperty('vehicleMiles')
                    || params.data.hasOwnProperty('type')) {
                    params.data.sourceId = `UTD-${params.node.rowIndex + 1}`
                    return params.data.sourceId;
                }
            },
            filter: false,
            flex: 1,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Source Description',
            field: 'sourceDescription',
            editable: true,
            filter: false,
            flex: 3,
        },
        {
            headerName: 'Type',
            field: 'type',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Transportation', 'Distribution'],
            },
            filter: false,
            editable: true,
            flex: 1,
        },
        {
            headerName: 'Vehicle Type',
            field: 'vehicleType',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                if (params.data?.type === 'Transportation') {
                    return { values: vehicleType }
                } else if (params.data?.type === 'Distribution') {
                    return { values: transportType }
                }
            },
            valueGetter: (params) => {
                if(params.data.vehicleType) { 
                    const tempArray = params.data.type === 'Personal' ? vehicleType : transportType;
                    params.data.resourceType = tempArray?.find(ele => ele?.vehicleType === params.data.vehicleType)?.resourceType;
                    return params.data.vehicleType;
                }
            },
            filter: false,
            editable: true,
            flex: 2.5,
        },
        {
            headerName: 'Distance Travelled',
            field: 'vehicleMiles',
            editable: true,
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 4
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.vehicleMiles ?
                    thousandSeparator(params?.data?.vehicleMiles?.toFixed(4)) : ''}</div>;
            },
            flex: 1,
        },
        {
            headerName: 'Units',
            field: 'units',
            filter: false,
            editable: true,
            cellEditor: 'agSelectCellEditor',
            autoHeight: true,
            wrapText: true,
            valueGetter: (params) => {
                if (params.data.type === 'Transportation') {
                    if (params.data?.vehicleType) {
                        const unitsData = vehicleData?.find(ele => ele?.vehicleType === params.data.vehicleType)
                        params.data.units = unitsData?.units
                        return params.data.units;
                    }
                }
                if (params.data.type === 'Distribution') {
                    if (params.data?.vehicleType) {
                        const unitsData = transportData?.find(ele => ele?.vehicleType === params.data.vehicleType)
                        params.data.units = unitsData?.units
                        return params.data.units;
                    }
                }
            },
            flex: 1,
            sortable: false,
        },
        {
            headerName: 'Evidence',
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            cellRenderer: (params) => {
                if(!params.data.fileName) {
                    return (
                        <div>
                            <label className="upload-btn">
                                <input
                                    type="file" hidden accept=".jpeg, .jpg, .png, .pdf"
                                    onChange={(event) => handleUpload(event, params?.data, params?.node?.rowIndex)}
                                />
                                Upload
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <h5 className="mb-0">{params.data.fileName}</h5>
                        </div>
                    )
                }
            },
            suppressMovable: true,
            cellClass: 'tick-center'
        },
        {
            headerName: 'Clear',
            width: 70,
            cellRenderer: (params) => {
                return (
                    params.data?.sourceId ?
                        <div>
                            <Trash2 onClick={() => handleClear(params, 1)}
                                className={'trash-icon mtop-0'} />
                        </div> : <></>
                )
            },
            suppressMovable: true,
            cellClass: 'icon-center'
        },
    ]


    const addingRows = (val) => {
        setNoOfRow(val);
    }

    const addRows = () => {
        const rowData = [];
        for (let i = 1; i <= noOfRow; i++) {
            rowData.push({
                id: data?.length + i, isInsert: false,
                isUpdate: false, isDelete: false, isSubmit: false
            })
        }
        setData([...data, ...rowData]);
    }

    const createCache = async (data, type) => {
        let tempData = {
            ...cacheData,
            upstreamVehicleMilesData: data?.filter(ele => ele?.type === 'Transportation'),
            upstreamVehicleTonsData: data?.filter(ele => ele?.type === 'Distribution'),
            modifiedTs: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSS')
        }
        await storeData(keyObject, tempData)
    }

    const onCellValueChanged = (event) => {
        if (event.value) {
            if (event.data.isSubmit === true && (event.data.isInsert === false || event.data.isUpdate === true)) {
                event.data.isUpdate = true;
                event.data.isInsert = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false && typeof event.data.id !== 'string') {
                event.data.id = uuidGenerator();
                event.data.isInsert = true;
                event.data.isUpdate = false;
            } else if(event.data.isSubmit === false && event.data.isInsert === false) {
                event.data.isInsert = true;
                event.data.isUpdate = false;
            }
        }
        createCache(data)
    }

    const getGridData = () => {
        if (vehicleRef.current) {
            const transportData = data
                .filter(rowNode => rowNode.sourceId && (rowNode.type === 'Transportation'))
                .map((rowNode, index) => {
                    if (rowNode.vehicleType && rowNode.units
                        && rowNode.vehicleMiles && rowNode.sourceDescription) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            vehicleType: rowNode.vehicleType,
                            resourceType: rowNode.resourceType,
                            vehicleMiles: parseFloat(rowNode.vehicleMiles),
                            units: rowNode.units,
                            documentId: rowNode.documentId ? rowNode.documentId : null,
                            fileName: rowNode.fileName ? rowNode.fileName : null,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(rowNode?.sourceId.split('-')[1]);
                        return {}
                    }
                });

            const distributeData = data
                .filter(rowNode => rowNode.sourceId
                    && (rowNode.type === 'Distribution'))
                .map((rowNode, index) => {
                    if (rowNode.vehicleType && rowNode.sourceDescription
                        && rowNode.vehicleMiles && rowNode.units) {
                        let data = {
                            id: rowNode.id,
                            sourceId: rowNode.sourceId,
                            sourceDescription: rowNode.sourceDescription,
                            vehicleType: rowNode.vehicleType,
                            resourceType: rowNode.resourceType,
                            shortTonMiles: parseFloat(rowNode.vehicleMiles),
                            units: rowNode.units,
                            documentId: rowNode.documentId ? rowNode.documentId : null,
                            fileName: rowNode.fileName ? rowNode.fileName : null,
                            isInsert: rowNode.isInsert,
                            isUpdate: rowNode.isUpdate,
                            isDelete: rowNode.isDelete,
                            isSubmit: rowNode.isSubmit
                        }
                        return data;
                    } else {
                        rowIndex.push(rowNode?.sourceId.split('-')[1]);
                        return {}
                    }
                });
            if (transportData?.some(ele => Object.keys(ele)?.length === 0)
                || distributeData?.some(ele => Object.keys(ele)?.length === 0)) {
                error = true;
            } else {
                error = false
            }
            return {
                upstreamVehicleMilesData: transportData.filter(ele => Object.keys(ele)?.length > 0),
                upstreamVehicleTonsData: distributeData.filter(ele => Object.keys(ele)?.length > 0)
            };
        }
        return [];

    }

    const onSubmit = async () => {
        const gridObject = getGridData();
        if (error === true) {
            toast.error(`Please fill missing values in row ${[...new Set(rowIndex)].join(',')}`)
            setRowIndex([])
            error = false;
            return
        } else {
            setTransportData(gridObject?.upstreamVehicleMilesData);
            setDistributeData(gridObject?.upstreamVehicleTonsData);
            setSteps(0)
        }
    }

    return (
        <Fragment>
            <div className="d-flex">
                <H3 attrH3={{ className: 'f-s-15' }}>Upstream Transportation and Distribution</H3>
                <button className="manage-asm-but border-1px-solid"
                    onClick={() => onSubmit()}>Back to Summary</button>
            </div>
            {deleteLoading ? <div className="loading-overlay"><i class="fa-solid fa-spinner fa-spin mr-1"></i> Loading....</div> : <></>}
            <div style={{ opacity: deleteLoading ? '0.2' : '1' }}>
                <div className="mtop-1">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={data?.filter(ele => ele?.isDelete === false)}
                            columnData={columnData}
                            agRef={vehicleRef}
                            onCellValueChanged={(e) => onCellValueChanged(e)}
                        />
                        <div className="ag-custom-footer">
                            <div className="d-flex ">
                                <button className="add-row-button" onClick={() => addRows()}><Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />  Add</button>
                                <input
                                    type="number"
                                    className="ml-1 input-number"
                                    value={noOfRow}
                                    onChange={(e) => addingRows(e.target.value)}
                                />
                                <h5 className="ml-1">rows</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default UpstreamTransport;