import React, { Fragment, useCallback, useState, useMemo, useEffect } from 'react';
import { Btn, H4, H3 } from '../../../AbstractElements';
import { Spinner } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';

const AgGridTable = ({ columnData, tableData, agRef, onGridReady, rowHeight, onCellValueChanged, paginationSize, pagination, getRowHeight }) => {
    console.log(pagination)
    return (
        <Fragment>
            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                <AgGridReact
                    columnDefs={columnData}
                    rowData={tableData}
                    domLayout='autoHeight'
                    animateRows={true}
                    rowModelType='clientSide'
                    ref={agRef}
                    enableBrowserTooltips={true}
                    getRowHeight={getRowHeight}
                    overlayLoadingTemplate={
                        // '<span class="ag-overlay-loading-center">Please wait while your data are loading...</span>'
                        `<div class="custom-loading-overlay">
                        <i class="fa fa-spinner fa-spin"></i> <span class='f-15'>Please wait while your data is loading...</span>
                      </div>`
                    }
                    overlayNoRowsTemplate={
                        '<span style="padding: 10px; border: 2px solid #444; border-radius: 5px; background: #0038680f">No Data Found</span>'
                    }
                    onGridReady={onGridReady}
                    pagination={pagination === false ? pagination : true}
                    paginationPageSize={paginationSize ? paginationSize : 10}
                    enterNavigatesVerticallyAfterEdit={true}
                    stopEditingWhenCellsLoseFocus={true}
                    paginationPageSizeSelector={[5, 8, 10, 15, 20]}
                    // paginationPageSizeOptions={paginationPageSizeOptions}
                    singleClickEdit={true}
                    rowHeight={rowHeight}
                    onCellValueChanged={onCellValueChanged}
                    // suppressMenuHide={true}
                />
            </div>

        </Fragment>
    )
}

export default AgGridTable;