import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "../../Data/CountryList/Defaultdata";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";
import { defaultUserType } from "../../api/helper";

const GroupForm = ({ title, action, navigateUrl, cancel, preData, asoOrgId, setAdd }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(action === 'Edit' ? preData : '')
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({ defaultValues: data });
    const [description, setDescription] = useState(preData?.description);
    const navigate = useNavigate();

    const userType = sessionStorage.getItem('userType');

    const onSubmit = async (data) => {
        setLoading(true)
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/group`
            message = 'Group Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/group/${data.id}`
            message = 'Groups Updated Successfully!'
        }
        const options = {
            method: method,
            body: {
                name: data.name,
                description: description,
                groupCode: data.groupCode,
                assetOwnerId: asoOrgId
            }
        }
        await fetchWrapper(url, options)
            .then((res) => {
                if (res.ok) {
                    action === 'Add' && setAdd(true)
                    setLoading(false)
                    action === 'Add' && cancel()
                    action === 'Edit' && navigate(navigateUrl)
                    toast.success(message)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.status === 409) {
                    toast.error('Group name alredy Exist!')
                } else {
                    toast.error('Something went wrong')
                }
            })
    }

    
    useEffect(() => {
        // loadAsoOrgList()
        setData(prev => ({ ...prev, ...preData }))
        setDescription(preData?.description)
        reset(preData)
    }, [preData])

   
    return (
        <Fragment>
            <Row>
                <Col lg={action === 'Add' ? '12' : '8'}>
                    {title && <H3 attrH3={{ className: 'mtop-2' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'}`}>
                        <CardBody className={action === 'Add' && 'p-0 bs-none'}>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Row>
                                    <Col lg="6">
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup >
                                                <Label>Group Name <span className='required'>*</span> </Label>
                                                <input
                                                    className="form-control"
                                                    name="name"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.name || ''}
                                                    {...register('name', { required: true })}
                                                />
                                                <span className="text-danger">{errors.name && 'Group Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div style={{ marginBottom: "35px" }}>
                                            <FormGroup >
                                                <Label>Group Code</Label>
                                                <input
                                                    className="form-control"
                                                    name="groupCode"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.groupCode || ''}
                                                    {...register('groupCode')}
                                                />
                                                <span className="text-danger">{errors.groupCode && 'Group Code is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} >
                                        <FormGroup>
                                            <Label >Description</Label>
                                            <textarea rows={4} cols={25} className="form-control" name='description' placeholder='Description..'
                                                value={description} onChange={(e) => setDescription(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="d-flex " style={{ marginTop: "25px" }}>
                                        {
                                            loading ? <ButtonLoader loaderText={`${action === "Add" ? action : 'Updat'}ing`} color="primary" /> : <Button color="primary" >{action === "Add" ? action : 'Update'}</Button>
                                        }
                                        
                                        <Button color="secondary" className="ml-1" onClick={() => action === 'Add' ? cancel() : navigate(navigateUrl)}> Cancel</Button>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default GroupForm;