import React, { Fragment, useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast, ToastContainer } from "react-toastify";
import { Col, Container, Form, FormGroup, Row, Label, Button } from "reactstrap";
import { H3, Image } from "../../AbstractElements";
import { API_URL } from "../Config/Config";
import fetchWrapper from "../api/fetchWrapper";
import ButtonLoader from '../Component/ButtonLoader';
import ResetConfirmationModal from "./ResetConfirmationModal";

const ResetPassword = () => {
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();
    const [toggleCurrentPassword, setToggleCurrentPassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [confirmationStatus, setConfirmStatus] = useState(false)
    const [open, setOpen] = useState(false);
    const [credentialData, setCreditaial] = useState();

   
    const onSubmit = async (data) => {
        const resetData = {
            userName: window.location.pathname.split('/')[2],
            oldPassword: data.currentPassword,
            newPassword: data.confirmPassword
        }
        setCreditaial(resetData);
        setOpen(true)
       
    }



    return (
        <Fragment>
            <ToastContainer />
            {open && <ResetConfirmationModal value={open} setOpenModal={setOpen} setConfirmStatus={setConfirmStatus} data={credentialData}/>}
            <section>
                <Container fluid={true} className="p-0" >
                    <div className="login-card">
                        <Row>
                            <Col xl='12'>
                                <div className="login-main">
                                    <div className='logo'>
                                        <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../assets/images/logo/airoi.png')}` }} />
                                    </div>
                                    <H3>
                                        Reset Password
                                    </H3>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col xl='12'>
                                                <FormGroup>
                                                    <Label>Current Password <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="currentPassword"
                                                        type={toggleCurrentPassword ? 'text' : 'password'}
                                                        {...register('currentPassword', {
                                                            required: true
                                                        })}
                                                    />
                                                    <div className="show-hide reset-password" onClick={() => setToggleCurrentPassword(!toggleCurrentPassword)}>
                                                        <span>{toggleCurrentPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                                    </div>
                                                    <span className="text-danger">{errors.currentPassword && errors.currentPassword.type === 'required' && 'Enter Current Password'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl='12'>
                                                <FormGroup>
                                                    <Label>New Password <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="newPassword"
                                                        onCopy={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                        type={toggleNewPassword ? 'text' : 'password'}
                                                        {...register('newPassword', { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g })}
                                                    />
                                                    <div className="show-hide reset-password" onClick={() => setToggleNewPassword(!toggleNewPassword)}>
                                                        <span>{toggleNewPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                                    </div>
                                                    <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'required' && 'Enter New Password'}</span>
                                                    <span className="text-danger">{errors.newPassword && errors.newPassword.type === 'pattern' && 'Your password contains 8 characters, One Upper case, One Special characters, One Numeric Characters'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl='12'>
                                                <FormGroup>
                                                    <Label>Confirm New Password <span className='required'>*</span></Label>
                                                    <input
                                                        className="form-control mb-1"
                                                        name="confirmPassword"
                                                        onCopy={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                        type={toggleConfirmPassword ? 'text' : 'password'}
                                                        {...register('confirmPassword', { required: true, validate: (value) => getValues('newPassword') === value })}
                                                    />
                                                    <div className="show-hide reset-password" onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
                                                        <span>{toggleConfirmPassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                                    </div>
                                                    <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'required' && 'Enter Confirm Password'}</span>
                                                    <span className="text-danger">{errors.confirmPassword && errors.confirmPassword.type === 'validate' && 'New password and Confirm password must be same'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="d-flex " style={{ marginTop: "25px" }}>
                                                {/* {
                                                    loading ? <ButtonLoader loaderText={'Submitting'} color='primary' /> : <Button color="primary" >Submit</Button>
                                                } */}
                                                <Button color="primary" >Submit</Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>


        </Fragment>
    )
}

export default ResetPassword