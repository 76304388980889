import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { H3 } from '../../../AbstractElements';
import TableView from "./TableView";
import {
    scColumns, msColumns, rfgSimplifiedColumns, rfgMaterialColumns,
    rfgScreeningColumns, fsMaterialColumns, fsSimplifiedColumns, fsScreeningColumns,
    purchasedGasesColumns, electricityColumns, steamColumns,
    businessTravelPersonalColumns,
    businessTravelPublicColumns,
    businessTravelAirwaysColumns,
    commutingPersonalColumns,
    commutingPublicColumns,
    transportColumns,
    distributeColumns,
    indirectWasteColumns,
    deleteCache,
    directWasteColumns,
    waterConsumptionColumns,
    waterDischargeColumns
} from "./ghgHelper";
import { X } from "react-feather";
import { toast } from "react-toastify";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const TablePreview = ({ value, setOpenModal, tableData, status, keyObject, setSubmitStatus }) => {
    const [open, setOpen] = useState(value);
    const [missedCategories, setCategories] = useState([]);
    const [scopeList, setScopeList] = useState([]);
    const [incompleteCategory, setIncompleteCategory] = useState([]);

    const getScopeCategories = async () => {
        await fetchWrapper(`${API_URL}/scopecategories/organization`)
            .then((res) => {
                let list = res?.json?.map(ele => ele?.categoryName);
                let scopeList = res?.json?.map(ele => ele?.scope);
                setScopeList(list)
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getScopeCategories()
    }, [])

    const onClose = () => {
        setCategories();

        setOpenModal(false);
        setOpen(false);
    }

    const isValidData = () => {
        if (tableData?.stationaryCombustionData?.length > 0
            && !tableData?.stationaryCombustionData?.every(ele => (ele.sourceId && ele.sourceArea && ele.fuelCombusted
                && ele.quantityCombusted && ele.sourceDescription))) {
            incompleteCategory.push('Stationary Combustion')
            return false
        } else if (tableData?.mobilesourcesData?.length > 0
            && (!tableData?.mobilesourcesData?.every(ele => (ele.roadType && ele.vehicleTypeDescription && ele.fuelUsage
                && ele.distanceTraveled && ele.sourceDescription)) ||
                !tableData?.mobilesourcesData?.some(ele => !(ele.yearsArray && (ele.yearsArray?.length > 1 && ele.vehicleYear) ||
                    (ele.yearsArray?.length === 1 && (!ele.vehicleYear || ele.vehicleYear === 'N/A')))) ||
                tableData?.mobilesourcesData?.some(ele => ((ele?.vehicleTypeDescription?.includes('Biodiesel') && !ele?.biodieselPercentage)
                    || (ele?.vehicleTypeDescription?.includes('Ethanol') && !ele?.ethanolPercentage))))) {
            incompleteCategory.push('Mobile sources')
            return false
        } else if ((tableData?.rfgMaterialData?.length > 0
            && !tableData?.rfgMaterialData?.every(ele => (ele.inventoryChange
                && ele.transferredAmount && ele.units
                && ele.capacityChange && ele.gas))) ||
            (tableData?.rfgSimplifiedMaterialData?.length > 0 &&
                !tableData?.rfgSimplifiedMaterialData?.every(ele => (ele.newCharge &&
                    ele.newCapacity && ele.unitsRecharge && ele.units
                    && ele.disposedCapacity && ele.disposedRecovered && ele.gas))) ||
            (tableData?.rfgScreeningData?.length > 0 &&
                !tableData?.rfgScreeningData?.every(ele => (ele.sourceId && ele.newUnitsCharge && ele.typeOfEquipment && ele.sourceId
                    && ele.capacityOperatingUnits && ele.units && ele.sourceId && ele.capacityDisposedUnits)))) {
            incompleteCategory.push('Refrigeration / AC Equipment Use')
            return false
        } else if ((tableData?.fsMaterialData?.length > 0
            && !tableData?.fsMaterialData?.every(ele => (ele.gas && ele.inventoryChange
                && ele.transferredAmount && ele.units
                && ele.capacityChange))) ||
            (tableData?.fsSimplifiedMaterialData?.length > 0 &&
                !tableData?.fsSimplifiedMaterialData?.every(ele => (ele.gas && ele.unitsCharge &&
                    ele.unitsCapacity && ele.unitsRecharge && ele.units
                    && ele.disposedCapacity && ele.disposedRecovered))) ||
            (tableData?.fsScreeningData?.length > 0 &&
                !tableData?.fsScreeningData?.every(ele => (ele.sourceId && ele.typeOfEquipment && ele.gasType && ele.units
                    && ele.unitCapacity)))) {
            incompleteCategory.push('Fire Suppression')
            return false
        } else if (tableData?.purchasedGasesData?.length > 0 &&
            !tableData?.purchasedGasesData?.every(ele => (ele.sourceId && ele.gas && ele.purchasedAmount && ele.units))) {
            incompleteCategory.push('Purchased Gases')
            return false
        } else if (tableData?.energyData?.length > 0 && !tableData?.energyData?.every(ele => (ele.sourceId &&
            ele.sourceArea && ele.electricitySource &&
            ele.electricityPurchased && ele.sourceDescription))) {
            incompleteCategory.push('Purchased and Consumed Electricity')
            return false
        } else if (tableData?.steamEnergyData?.length > 0 && !tableData?.steamEnergyData?.every(ele => (ele.sourceId && ele.sourceArea
            && ele.fuelType && ele.sourceDescription && ele.fuelState
            && (ele.boilerEfficiency > -1) && ele.steamPurchased))) {
            incompleteCategory.push('Purchased and Consumed Steam')
            return false
        } else if ((tableData?.businessTravelPersonalData?.length > 0
            && !tableData?.businessTravelPersonalData?.every(ele => (ele.sourceId
                && ele.vehicleType && ele.units
                && ele.vehicleMiles && ele.sourceDescription))) ||
            (tableData?.businessTravelAirwaysData?.length > 0 &&
                !tableData?.businessTravelAirwaysData?.every(ele => (ele.sourceId && ele.flightLength &&
                    ele.sourceDescription && ele.units
                    && ele.passengerMiles))) ||
            (tableData?.businessTravelPublicData?.length > 0 &&
                !tableData?.businessTravelPublicData?.every(ele => (ele.sourceId && ele.vehicleType
                    && ele.sourceDescription && ele.units
                    && !(ele.passengerMiles && ele.vehicleMiles))))) {
            incompleteCategory.push('Employee Business Travel')
            return false
        } else if ((tableData?.travelCommutePersonalData?.length > 0
            && !tableData?.travelCommutePersonalData?.every(ele => (ele.sourceId
                && ele.vehicleType && ele.units
                && ele.vehicleMiles && ele.sourceDescription))) ||
            (tableData?.travelCommutePublicData?.length > 0 &&
                !tableData?.travelCommutePublicData?.every(ele => (ele.sourceId
                    && ele.transportType && ele.units
                    && ele.passengerMiles && ele.sourceDescription)))) {
            incompleteCategory.push('Employee Commuting')
            return false
        } else if ((tableData?.upstreamVehicleMilesData?.length > 0
            && !tableData?.upstreamVehicleMilesData?.every(ele => (ele.sourceId
                && ele.vehicleType && ele.units
                && ele.vehicleMiles && ele.sourceDescription))) ||
            (tableData?.upstreamVehicleTonsData?.length > 0 &&
                !tableData?.upstreamVehicleTonsData?.every(ele => (ele.sourceId
                    && ele.vehicleType && ele.units
                    && (ele.shortTonMiles || ele.vehicleMiles) && ele.sourceDescription)))) {
            incompleteCategory.push('Upstream Transportation and Distribution')
            return false
        } else if ((tableData?.indirectWasteData?.length > 0 &&
            !tableData?.indirectWasteData?.every(ele => (ele.sourceId && ele.sourceDescription
                && ele.wasteMaterial
                && ele.disposalMethod && ele.wasteClassification
                && ele.disposalLocation && ele.wasteType
                && ele.weight && ele.units))) ||
            (tableData?.directWasteData?.length > 0 && !tableData?.directWasteData?.every(ele => (ele.sourceId && ele.sourceDescription
                && ele.wasteMaterial && ele.disposalType
                && ele.disposalMethod && ele.wasteClassification
                && ele.disposalLocation && ele.wasteType
                && ele.weight && ele.units
            )))) {
            incompleteCategory.push('Waste')
            return false
        } else if ((tableData?.waterConsumptionData?.length > 0 &&
            !tableData?.waterConsumptionData?.every(ele => (ele.sourceId && ele.categoryType
                && ele.waterSource
                && ele.waterConsumption && ele.energyUsed
                && ele.units))) ||
            (tableData?.waterDischargeData?.length > 0 && !tableData?.waterDischargeData?.every(ele => (ele.sourceId && ele.wasteWaterType
                && ele.waterVolume && ele.units
            )))) {
            incompleteCategory.push('Water')
            return false
        } else {
            return true
        }
    }

    const handleSubmit = async () => {
        if (!isValidData()) {
            toast.error(`Incomplete data detected in '${incompleteCategory[0]}'. Please review and update.`)
            onClose()
            return
        } else {
            if (tableData?.stationaryCombustionData?.length === 0 && scopeList?.some(ele => ele === 'Stationary Combustion')) {
                missedCategories?.push('Stationary Combustion');
            }
            if (tableData?.mobilesourcesData?.length === 0 && scopeList?.some(ele => ele === 'Mobile Sources')) {
                missedCategories?.push('Mobile Sources');
            }
            if ((tableData?.rfgMaterialData?.length === 0 && tableData?.rfgSimplifiedMaterialData?.length === 0
                && tableData?.rfgScreeningData?.length === 0) && scopeList?.some(ele => ele === 'Refrigeration / AC Equipment Use')) {
                missedCategories?.push('Refrigeration / AC Equipment Use');
            }
            if ((tableData?.fsMaterialData?.length === 0 && tableData?.fsSimplifiedMaterialData?.length === 0
                && tableData?.fsScreeningData?.length === 0) && scopeList?.some(ele => ele === 'Fire Suppression')) {
                missedCategories?.push('Fire Suppression');
            }
            if (tableData?.purchasedGasesData?.length === 0 && scopeList?.some(ele => ele === 'Purchased Gases')) {
                missedCategories?.push('Purchased Gases');
            }
            if (tableData?.energyData?.length === 0 && scopeList?.some(ele => ele === 'Purchased and Consumed Electricity')) {
                missedCategories?.push('Purchased and Consumed Electricity');
            }
            if (tableData?.steamEnergyData?.length === 0 && scopeList?.some(ele => ele === 'Purchased and Consumed Steam')) {
                missedCategories?.push('Purchased and Consumed Steam');
            }
            if ((tableData?.businessTravelPersonalData?.length === 0 && tableData?.businessTravelAirwaysData?.length === 0
                && tableData?.businessTravelPublicData?.length === 0) && scopeList?.some(ele => ele === 'Employee Business Travel')) {
                missedCategories?.push('Employee Business Travel');
            }
            if ((tableData?.travelCommutePersonalData?.length === 0 &&
                tableData?.travelCommutePublicData?.length === 0) && scopeList?.some(ele => ele === 'Employee Commuting')) {
                missedCategories?.push('Employee Commuting');
            }
            if ((tableData?.upstreamVehicleMilesData?.length === 0 && tableData?.upstreamVehicleTonsData?.length === 0) && scopeList?.some(ele => ele === 'Upstream Transportation and Distribution')) {
                missedCategories?.push('Upstream Transportation and Distribution');
            }
            if ((tableData?.indirectWasteData?.length === 0 && tableData?.directWasteData?.length === 0) && scopeList?.some(ele => ele === 'Waste')) {
                missedCategories?.push('Waste');
            }
            if ((tableData?.waterConsumptionData?.length === 0 && tableData?.waterDischargeData?.length === 0) && scopeList?.some(ele => ele === 'Water')) {
                missedCategories?.push('Water')
            }
            if (missedCategories?.length === 0) {
                const options = {
                    method: 'POST',
                    body: tableData
                }
                await fetchWrapper(`${API_URL}/ghgemissionsdata/submit`, options)
                    .then((res) => {
                        console.log(res.json)
                        
                    }, async (err) => {
                        console.log(err)
                        if (err.status === 200) {
                            setSubmitStatus(true)
                            toast.success('Data submitted successfully!')
                            await deleteCache(keyObject)
                            onClose()
                        }
                    })
            } else {
                Swal.fire({
                    title: 'Warning',
                    text: `Data is missing for the following categories: ${JSON.stringify(missedCategories)}. 
                    You may proceed without this data, but it could affect the accuracy of the results. Would you like to continue?`,
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const options = {
                            method: 'POST',
                            body: tableData
                        }
                        await fetchWrapper(`${API_URL}/ghgemissionsdata/submit`, options)
                            .then((res) => {
                                console.log(res.json)
                            }, async (err) => {
                                console.log(err)
                                if (err.status === 200) {
                                    setSubmitStatus(true)
                                    toast.success('Data submitted successfully!')
                                    await deleteCache(keyObject)
                                    onClose()
                                }
                            })
                    }
                    if (result.isDismissed) {
                        setCategories()
                        onClose()
                    }
                })

            }
        }

    }

    return (
        <Fragment>
            <Modal isOpen={open} size="xl" centered>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Data Preview</H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    {
                        tableData?.stationaryCombustionData?.length > 0 && <div>
                            <TableView columns={scColumns} data={tableData?.stationaryCombustionData?.filter(ele => ele?.isDelete === false)} title={'Stationary Combustion'} />
                        </div>
                    }
                    {
                        tableData?.mobilesourcesData?.length > 0 && <div className="mtop-1">
                            <TableView columns={msColumns} data={tableData?.mobilesourcesData?.filter(ele => ele?.isDelete === false)}
                                title={'Mobile Sources'} />
                        </div>
                    }
                    {
                        tableData?.rfgMaterialData?.length > 0 && <div className="mtop-1">
                            <TableView columns={rfgMaterialColumns} data={tableData?.rfgMaterialData?.filter(ele => ele?.isDelete === false)}
                                title={'Refrigeration / AC Equipment Use (Material)'} />
                        </div>
                    }
                    {
                        tableData?.rfgSimplifiedMaterialData?.length > 0 && <div className="mtop-1">
                            <TableView columns={rfgSimplifiedColumns} data={tableData?.rfgSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)}
                                title={'Refrigeration / AC Equipment Use (Simplified Material)'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.rfgScreeningData?.length > 0 && <div className="mtop-1">
                            <TableView columns={rfgScreeningColumns} data={tableData?.rfgScreeningData?.filter(ele => ele?.isDelete === false)}
                                title={'Refrigeration / AC Equipment Use (Screening Method)'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.fsMaterialData?.length > 0 && <div className="mtop-1">
                            <TableView columns={fsMaterialColumns} data={tableData?.fsMaterialData?.filter(ele => ele?.isDelete === false)}
                                title={'Fire Suppression (Material)'} />
                        </div>
                    }
                    {
                        tableData?.fsSimplifiedMaterialData?.length > 0 && <div className="mtop-1">
                            <TableView columns={fsSimplifiedColumns} data={tableData?.fsSimplifiedMaterialData?.filter(ele => ele?.isDelete === false)}
                                title={'Fire Suppression (Simplified Material)'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.fsScreeningData?.length > 0 && <div className="mtop-1">
                            <TableView columns={fsScreeningColumns} data={tableData?.fsScreeningData?.filter(ele => ele?.isDelete === false)}
                                title={'Fire Suppression (Screening Method)'} />
                        </div>
                    }
                    {
                        tableData?.purchasedGasesData?.length > 0 && <div className="mtop-1">
                            <TableView columns={purchasedGasesColumns} data={tableData?.purchasedGasesData?.filter(ele => ele?.isDelete === false)}
                                title={'Purchased Gases'} />
                        </div>
                    }
                    {
                        tableData?.energyData?.length > 0 && <div className="mtop-1">
                            <TableView columns={electricityColumns} data={tableData?.energyData?.filter(ele => ele?.isDelete === false)}
                                title={'Electricity'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.steamEnergyData?.length > 0 && <div className="mtop-1">
                            <TableView columns={steamColumns} data={tableData?.steamEnergyData?.filter(ele => ele?.isDelete === false)}
                                title={'Steam'} spanTable={true} />
                        </div>
                    }
                    {
                        tableData?.businessTravelPersonalData?.length > 0 && <div className="mtop-1">
                            <TableView columns={businessTravelPersonalColumns} data={tableData?.businessTravelPersonalData?.filter(ele => ele?.isDelete === false)}
                                title={'Business Travel(Personal)'} />
                        </div>
                    }
                    {
                        tableData?.businessTravelPublicData?.length > 0 && <div className="mtop-1">
                            <TableView columns={businessTravelPublicColumns} data={tableData?.businessTravelPublicData?.filter(ele => ele?.isDelete === false)}
                                title={'Business Travel(Public)'} />
                        </div>
                    }
                    {
                        tableData?.businessTravelAirwaysData?.length > 0 && <div className="mtop-1">
                            <TableView columns={businessTravelAirwaysColumns} data={tableData?.businessTravelAirwaysData?.filter(ele => ele?.isDelete === false)}
                                title={'Business Travel(Airways)'} />
                        </div>
                    }
                    {
                        tableData?.travelCommutePersonalData?.length > 0 && <div className="mtop-1">
                            <TableView columns={commutingPersonalColumns} data={tableData?.travelCommutePersonalData?.filter(ele => ele?.isDelete === false)}
                                title={'Employee Commuting(Personal)'} />
                        </div>
                    }
                    {
                        tableData?.travelCommutePublicData?.length > 0 && <div className="mtop-1">
                            <TableView columns={commutingPublicColumns} data={tableData?.travelCommutePublicData?.filter(ele => ele?.isDelete === false)}
                                title={'Employee Commuting(Public)'} />
                        </div>
                    }
                    {
                        tableData?.upstreamVehicleMilesData?.length > 0 && <div className="mtop-1">
                            <TableView columns={transportColumns} data={tableData?.upstreamVehicleMilesData?.filter(ele => ele?.isDelete === false)}
                                title={'Upstream Transportation'} />
                        </div>
                    }
                    {
                        tableData?.upstreamVehicleTonsData?.length > 0 && <div className="mtop-1">
                            <TableView columns={distributeColumns} data={tableData?.upstreamVehicleTonsData?.filter(ele => ele?.isDelete === false)}
                                title={'Upstream Distribution'} />
                        </div>
                    }
                    {
                        tableData?.indirectWasteData?.length > 0 && <div className="mtop-1">
                            <TableView columns={indirectWasteColumns} data={tableData?.indirectWasteData?.filter(ele => ele?.isDelete === false)}
                                title={'Indirect Waste'} />
                        </div>
                    }
                    {
                        tableData?.directWasteData?.length > 0 && <div className="mtop-1">
                            <TableView columns={directWasteColumns} data={tableData?.directWasteData?.filter(ele => ele?.isDelete === false)}
                                title={'Direct Waste'} />
                        </div>
                    }
                    {
                        tableData?.waterConsumptionData?.length > 0 && <div className="mtop-1">
                            <TableView columns={waterConsumptionColumns} data={tableData?.waterConsumptionData?.filter(ele => ele?.isDelete === false)}
                                title={'Water Consumption'} />
                        </div>
                    }
                    {
                        tableData?.waterDischargeData?.length > 0 && <div className="mtop-1">
                            <TableView columns={waterDischargeColumns} data={tableData?.waterDischargeData?.filter(ele => ele?.isDelete === false)}
                                title={'Water Discharge'} />
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex">
                        <Button color="secondary" onClick={onClose}>Back</Button>
                        <Button color="primary"
                            disabled={!['NO_DATA', 'PROCESSING_COMPLETE', 'SAVE_AS_DRAFT'].includes(status)}
                            className="ml-1" onClick={handleSubmit}>Submit</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default TablePreview;