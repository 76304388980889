import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Card, Row, Col, FormGroup, Label, Button, Table, Spinner, CardBody, Container } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { Download, Eye } from 'react-feather';
import moment from "moment";
import { Breadcrumbs } from '../../../../AbstractElements';
import { useSelector } from "react-redux";
import { populateFiscalTwoYears, populateFiscalYear } from "../../../api/helper";
import AgGridTable from "../../AgGridTable";
import ViewReport from "./ViewReport";
import { toast } from "react-toastify";

const Reporting = () => {
    const reportType = useSelector(state => state?.user?.userDetails?.reportType);
    const [reportData, setReportData] = useState([]);
    const [financialYear, setFinancialYear] = useState();
    const [startMonth, setStartMonth] = useState();
    const [fiscalYearArray, setFiscalYear] = useState(populateFiscalTwoYears());
    const [financialYearArray, setFinancialYearArray] = useState(populateFiscalYear());
    const [open, setOpen] = useState(false);
    const [viewData, setViewData] = useState();

    const getAllReports = async () => {
        await fetchWrapper(`${API_URL}/api/docx/reportsstatus`)
            .then((res) => {
                console.log(res?.json)
                setReportData(res?.json)
            }, (err) => {
                console.log(err)
            })
    }

    const downloadRef = useRef()

    const getFinancialYearSetup = async () => {
        await fetchWrapper(`${API_URL}/orgattributes`)
            .then((res) => {
                console.log(res.json?.startMonth)
                setStartMonth(res.json?.startMonth)
            }, (err) => { console.log(err) })
    }

    const downloadFile = async (data) => {
        if (data?.status === 'Report generation completed.') {
            await fetchWrapper(`${API_URL}/api/docx/getPresignedUrl/BRSR/${data?.financialYear}/${data?.requestId}`)
                .then(async (res) => {
                    console.log(res?.json)
                    const link = document.createElement('a');
                    link.href = res?.json;
                    link.download = 'document.pdf '
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }, (err) => {
                    console.log(err)
                })
        }
    }

    const handleView = (params) => {
        setOpen(true);
        setViewData(params);
    }

    const columnData = [
        {
            headerName: 'Report ID',
            field: 'requestId',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Fiscal year',
            field: 'financialYear',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Report Type',
            field: 'reportType',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            field: 'status',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'View',
            width: 60,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleView(params.data)}>
                        <Eye className={`action-icon`} />
                    </div>
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Downloads',
            cellRenderer: (params) => {
                return (
                    <a className="d-flex" onClick={() => downloadFile(params.data)}>
                        <Download className={`document-icon ${!params.data.status?.includes('Report generation completed') ? 'disable-download' : ''}`} />
                    </a>
                )
            },
            width: 150,
            sortable: true,
            suppressMovable: true,
        },
    ]

    useEffect(() => {
        getFinancialYearSetup()
        getAllReports()
    }, [])

    const generateFile = async () => {
        const requestBody = {
            reportType: reportType,
            financialYear: financialYear
        }
        const options = {
            method: 'POST',
            body: requestBody
        }
        await fetchWrapper(`${API_URL}/api/docx/generate`, options)
            .then((res) => {
                console.log(res.json)

            }, (err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Processing your report. Thank you for your patience.')
                    getAllReports()
                } else if (err.status === 500) {
                    toast.error('Something went wrong!')
                }

            })
    }

    return (
        <Fragment>
            <Container fluid={true} >
                <Breadcrumbs mainTitle="Reports" />
                <Card>
                    <CardBody>
                        <div className="d-flex justify-between">
                            <div className="d-flex">
                                <FormGroup>
                                    <label>Report Type</label>
                                    <select className="form-select w-12 " name='reportType'
                                        value={reportType} disabled>
                                        <option value={''}>-Select Report Type-</option>
                                        <option value="Default">Default</option>
                                        <option value="BRSR">BRSR</option>
                                        <option value="GRI">GRI</option>
                                        <option value="SASB">SASB</option>
                                        <option value="TCFD">TCFD</option>
                                        <option value="ESG">ESG</option>
                                    </select>
                                </FormGroup>
                                <FormGroup className="ml-1 ">
                                    <label>Financial Year</label>
                                    {
                                        startMonth === 'January' ?
                                            <select className="form-select w-15" value={financialYear} onChange={(e) => setFinancialYear(e.target.value)}>
                                                <option value={''}>-Select Fiscal Year-</option>
                                                {
                                                    financialYearArray?.length > 0 && financialYearArray?.map((item) =>
                                                        (<option value={item}>{item}</option>))
                                                }
                                            </select> :
                                            <select className="form-select w-15" value={financialYear} onChange={(e) => setFinancialYear(e.target.value)}>
                                                <option value={''}>-Select Fiscal Year-</option>
                                                {
                                                    fiscalYearArray?.length > 0 && fiscalYearArray?.map((item) =>
                                                        (<option value={item}>{item}</option>))
                                                }
                                            </select>
                                    }
                                </FormGroup>
                            </div>
                            <Button color="primary" disabled={(reportData?.some(report => report.financialYear === financialYear) || !financialYear)} onClick={generateFile}>Generate</Button>
                        </div>
                        <div className="mtop-1">
                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                <AgGridTable
                                    columnData={columnData}
                                    tableData={reportData}
                                    agRef={downloadRef}
                                // onGridReady={onGridReady}
                                />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
            {open && <ViewReport value={open} setOpenModal={setOpen} data={viewData} />}
        </Fragment>
    )
}

export default Reporting;