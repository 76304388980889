import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button } from "reactstrap";
import moment from "moment";
import { greivanceData } from "../../Settings/settingshelper";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";

const TransparencyCompliance = ({ setActive, financialYear, previousYear, previous2Years }) => {
    const [grievances, setGrievances] = useState(greivanceData);
    const [materialIssue, setMaterialIssue] = useState([...Array(3)].map((_, index) => ({ serialNo: index + 1 })));

    const getGrievanceData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/stakeholderComplaintsData/${financialYear}`)
            .then((res) => {
                console.log(res)
                const tempArray = greivanceData.map(ele => {
                    const temp = res?.json?.map(item => ({ ...item, grievanceRedressalMechanism: true ? 'Yes' : 'No' }))
                        ?.find(item => item?.stakeholderGroup === ele?.stakeholderGroup);
                    return temp ? temp : ele;
                });
                setGrievances(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getMaterialIssuesData = async() => {
        await fetchWrapper(`${API_URL}/emissionsReport/materialConductIssuesData/${financialYear}`)
        .then((res) => {
            setMaterialIssue(res?.json)
        }, (err) => console.log(err))
    }

    useEffect(() => {
        if(financialYear) {
            getGrievanceData()
            getMaterialIssuesData()
        }
    }, [financialYear])

    const materialColumns = [
        {
            headerName: 'S.No',
            field: 'serialNo',
            editable: false,
            filter: false,
            flex: 0.5,
            cellClass: 'disable-cell'
        },
        {
            headerName: 'Material Issue Indentified',
            field: 'issueIdentified',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Risk/Opportunity (R/O)',
            field: 'riskOrOpportunity',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Risk', 'Opportunity']
            },
            filter: false,
            editable: true,
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Risk/Opportunity Rationale',
            field: 'rationaleForIdentification',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'If risk, Risk Management Approach',
            field: 'adaptOrMitigate',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Financial Impact',
            field: 'financialImplications',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1.5,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ]

    const grievanceColumn = [
        {
            headerName: 'Stakeholder group from whom complaint is received',
            field: 'stakeholderGroup',
            editable: false,
            filter: false,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Grievance Redressal Mechanism (Yes/No) (If Yes, then provide web-link)',
            field: 'grievanceRedressalMechanism',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true, // Set this to true if the header is spanning multiple columns
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Number of complaints filed during the year ',
                    field: 'complaintsFiledCurrentYear',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Number of complaints pending resolution',
                    field: 'complaintsPendingCurrentYear',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Remarks',
                    field: 'remarksCurrentYear',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true, 
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align',
            children: [
                {
                    headerName: 'Number of complaints filed during the year',
                    field: 'complaintsFiledPreviousYear',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Number of complaints pending resolution',
                    field: 'complaintsPendingPreviousYear',
                    editable: true,
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Remarks',
                    field: 'remarksPreviousYear',
                    editable: true,
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
    ]

    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    }

    const onSubmit = async () => {
        const data = {
            financialYear: financialYear,
            stakeholderComplaintsData: grievances?.filter((item) => Object.keys(item)?.length > 2)
                ?.map((ele, index) => ({ ...ele, grievanceRedressalMechanism: 'Yes' ? true : false, serialNo: index + 1 })),
            materialConductIssuesData: materialIssue?.filter((item) => Object.keys(item)?.length > 2)
            ?.map((item, index) => ({ ...item, serialNo: index + 1 }))
        }
        const options = {
            method: 'POST',
            body: data
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            }, (err) => {
                if (err.status === 200) {
                    setActive(2)
                } else if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
    }

    return (
        <Fragment>
            <h3>Transparency Complaints</h3>
            <div className="mtop-1 company-profile ag-group-header">
                <h5> Complaints/Grievances on any of the principles under the National Guidelines on Responsible Business Conduct: </h5>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={grievances}
                            columnData={grievanceColumn}
                            gridOptions={gridOptions}
                        />
                    </div>
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5> Overview of the entity’s material responsible business conduct issues</h5>
                <div className="employee">
                    <div style={{ width: '100%' }} className="ag-datasheet">
                        <AgGridDatasheet
                            tableData={materialIssue}
                            columnData={materialColumns}
                        />
                        <CustomFooter handleAdd={() => setProductData([...materialIssue, { id: materialIssue?.length + 1 }])} />
                    </div>
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="primary" disabled={!financialYear ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default TransparencyCompliance;
