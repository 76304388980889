import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, FormGroup, Label, Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";
import ButtonLoader from '../../ButtonLoader';
import LayoutLoader from '../../LayoutLoader/LayoutLoader';
import DatePicker from 'react-datepicker';
import moment from "moment";

const FinancialYearSetup = () => {
    const [startMonth, setStartMonth] = useState()
    const [endMonth, setEndMonth] = useState()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({})

    const getFinancialSetup = async() => {
        await fetchWrapper(`${API_URL}/orgattributes`)
        .then((res) => {
            console.log(res.json)
            setData(res.json)
            if(res?.json) {
                setStartMonth(new Date(`${res.json?.startMonth} 1, 2024`))
                setEndMonth(new Date(`${res.json?.endMonth} 1, 2024`))
            }
            
        }, (err) => {
            console.log(err.json)
        })
    }

    useEffect(() => {
        getFinancialSetup()
    }, [])

    const onSubmit = async() => {
        const bodyData = {
            ...data,
            startMonth: moment(startMonth).format('MMMM'),
            endMonth: moment(endMonth).format('MMMM')
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/orgattributes`, options)
        .then((res) => {
            console.log(res)
            if(res.status === 201) {
                toast.success('Financial year setup saved successfully!')
            }
        }, (err) => {
            if(err.status === 500) {
                toast.error('Something went wrong')
            }
        })
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col lg='12'>
                        <Breadcrumbs mainTitle="Financial year" />
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup>
                                                <Label for="exampleEmail">Start Month <span className='required'>*</span> </Label>
                                                <div className="d-block fiscal-year month-picker">
                                                    <DatePicker
                                                        className="datepicker-here form-control"
                                                        selected={startMonth}
                                                        onChange={(e) => setStartMonth(e)}
                                                        showMonthYearPicker
                                                        dateFormat="MMMM"
                                                        showThreeColumnMonthYearPicker
                                                        placeholderText="-Month-"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup>
                                                <Label for="exampleEmail">End Month <span className='required'>*</span> </Label>
                                                <div className="d-block fiscal-year month-picker">
                                                    <DatePicker
                                                        className="datepicker-here form-control w-100"
                                                        selected={endMonth}
                                                        onChange={(e) => setEndMonth(e)}
                                                        showMonthYearPicker
                                                        dateFormat="MMMM"
                                                        showThreeColumnMonthYearPicker
                                                        placeholderText="-Month-"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <div className="mtop-1">
                            {
                                loading ? <ButtonLoader color={"primary"} loaderText={'Submitting'} /> :
                                    <Button color="primary" onClick={onSubmit}>Submit</Button>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default FinancialYearSetup;