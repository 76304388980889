import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AlignCenter } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { Image, UL, LI } from '../../../AbstractElements';
import CustomizerContext from '../../../Common/_helper/Customizer';
import appLogo from '../../../assets/images/logo/app-logo@3x1.png';
import greenCarbon from '../../../assets/images/logo/gc-app.png'
import netTrace from '../../../assets/images/logo/net0.png';
import aqiLogo from '../../../assets/images/logo/aqi.png';
import decarbbonLogo from '../../../assets/images/logo/decarb.png';
import airoiLogo from '../../../assets/images/logo/airoi.png';
import netLogo from '../../../assets/images/logo/net0trace.png';
import aqi from '../../../assets/images/logo/aqiLight.png';
import decarbon from '../../../assets/images/logo/decarbonLight.png';
import { useDispatch, useSelector } from 'react-redux';
import { TbGridDots } from "react-icons/tb";
import { CURRENT_APP } from '../../Constant';

const Leftbar = () => {
    const { setToggleIcon, toggleIcon, width } = useContext(CustomizerContext);
    const app = useSelector(state => state?.user?.appName);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const subscriptionDetails = useSelector(state => state?.user?.subscriptionDetails);
    const dispatch = useDispatch();
    const notAllowedTypes = ['SYS_USER', 'SYS_ADMIN', 'ASM_USER', 'ASR_ADMIN', 'ASR_USER', 'ASM_ADMIN'];
    const userType = useSelector(state => state?.user?.userType);

    useEffect(() => {
        console.log(open)
    }, [open])

    const handleApps = (id) => {
        dispatch({
            type: CURRENT_APP,
            payload: id
        })
        navigate(`${process.env.PUBLIC_URL}/settings/application-settings`)
        setOpen(false)
    }

    return (
        <Fragment>
            <div className={`header-logo-wrapper col-auto p-0`}>
                <div className="logo-wrapper d-flex">
                    {
                        !notAllowedTypes?.includes(userType) && <LI attrLI={{ className: "profile-nav onhover-dropdown p-0" }} >
                            <TbGridDots className={`${(open) ? 'grid-dots-active' : 'grid-dots'} mr-1`} onClick={() => setOpen(!open)} />
                            {
                                open && <UL attrUL={{ className: "profile-dropdown onhover-app-list" }}>
                                    {
                                        subscriptionDetails?.some(ele => ele?.moduleId == 1) && <LI attrLI={{ className: 'd-flex', onClick: () => handleApps(1) }}>
                                            <img src={greenCarbon} width={20} height={20} /> <div className='ml-1 app-tile-font mtop-0'>Carbon Credits</div>
                                        </LI>
                                    }
                                    {
                                        subscriptionDetails?.some(ele => ele?.moduleId == 2) && <LI attrLI={{ className: 'd-flex', onClick: () => handleApps(2) }}>
                                            <img src={netTrace} width={20} height={20} /> <div className='ml-1 app-tile-font mtop-0'>Net0Trace</div>
                                        </LI>
                                    }
                                    {
                                        subscriptionDetails?.some(ele => ele?.moduleId == 5) && <LI attrLI={{ className: 'd-flex', onClick: () => handleApps(5) }}>
                                            <img src={aqiLogo} width={20} height={20} /> <div className='ml-1 app-tile-font mtop-0'>AQI Insights</div>
                                        </LI>
                                    }
                                    {
                                        subscriptionDetails?.some(ele => ele?.moduleId == 3) && <LI attrLI={{ className: 'd-flex', onClick: () => handleApps(3) }}>
                                            <img src={decarbbonLogo} width={20} height={20} /> <div className='ml-1 app-tile-font mtop-0'>Decarbonization Analystics</div>
                                        </LI>
                                    }
                                </UL>
                            }
                        </LI>
                    }

                    <div>
                        <Image attrImage={{
                            className: `img-fluid for-light ${!app ? 'bg-fff airoi-logo' : ''}`, style: { width: (app !== 1 && app !== null) ? 40 : (app == null) ? 96 : app == 1 ? 70 : '' },
                            src: app == 1 ? appLogo : app == 2 ? netLogo : app == 3 ? decarbon : app == 5 ? aqi : airoiLogo, alt: ""
                        }} />
                    </div>
                </div>
                <div className={`toggle-sidebar ${(width - 4) > 1199 && 'd-none'}`} onClick={() => setToggleIcon(!toggleIcon)}>
                    <AlignCenter className="status_toggle middle sidebar-toggle" id="sidebar-toggle" />
                </div>
            </div>
        </Fragment >
    );
};
export default Leftbar;