import React, { Fragment, useEffect, useRef, useState } from "react";
import { Container, Row, Col, CardBody, Card, FormGroup, Label, Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";
import ButtonLoader from '../../ButtonLoader';
import LayoutLoader from '../../LayoutLoader/LayoutLoader';
import { scope1Categories, scope2Categories, scope3Categories } from "../settingshelper";

const ScopeConfiguration = () => {
    const [scope1Checked, setScope1Checked] = useState(false);
    const [scope2Checked, setScope2Checked] = useState(false);
    const [scope3Checked, setScope3Checked] = useState(false);
    const [scope1, setScope1] = useState(scope1Categories);
    const [scope2, setScope2] = useState(scope2Categories);
    const [scope3, setScope3] = useState(scope3Categories);
    const scope1ref = useRef(null);
    const scope2ref = useRef(null);
    const scope3ref = useRef(null);

    const getScopeCategories = async () => {
        await fetchWrapper(`${API_URL}/scopecategories/organization`)
            .then((res) => {
                console.log(res.json)
                const list = res.json?.map(ele => ele?.categoryName);
                setScope1(scope1Categories?.map(item => {
                    if (list?.includes(item?.category)) {
                        return {
                            category: item.category,
                            checked: true
                        }
                    } else {
                        return {
                            category: item.category,
                            checked: false
                        }
                    }
                }))
                setScope2(scope2Categories?.map(item => {
                    if (list?.includes(item?.category)) {
                        return {
                            category: item.category,
                            checked: true
                        }
                    } else {
                        return {
                            category: item.category,
                            checked: false
                        }
                    }
                }))
                setScope3(scope3Categories?.map(item => {
                    if (list?.includes(item?.category)) {
                        return {
                            category: item.category,
                            checked: true
                        }
                    } else {
                        return {
                            category: item.category,
                            checked: false
                        }
                    }
                }))
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getScopeCategories()
    }, [])


    useEffect(() => {
        const allChecked = scope1?.map(ele => ele?.checked).every(Boolean);
        const someChecked = scope1?.map(ele => ele?.checked).some(Boolean);

        setScope1Checked(allChecked);
        if (scope1ref.current) {
            scope1ref.current.indeterminate = !allChecked && someChecked;
            if (!allChecked && someChecked) {
                scope1ref.current.classList.add('indeterminate');
            } else {
                scope1ref.current.classList.remove('indeterminate');
            }
        }
    }, [scope1]);

    useEffect(() => {
        const allChecked = scope2?.map(ele => ele?.checked).every(Boolean);
        const someChecked = scope2?.map(ele => ele?.checked).some(Boolean);

        setScope2Checked(allChecked);
        if (scope2ref.current) {
            scope2ref.current.indeterminate = !allChecked && someChecked;
            if (!allChecked && someChecked) {
                scope2ref.current.classList.add('indeterminate');
            } else {
                scope2ref.current.classList.remove('indeterminate');
            }
        }
    }, [scope2]);

    useEffect(() => {
        const allChecked = scope3?.map(ele => ele?.checked).every(Boolean);
        const someChecked = scope3?.map(ele => ele?.checked).some(Boolean);

        setScope3Checked(allChecked);
        if (scope3ref.current) {
            scope3ref.current.indeterminate = !allChecked && someChecked;
            if (!allChecked && someChecked) {
                scope3ref.current.classList.add('indeterminate');
            } else {
                scope3ref.current.classList.remove('indeterminate');
            }
        }
    }, [scope3]);

    const handleParentChange = (value, type) => {
        if (type === 1) {
            setScope1Checked(value);
            setScope1(scope1?.map(ele => ({ ...ele, checked: value })))
        }
        if (type === 2) {
            setScope2Checked(value);
            setScope2(scope2?.map(ele => ({ ...ele, checked: value })))
        }
        if (type === 3) {
            setScope3Checked(value);
            setScope3(scope3?.map(ele => ({ ...ele, checked: value })))
        }
    };

    const handleChildChange = (value, index, type) => {
        if (type === 1) {
            let newScope1 = [...scope1];
            newScope1[index].checked = value;
            setScope1(newScope1);
        }
        if (type === 2) {
            let newScope2 = [...scope2];
            newScope2[index].checked = value;
            setScope2(newScope2);
        }
        if (type === 3) {
            let newScope3 = [...scope3];
            newScope3[index].checked = value;
            setScope3(newScope3);
        }
    };

    useEffect(() => {
        for (let i = 1; i <= 3; i++) {
            handleParentChange(true, i)
        }
    }, [])

    const onSubmit = async () => {
        const bodyData = {
            scopeCategoryDetails: [
                {
                    scope: 1,
                    category: "Scope 1 Emissions",
                    categoryDetails: scope1?.filter(ele => ele?.checked === true).map((item) => {
                        return {
                            categoryName: item.category,
                            isApplicable: true
                        }
                    })
                },
                {
                    scope: 2,
                    category: "Scope 2 Emissions",
                    categoryDetails: scope2?.filter(ele => ele?.checked === true).map((item) => {
                        return {
                            categoryName: item.category,
                            isApplicable: true
                        }
                    })
                },
                {
                    scope: 3,
                    category: "Scope 3 Emissions",
                    categoryDetails: scope3?.filter(ele => ele?.checked === true).map((item) => {
                        return {
                            categoryName: item.category,
                            isApplicable: true
                        }
                    })
                },
            ]
        }
        console.log(bodyData);
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/scopecategories`, options)
            .then((res) => {
                console.log(res.json)
                toast.success('Scope applicability saved successfully!')
            }, (err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Scope applicability saved successfully!')
                }
            })
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col lg='12'>
                        <div>
                            <Breadcrumbs mainTitle="Scope Applicability" />
                        </div>
                        <Card>
                            <CardBody>
                                <div className="form-check checkbox-container">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        ref={scope1ref}
                                        checked={scope1Checked}
                                        onChange={(e) => handleParentChange(e.target.checked, 1)}
                                        id="scope1Checkbox"
                                    />
                                    <label className="form-check-label" htmlFor="scope1Checkbox">
                                        Scope 1
                                    </label>
                                    <div>
                                        {
                                            scope1?.map((item, index) => (
                                                <div className={`form-check`} key={index}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={item?.checked}
                                                        value={item?.checked}
                                                        onChange={(e) => handleChildChange(e.target.checked, index, 1)}
                                                        id={`childCheckbox${index}`}
                                                    />
                                                    <label className="form-check-label" htmlFor={`childCheckbox${index}`}>
                                                        {item?.category}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="form-check checkbox-container mtop-1">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        ref={scope2ref}
                                        checked={scope2Checked}
                                        onChange={(e) => handleParentChange(e.target.checked, 2)}
                                        id="scope2Checkbox"
                                    />
                                    <label className="form-check-label" htmlFor="scope2Checkbox">
                                        Scope 2
                                    </label>
                                    <div>
                                        {
                                            scope2?.map((item, index) => (
                                                <div className={`form-check`} key={index}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={item?.checked}
                                                        value={item?.checked}
                                                        onChange={(e) => handleChildChange(e.target.checked, index, 2)}
                                                        id={`childCheckbox${index}`}
                                                    />
                                                    <label className="form-check-label" htmlFor={`childCheckbox${index}`}>
                                                        {item?.category}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="form-check checkbox-container mtop-1">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        ref={scope3ref}
                                        checked={scope3Checked}
                                        onChange={(e) => handleParentChange(e.target.checked, 3)}
                                        id="scope3Checkbox"
                                    />
                                    <label className="form-check-label" htmlFor="scope3Checkbox">
                                        Scope 3
                                    </label>
                                    <div>
                                        {
                                            scope3?.map((item, index) => (
                                                <div className={`form-check`} key={index}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={item?.checked}
                                                        value={item?.checked}
                                                        onChange={(e) => handleChildChange(e.target.checked, index, 3)}
                                                        id={`childCheckbox${index}`}
                                                    />
                                                    <label className="form-check-label" htmlFor={`childCheckbox${index}`}>
                                                        {item?.category}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <div>
                            <Button color="primary" onClick={onSubmit}>Submit</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ScopeConfiguration;