export const scope1Categories = [
    { category: 'Stationary Combustion', checked: false },
    { category: 'Mobile Sources', checked: false },
    { category: 'Refrigeration / AC Equipment Use', checked: false },
    { category: 'Fire Suppression', checked: false },
    { category: 'Purchased Gases', checked: false },
]

export const scope2Categories = [
    { category: 'Purchased and Consumed Electricity', checked: false },
    { category: 'Purchased and Consumed Steam', checked: false },
]

export const scope3Categories = [
    { category: 'Employee Business Travel', checked: false },
    { category: 'Employee Commuting', checked: false },
    { category: 'Upstream Transportation and Distribution', checked: false },
    { category: 'Waste', checked: false },
    { category: 'Water', checked: false },
]

export const greivanceData = [
    {
        stakeholderGroup: 'Communities'
    },
    {
        stakeholderGroup: 'Investors (other than shareholders)'
    },
    {
        stakeholderGroup: 'Shareholders'
    },
    {
        stakeholderGroup: 'Employees and workers'
    },
    {
        stakeholderGroup: 'Customers'
    },
    {
        stakeholderGroup: 'Value Chain Partners'
    },
    {
        stakeholderGroup: 'Other (please specify)'
    },
];

export const employee = [
    {
        particulars: 'Permanent (D)'
    },
    {
        particulars: 'Other than Permanent (E) '
    }
]

export const workers = [
    {
        particulars: 'Permanent (F)'
    },
    {
        particulars: 'Other than Permanent (G) '
    }
]

export const diffabledEmployee = [
    {
        particulars: 'Permanent (D)'
    },
    {
        particulars: 'Other than Permanent (E) '
    },

]

export const diffabledWorkers = [
    {
        particulars: 'Permanent (F)'
    },
    {
        particulars: 'Other than Permanent (G) '
    }
]

export const female = [
    {
        representationType: 'Board of Directors'
    },
    {
        representationType: 'Key Management Personnel'
    }
];

export const permanentEmpData = [
    {
        particulars: 'Permanent Employees'
    },
    {
        particulars: 'Permanent Workers'
    }
]

export const policyTemplateData = [
    {
        serialId: 1,
        serialNo: 'PP-1',
        questionText: 'Whether your entity’s policy/policies cover each principle and its core elements of the Responsible Business Guidelines. (Yes/No)',
        type: 'dropdown'
    },
    {
        serialId: 2,
        serialNo: 'PP-2',
        questionText: 'Has the policy been approved by the Board? (Yes/No)',
        type: 'dropdown'
    },
    {
        serialId: 3,
        serialNo: 'PP-3',
        questionText: 'Web Link of the Policies, if available',
        type: 'text'
    },
    {
        serialId: 4,
        serialNo: 'PP-4',
        questionText: 'Whether the entity has translated the policy into procedures.(Yes/No)',
        type: 'dropdown'
    },
    {
        serialId: 5,
        serialNo: 'PP-5',
        questionText: 'Do the enlisted policies extend to your value chain partners? (Yes/No)',
        type: 'dropdown'
    },
    {
        serialId: 6,
        serialNo: 'PP-6',
        questionText: 'Name of the national and international codes/certifications/standards/labels standards (e.g. SA 8000, OHSAS, ISO, BIS) adopted by your entity and mapped to each principle.',
        type: 'text'
    },
    {
        serialId: 7,
        serialNo: 'PP-7',
        questionText: 'Specific commitments, goals and targets set by the entity with defined timelines, if any.',
        type: 'text'
    },
    {
        serialId: 8,
        serialNo: 'PP-8',
        questionText: 'Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met.',
        type: 'text'
    }
];

export const reviewParticulars = [
    {
        questionText: 'Performance against above policies and follow up action',
        serialId: 'NR-1'
    },
    {
        questionText: 'Compliance with statutory requirements of relevance to the principles, and rectification of any non-compliances',
        serialId: 'NR-2'
    }
];

export const principleList = [
    'Ethical Transparent Accountability',
    'Sustainable Safe Practices',
    'Employee Well - being Promotion',
    'Stakeholder Interest Responsiveness',
    'Human Rights Advocacy',
    'Environmental Protection Efforts',
    'Responsible Transparent Advocacy',
    'Inclusive Equitable Development',
    'Responsible Consumer Engagement'
]

export const externalParticulars = [
    {
        serialId: 'EE-01',
        questionText: 'Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.'
    }
];

export const policyQuestions = [
    {
        serialId: 1,
        questionText: 'The entity does not consider the principles material to its business (Yes/No)', type: 'dropdown'
    },
    {
        serialId: 2,
        questionText: 'The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)', type: 'dropdown'
    },
    {
        serialId: 3,
        questionText: 'The entity does not have the financial or/human and technical resources available for the task (Yes/No)', type: 'dropdown'
    },
    {
        serialId: 4,
        questionText: 'It is planned to be done in the next financial year (Yes/No)', type: 'dropdown'
    },
    {
        serialId: 5,
        questionText: 'Any other reason (please specify)', type: 'text'
    }
];

export const trainingData = [
    {
        segment: 'Board of Directors'
    },
    {
        segment: 'Key Managerial Personnel'
    },
    {
        segment: 'Employees other than BoD and  KMPs'
    }, 
    {
        segment: 'Workers'
    }
]

export const penaltyData = [
    {
        particulars: 'Penalty/Fine'
    },
    {
        particulars: 'Settlement'
    },
    {
        particulars: 'Compounding Fee'
    }
]

export const nonmonetaryPenaltyData = [
    {
        particulars: 'Imprisonment'
    },
    {
        particulars: 'Punishment'
    }
];

export const misleadingData = [
    {
        particulars: 'Directors'
    },
    {
        particulars: 'KMPs'
    },
    {
        particulars: 'Employees'
    },
    {
        particulars: 'Workers'
    },
];

export const complaintsData = [
    {
        particulars: 'Number of complaints received in relation to issues of Conflict of Interest of the Directors'
    },
    {
        particulars: 'Number of complaints received in relation to issues of Conflict of Interest of the KMPs'
    },
];

export const businessDetailsData = [
    {
        parameters: 'Concentration of Purchases', 
        metrics: 'Total purchase percentage(%)'
    },
    {
        parameters: 'Concentration of Purchases', 
        metrics: 'Total number of purchases'
    },
    {
        parameters: 'Concentration of Purchases', 
        metrics: 'Total purchase Percentage(%)'
    },
];

export const ESimpactData = [
    {
        particulars: 'R&D'
    },
    {
        particulars: 'Capex'
    },
];

export const materialData = [
    {
        particulars: 'Plastics(Including packaging)'
    },
    {
        particulars: 'E-waste'
    },
    {
        particulars: 'Hazardous waste'
    },
    {
        particulars: 'Other Waste'
    },
];

export const retirementBenefitsData = [
    {
        benefitType: 'PF'
    },
    {
        benefitType: 'Gratuity'
    },
    {
        benefitType: 'ESI'
    },
    {
        benefitType: 'Others'
    },
];

export const yearlyDevelopCategoryData = [
    {
        category: 'Male'
    },
    {
        category: 'Female'
    }
]

export const categoryData = [
    {
        category: 'Male'
    },
    {
        category: 'Female'
    },
]

export const parentalRetentionCategory = [
    {
        gender: 'Male'
    },
    {
        gender: 'Female'
    },
    {
        gender: 'Total'
    },
]

export const employeeCategoryData = [
    {
        category: 'Total Permanent Employees'
    },
    {
        category: 'Male'
    },
    {
        category: 'Female'
    },
    {
        category: 'Total Permanent Workers'
    },
    {
        category: 'Male'
    },
    {
        category: 'Female'
    },
];

export const safetyIncident = [
    {
        incidentNumber: 'Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)',
        category: 'Employees'
    },
    {
        incidentNumber: '',
        category: 'Workers'
    },
    {
        incidentNumber: 'Total recordable work-related injuries',
        category: 'Employees'
    },
    {
        incidentNumber: '',
        category: 'Workers'
    },
    {
        incidentNumber: 'No. of fatalities',
        category: 'Employees'
    },
    {
        incidentNumber: '',
        category: 'Workers'
    },
    {
        incidentNumber: 'High consequence work-related injury or ill-health (excluding fatalities)',
        category: 'Employees'
    },
    {
        incidentNumber: '',
        category: 'Workers'
    },
];

export const businessOpenness = [
    {
        parameter: 'Concentration of Purchases',
        metrics: 'a. Purchases from trading houses as % of total purchases',
        type: 'percentage'
    },
    {
        parameter: '',
        metrics: 'b. Number of trading houses where purchases are made from',
        type: 'number'
    },
    {
        parameter: '',
        metrics: 'c. Purchases from top 10 trading houses as % of total purchases from trading house',
        type: 'percentage'
    },
    {
        parameter: 'Concentration of Sales',
        metrics: 'a. Sales to dealers / distributors as % of total sales',
        type: 'percentage'
    },
    {
        parameter: '',
        metrics: 'b. Number of dealers / distributors to whom sales are made',
        type: 'number'
    },
    {
        parameter: '',
        metrics: 'c. Sales to top 10 dealers / distributors as % of total sales to dealers / distributors',
        type: 'percentage'
    },
    {
        parameter: 'Share of RPT’s in',
        metrics: 'a. Purchases (Purchases with related parties / Total Purchases)',
        type: 'number'
    },
    {
        parameter: '',
        metrics: 'b. Sales (Sales to related parties / Total Sales)',
        type: 'number'
    },
    {
        parameter: '',
        metrics: 'c. Loans & advances (Loans & advances given to related parties / Total loans and advances)',
        type: 'number'
    },
    {
        parameter: '',
        metrics: 'd. Investments (Investments in related parties/Total investments made)',
        type: 'number'
    },
]

export const employeeComplaints = [
    {
        particulars: 'Working condition'
    },
    {
        particulars: 'Health & safety'
    }
]

export const rehabilitaion = [
    {
        category: 'Employees'
    },
    {
        category: 'Workers'
    }
];

export const supplierMaterial = [
    {
        particulars: 'Directly sourced from MSMEs / small producers'
    },
    {
        particulars: 'Directly from within India '
    }
];

export const ruralAreaWage = [
    {
        location: 'Rural'
    },
    {
        location: 'Semi-urban'
    },
    {
        location: 'Urban'
    },
    {
        location: 'Metropolitan'
    },
];

export const numberOfConsumerComplaints = [
    {
        particulars: 'Data Privacy'
    },
    {
        particulars: 'Advertising'
    },
    {
        particulars: 'Cyber Security'
    },
    {
        particulars: 'Delivery of essential services'
    },
    {
        particulars: 'Restrictive Trade Practices'
    },
    {
        particulars: 'Unfair Trade Practices'
    },
    {
        particulars: 'Other'
    },
];

export const productRecalls = [
    {
        particulars: 'Voluntary Recalls'
    },
    {
        particulars: 'Forced Recalls'
    },
];

export const humanrightsCategory = [
    {
        category: 'Permanent'
    },
    {
        category: 'Other than permanent'
    }
]


export const employeeWagesCategory = [
    {
        category: 'Permanent'
    },
    {
        category: 'Male'
    },
    {
        category: 'Female'
    },
    {
        category: 'Other than Permanent'
    },
    {
        category: 'Male'
    },
    {
        category: 'Female'
    },  
]

export const employeeRemunerationCategory = [
    {
        particulars: 'Board of Directors (BoD)'
    },
    {
        particulars: 'Key Managerial Personnel'
    },
    {
        particulars: 'Employees other than BoD and KMP'
    },
    {
        particulars: 'Workers'
    }
]

export const employeeComplaintsCategory = [
    {
        particulars: 'Sexual Harassment'
    },
    {
        particulars: 'Discrimination at Workplace'
    },
    {
        particulars: 'Child Labour'
    },
    {
        particulars: 'Forced Labour/Involuntary labour'
    },
    {
        particulars: 'Wages'
    },
    {
        particulars: 'Other human rights related issues'
    },
];


export const employeeHarassmentParticulars = [
    {
        particulars: 'Total Complaints reported under Sexual Harassment on of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 (POSH)'
    },
    {
        particulars: 'Complaints on POSH as a % of female employees / workers'
    },
    {
        particulars: 'Complaints on POSH upheld'
    }
];

export const annualAssessmentParticulars = [
    {
        particulars: 'Child labour',
        principleNumber: 5
    },
    {
        particulars: 'Forced/involuntary labour',
        principleNumber: 5
    },
    {
        particulars: 'Sexual harassment',
        principleNumber: 5
    },
    {
        particulars: 'Discrimination at workplace',
        principleNumber: 5
    },

    {
        particulars: 'Wages',
        principleNumber: 5
    },
    {
        particulars: 'Others',
        principleNumber: 5
    },
]