import React, { Fragment, useState, useEffect } from "react";
import ViewPage from "../../Common/Component/ViewProject";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

const ViewAsoAdminProject = () => {
    const [formData, setFormData] = useState({});
    const projectId = useParams()?.id;
    const userObject = useSelector(state => state?.user?.userRoles);

    const loadData = async (id) => {
        const options = {
          method: 'GET'
        }
        await fetchWrapper(`${API_URL}/document/${id}`, options)
          .then((res) => {
            console.log(res);
            const data = res.json;
            setFormData(data)
            
           })
          .catch((err) => console.log(err));
          
      }
     useEffect(()=>{
        loadData(projectId)
     }, [])
    
    return (
        <Fragment>
           { formData && <ViewPage type={userObject[1]} projectId={projectId} documents={formData}/> } 
        </Fragment>
    )
}

export default ViewAsoAdminProject;