import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { defaultUserType } from '../../api/helper';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import ButtonLoader from '../ButtonLoader';

let total = 0;
const MonthlyMonitoring = ({ projectId, frequency, userType, month, year, columnType, projectName, type }) => {
    const gridRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const [tableData, setTableData] = useState([]);
    const [totalCollection, setTotal] = useState(0);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState({
        id: 'total',
        period: 'Total',
        quantity: total,
    });
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    
    const columnData = [
        {
            headerName: 'Period',
            field: 'period',
            valueGetter: (params) => {
                if (params.node?.rowPinned !== 'bottom') {
                    return params.data.id ? params.data.period.split(',')[0] :  params.data.period
                } else {
                    return 'Total'
                }
            },
            suppressMovable: true
        },
        {
            headerName: columnType,
            field: 'quantity',
            editable: (params) => {
                return (!params.data.id)
            },
            cellEditor: 'agNumberCellEditor',
            singleClickEdit: true,
            cellEditorPopup: false,
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellClass: (params) => {
                return params.data.id ? 'disable-field text-input-field' : 'text-input-field'
            },
            suppressMovable: true
        },
    ]

    const getCollectionList = async () => {
        let total = 0;
        const dateArray = loadDateArray();
        await fetchWrapper(`${API_URL}/projectmonitoring/project/${projectId}/year/${moment(year).format('YYYY')}`)
            .then((res) => {
                if (res.status === 200) {
                    const data = res.json;
                    const formatArray = dateArray?.map((item) => {
                        let findItem = data.find((ele) => ele.period.split(',')[0] === item.period);
                        return { ...item, ...findItem }
                    })

                    data?.map((i) => {
                        total = total + Number(i.quantity)
                    })
                    setTotal(total)
                    setPinnedBottomRowData({
                        id: 'total',
                        period: "Total",
                        quantity: total
                    });
                    setTableData(formatArray)
                }
            }, (err) => console.log(err))
    }

    const getGridData = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const data = gridApi.getModel().rowsToDisplay.filter(rowNode => (rowNode.data.quantity)).map((rowNode) => {
                return {
                    'period': rowNode.data.id ? rowNode.data.period : `${rowNode.data.period}, ${moment(year).format('YYYY')}`,
                    'quantity': rowNode.data.quantity
                }
            });
            return data;
        }
        return [];
    }


    const loadDateArray = () => {
        let array = moment.months();
        const monthsArray = array.map((item) => {
            return {
                period: item
            }
        })
        return [...monthsArray];
    }

    useEffect(()=>{
        getCollectionList()
    }, [year])


    const onSubmit = async () => {
        setLoading(true)
        let collectionList = getGridData();
        let bodyData = {
            projectId: projectId,
            frequency: frequency,
            year: moment(year).format('YYYY'),
            monitoringData: [...collectionList]
        }
        const options = {
            method: "POST",
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/projectmonitoring`, options)
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    toast.success('Submitted Successfully!')
                    getCollectionList();
                } else {
                    toast.error('Something went wrong')
                }
            }, (err) => {
                console.log(err)
                setLoading(false)
                if (err.status === 200) {
                    toast.success('Submitted Successfully!')
                    getCollectionList()
                } else {
                    toast.error('Something went wrong')
                }
            })
    }

    const onCellValueChanged = (event) => {
        let total = totalCollection;
        const newValue = event.newValue; // The new value of the edited cell
        const columnId = event.column.getId(); // The column ID of the edited cell
        if (columnId === 'quantity') {
            total = total + newValue;
            setTotal(total);
        }
        setPinnedBottomRowData({
            id: 'total',
            period: "Total",
            quantity: total,
        });
    };

    
    const handleHistory = () => {
        navigate(`${process.env.PUBLIC_URL}/monitoring-history/${projectId}/${projectName}/${type}`)
    }

    const onGridReady = useCallback(async (params) => {
        gridRef.current.api.showLoadingOverlay();
        const dateArray = loadDateArray();
        let total = 0;
        setTotal(total)
        setPinnedBottomRowData({
            id: 'total',
            period: "Total",
            quantity: total
        });
        // setTableData(dateArray)
        await fetchWrapper(`${API_URL}/projectmonitoring/project/${projectId}/year/${moment(year).format('YYYY')}`)
            .then((res) => {
                gridRef.current.api.hideOverlay();
                if (res.status === 200) {
                    const data = res.json;
                    const formatArray = dateArray?.map((item) => {
                        let findItem = data.find((ele) => ele.period.split(',')[0] === item.period);
                        return { ...item, ...findItem }
                    })

                    data?.map((i) => {
                        total = total + Number(i.quantity)
                    })
                    setTotal(total)
                    setPinnedBottomRowData({
                        id: 'total',
                        period: "Total",
                        quantity: total
                    });
                    setTableData(formatArray)
                }
            }, (err) => console.log(err))
    })
    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/projects`)
    }

    return (
        <Fragment>
            <Row>
                <Col lg='12'>
                    <div className='waste-collection-monthly'>
                        <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnData}
                                rowData={tableData}
                                ref={gridRef}
                                rowModelType='clientSide'
                                domLayout='autoHeight'
                                animateRows={true}
                                getRowStyle={() => {
                                    return {
                                        background: '#fafafa'
                                    }
                                }}
                                overlayLoadingTemplate={
                                    `<div class="custom-loading-overlay">
                                                    <i class="fa fa-spinner fa-spin"></i> <span class='f-15'>Please wait while your data are loading...</span>
                                                </div>`
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">This is a custom \'no rows\' overlay</span>'
                                }
                                // pagination={true}
                                // paginationPageSize={12}
                                onCellValueChanged={onCellValueChanged}
                                pinnedBottomRowData={[pinnedBottomRowData]}
                                enterNavigatesVerticallyAfterEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                                onGridReady={onGridReady}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='d-flex mtop-1'>
                            {
                                loading ? <ButtonLoader color={'primary'} loaderText={'Submitting'} /> : <Button color='primary' onClick={onSubmit}>Submit</Button>
                            }
                            <Button color='secondary' className='ml-1' onClick={handleBack}>Back</Button>
                            <Button color='primary' className='right-1' onClick={handleHistory}>History View/Update</Button>
                        </div>
        </Fragment>
    )
}

export default MonthlyMonitoring;