import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Nav, Tab, Row, Col, Tabs, Accordion, Card, FormGroup } from "react-bootstrap";
import { CardBody, Container } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import LayoutLoader from '../../LayoutLoader/LayoutLoader';
import { populateFiscalTwoYears, populateFiscalYear } from "../../../api/helper";
import { Fragment } from "react";
import Employees from "./Employees";
import SocialResponsibility from "./SocialResponsibility";
import EmployeeWellbeing from "./EmployeeWellbeing";
import StakeholderInterest from "./StakeholderInterest";
import HumanRightsAdvocacy from "./HumanRightsAdvocacy";
import InclusiveEquitable from "./InclusiveEquitable";
import ResponsibleTransparentAdvocacy from "./ResponsibleTransparentAdvocacy";

const Social = () => {
    const [activeKey, setActiveKey] = useState(1);
    const [financialYear, setFinancialYear] = useState();
    const [startMonth, setStartMonth] = useState();
    const [fiscalYearArray, setFiscalYear] = useState(populateFiscalTwoYears());
    const [financialYearArray, setFinancialYearArray] = useState(populateFiscalYear());
    const [previousYear, setPreviousYear] = useState();
    const [previous2Years, setPrevious2Years] = useState();

    const getFinancialYearSetup = async () => {
        await fetchWrapper(`${API_URL}/orgattributes`)
            .then((res) => {
                console.log(res.json?.startMonth)
                setStartMonth(res.json?.startMonth)
            }, (err) => { console.log(err) })
    }

    const handleFinancialYear = (value) => {
        setFinancialYear(value)
        if(startMonth === 'January') {
            const index = financialYearArray?.findIndex(value);
            setPreviousYear(financialYearArray[index + 1])
            setPrevious2Years(financialYearArray[index + 2])
        } else {
            const index = fiscalYearArray?.findIndex((item) => item === value);
            setPreviousYear(fiscalYearArray[index + 1])
            setPrevious2Years(fiscalYearArray[index + 2])
        }
    }

    useEffect(() => {
        getFinancialYearSetup()
    }, [activeKey])

    return (
        <Fragment>
            <Container fluid={true} >
                <LayoutLoader />
                <div className="d-flex">
                    <Breadcrumbs mainTitle={'Social'} />
                </div>
                <Card>
                    <CardBody>
                        <div className="d-flex justify-end ">
                            <label>Financial Year</label>
                            {
                                startMonth === 'January' ?
                                    <select className="form-select ml-1 w-12" value={financialYear} onChange={(e) => handleFinancialYear(e.target.value)}>
                                        <option value={''}>-Select Fiscal Year-</option>
                                        {
                                            financialYearArray?.length > 0 && financialYearArray?.map((item) =>
                                                (<option value={item}>{item}</option>))
                                        }
                                    </select> :
                                    <select className="form-select ml-1 w-12" value={financialYear} onChange={(e) => handleFinancialYear(e.target.value)}>
                                        <option value={''}>-Select Fiscal Year-</option>
                                        {
                                            fiscalYearArray?.length > 0 && fiscalYearArray?.map((item) =>
                                                (<option value={item}>{item}</option>))
                                        }
                                    </select>
                            }
                        </div>
                        <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <div className="d-flex company-profile justify-between">
                                <Nav variant="underlines">
                                    <Nav.Item className="mr-5px">
                                        <Nav.Link eventKey={'1'} className={activeKey == 1 ? 'application-active' : ''}>Employees</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'2'} className={activeKey == 2 ? 'application-active' : ''} >Corporate Social Responsibility</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'3'} className={activeKey == 3 ? 'application-active' : ''} >Employee Wellbeing</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'4'} className={activeKey == 4 ? 'application-active' : ''} >Stakeholder Interest</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'5'} className={activeKey == 5 ? 'application-active' : ''} >Human Rights Advocacy</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'6'} className={activeKey == 6 ? 'application-active' : ''} >Inclusive Equitable Development</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'7'} className={activeKey == 7 ? 'application-active' : ''} >Responsible Transparent Advocacy</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </div>
                            <Tab.Content className="mtop-1">
                                <Tab.Pane eventKey={1}>
                                    <Employees setActive={setActiveKey} financialYear={financialYear} previousYear={previousYear} previous2Years={previous2Years} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={2}>
                                    <SocialResponsibility setActive={setActiveKey} financialYear={financialYear} previousYear={previousYear} previous2Years={previous2Years} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={3}>
                                    <EmployeeWellbeing setActive={setActiveKey} financialYear={financialYear} previousYear={previousYear} previous2Years={previous2Years} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={4}>
                                    <StakeholderInterest setActive={setActiveKey} financialYear={financialYear} previousYear={previousYear} previous2Years={previous2Years} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={5}>
                                    <HumanRightsAdvocacy setActive={setActiveKey} financialYear={financialYear} previousYear={previousYear} previous2Years={previous2Years} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={6}>
                                    <InclusiveEquitable setActive={setActiveKey} financialYear={financialYear} previousYear={previousYear} previous2Years={previous2Years} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={7}>
                                    <ResponsibleTransparentAdvocacy setActive={setActiveKey} financialYear={financialYear} previousYear={previousYear} previous2Years={previous2Years} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    )
}

export default Social;