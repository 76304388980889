import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Eye, PlusCircle, Trash2 } from 'react-feather';
import AddCustomerModal from './AddCustomer';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { toast } from 'react-toastify';
import LayoutLoader from '../../Common/Component/LayoutLoader/LayoutLoader';
import AgGridTable from '../../Common/Component/AgGridTable';

const CustomersList = () => {
    const [open, setOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [orgList, setOrgList] = useState()
    const [loading, setLoading] = useState(false);
    const [addStatus, setAddStatus] = useState(false)
    const customerRef = useRef(null);

    const defaultOrgType = {
        "ASSET_OWNER": 'Asset Owner',
        "ASSESSOR": 'Assessor',
        "ASSET_MANAGER": 'Asset Manager'
    }

    const loadData = async () => {
        customerRef?.current?.api?.showLoadingOverlay();
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization`, options)
            .then(res => {
                customerRef?.current?.api?.hideOverlay();
                setOrgList(res.json)
            })
            .catch((err) => {
                customerRef?.current?.api?.hideOverlay();
                console.log(err)
            })
    }

    const handleStatus = async (id, status) => {
        let updateStatus = status === true ? false : true;
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/organization/status/${id}/${updateStatus}`, options)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.json?.isactive === false) {
                        toast.error('User has been disabled!')
                    } else {
                        toast.success('User has been enabled!')
                    }
                    loadData()
                }
            })
            .catch(err => console.log(err))
    }

    const columnData = [
        {
            headerName: 'Company Name',
            field: 'companyName',
            filter: true,
            flex: 2.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }, 
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Website',
            field: 'website',
            filter: true,
            flex: 1.7,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'City',
            flex: 2,
            filter: true,
            valueGetter: (params) => {
                return `${params.data.state}, ${params.data.country}`
            },
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Organization Type',
            flex: 1.5,
            filter: true,
            valueGetter: (params) => {
                return defaultOrgType[params.data.orgType]
            },
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            width: 80,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-36'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" checked={params.data.isactive}
                                onChange={() => handleStatus(params.data.id, params.data.isactive)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'View',
            width: 60,
            cellRenderer: params => {
                return (
                    <Link to={`${process.env.PUBLIC_URL}/admin/view-customer/${params.data.id}`}>
                        <Eye className={`action-icon`} />
                    </Link>
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Edit',
            width: 60,
            cellRenderer: params => {
                return (
                    <Link to={`${process.env.PUBLIC_URL}/admin/edit-customer/${params.data.id}`}>
                        <Edit className={`edit-icon`} />
                    </Link>
                )
            },
            suppressMovable: true,
        }
    ]

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (addStatus === true) {
            loadData()
        }
    }, [addStatus])

    const handleClick = () => setOpen(true);

    const onGridReady = useCallback(async(params) => {
        loadData()
    })
    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex mtop-1">
                            <Breadcrumbs mainTitle="Organizations" parent="Organizations" title="List" />
                            <div className="text-right action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add Organization"}
                                </div>
                            </div>
                        </div>
                        <Card className=" newtable">
                            <CardBody className='data-tables responsive-view ptop-0'>
                            <div className='exchange-table'>
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={orgList}
                                            agRef={customerRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddCustomerModal value={open} setOpenModal={setOpen} setAdd={setAddStatus} />}
                </Row>
            </Container>
        </Fragment>
    );
};

export default CustomersList;