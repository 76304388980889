import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Nav, Tab, Row, Col, Tabs, Accordion, Card, FormGroup } from "react-bootstrap";
import { CardBody, Container } from "reactstrap";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import LayoutLoader from '../../LayoutLoader/LayoutLoader';
import { populateFiscalTwoYears, populateFiscalYear } from "../../../api/helper";
import ProductService from "./ProductService";
import Operations from "./Operations";

const BusinessInfo = () => {
    const [activeKey, setActiveKey] = useState(1);
    const [financialYear, setFinancialYear] = useState();
    const [startMonth, setStartMonth] = useState();
    const [fiscalYearArray, setFiscalYear] = useState(populateFiscalTwoYears());
    const [financialYearArray, setFinancialYearArray] = useState(populateFiscalYear());

    const getFinancialYearSetup = async () => {
        await fetchWrapper(`${API_URL}/orgattributes`)
            .then((res) => {
                console.log(res.json?.startMonth)
                setStartMonth(res.json?.startMonth)
            }, (err) => { console.log(err) })
    }

    useEffect(() => {
        getFinancialYearSetup()
    }, [activeKey])

    return (
        <Fragment>
            <Container fluid={true}>
                <LayoutLoader />
                <div className="d-flex">
                    <Breadcrumbs mainTitle={'Business Info'} />
                </div>
                <Card>
                    <CardBody>
                        <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                            <div className="d-flex company-profile justify-between">
                                <Nav variant="underlines">
                                    <Nav.Item className="mr-5px">
                                        <Nav.Link eventKey={'1'} className={activeKey == 1 ? 'application-active' : ''}>Products / Services</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'2'} className={activeKey == 2 ? 'application-active' : ''} >Operations</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <div className="d-flex justify-end ">
                                    <label>Financial Year</label>
                                    {
                                        startMonth === 'January' ?
                                            <select className="form-select ml-1 w-12" value={financialYear} onChange={(e) => setFinancialYear(e.target.value)}>
                                                <option value={''}>-Select Fiscal Year-</option>
                                                {
                                                    financialYearArray?.length > 0 && financialYearArray?.map((item) =>
                                                        (<option value={item}>{item}</option>))
                                                }
                                            </select> :
                                            <select className="form-select ml-1 w-12" value={financialYear} onChange={(e) => setFinancialYear(e.target.value)}>
                                                <option value={''}>-Select Fiscal Year-</option>
                                                {
                                                    fiscalYearArray?.length > 0 && fiscalYearArray?.map((item) =>
                                                        (<option value={item}>{item}</option>))
                                                }
                                            </select>
                                    }
                                </div>
                            </div>
                            <Tab.Content className="mtop-1">
                                <Tab.Pane eventKey={1}>
                                    <ProductService financialYear={financialYear} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={2}>
                                    <Operations financialYear={financialYear} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    )
}

export default BusinessInfo;