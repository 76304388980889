import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Footer, P } from '../../../AbstractElements';
import { useLocation } from 'react-router-dom';
import { FooterText } from '../../../Common/Constant';
import airoi from '../../../assets/images/logo/airoi.png';

const FooterClass = () => {
  const location = useLocation();
  return (
    <Fragment>
      <Footer attrFooter={{ className: `footer ${location.pathname === '/koho/page-layout/footer-dark' ? 'footer-dark' : location.pathname === '/koho/page-layout/footer-fixed' ? 'footer-fix' : ''}` }} >
        <Container fluid={true}>
          <Row>
            <Col xs="12" className="p-0 footer-right">
              <P attrPara={{ className: 'mb-0' }} >
                {FooterText}
                {/* To Powered by Smartitframe */}
              </P>
            </Col>
          </Row>
        </Container>
      </Footer>
    </Fragment >
  );
};

export default FooterClass;