import React, { Fragment, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { routes } from './Routes';
import Layout from '../Common/Layout/Layout';
import { useSelector } from 'react-redux'

const ProtectedRoute = ({ allowedRoles, children, moduleId }) => {
  const userType = useSelector(state => state?.user?.userDetails?.personaType);
  // const subscription = useSelector(state => state?.user?.subscriptionDetails);
  const roles = useSelector(state => state?.user?.userRoles)
  // Define roles for different modules
  let userRoleObject = {};
  userRoleObject = roles;
  console.log(userRoleObject[moduleId], allowedRoles)
  // Check if the user has access
  const hasAccess = userRoleObject[moduleId]?.some(role => allowedRoles?.includes(role));
  
  // Render children if access granted, otherwise redirect
  return hasAccess ? children : <Navigate to={`${process.env.PUBLIC_URL}/404-error`} />;
};

const LayoutRoutes = () => {
  const userType = useSelector(state => state?.user?.userDetails?.personaType);
  
  return (
    <Fragment>
      <Routes>
        {routes.map(({ path, Component, allowedRoles, moduleId }, i) => {
          const needsProtectedRoute = !['SYS_USER', 'SYS_ADMIN', 'ASM_USER', 'ASR_ADMIN', 'ASR_USER', 'ASM_ADMIN'].includes(userType);

          // Ensure allowedRoles and moduleId are defined
          // console.log("Allowed Roles:", allowedRoles, "Module ID:", moduleId);
          if ((allowedRoles && moduleId) && !['SYS_USER', 'SYS_ADMIN', 'ASM_USER', 'ASR_ADMIN', 'ASR_USER', 'ASM_ADMIN'].includes(userType)) {
            return (
              <Route element={<Layout />} key={i}>
                <Route
                  path={path}
                  element={
                    needsProtectedRoute ? (
                      <ProtectedRoute allowedRoles={allowedRoles} moduleId={moduleId}>
                        {Component}
                      </ProtectedRoute>
                    ) : (
                      { Component }
                    )
                  }
                />
              </Route>
            );
          } else {
            return (
              <Route element={<Layout />} key={i}>
                <Route
                  path={path}
                  element={Component}
                />
              </Route>
            )
          }

        })}
      </Routes>
    </Fragment>
  );
};

export default LayoutRoutes;